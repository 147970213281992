import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { MembershipInvite } from '../models/membership-invite'

import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})
export class MembershipInviteService {
  constructor(private api: ApiService) { }

  public findMine(options?: any): Observable<MembershipInvite[]> {
    return this.api.findAllAs('membership_invites/mine', options, MembershipInvite)
  }

  public dismiss(inviteId: string): Observable<MembershipInvite> {
    return this.api.create(`membership_invites/${inviteId}/dismiss`, { })
  }
}
