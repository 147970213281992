import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { PersonaListenee } from '@se-po/shared-data-access-models'

import { first, map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'
import { nginV2Headers } from '@se-po/shared-data-access-models'
@Injectable({
  providedIn: 'root',
})
export class PersonaListeneeService {
  constructor(private api: SeFeApiService) {
    // noop
  }

  public create(personaListenee: any): Observable<PersonaListenee> {
    return this.api.post<PersonaListenee>('persona_listenees', personaListenee, { headers: nginV2Headers }).pipe(
      first(),
      map(data => data.result)
    )
  }

  public destroy(id: number): Observable<PersonaListenee> {
    return this.api.delete(`persona_listenees/${id}`, { headers: nginV2Headers }).pipe(
      first(),
      map(data => data.result)
    )
  }

  public resendInvite(id: number): Observable<PersonaListenee> {
    return this.api.put<PersonaListenee>(`persona_listenees/${id}/resend_invite`, { }, { headers: nginV2Headers }).pipe(
      first(),
      map(data => data.result)
    )
  }

  public getExtended(params?: any): Observable<PersonaListenee[]> {
    return this.api.get<PersonaListenee[]>('persona_listenees/extended', { params, headers: nginV2Headers }).pipe(
      first(),
      map(data => data.result)
    )
  }
}
