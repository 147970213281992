import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  public transform(array: any, field: string): any[] {
    array.sort((a: any, b: any) => {
      if (a[field].toLowerCase() < b[field].toLowerCase()) {
        return -1
      } else if (a[field].toLowerCase() > b[field].toLowerCase()) {
        return 1
      } else {
        return 0
      }
    })
    return array
  }
}
