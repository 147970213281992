<div class="pl-pane__container--horizontal pl-pane--full-screen">

  <div class="pl-pane__container--horizontal pl-pane--full-screen">
    <app-ngin-iframe
      *ngIf="orgSiteDomain"
      [path]="surveyResultUrl"
      [domain]="orgSiteDomain">
    </app-ngin-iframe>
  </div>

</div>
