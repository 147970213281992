import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { STATE_OPTIONS } from '@se-po/shared-data-access-models'
import { BaseValueAccessorDirective } from '@se-po/shared-ui-form-directives'
import { SeFeSelectOption } from 'se-fe-form-field-select'
import { SeFeTranslationsLoader } from 'se-fe-translations'

@Component({
  selector: 'se-po-state-select',
  templateUrl: './state-select.component.html',
  styleUrls: ['./state-select.component.scss'],
  hostDirectives: [BaseValueAccessorDirective]
})
export class StateSelectComponent implements OnInit {
  @Input() public seFeDataLabel?: string

  public allStateOptions: SeFeSelectOption[] = []
  public loaded: boolean
  public ngControl = BaseValueAccessorDirective.ngControl(this) // get the forwarded control
  public stateOptions: SeFeSelectOption[] = []

  private _country: string

  constructor(private seFeTranslationsLoader: SeFeTranslationsLoader,
              private translateService: TranslateService
  ) { }

  public get country(): string {
    return this._country
  }

  public get form(): UntypedFormGroup | UntypedFormArray {
    return this.ngControl?.control?.parent
  }

  @Input() public set country(country: string) {
    this._country = country
    this.setOptions()
  }

  public ngOnInit(): void {
    this.loaded = false
    this.initTranslations()
  }

  public initTranslations(): void {
    this.seFeTranslationsLoader.load('shared/ui-state-select/assets/translations').then(loaded => {
      STATE_OPTIONS.forEach(stateOption => {
        this.allStateOptions.push(this.initStates(stateOption))
      })
      this.setOptions()
      this.loaded = true
    })
  }

  public initStates(stateOption: { country: string; states: string[] }): SeFeSelectOption {
    const translations = this.translateService.instant(
      stateOption.states.map(val => `SE_PO_UI_STATE_SELECT.STATE.${stateOption.country}.${val}`))
    const values = stateOption.states.map((state) => ({
        label: translations[`SE_PO_UI_STATE_SELECT.STATE.${stateOption.country}.${state}`],
        value: state
      })
    )
    return ({
      label: this.translateService.instant(`SE_PO_UI_STATE_SELECT.COUNTRY.${stateOption.country}`),
      value: values
    })
  }

  public setOptions(): void {
    if(this.allStateOptions.length === 0) return

    const index = STATE_OPTIONS.findIndex(stateOption => stateOption.country === this.country)
    if(!this.country || index < 0) {
      this.stateOptions = this.allStateOptions
      return
    }

    this.stateOptions = this.allStateOptions[index].value
    const stateIndex = this.stateOptions.findIndex(stateOption => stateOption.value === this.ngControl.value)
    if (stateIndex < 0) {
      this.form.get(this.ngControl.name.toString()).setValue('')
    }
  }
}
