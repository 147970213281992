import { MappableModel } from '@se-po/shared-data-access-models'

export interface Money {
  currency: string
  dollars: number
  cents: number
}

export class Money implements Money {
  constructor(amount: number) {
    if (!amount) return
    const intrRep = (amount / 100).toFixed(2).split('.')
    this.currency = '$'
    this.dollars = Number(intrRep[0]) || 0
    this.cents = Number(intrRep[1]) || 0
  }

  public get amountString(): string {
    const dollarString = this.dollars?.toString() || '0'
    const centsString = this.cents?.toString().padStart(2, '0') || '00'
    return `${this.currency}${dollarString}.${centsString}`
  }
}

export class Tag {
  constructor(
    public color?: string,
    public value?: string
  ) { }
}

export class FinancialSummary extends MappableModel {
  public id: number
  public amount: number
  public description: string
  public dueDate: string
  public href: string
  public sourceType: string
  public state: any[]
  public view_sale: boolean
  public tags: Tag[]
  public money: Money

  public constructor(data: any) {
    super(data)
    this.money = new Money(this.amount)
  }
}
