import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core'
import { Subscription } from 'rxjs'
import { SeFeModalButton, SeFeModalComponent } from 'se-fe-modal'
import { UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { MergePersonaModalSubmitter } from './merge-persona-modal-submit.service'
import { SeFeToastService } from 'se-fe-toast'

@Component({
  selector: 'se-po-merge-persona-modal',
  templateUrl: './merge-persona-modal.component.html',
  styleUrls: ['./merge-persona-modal.component.scss']
})
export class MergePersonaModalComponent implements OnDestroy {
  @Output() public personaMerged = new EventEmitter<UserProfile>()
  @ViewChild('modal') public modal: SeFeModalComponent
  @ViewChild('modal2') public modal2: SeFeModalComponent

  public description: string
  public keepProfile: UserProfile
  public mergeError = ''
  public mergeProfile: UserProfile
  public mergeAvatarUrl: string
  public options: SeFeModalButton[] = [
    {
      label: 'Cancel',
      action: () => this.modal.close()
    },
    {
      primary: true,
      label: 'Continue',
      action: () => this.openModal2()
    }
  ]
  public options2: SeFeModalButton[] = [
    {
      label: 'Cancel',
      action: () => this.modal2.close()
    },
    {
      primary: true,
      label: 'Merge',
      action: () => this.mergePersonas()
    }
  ]

  private _subscriptions: Subscription[] = []

  constructor(private submitter: MergePersonaModalSubmitter,
              private toastService: SeFeToastService
  ) {
    // noop
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
    this._subscriptions.length = 0
  }

  public open(keepProfile: UserProfile, mergeProfile: UserProfile, description: string) {
    this.mergeError = ''
    this.description = description
    this.keepProfile = keepProfile
    this.mergeProfile = mergeProfile
    this.mergeAvatarUrl = mergeProfile.persona.profile_images?.find(img => img.image_type === 'crop_icon')?.url
    this.modal.open(null)
  }

  public openModal2() {
    this.modal2.open(null)
  }

  public mergePersonas() {
    this._subscriptions.push(this.submitter.submit(this.keepProfile, this.mergeProfile, this.description)
      .subscribe({
        next: _ => {
          if (this.submitter.error) {
            this.mergeError = this.submitter.error
            this.modal2.close()
          } else {
            this.personaMerged.emit(this.mergeProfile)
            this.modal2.close()
            this.modal.close()
            this.toastService.success(
              `Successfully merged ${this.mergeProfile.persona.name} into ${this.keepProfile.persona.name}`)
          }
        }
      }))
  }

}
