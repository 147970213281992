import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { HouseholdProfilesService } from '@se-po/shared-data-access-services'
import { Observable } from 'rxjs'
import { CentralServiceResources as cs } from 'se-resource-types'
import { UserProfilesResult } from 'se-resource-types/dist/lib/CentralService/Households'

export const HouseholdsUserProfilesResolver: ResolveFn<cs.Households.UserProfilesResult> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: HouseholdProfilesService = inject(HouseholdProfilesService)
): Observable<UserProfilesResult> => service.mine()
