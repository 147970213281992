import { Observable, BehaviorSubject } from 'rxjs'

export abstract class Store<T> {
  public current: Observable<T>
  protected dummy: T
  protected subject: BehaviorSubject<T>

  constructor(value: T) {
    this.dummy = value
    this.subject = new BehaviorSubject<T>(this.dummy)
    this.current = this.subject.asObservable()
  }

  public setCurrent(next: T): void {
    this.subject.next(next)
  }

  public reset() {
    this.subject.next(this.dummy)
  }

}
