<div class="persona-info">

  <div role="content" class="persona-info-content">
    <div *ngIf="!ready">
      <se-fe-spinner
        seFeDataSize="950"
        [seFeDataInverted]="false"
        [seFeDataCenter]="true"
      ></se-fe-spinner>
    </div>
    <form
      *ngIf="ready"
      [formGroup]="form"
      novalidate>

      <section>
        <div class="form-field" *ngIf="isEnabled('name')">
          <se-po-name
            [seFeDataLabel]="labelName"
            formControlName="name"
            enablePreferred="true"
            enableSuffix="true">
          </se-po-name>
        </div>

        <div class="form-field fitted-field" *ngIf="isEnabled('gender')">
          <se-po-gender-select
            formControlName="gender"
            [seFeDataLabel]="labelGender"
          ></se-po-gender-select>
        </div>

        <div class="form-field fitted-field" *ngIf="isEnabled('dateOfBirth')">
          <se-po-dob-select
            formControlName="dateOfBirth"
            [seFeDataLabel]="labelDateOfBirth"
          ></se-po-dob-select>
          <se-fe-form-control-help *ngIf="accountAgeRequired && showDateOfBirthLearnMore">
            <button type="button"
              class="popover-link"
              seFePopoverTrigger="dateOfBirthLearnMore"
              seFeLink>
              Why we need this
            </button>
            <se-fe-popover seFePopoverName="dateOfBirthLearnMore">
              <p>To comply with the Children's Online Privacy Policy Act,
                you must be at least 13 years old to create a SportsEngine account.</p>
              <p>If you are a parent/guardian signing up your child, add your name and
                date of birth here first. You'll have the opportunity to create your child's
                profile later.
              </p>
            </se-fe-popover>
          </se-fe-form-control-help>
        </div>

        <div class="form-field fitted-field" *ngIf="isEnabled('gradYear')">
          <se-po-graduation-select
            formControlName="gradYear"
            [seFeDataLabel]="labelGradYear"
          ></se-po-graduation-select>
        </div>

      </section>
    </form>
  </div>
</div>
