import { Component, EventEmitter, Output, ViewChild } from '@angular/core'
import { SeFeModalButton, SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { SeFeToastService } from 'se-fe-toast'
import { UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { RemovePersonaModalSubmitter } from './remove-persona-modal-submit.service'

@Component({
  selector: 'se-po-remove-persona-modal',
  templateUrl: './remove-persona-modal.component.html',
  styleUrls: ['./remove-persona-modal.component.scss'],
})
export class RemovePersonaModalComponent {
  @Output() public personaRemoved = new EventEmitter<UserProfile>()
  @ViewChild('removePersonaFooter') public footer: SeFeModalFooterComponent
  @ViewChild('removePersonaModal') public modal: SeFeModalComponent
  public profile: UserProfile
  public options: SeFeModalButton[] = [
    {
      cancel: true,
      label: 'Nevermind',
      action: () => this.cancel()
    },
    {
      destructive: true,
      label: 'Yes, remove',
      action: () => this.removePersona(),
      loadingLabel: 'Saving',
    }
  ]

  constructor(
    protected submitter: RemovePersonaModalSubmitter,
    private toastService: SeFeToastService
  ) { }

  public open(profile: UserProfile, trigger?: any): void {
    this.submitter.error = null
    this.profile = { ...profile }
    this.modal.open(trigger || null)
  }

  private removePersona(): void {
    this.submitter.submit(this.profile).subscribe(_ => {
      if (this.submitter.error) {
        this.footer.cancelSpinner()
      } else {
        this.cancel()
        this.personaRemoved.emit(this.profile)
        this.toastService.success('Profile Removed')
      }
    })
  }

  private cancel(): void {
    this.footer.cancelSpinner()
    this.modal.close()
  }

}
