import { Component } from '@angular/core'

@Component({
  selector: 'app-ngin-order',
  templateUrl: './ngin-order.component.html'
})
export class NginOrderComponent {

  constructor() {
    // noop
   }

}
