import { Injectable } from '@angular/core'
import { ProfileImageUpdateParams } from '@se-po/shared-data-access-models'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'

import { CentralServiceResources as cs } from 'se-resource-types'

@Injectable({
  providedIn: 'root'
})
  export class HouseholdProfileImageService {
    public baseEndpoint = 'v3/households/personas'

    constructor(
      private api: SeFeApiService
    ) {
      // noop
    }

    public save(personaId: string, payload: ProfileImageUpdateParams): Observable<cs.Profiles.Persona> {
      return this.api.put<cs.Profiles.Persona>(`${this.baseEndpoint}/${personaId}/profile_images`, payload).pipe(
        map(response => response.result))
    }
  }
