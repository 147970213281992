<form [formGroup]="form" *ngIf="form">
  <se-fe-form-control-label
    [seFeDataLabel]="seFeDataLabel"
    [seFeDataLabelFor]="labelFor"
    [seFeDataLabelHidden]="hiddenLabel">
  </se-fe-form-control-label>
  <se-fe-form-field-select
    [formControlName]="ngControl.name"
    [seFeDataOptions]="suffixOptions"
    [seFeDataComfy]="true"
    [seFeDataPlaceholder]="seFeDataPlaceholder">
  </se-fe-form-field-select>
</form>
