<se-fe-card>
  <se-fe-card-header seFeDataTitle="Contact Information" seFeDataHeavyBorder="true">
    <se-fe-button *ngIf="canEdit" seFeCardHeaderActions (click)="openModal()">
      Edit
    </se-fe-button>
  </se-fe-card-header>
  <se-fe-card-content>
    <div class="contact-section">
      <span class="subheading">Primary</span>
      <se-fe-chip-group class="contact-section-content">
        <se-fe-chip [seFeDataText]="primaryEmailDisplay" seFeDataIconLeft="envelope_filled"></se-fe-chip>
        <se-fe-chip [seFeDataText]="primaryPhoneDisplay" seFeDataIconLeft="phone_fill"></se-fe-chip>
      </se-fe-chip-group>
    </div>
    <div class="contact-section">
      <span class="subheading">Additional</span>
      <se-fe-chip-group class="contact-section-content">
        <se-fe-chip [seFeDataText]="secondaryEmailDisplay" seFeDataIconLeft="envelope_filled"></se-fe-chip>
        <se-fe-chip [seFeDataText]="secondaryPhoneDisplay" seFeDataIconLeft="phone_fill"></se-fe-chip>
      </se-fe-chip-group>
    </div>
    <div class="contact-section" *ngIf="address">
      <span class="subheading">Address</span>
      <div class="contact-section-content" style="display: flex; flex-direction: column;">
        <span>{{ address.street_1 }}</span>
        <span>{{ address.city + ', ' + address.state_or_province + ' ' + address.zip }}</span>
        <span>{{ address.country }}</span>
      </div>
    </div>
  </se-fe-card-content>
</se-fe-card>

<se-fe-modal #editModal (seFeClose)="closeModal()">

  <div seFeModalHeader>
    <se-fe-modal-header
      [seFeDataTitle]="'Edit Contact Information'">
    </se-fe-modal-header>
  </div>

  <div *ngIf="ready && form" seFeModalContent>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <se-fe-header class="form-header" seFeDataTitle="Primary" seFeDataSize="600"></se-fe-header>
      <se-fe-grid seFeGridOpts>
        <se-fe-grid-cell seFeGridOpts="1/1 1/2@tablet">
          <se-po-email #primaryEmailControl
            seFeDataLabel="Email"
            formControlName="primaryEmail">
          </se-po-email>
        </se-fe-grid-cell>
        <se-fe-grid-cell seFeGridOpts="1/1 1/2@tablet">
          <se-po-phone
            seFeDataLabel="Phone"
            formControlName="primaryPhone">
          </se-po-phone>
        </se-fe-grid-cell>
      </se-fe-grid>
      <se-fe-header class="form-header" seFeDataTitle="Secondary" seFeDataSize="600"></se-fe-header>
      <se-fe-grid seFeGridOpts>
        <se-fe-grid-cell seFeGridOpts="1/1 1/2@tablet">
          <se-po-email
            seFeDataLabel="Email"
            formControlName="secondaryEmail">
          </se-po-email>
        </se-fe-grid-cell>
        <se-fe-grid-cell seFeGridOpts="1/1 1/2@tablet">
          <se-po-phone
            seFeDataLabel="Phone"
            formControlName="secondaryPhone">
          </se-po-phone>
        </se-fe-grid-cell>
      </se-fe-grid>
      <se-fe-header class="form-header" seFeDataTitle="Address" seFeDataSize="600"></se-fe-header>
      <se-po-address formControlName="address">
      </se-po-address>
    </form>
  </div>

  <div seFeModalFooter class="se-fe-modal-footer">
    <se-fe-button seFeDataEmphasis="low" (click)="editModal.close()" class="left-button">
      Cancel
    </se-fe-button>
    <se-fe-button seFeDataEmphasis="high" (click)="submit()"
      [seFeDataIsLoading]="submitting" seFeDataLoadingLabel="saving">
      Save
    </se-fe-button>
  </div>

</se-fe-modal>

<se-fe-toast></se-fe-toast>
