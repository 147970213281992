import { AfterViewInit, Directive, forwardRef, Injector, Input, OnDestroy, OnInit } from '@angular/core'
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormGroup, NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NgControl,
  ValidationErrors,
  Validator } from '@angular/forms'
  import { Subscription } from 'rxjs'

/**
 * Implementation of the ControlValueAccessor for use in custom form group components.
 */
@Directive({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormGroupValueAccessorDirective),
    multi: true,
  }],
})

export abstract class FormGroupValueAccessorDirective implements ControlValueAccessor, OnInit, OnDestroy, Validator, AfterViewInit {
  @Input() public seFeDataLabel?: string
  public control: NgControl
  public loaded: boolean
  public subscriptions: Subscription[] = []
  public onChange: (value: any) => void
  public onTouched: (value: any) => void
  public abstract form: UntypedFormGroup

  constructor(protected injector: Injector) { }

  protected get formControl(): AbstractControl {
    return this.control?.control
  }

  public ngOnInit(): void {
    this.control = this.injector.get(NgControl)
    if (this.control !== null) {
      this.control.valueAccessor = this
    }
    this.loaded = false
    this.initForm()
  }

  public ngAfterViewInit(): void {
    this.bindForm()
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe())
    this.subscriptions.length = 0
  }

  public registerOnChange(fn: (val: any) => void): void {
    this.subscriptions.push(this.form.valueChanges.subscribe(fn))
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  // called by the parent form to set a value in the child control
  public writeValue(value: any): void {
    this.setForm(value)
  }

  public validate(control: AbstractControl): ValidationErrors {
    return this.form.valid ? null : { invalid: true }
  }

  public trimValue(controlName: string): void {
    const control = this.form.get(controlName)
    const val = control.value
    const trimmedVal = val.trim()
    if (trimmedVal !== val) {
      // Waiting for change detection so that it will notice that there's a change
      // between the untrimmed input and the new trimmed input and update accordingly
      setTimeout(() => {
        control.setValue(trimmedVal)
      })
    }
  }

  private bindForm(): void {
    // Monkey patching so that can propagate markAllAsTouched
    // down to the controls within a component implementing CVA
    // to validate upon submit
    // https://github.com/angular/angular/issues/10887
    const prevMarkAllAsTouched = this.formControl.markAllAsTouched
    this.formControl.markAllAsTouched = (...args: any) => {
      this.form.markAllAsTouched()
      prevMarkAllAsTouched.bind(this.formControl)(...args)
    }
    const prevMarkAsDirty = this.formControl.markAsDirty
    this.formControl.markAsDirty = (...args: any) => {
      Object.values(this.form.controls).forEach(control => control.markAsDirty())
      this.form.markAsDirty({ onlySelf: true })
      prevMarkAsDirty.bind(this.formControl)(...args)
    }
  }

  public abstract initForm(): void

  public abstract setForm(value: any): void
}
