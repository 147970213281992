import { CommonModule, DatePipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedDataAccessParsersModule } from '@se-po/shared-data-access-parsers'
import { SeFeAvatarModule } from 'se-fe-avatar'
import { SeFeHeaderModule } from 'se-fe-header'
import { SeFeLinkModule } from 'se-fe-link'
import { SeFeTranslationsModule } from 'se-fe-translations'
import { PersonaAvatarComponent } from './persona-avatar/persona-avatar.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeAvatarModule,
    SeFeHeaderModule,
    SeFeLinkModule,
    SharedDataAccessParsersModule,
    SeFeTranslationsModule
  ],
  declarations: [PersonaAvatarComponent],
  exports: [PersonaAvatarComponent],
  providers: [DatePipe]
})
export class SharedUiPersonaAvatarModule {}
