import { Event, Rsvp } from '../models'
export class RsvpView {
  public id: string
  public personaId: string
  public eventId: string
  public teamId: string
  public response: string
  public note: string
  public eventType: string
  public identity: string
  public imgUrl: string
  public actionLinks: any[]

  public constructor(
    public rsvp: Rsvp,
    public event: Event
  ) {
    //this is the foramt the id takes ater it has been returned with a response, adding it here for unique menu ids
    this.id = `${rsvp.event_id}_${rsvp.principal_id}_${rsvp.persona_id}`
    this.personaId = `${rsvp.persona_id}` // todo - standardize this as either string or number
    this.eventId = `${rsvp.event_id}`
    this.teamId = `${rsvp.principal_id}`
    this.response = rsvp.response
    this.note = rsvp.note
    this.eventType = event.event_type
  }
}
