<div class="edit-persona">
  <div role="content" class="child-info-content">
    <div *ngIf="!ready">
      <se-fe-spinner
        seFeDataSize="950"
        [seFeDataInverted]="false"
        [seFeDataCenter]="true"
      ></se-fe-spinner>
    </div>
    <form
      *ngIf="ready"
      [formGroup]="form"
      novalidate>
      <section class="info-section">
        <se-po-persona-info #personaInfo
          formControlName="personaInfo"
          [requiredFields]="requiredFields"
          [optionalFields]="optionalFields"
          [accountAgeRequired]="isSelfPersona"
          [labels]="labels"
        ></se-po-persona-info>
      </section>
      <section class="info-section" *ngIf="showContactInfo">
        <se-po-contact-info #contactInfo
          formControlName="contactInfo">
        </se-po-contact-info>
      </section>
    </form>
  </div>
</div>
