import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { AnalyticsService } from '@se-po/shared-data-access-services'
import { DateTime } from 'luxon'
import { forkJoin } from 'rxjs'
import { take } from 'rxjs/operators'
import { SeEnvironmentUrlService } from 'se-fe-auth-support'
import { SeFeToastService } from 'se-fe-toast'
import { ModalData, ModalService } from '../modals/modal/modal.service'
import { RsvpModalComponent } from '../modals/rsvp-modal/rsvp-modal.component'
import { Event, Persona, RsvpListItem } from '../models'
import { DayView, EventView } from '../view-models'
import { ApiService } from './api.service'
import { RsvpService } from './rsvp.service'
import { Store } from './store'

export type CalendarMineOptions = {
  date_time?: string
  include_favorites?: string
  past?: boolean
  page?: number
  per_page?: number
}

const DEFAULT_CALENDAR_OPTIONS: CalendarMineOptions = {
  include_favorites: '1',
  page: 1,
  per_page: 30,
}

@Injectable({
  providedIn: 'root'
})
export class CalendarService extends Store<Event[]>{

  public paginationMetadata: any
  public requestMetadata: any
  public pastPaginationMetadata: any
  public futurePaginationMetadata: any

  constructor(private api: ApiService,
    private translateService: TranslateService,
    private rsvpService: RsvpService,
    private modalService: ModalService,
    private toast: SeFeToastService,
    private analyticsService: AnalyticsService,
    private urlService: SeEnvironmentUrlService
    ) {
    super(null)
  }

  public get earliestPage(): boolean {
    if (!this.requestMetadata || !this.paginationMetadata) {
      return true
    }
    else if (this.requestMetadata.past && this.paginationMetadata.total_pages === 0) {
      return true
    }
    else if (this.requestMetadata.past && !this.paginationMetadata.last_page) {
      return false
    }
    else if (!this.requestMetadata?.past && this.pastPaginationMetadata?.total > 0) {
      return false
    }
    else if (!this.requestMetadata?.past && !this.paginationMetadata?.first_page) {
      return false
    } else {
      return true
    }
  }

  public get lastPage(): boolean {
    if (!this.requestMetadata || !this.paginationMetadata) {
      return true
    }
    else if (!this.requestMetadata.past && this.paginationMetadata.total_pages === 0) {
      return true
    }
    else if (!this.requestMetadata.past && !this.paginationMetadata.last_page) {
      return false
    }
    else if (this.requestMetadata.past && this.futurePaginationMetadata.total > 0) {
      return false
    }
    else if (this.requestMetadata.past && !this.paginationMetadata.first_page) {
      return false
    } else {
      return true
    }
  }

  public get pageOne(): boolean {
    return this.requestMetadata?.page === 1 && !this.requestMetadata?.past
  }

  public mine(opt: CalendarMineOptions = { }): void {
    const defaults = {
      date_time: this.startOfToday(),
      past: false
    }
    const params = { ...DEFAULT_CALENDAR_OPTIONS, ...defaults, ...opt }

    this.requestMetadata = params

    const pageDataRequestParams = {
      ...DEFAULT_CALENDAR_OPTIONS,
      ...defaults,
      per_page: 1
    }

    /*
     * The pagination metadata that comes back from the api is specific to past / not past calls. A call to get
     * past events only contains paging metadata for past events, similarly so for calls for future events. We need to
     * grab metadata for the future pages and past pages at least once to properly display paging controls,
     * here we do it on every call to accomodate events changing between pages.
     */
    forkJoin(
      {
        pastPageData: this.api.findAllAs<Event>(
          'v3/calendar/mine', { params: { ...pageDataRequestParams, past: true } }, Event).pipe(take(1)),
        futurePageData: this.api.findAllAs<Event>('v3/calendar/mine', { params: pageDataRequestParams }, Event).pipe(take(1)),
        result: this.api.findAllAs<Event>('v3/calendar/mine', { params }, Event).pipe(take(1))
      }
    ).subscribe(
      (data: any) => {
        if (data.pastPageData.metadata?.pagination) {
          this.pastPaginationMetadata = data.pastPageData.metadata?.pagination
        }
        if (data.futurePageData.metadata?.pagination) {
          this.futurePaginationMetadata = data.futurePageData.metadata?.pagination
        }
        this.setCurrent(data.result as Event[])
      }
    )
  }

  public minePast(opt: CalendarMineOptions = { }): void {
    this.mine({ ...DEFAULT_CALENDAR_OPTIONS, ...opt })
  }

  public previousPage(): void {
    const page = this.requestMetadata?.page || 1
    let params = {}

    if (!this.requestMetadata.past && this.paginationMetadata.first_page) {
      params = { past: true }
    }
    else if (!this.requestMetadata.past && !this.paginationMetadata.first_page) {
      params = { page: page as number - 1}
    }
    else if (this.requestMetadata.past && !this.paginationMetadata.last_page) {
      params = { past: true, page: page as number + 1}
    }

    this.mine(params)
  }

  public nextPage(): void {
    const page = this.requestMetadata?.page || 1
    let params = {}

    if (!this.requestMetadata.past && !this.paginationMetadata.last_page) {
      params = { page: page as number + 1}
    }
    else if (this.requestMetadata.past && this.paginationMetadata.first_page) {
      params = { past: false }
    }
    else if (this.requestMetadata.past && !this.paginationMetadata.first_page) {
      params = { past: true, page: page as number - 1}
    }

    this.mine(params)
  }

  public setCurrent(next: Event[]): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    if (next['metadata']?.pagination) {
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.paginationMetadata = next['metadata']?.pagination
    }
    this.subject.next(next)
  }


  /**
   * initDays takes events and personas and transforms them into the day model for consumption by app-event-card
   * @param events The events that will be passed and transformed into days.
   * @param personas The users personas to be used for rsvps.
   * @param includeEmptyToday If no events are scheduled for today,
   * the return value will include an empty state card that indicates today has no events.
   * Defaults to true.
   */
  public initDays(events: Event[], personas: Persona[], includeEmptyToday = true): DayView[] {
    let days = []
    events.forEach(event => {
      const view = new EventView(event, personas, this.translateService, this.urlService)
      view.rsvps.forEach(rsvp => this.buildRsvpLinks(rsvp, event))
      const day = view.shortDate
      let dayObj = days.find(d => d.day === day)
      const found = !!dayObj
      if (!dayObj) dayObj = { day, events: [] }
      dayObj.events.push(view)
      if (!found) days.push(dayObj)
    })
    if (includeEmptyToday) {
      days = this.initToday(days)
    }
    days = days.sort((a, b) => a.day > b.day ? 1 : -1)
    return days
  }

  private initToday(days: DayView[]): DayView[] {
    const today = DateTime.local().toISODate()
    if (this.pageOne && !days.find(d => d.day === today)) {
      days.push({
        day: today,
        events: [{
          id: -1,
          index: -.1,
          emptyMessage: this.translateService.instant('SCHEDULE.nothing_scheduled_today')
        } as any as EventView]
      })
    }
    return days
  }

  private buildRsvpLinks(rsvp: RsvpListItem, event: Event): void {
    const deleteLink = {
      text: this.translateService.instant('SCHEDULE.rsvp_delete'),
      action: () => {
        this.analyticsService.seEvent(`${event.cardEventType}.DeleteNote`, 8)
        this.rsvpService.deleteNote(rsvp).subscribe(
          res => {
            this.toast.success(
              this.translateService.instant('SCHEDULE.rsvp_success', {
                identity: rsvp.identity,
                response: rsvp.response
              })
            )
          },
          err => this.toast.error(
            this.translateService.instant('SCHEDULE.rsvp_error')
          )
        )
        this.buildRsvpLinks(rsvp, event)
      }
    }
    const editLink = {
      text: this.translateService.instant('SCHEDULE.rsvp_edit'),
      action: () => {
        if (rsvp.note) {
          this.analyticsService.seEvent(`${event.cardEventType}.EditNote`, 8)
        } else {
          this.analyticsService.seEvent(`${event.cardEventType}.AddNote`, 8)
        }

        const data: ModalData = {
          component: RsvpModalComponent,
          properties: { rsvp },
          options: {
            title: this.translateService.instant('SCHEDULE.rsvp_modal.header', { identity: rsvp.identity}),
            actions: [
              {
                action: () => this.modalService.close(),
                label: this.translateService.instant('SCHEDULE.rsvp_modal.cancel'),
                cancel: true
              },
              {
                action: () => {
                  this.modalService.component.onSubmit()
                  this.modalService.close()
                  this.analyticsService.seEvent(`${event.cardEventType}.SaveNote.RSVP.${rsvp.response}`, 8) // eslint-disable-line
                  this.buildRsvpLinks(rsvp, event)
                },
                label: this.translateService.instant('SCHEDULE.rsvp_modal.save'),
                primary: true
              }
            ]
          }
        }
        this.modalService.open(data)
      }
    }
    if (rsvp.note) {
      rsvp.actionLinks = [editLink, deleteLink]
    }
    else {
      editLink.text = this.translateService.instant('SCHEDULE.rsvp_add')
      rsvp.actionLinks = [editLink]
    }
  }

  private startOfToday(): string {
    const d = new Date()
    return (new Date(d.getFullYear(), d.getMonth(), d.getDate())).toISOString()
  }
}
