<div class="pl-pane__container--horizontal">

  <div *ngIf="loaded" class="pl-pane--collapse">
    <div *ngIf="!teams.length" class="pl-empty-state">
      <se-fe-empty-state seFeDataTitle="{{ 'MY_TEAMS.EMPTY.header' | translate }}">
        <se-fe-icon seFeEmptyStateIcon seFeDataName="question_thin" seFeDataSize="950" seFeDataColor="gray-900"></se-fe-icon>
        {{ 'MY_TEAMS.EMPTY.message' | translate }}
      </se-fe-empty-state>
      <se-fe-button seFeDataEmphasis="medium" (click)="back()">
        <span>{{ 'USER.back' | translate }}</span>
      </se-fe-button>
    </div>
    <div class="pl-pane--shrink pl-pane--condense-bottom">
      <div *ngIf="teams && teams.length">
        <div pl-grid="loose align-center">
          <div pl-grid-el pl-grid="1/1@phone">
            <se-fe-card id="myse-teams">
              <se-fe-card-header
                [seFeDataTitle]="'MY_TEAMS.title' | translate"
                [seFeDataHeavyBorder]="true">
              </se-fe-card-header>
              <se-fe-card-content>
                <se-fe-list>
                  <se-fe-list-item
                    *ngFor="let team of teams | orderBy:'originator_system'"
                    style="padding: 4px;">
                    <se-fe-header
                      [seFeDataSubtitle]="teamDetails[team.id]"
                      size="600">
                      <div seFeHeaderTitle>
                        <a seFeLink="low" [href]="team.nextPath">{{ team.name }}</a>
                      </div>
                      <div seFeHeaderTitleExtra class="se-list-item__subtitle">
                         {{ teamExtra[team.id] }}
                      </div>
                      <se-fe-avatar seFeHeaderVisual
                        seFeDataType="team"
                        [seFeDataSport]="team.sportIcon === 'other' ? undefined : team.sportIcon"
                        [seFeDataTeamColor]="'#' + team.primary_color"
                        [seFeDataImgUrl]="team.imgUrl"
                        seFeDataSize="300">
                      </se-fe-avatar>
                      <div seFeHeaderActions>
                        <se-fe-icon-button [seFeMenuTrigger]="team.actionLinks.name" seFeDataEmphasis="low">
                          <se-fe-icon
                            [seFeDataMargin]="false"
                            seFeDataName="more_verticle"
                            seFeDataSize="700"
                            seFeDataAriaLabel="team actions">
                          </se-fe-icon>
                        </se-fe-icon-button>
                        <se-fe-menu
                          [seFeDataOptions]="team.actionLinks">
                        </se-fe-menu>
                      </div>
                    </se-fe-header>
                  </se-fe-list-item>
                </se-fe-list>
                <div *ngIf="pageMetadata?.pagination && !pageMetadata.pagination.last_page">
                  <hr class="pl-divider">
                  <div class="pl-text--center">
                    <se-fe-button [seFeDataIsLoading]="loadingMore" seFeDataEmphasis="low" (click)="loadMore()">
                      <span>{{ 'MY_TEAMS.see_more' | translate }}</span>
                    </se-fe-button>
                  </div>
                </div>
              </se-fe-card-content>
            </se-fe-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<se-fe-modal #modal>
  <div seFeModalHeader>
    <se-fe-modal-header
      [seFeDataTitle]="modalTitle"
      (seFeButtonAction)="modal.close()">
    </se-fe-modal-header>
   </div>
   <div seFeModalContent>
    <app-teams-reminders
      *ngIf="modalOpen"
      [team]="selectedTeam">
    </app-teams-reminders>
  </div>
  <div seFeModalFooter>
    <se-fe-modal-footer #modalFooter
      [seFeDataFooterButtons]="options">
    </se-fe-modal-footer>
  </div>
</se-fe-modal>
