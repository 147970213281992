import { Component, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output } from '@angular/core'
import { ColumnSort } from '../../models/column-sort.interface'
const STATUS_LIST = ['asc', 'desc']

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'th[app-sort-column]',
  templateUrl: './sort-column.component.html',
  styleUrls: ['./sort-column.component.scss']
})
export class SortColumnComponent implements OnChanges {

  @Input('app-sort-column') public column: string
  @Input() public currentColumn: ColumnSort
  @Output() public currentColumnChange: EventEmitter<ColumnSort> = new EventEmitter<ColumnSort>()
  // This class pulls from platform styles
  @HostBinding('class.pl-sortable-column') public baseClass = true

  public status = 'none'
  public selected = false

  constructor() {
    // noop
  }

  // This class pulls from platform styles
  @HostBinding('class.pl-sortable-active') public get selectedClass(): boolean {
    return !!this.selected
  }
  @HostListener('click') public onClick(){
    this.changeStatus()
  }

  public ngOnChanges(): void {
    if (this.currentColumn && this.column !== this.currentColumn.column) {
      this.clearStatus()
    } else if(this.currentColumn && this.column === this.currentColumn.column) {
      this.selected = true
      this.status = this.currentColumn.sort
    }
  }

  private changeStatus(): void {
    this.selected = true
    const i = STATUS_LIST.findIndex(s => s === this.status)
    const nextIndex = (i + 1) % STATUS_LIST.length
    this.status = STATUS_LIST[nextIndex]
    this.currentColumnChange.emit({ column: this.column, sort: this.status })
  }

  private clearStatus(): void {
    this.status = 'none'
    this.selected = false
  }

}
