import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedUiEmailModule } from '@se-po/shared-ui-email'
import { SharedUiPhoneModule } from '@se-po/shared-ui-phone'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeLinkModule } from 'se-fe-link'
import { SeFePopoverModule } from 'se-fe-popover'
import { SeFeTranslationsModule } from 'se-fe-translations'
import { ContactInfoComponent } from './contact-info/contact-info.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SeFeFormFieldModule,
    SeFeLinkModule,
    SeFePopoverModule,
    SeFeTranslationsModule,
    SharedUiEmailModule,
    SharedUiPhoneModule
  ],
  declarations: [ContactInfoComponent],
  exports: [ContactInfoComponent],
})
export class SharedUiContactInfoModule {}
