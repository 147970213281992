import { DatePipe, TitleCasePipe } from '@angular/common'
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { SeFeMenuOptions } from 'se-fe-menu'
import { PersonaAccount, UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { Persona } from 'se-resource-types/dist/lib/CentralService/Profiles'

@Component({
  selector: 'se-po-profile-side-panel',
  templateUrl: './profile-side-panel.component.html',
  styleUrls: ['./profile-side-panel.component.scss'],
})
export class ProfileSidePanelComponent {
  @Input() public editableAvatar = false
  @Input() public headerLink: string
  @Input() public menuOptions: SeFeMenuOptions
  @Output() public closePanel = new EventEmitter<void>()
  @Output() public avatarClicked = new EventEmitter<void>()
  @Output() public showSidePanelChange = new EventEmitter<boolean>()

  @ViewChild('sidePanel', { read: ElementRef }) public sidePanel: ElementRef

  public persona: Persona
  public profileImageUrl = ''
  public profileSubtitle: string | string[] = []

  private _profile: UserProfile | PersonaAccount
  private _showSidePanel: boolean

  constructor(
    private datePipe: DatePipe,
    private titleCasePipe: TitleCasePipe
  ) {
    // noop
  }

  public get profile(): UserProfile | PersonaAccount {
    return this._profile
  }

  public get showSidePanel(): boolean {
    return this._showSidePanel
  }

  @Input() public set showSidePanel(showSidePanel: boolean) {
    this._showSidePanel = showSidePanel
    this.showSidePanelChange.emit(showSidePanel)
  }

  @Input() public set profile(profile: UserProfile | PersonaAccount) {
    this._profile = profile
    if (this.profile) {
      this.persona = 'user' in this.profile ? this.profile.user.self_persona : this.profile.persona
    }
    else {
      this.persona = null
    }

    this.setHeaderAttributes()
    this.updateSidePanel()
  }

  public close(): void {
    this.closePanel.emit()
  }

  public onAvatarClick(): void {
    this.avatarClicked.emit()
  }

  private updateSidePanel(): void {
    if (this.sidePanel) {
      this.sidePanel.nativeElement.scrollTop = 0
    }
  }

  private setHeaderAttributes(): void {
    if (!this.profile) return

    if (this.profile.relationship === 'self') {
      this.profileSubtitle = 'Account Owner'
    }
    else if ('user' in this.profile) {
      this.profileSubtitle = this.profile.user?.email_address?.address
    }
    else {
      this.profileSubtitle = [
        this.datePipe.transform(this.persona?.date_of_birth, 'MMM d, y'),
        this.getGraduation(this.persona?.graduation_year),
        this.persona?.gender === 'no_response' ? '' : this.titleCasePipe.transform(this.persona?.gender)
      ].filter(x => !!x)
    }
    const profileImages = this.persona.profile_images || []
    this.profileImageUrl = profileImages.find(i => i.image_type === 'crop')?.url
  }

  private getGraduation(graduationYear: string): string {
    if (!graduationYear) {
      return ''
    }
    else if (Number(graduationYear) < new Date().getFullYear()) {
      return `Graduated ${graduationYear}`
    }
    else {
      return `Graduates ${graduationYear}`
    }
  }
}
