import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'

import { SeFeCardModule } from 'se-fe-card'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeIconModule, seFeIconMoreVerticle } from 'se-fe-icon'
import { SeFeMenuModule } from 'se-fe-menu'

import { PipesModule } from '../pipes/pipes.module'

import { NavBarComponent } from './nav-bar/nav-bar.component'
import { NginIframeComponent } from './ngin-iframe/ngin-iframe.component'
import { PaginationComponent } from './pagination/pagination.component'
import { PaymentPlanComponent } from './payment-plan/payment-plan.component'
import { SortColumnComponent } from './sort-column/sort-column.component'
import { SpinnerComponent } from './spinner/spinner.component'
import { DateStampComponent } from './date-stamp/date-stamp.component'
import { SeFeAvatarModule } from 'se-fe-avatar'
@NgModule({
  declarations: [
    DateStampComponent,
    NavBarComponent,
    NginIframeComponent,
    PaginationComponent,
    PaymentPlanComponent,
    SortColumnComponent,
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    PipesModule,
    SeFeAvatarModule,
    SeFeCardModule,
    SeFeButtonModule,
    SeFeIconModule,
    SeFeIconModule.include([
      seFeIconMoreVerticle
    ]),
    SeFeMenuModule,
    TranslateModule
  ],
  exports: [
    DateStampComponent,
    NavBarComponent,
    NginIframeComponent,
    PaginationComponent,
    PaymentPlanComponent,
    SortColumnComponent,
    SpinnerComponent
  ],
})
export class ComponentsModule { }
