import { MappableModel } from '@se-po/shared-data-access-models'
import { DateTime } from 'luxon'
import { CommonResources as cr, NginResources as nr, UserServiceResources as us } from 'se-resource-types'

export interface PersonaOwner {
  id: number
}

export class Persona extends MappableModel implements us.Persona {
  public id: number
  public first_name: string
  public middle_name: string
  public nickname?: string
  public last_name: string
  public suffix: string
  public owner: PersonaOwner
  public owner_full_name: string
  public owner_mobile_phone?: nr.MobilePhone
  public owner_primary_email: string
  public owner_user?: us.PersonaUser
  public orphan_invite_id?: number
  public persona_type: string
  public profile_images: cr.ProfileImage[]
  public email_addresses: us.PersonaEmailAddress[]
  public phone_numbers: us.PhoneNumber[]
  public owner_phone_numbers: us.PhoneNumber[]
  public date_of_birth: string
  public gender: cr.Gender
  public guardian_listeners: any[]
  public guardian_users?: us.PersonaUser[]
  public addresses: us.Address[]
  public persona_listenee: false
  public citizenship_countries: string[]
  public country_of_birth?: string
  public disability?: string
  public ethnicities: us.Ethnicity[]
  public graduation_year?: string
  public military_status?: string
  public played_outside_us?: boolean
  public uri: string
  public type: string
  public user: us.PersonaUser
  public verified_information: us.VerifiedInformation[]

  // CALCULATED PROPERTIES

  public get name(): string {
    let name = this.first_name || ''
    if (this.middle_name) name += ` ${this.middle_name}`
    if (this.last_name) name += ` ${this.last_name}`
    if (this.suffix) name += ` ${this.suffix}`
    return name
  }

  public get firstLast(): string {
    let name = this.first_name || ''
    if (this.last_name) name += ` ${this.last_name}`
    return name
  }

  public get firstLastSuffix(): string {
    return [this.first_name, this.last_name, this.suffix]
      .filter(n => !!n)
      .join(' ')
  }

  public get fullName(): string {
    // for compatibility
    return this.name
  }

  public get isOwned(): boolean {
    return !!this.owner
  }

  public get isOrphan(): boolean {
    return this.persona_type === 'orphan'
  }

  public get cropImage(): string {
    const images = this.profile_images || []
    const croppedImage = images.find(i => i.image_type === 'crop_icon')
    return croppedImage && croppedImage.url ? croppedImage.url : ''
  }

  public get originalImage(): string {
    const images = this.profile_images || []
    const originalImage = images.find(i => i.image_type === 'original')
    return originalImage && originalImage.url ? originalImage.url : ''
  }

  public get email_address(): string {
    const emails = this.email_addresses || []
    const email = emails.find(address => address.is_primary) || emails[0]
    return email && email.address
  }

  public get phone_number(): string {
    return this.preferredPhone(this.phone_numbers) ||
           this.preferredPhone(this.owner_phone_numbers)
  }

  public get address(): us.Address {
    return [].concat(this.addresses || []).sort(a => a.id)[0] // assumes most recent is best
  }

  // Return `City State` handling for missing info
  public get city_state(): string {
    if (this.address) return [].concat(this.address.city || [], this.address.state_or_province || []).join(', ')
  }

  // Return `City State, 55555` handling for missing info
  public get city_state_zip(): string {
    if (this.address) return [].concat(this.city_state || [], this.address.zip || []).join(' ')
  }

  public get isSelf(): boolean {
    return this.type === 'self' || this.persona_type === 'self'
  }

  public get meetsCopaAgeRequirement(): boolean {
    if (!this.date_of_birth) return true
    const nowDate = DateTime.now()
    const dobDate = DateTime.fromISO(this.date_of_birth)
    return nowDate.diff(dobDate, 'years').toObject().years >= 13
  }

  // PUBLIC INSTANCE METHODS

  public ownedByUser(userId: number): boolean {
    return this.owner && this.owner.id === userId
  }

  // PRIVATE METHODS

  private preferredPhone(phones: us.PhoneNumber[]): string {
    if (!phones) return
    const phone = phones.find(p => p.phone_type === 'cellPhone') ||
                  phones.find(p => p.phone_type === null) ||
                  phones.find(p => p.phone_type === 'additionalPhone') ||
                  phones[0]
    return phone && phone.number
  }
}
