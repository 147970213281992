import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { EditPersonaComponent } from '@se-po/shared-ui-edit-persona'
import { SeFeModalButton, SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { SeFeToastService } from 'se-fe-toast'
import { CentralServiceResources as cs } from 'se-resource-types'
import { EditPersonaModalSubmitter } from './edit-persona-modal-submit.service'

@Component({
  selector: 'se-po-edit-persona-modal',
  templateUrl: './edit-persona-modal.component.html',
  styleUrls: ['./edit-persona-modal.component.scss'],
})
export class EditPersonaModalComponent {
  @Input() public showContactInfo = false
  @Output() public personaEdited = new EventEmitter<number>()
  @ViewChild('editInfo') public editInfo: EditPersonaComponent
  @ViewChild('editPersonaFooter') public footer: SeFeModalFooterComponent
  @ViewChild('editPersonaModal') public modal: SeFeModalComponent

  public profile: cs.Households.UserProfile
  public editPersonaButtons: SeFeModalButton[] = [
    {
      cancel: true,
      label: 'Cancel',
      action: () => this.cancel()
    },
    {
      primary: true,
      label: 'Save Profile',
      action: () => this.savePersona(),
      loadingLabel: 'Saving',
    }
  ]

  constructor(
    private submitter: EditPersonaModalSubmitter,
    private toastService: SeFeToastService
  ) { }

  public open(profile: cs.Households.UserProfile, trigger?: any): void {
    this.profile = { ...profile }
    this.modal.open(trigger || null)
  }

  public onClick(event: MouseEvent): void {
    if (this.modal.isOpen) {
      event.stopImmediatePropagation()
    }
  }

  private savePersona(): void {
    if (!this.editInfo.isValid()) {
      this.footer.cancelSpinner()
      return
    }

    this.submitter.submit(this.editInfo.value, this.profile.persona).subscribe(updatedPersona => {
      this.cancel()
      this.personaEdited.emit(updatedPersona.id)
      this.toastService.success('Profile Saved')
    })
  }

  private cancel(): void {
    this.footer.cancelSpinner()
    this.modal.close()
  }
}
