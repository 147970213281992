import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core'
import { PersonaEligibilityComponent } from '@se-po/se-my-sportsengine-ui-persona-eligibility'
import { UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'

@Component({
  selector: 'se-po-persona-eligibility-side-panel',
  templateUrl: './persona-eligibility-side-panel.component.html',
  styleUrls: ['./persona-eligibility-side-panel.component.scss']
})
export class PersonaEligibilitySidePanelComponent {
  @Input() public profile: UserProfile
  @Input() public eligibilityRuleSetId: string
  @Input() public organizationId: number
  @Output() public closePanel = new EventEmitter<void>()

  @ViewChild(PersonaEligibilityComponent) private personaEligibilityComponent: PersonaEligibilityComponent

  public showSidePanel: boolean

  public open() {
    if (this.showSidePanel) {
      this.personaEligibilityComponent.ngOnInit()
    }
    this.showSidePanel = true
  }

  public close(): void {
    this.showSidePanel = false
    this.closePanel.emit()
  }

}
