<div pl-grid-el pl-grid="1/1@phone">
  <h2 class="myse-dashboard__section-title" [textContent]="'DASHBOARD.TEAMS.title' | translate"></h2>
</div>

<div pl-grid-el pl-grid="1/1@phone">
  <!-- Skeleton -->
  <div *ngIf="!loaded"
    class="myse-dashboard-skeleton--section-card myse-dashboard__full-card se-card"></div>
  <!-- Empty State -->
  <se-fe-card *ngIf="loaded && !teams.length"
    class="myse-dashboard__full-card myse-dashboard__compact-card">
    <se-fe-card-content>
      <se-fe-empty-state seFeDataTitle="{{ 'DASHBOARD.TEAMS_EMPTY.title' | translate }}" seFeDataSize="500">
        {{ 'DASHBOARD.TEAMS_EMPTY.description' | translate }}
      </se-fe-empty-state>

    </se-fe-card-content>
  </se-fe-card>

  <se-fe-card *ngIf="loaded && teams.length"
    class="myse-dashboard__full-card js-team-list">
    <se-fe-card-content style="margin:0; padding: 0;">
      <se-fe-list>
        <se-fe-list-item
          *ngFor="let team of teams"
          class="ba-dashboard__list-item">
          <se-fe-header
            [seFeDataSubtitle]="team.org_details?.name ? team.org_details.name : '' "
            size="600">
            <a seFeHeaderTitle class="pl-link" [href]="team.url">{{ team.name }}</a>
            <div seFeHeaderVisual>
              <se-fe-avatar *ngIf="team.sportIcon"
                seFeDataType="team"
                [seFeDataSport]="team.sportIcon === 'other' ? undefined :team.sportIcon"
                seFeDataTeamColor="{{ '#' + team.primary_color }}"
                seFeDataImgUrl="{{ team.imgUrl }}"
                seFeDataSize="300">
              </se-fe-avatar>
            </div>
          </se-fe-header
          >
        </se-fe-list-item>

        <se-fe-list-footer se-data-centered="true">
          <span [textContent]="'DASHBOARD.TEAMS.display_message' | translate: { displayed: teams.length, total: teams.metadata.pagination.total }"></span>
          &nbsp;
          <a
            routerLink="/user/my-teams"
            class="se-list__more"
            (click)="fireAnalytics()"
            [textContent]="'DASHBOARD.TEAMS.display_link' | translate">
          </a>
        </se-fe-list-footer>

      </se-fe-list>
    </se-fe-card-content>
  </se-fe-card>
</div>
