import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { GENDER_OPTIONS } from '@se-po/shared-data-access-models'
import { BaseValueAccessorDirective } from '@se-po/shared-ui-form-directives'
import { SeFeSelectOption } from 'se-fe-form-field-select'
import { SeFeTranslationsLoader } from 'se-fe-translations'

@Component({
  selector: 'se-po-gender-select',
  templateUrl: './gender-select.component.html',
  styleUrls: ['./gender-select.component.scss'],
  hostDirectives: [BaseValueAccessorDirective]
})
export class GenderSelectComponent implements OnInit {
  @Input() public seFeDataLabel?: string

  public genderOptions: SeFeSelectOption[] = []
  public loaded = false
  public ngControl = BaseValueAccessorDirective.ngControl(this) // get the forwarded control

  constructor(private seFeTranslationsLoader: SeFeTranslationsLoader,
              private translateService: TranslateService
  ) { }

  public get form(): UntypedFormGroup | UntypedFormArray {
    return this.ngControl?.control?.parent
  }

  public get isRequired(): boolean {
    return this.ngControl?.control?.hasValidator(Validators.required)
  }

  public ngOnInit(): void {
    this.loaded = false
    this.initTranslations()
  }

  public initTranslations(): void {
    this.seFeTranslationsLoader.load('shared/ui-gender-select/assets/translations').then(_ => {
      GENDER_OPTIONS.forEach(val => {
        this.genderOptions.push({
          label: this.translateService.instant(`SE_PO_UI_GENDER_SELECT.GENDER.${val}`),
          value: val
        })
      })
      this.loaded = true
    })
  }
}
