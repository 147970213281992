import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { PublicOrganization, SiteMembership } from '@se-po/shared-data-access-models'
import { HouseholdProfilesService, OrganizationService, SiteMembershipService } from '@se-po/shared-data-access-services'
import { Observable, Subscription, of } from 'rxjs'
import { SeFeToastService } from 'se-fe-toast'

@Component({
  selector: 'se-po-organizations-page',
  templateUrl: './organizations-page.component.html',
  styleUrls: ['./organizations-page.component.scss'],
})
export class NewOrganizationsPageComponent implements OnInit, OnDestroy {
  public loaded = false
  public displayName: string
  public siteMemberships: SiteMembership[]
  public organizationIds: number[] = []
  public personaId: string
  public backParams
  private _subscriptions: Subscription[] = []

  constructor(
    private route: ActivatedRoute,
    private householdProfilesService: HouseholdProfilesService,
    private organizationService: OrganizationService,
    private siteMembershipService: SiteMembershipService,
    private toast: SeFeToastService
    ) {}

  public ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    this.siteMemberships = this.route.snapshot.data['siteMemberships']
    this.createOrgIdArray()
    this._subscriptions.push(this.getOrgsForSites().subscribe(organizations => {
      organizations.forEach(org => {
        const matchedItem = this.siteMemberships.find(item => item.organization_id === org.id)
        matchedItem.site_logo_graphic = org.logo
      })
    }))
    this.personaId = this.route.snapshot.params.personaId
    this.backParams = { id: this.personaId }
    this.getPersona()
    this.loaded = true
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
    this._subscriptions.length = 0
  }

  public getPersona(): void {
    this._subscriptions.push(this.householdProfilesService.find(this.personaId).subscribe( res =>
      this.displayName = `${res.persona.first_name}'s Organizations`
    ))
  }

  public handleRemove($event): void {
    const membershipToRemove = this.siteMemberships.find(membership => membership.id.toString() === $event)
    this._subscriptions.push(this.siteMembershipService.cancel(membershipToRemove).subscribe(
      _ => this.success(membershipToRemove),
      _ => this.fail(membershipToRemove)
    ))
  }

  public success(membership: SiteMembership) {
    this.siteMemberships = this.siteMemberships.filter(m => m.id !== membership.id)
    this.toast.success(`Your membership to ${membership.organization_name} has been canceled.`)
  }

  public fail(membership: SiteMembership) {
    this.toast.error(`Could not cancel your membership to ${membership.organization_name}.`)
  }

  public getOrgsForSites(): Observable<PublicOrganization[]> {
    if (!this.organizationIds.length) {
      return of([])
    }
    return this.organizationService.indexPublic(this.organizationIds)
  }

  private createOrgIdArray(): void {
    this.organizationIds = this.siteMemberships.map(membership => membership.organization_id)
  }
}
