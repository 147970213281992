import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core'
import { TeamService } from '@se-po/shared-data-access-services'
import { environment } from '@se-po/shared-environments'
import { DateTime } from 'luxon'
import { Team } from 'se-resource-types/dist/lib/TeamService'

@Component({
  selector: 'se-po-team-list',
  templateUrl: './team-list.component.html',
  styleUrls: ['./team-list.component.scss'],
})
export class TeamListComponent implements OnChanges {

  @Input() public personaId: string | number
  @Output() public hasResults: EventEmitter<boolean> = new EventEmitter()

  public listItems: any[]
  public loaded = false

  constructor(
    private teamService: TeamService
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    const { currentValue, firstChange, previousValue } = changes.personaId
    if (firstChange || currentValue !== previousValue) {
      this.setListItems()
    }
  }

  private async setListItems(): Promise<void> {
    this.loaded = false
    const teams = await this.getTeams()
    this.listItems = teams.filter((team) => this.filterTeam(team)).slice(0, 5).map((team: Team) => ({
      subtitle: this.getSubtitle(team),
      title: team.name,
      url: this.viewTeamUrl(team)
    }))
    this.loaded = true
    this.hasResults.emit(!!this.listItems.length)
  }

  private getSubtitle(team: any): string[] {
    if (!team.program) {
      return
    }

    const { start_date, end_date } = team.program
    const start = DateTime.fromISO(start_date).toFormat('MMM yyyy')
    const end = end_date ? DateTime.fromISO(end_date).toFormat('MMM yyyy') : 'No End Date'
    return [start, end]
  }

  private filterTeam(team: Team): boolean {
    return !!team.trigger_roster_personas.find((rosterPersona: any) =>
      Number(rosterPersona.persona.persona_id) === Number(this.personaId)
    )
  }

  private async getTeams(): Promise<Team[]> {
    if (!this.personaId) {
      return Promise.resolve([])
    }
    return this.teamService.mineWithProgramDetails({ per_page: 100 })
  }

  private viewTeamUrl(team: Team): string {
    if (team.originator_system === 'Ngin') {
      return `${environment.app}teams/team-instance-redirect/${team.originator_id}`
    }
    return `${environment.app}teams/${team.id}`
  }

}
