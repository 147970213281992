import { Directive, inject } from '@angular/core'
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms'

/**
 * Implementation of the ControlValueAccessor for use in simple custom form control components.
 * This does not include logic to write values to the native form control
 * because it is meant to be used when the custom control forwards the existing control.
 */
@Directive({
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: BaseValueAccessorDirective,
      multi: true,
    }
  ],
})
export class BaseValueAccessorDirective implements ControlValueAccessor {
  public onChange: (value: any) => void
  public onTouched: (value: any) => void

  static ngControl(component: any) {
    const componentName = component.constructor.name
    const errMsg = `${componentName} does not have an NgControl reference.`
    const ngControl = inject(NgControl, { self: true, optional: true })
    if (!ngControl) throw new Error(errMsg)
    return ngControl
  }

  /**
   * Registers a callback that triggers
   * when the native form control is updated
   */
  public registerOnChange(fn: (val: any) => void): void {
    this.onChange = fn
  }

  /**
   * Registers a callback that triggers
   * when the user interacts with the control
   */
  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  // called by the parent form to set a value in the child control
  public writeValue(value: any): void {
    // This directive is only for use when forwarding the existing form control,
    // so leaving this as a noop.
  }
}
