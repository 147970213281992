import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'

@Injectable({
  providedIn: 'root'
})
export class HouseholdGuardianshipsService {
  private endpoint = 'v3/households/guardianships'

  constructor(
    private api: SeFeApiService
  ) {
      // noop
  }

  public update(id: string | number, access: string): Observable<any> {
    return this.api.put(`${this.endpoint}/${id}`, { access } ).pipe(
      first(),
      map(response => response.result)
    )
  }

  public remove(id: string | number): Observable<any> {
    return this.api.delete(`${this.endpoint}/${id}`).pipe(
      first(),
      map(response => response)
    )
  }
}
