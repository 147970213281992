<div>
  <section *ngIf="!loaded" >
    <se-fe-spinner [seFeDataSize]="900" [seFeDataCenter]="true" [seFeDataInverted]="false"></se-fe-spinner>
  </section>
  <form *ngIf="loaded">
    <section class="pl-modal__content">
      <div pl-grid="loose">
        <div>
          <strong> {{ 'TEAM_REMINDERS.header' | translate }}</strong>
          <div class="pl-text--xsmall pl-text--light">
            {{ 'TEAM_REMINDERS.label' | translate }}
          </div>
        </div>
        <fieldset pl-grid="1/1@phone" *ngFor="let rosterPersona of teamPreferences.rosterPersonaList">
          <div pl-grid="tight">
            <div pl-grid-el pl-grid="1/1@phone">
              <legend class="pl-text--xsmall pl-text--light">
                {{ (teamPreferences.rosterPersonaList.length > 1 ? 'TEAM_REMINDERS.group_label' : '') | translate:{ name: rosterPersona.nickname } }}
              </legend>
            </div>
              <div pl-grid-el pl-grid="1/1@phone" *ngIf="teamPreferences.teamAlertPreferences">
              <div
                class="pl-option"
                *ngFor="let pref of teamPreferences.teamAlertPreferences[rosterPersona.persona.persona_id].result">
                <input
                  type="checkbox"
                  class="pl-option__input"
                  name="alertPreference_{{pref.id}}"
                  id="alertPreference_{{pref.id}}"
                  [(ngModel)]="pref.enabled"
                  [checked]="pref.enabled">
                <label for="alertPreference_{{pref.id}}" class="pl-option__label">
                  {{ pref.contact_preference.contact_preference_value }}
                </label>
              </div>
            </div>
          </div>
        </fieldset>
        <div pl-grid-el pl-grid="1/1@phone">
          <p class="pl-text--xsmall pl-text--light">
            {{ 'TEAM_REMINDERS.help' | translate }}
          </p>
        </div>
      </div>
    </section>
  </form>
</div>
