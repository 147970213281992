import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { NginV2ApiService } from './nginV2Api.service'

@Injectable({
  providedIn: 'root'
})
export class AlertPreferenceService {

  constructor(private api: NginV2ApiService) {
    //noop
  }

  public get(params: any): Observable<any> {
    return this.api.get('alert_preferences/', { params: {...params}}).pipe(take(1))
  }

  public put(pref: any): Observable<any> {
    return this.api.put(`alert_preferences/${String(pref.id)}`,
      pref
  )
  }
}
