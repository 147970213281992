import { Component, HostListener, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Site } from '@se-po/shared-data-access-models'
import { environment } from '@se-po/shared-environments'

@Component({
  selector: 'app-registration-detail',
  templateUrl: './registration-detail.component.html',
  styleUrls: ['./registration-detail.component.scss']
})
export class RegistrationDetailComponent implements OnInit {

  public orgSiteDomain: string
  public surveyResultUrl: string
  public surveyResultId: number
  public siteId: number

  constructor(private activatedRoute: ActivatedRoute,
    private router: Router) { }

  @HostListener('window:message', ['$event.data'])
  public onPostMessage(message: any) {
    const data = message || {}
    if (data.type === 'surveyResult:removed') {
      void this.router.navigate(['/user/registrations'])
    }
  }

  public ngOnInit(): void {
    this.surveyResultId = this.activatedRoute.snapshot.params.surveyResultId
    this.siteId = this.activatedRoute.snapshot.queryParams.siteId
    this.surveyResultUrl = `/survey_result/show_user/${this.surveyResultId}`
    const site = this.activatedRoute.snapshot.data.site
    this.setUrls(site)
  }

  private setUrls(site: Site): void {
    this.orgSiteDomain = environment.nginUserDashboard.replace('login', site.secure_subdomain)
  }
}
