import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SeMySportsengineFeatureChildGrantAccessModalModule } from '@se-po/se-my-sportsengine-feature-child-grant-access-modal'
import { SeMySportsengineFeatureParentGrantAccessModalModule } from '@se-po/se-my-sportsengine-feature-parent-grant-access-modal'
import { SharedUiEmailModule } from '@se-po/shared-ui-email'
import { SharedUiPersonaAvatarModule } from '@se-po/shared-ui-persona-avatar'
import { SeFeAlertModule } from 'se-fe-alert'
import { SeFeAvatarModule } from 'se-fe-avatar'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeCardModule } from 'se-fe-card'
import { SeFeChipModule } from 'se-fe-chip'
import { SeFeFormFieldOptionsModule } from 'se-fe-form-field-options'
import { SeFeHeaderModule } from 'se-fe-header'
import { SeFeIconModule, seFeIconInfoCircle, seFeIconMore, seFeIconPlus } from 'se-fe-icon'
import { SeFeListModule } from 'se-fe-list'
import { SeFeMenuModule } from 'se-fe-menu'
import { SeFeModalModule } from 'se-fe-modal'
import { SeFePopoverModule } from 'se-fe-popover'
import { SeFeSpinnerModule } from 'se-fe-spinner'
import { SeFeToastModule } from 'se-fe-toast'
import { SeFeTooltipModule } from 'se-fe-tooltip'
import { PermissionsCardComponent } from './permissions-card/permissions-card.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SeFeAlertModule,
    SeFeAvatarModule,
    SeFeCardModule,
    SeFeChipModule,
    SeFeButtonModule,
    SeFeFormFieldOptionsModule,
    SeFeHeaderModule,
    SeFeIconModule.include([
      seFeIconInfoCircle,
      seFeIconMore,
      seFeIconPlus
    ]),
    SeFeListModule,
    SeFeMenuModule,
    SeFeModalModule,
    SeFePopoverModule,
    SeFeSpinnerModule,
    SeFeToastModule,
    SeFeTooltipModule,
    SharedUiEmailModule,
    SharedUiPersonaAvatarModule,
    SeMySportsengineFeatureChildGrantAccessModalModule,
    SeMySportsengineFeatureParentGrantAccessModalModule
  ],
  declarations: [PermissionsCardComponent],
  exports: [PermissionsCardComponent]
})
export class SeMySportsengineFeaturePermissionsCardModule {}
