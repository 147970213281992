
<div class="rsvp-note-modal">
  <div>
    <label for="rsvp-note" class="pl-field__label">Note</label>
    <textarea id="rsvp-note"
      rows="4"
      [(ngModel)]="note"
      style="width: 100%;"
      placeholder="{{ 'SCHEDULE.rsvp_modal.text_placeholder' | translate }}">
    </textarea>
  </div>
  <br />
  <div>
    <label for="rsvp-select" class="pl-field__label">RSVP</label>
    <fieldset pl-grid-el pl-grid="1/1@phone">
      <div class="pl-detailed-option__group">
        <div class="pl-detailed-option">
          <input
            type="radio"
            id="yes_response"
            name="detailed-radio"
            class="pl-detailed-option__input"
            [(ngModel)]="selected"
            value="yes">
          <label for="yes_response" class="pl-detailed-option__label">
            <div class="pl-detailed-option__info">
              <p class="pl-detailed-option__heading" [textContent]="'SCHEDULE.rsvp_modal.options.yes' | translate"></p>
            </div>
          </label>
        </div>
        <div class="pl-detailed-option">
          <input
            type="radio"
            id="maybe_response"
            name="detailed-radio"
            class="pl-detailed-option__input"
            [(ngModel)]="selected"
            value="maybe">
          <label for="maybe_response" class="pl-detailed-option__label">
            <div class="pl-detailed-option__info">
              <p class="pl-detailed-option__heading" [textContent]="'SCHEDULE.rsvp_modal.options.maybe' | translate"></p>
            </div>
          </label>
        </div>
        <div class="pl-detailed-option">
          <input
            type="radio"
            id="no_response"
            name="detailed-radio"
            class="pl-detailed-option__input"
            [(ngModel)]="selected"
            value="no">
          <label for="no_response" class="pl-detailed-option__label">
            <div class="pl-detailed-option__info">
              <p class="pl-detailed-option__heading" [textContent]="'SCHEDULE.rsvp_modal.options.no' | translate"></p>
            </div>
          </label>
        </div>
      </div>
    </fieldset>
  </div>
</div>
