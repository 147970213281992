import { Injectable } from '@angular/core'
import { Site, nginV2Headers } from '@se-po/shared-data-access-models'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  public endpoint = 'sites'

  constructor(private api: SeFeApiService) {
    // noop
   }

  public find(id: string): Observable<Site> {
    return this.api.get<Site>(`${this.endpoint}/${id}`, { headers: nginV2Headers })
    .pipe(
      first(),
      map(data => data.result)
    )
  }

  public findAll(org_ids: string | number | number[]): Observable<Site[]> {
    const ids = typeof org_ids === 'object' ? org_ids : [org_ids]
    return this.api.get<Site[]>('sites', { params: { 'organization_id[]': ids }, headers: nginV2Headers })
      .pipe(
        first(),
        map(data => data.result)
      )
  }
}
