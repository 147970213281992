import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { HouseholdProfilesService } from './household-profiles.service'

@Injectable({
  providedIn: 'root'
})
export class GuardPersona implements CanActivate, CanActivateChild {
  constructor(
    private householdProfilesService: HouseholdProfilesService,
    private router: Router) { }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean|UrlTree> {
    const personaID = parseInt(route.params.personaId || route.parent.params.personaId, 10)
    return this.householdProfilesService.findMyProfiles().pipe(
      map(profiles => {
        const profile = profiles.find(p => p.persona.id === personaID)
        if (profile) {
          return true
        } else {
          return this.router.createUrlTree(['user', 'access_denied'])
        }
      })
    )
  }

  public canActivateChild(route: ActivatedRouteSnapshot,
                          state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(route, state)
  }
}
