<div class="persona-avatar" *ngIf="ready">
  <se-fe-header
    (click)="onChosen($event)"
    (keydown)="onKeydown($event)" tabindex="0"
    class="panel-trigger"
    [seFeDataTitle]="!headerLink && title"
    [seFeDataSubtitle]="!isGuardian && subtitle"
    [seFeDataLevel]="0"
    [seFeDataSize]="600">
    <se-fe-avatar
      seFeHeaderVisual
      seFeDataType="persona"
      [seFeDataIdentity]="title"
      [seFeDataImgUrl]="imageUrl"
      [seFeDataSize]="500">
    </se-fe-avatar>
    <!-- guardian subtitle is separated out so that email can be ellipsis truncated if it overflows in mobile -->
    <div seFeHeaderSubtitle *ngIf="isGuardian" class="persona-subtitle">
      {{ subtitle }}
    </div>
    <a seFeHeaderTitle *ngIf="headerLink" class="persona-avatar-link" [href]="headerLink">{{ title }}</a>
  </se-fe-header>
  <div *ngIf="menuOptions" (click)="onActionClick($event)" class="menu-trigger">
    <se-fe-icon-button [seFeMenuTrigger]="menuOptions.name">
      <se-fe-icon
        seFeDataName="more"
        seFeDataSize="700"
        seFeDataColor="gray-700"
        seFeDataAriaLabel="more">
      </se-fe-icon>
    </se-fe-icon-button>
    <se-fe-menu
      [seFeDataOptions]="menuOptions">
    </se-fe-menu>
  </div>
</div>
