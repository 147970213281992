import { Injectable } from '@angular/core'
import { environment } from '@se-po/shared-environments'
import { Observable } from 'rxjs'
import { SalePayment } from '../models'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private api: ApiService, private window: Window) {
    // noop
  }

  public adjustmentCounts(id: number): Observable<SalePayment[]> {
    return this.api.findAllAs(
      `payments/${id}/adjustment_counts.json`,
      {},
      SalePayment)
  }

  public makePayment(saleNumber: string): void {
    this.window.open(environment.sePayments + saleNumber)
  }
}
