import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SeFeDatepickerModule } from 'se-fe-datepicker'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeTranslationsModule } from 'se-fe-translations'
import { DobSelectComponent } from './dob-select/dob-select.component'

import { HttpClientModule } from '@angular/common/http'

@NgModule({
  declarations: [DobSelectComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SeFeDatepickerModule,
    SeFeFormFieldModule,
    SeFeTranslationsModule
  ],
  exports: [DobSelectComponent]
})
export class SharedUiDobSelectModule {}
