import { inject } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router'

export const credentialsRouteGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): UrlTree => {
    const router = inject(Router)
    const personFilters = next.parent.params.id
    const orgId = next.parent.params.orgId
    const urlTree = router.createUrlTree(['user', 'credentials'], { queryParams: { orgId, personFilters } })
    return urlTree
}

export const membershipsRouteGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): UrlTree => {
    const router = inject(Router)
    const personFilters = next.parent.params.id
    const urlTree = router.createUrlTree(['user', 'memberships'], { queryParams: { personFilters } })
    return urlTree
}

export const orgRouteGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): UrlTree => {
    const router = inject(Router)
    const personaId = next.parent.params.id
    const urlTree = router.createUrlTree(['user', 'organizations-page', personaId])
    return urlTree
}

export const profileRouteGuard: CanActivateFn = (
  next: ActivatedRouteSnapshot,
  state: RouterStateSnapshot): UrlTree => {
    const router = inject(Router)
    const personaId = next.params.id
    const navExtras = personaId ? { queryParams: { id: personaId } } : { }
    const urlTree = router.createUrlTree(['user', 'household'], navExtras)
    return urlTree
}
