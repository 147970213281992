import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { first, map, take, tap } from 'rxjs/operators'

import { Persona } from '../models'

import { Mapper } from './mapper.service'
import { NginV2ApiService } from './nginV2Api.service'
import { Store } from './store'
@Injectable({
  providedIn: 'root'
})
export class PersonaService extends Store<Persona[]>{

  constructor(private api: NginV2ApiService) {
    super([])
  }

  public findAll(params?: any, options?: any): Observable<Persona[]> {
    return this.api.findAllAs(
      'user_personas',
      {
        ...options,
        params
      },
      Persona)
  }

  /**
   * Getting guarded personas is a multiple second call.
   * getGuarded() returns cached guarded personas, if any,
   * else it gets them from server.
   * To reset the cache use resetGuarded().
   */
  public getGuarded(): Observable<Persona[]> {
    if (!this.subject?.value || this.subject.value.length === 0) {
      return this.resetGuarded()
    } else {
      return this.current.pipe(take(1))
    }

  }

  public resetGuarded(): Observable<Persona[]> {
    return this.api.findAllAs(
      'user_personas',
      { params: { include_guarded_personas: 1 }},
      Persona).pipe(
        map(personas => Mapper.asArray<Persona>((personas) || [], Persona)),
        tap(personas => this.setCurrent(personas)))
  }

  public savePersona(personaId: number, data: any): Observable<Persona> {
    return this.api.put(`user_personas/${personaId}`, data)
      .pipe(first())
      .pipe(map(response => Mapper.as<Persona>(response.result, Persona)))
  }
}
