// tslint:disable
export class Mapper {
  public static as<T>(
    data: any,
    klass: new(...args: any[]) => T
  ): T {
    const result = data || { }
    return new klass(result)
  }

  public static asArray<T>(
    data: any,
    klass: new(...args: any[]) => T
  ): T[] {
    const result: T[] = data || []
    return result.map(r => new klass(r))
  }
}
