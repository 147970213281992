import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, RouterStateSnapshot } from '@angular/router'

@Component({
  selector: 'app-invalid',
  templateUrl: './invalid.component.html',
  styleUrls: ['./invalid.component.scss']
})
export class InvalidComponent implements OnInit {
  public path: string

  constructor(private route: ActivatedRoute) {
      // noop
     }

  public ngOnInit(): void {
    this.path = this.route?.snapshot?.routeConfig?.path
  }

}
