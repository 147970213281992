import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { SeAuthGuardService } from 'se-fe-auth-support'
import {
  InvoiceResolver,
  MyOrganizationsResolver,
  MyTeamsResolver,
  OrderResolver,
  PersonaSelfResolver,
  SiteResolver
} from './resolvers'

import { CredentialTableComponent } from '@se-po/se-my-sportsengine-feature-credential-table'
import { HouseholdComponent } from '@se-po/se-my-sportsengine-feature-household'
import { MembershipTableComponent } from '@se-po/se-my-sportsengine-feature-membership-table'
import { NewOrganizationsPageComponent } from '@se-po/se-my-sportsengine-feature-organizations-page'
import { HouseholdsUserProfilesResolver, SiteMembershipResolver } from '@se-po/shared-data-access-resolvers'
import { GuardPersona } from '@se-po/shared-data-access-services'
import { AccessDeniedComponent } from './access-denied/access-denied.component'
import { AccountPageComponent } from './account/account-page/account-page.component'
import { BillDetailComponent } from './bills-page/bill-detail/bill-detail.component'
import { BillsListComponent } from './bills-page/bills-list.component'
import { DashboardComponent } from './dashboard/dashboard/dashboard.component'
import { DibsPageComponent } from './dibs-page/dibs-page.component'
import { GroupsPageComponent } from './groups-page/groups-page.component'
import { InvalidComponent } from './invalid/invalid.component'
import { MessagesPageComponent } from './messages-page/messages-page.component'
import { OrderHistoryComponent } from './order-history/order-history.component'
import { NginOrderComponent } from './order/ngin-order.component'
import { OrderDetailComponent } from './order/order-detail.component'
import { OrganizationsPageComponent } from './organizations-page/organizations-page.component'
import { RegistrationDetailComponent } from './registration/registration-detail.component'
import { RegistrationListComponent } from './registration/registration-list.component'
import { ScheduleComponent } from './schedule/schedule.component'
import { credentialsRouteGuard, membershipsRouteGuard, orgRouteGuard, profileRouteGuard } from './services'
import { TeamsPageComponent } from './teams-page/teams-page.component'
import { VideoStreamingComponent } from './video-streaming/video-streaming.component'
import { WelcomeComponent } from './welcome/welcome.component'

const routes: Routes = [
  { path: '', redirectTo: 'user', pathMatch: 'full' },
  {
    path: 'user/profile/:id',
    // redirect legacy profile page routes
    children: [
      {
        path: '',
        children: [],
        canActivate: [profileRouteGuard]
      },
      {
        path: 'orgs',
        children: [],
        canActivate: [orgRouteGuard]
      },
      {
        path: 'org-memberships/:orgId',
        children: [
          {
            path: '',
            children: [],
            canActivate: [membershipsRouteGuard]
          },
          {
            path: 'credentials',
            children: [],
            canActivate: [credentialsRouteGuard]
          }
        ],
      }
    ]
  },
  {
    path: 'user',
    canActivateChild: [SeAuthGuardService],
    resolve: {
      personaSelf: PersonaSelfResolver,
      userProfiles: HouseholdsUserProfilesResolver
    },
    children: [
      {
        path: '',
        component: DashboardComponent,
        data: {
          leftNavKey: 'Dashboard',
          title: 'Dashboard'
        }
      },
      {
        path: 'schedule',
        component: ScheduleComponent,
        data: {
          title: 'Schedule'
        }
      },
      {
        path: 'my-teams',
        component: TeamsPageComponent,
        data: {
          title: 'Teams'
        }
      },
      {
        path: 'groups',
        component: GroupsPageComponent,
        data: {
          title: 'Groups'
        }
      },
      {
        path: 'bills',
        component: BillsListComponent,
        data: {
          title: 'Bills'
        }
      },
      {
        path: 'bills/:saleNumber',
        component: BillDetailComponent,
        resolve: { invoice: InvoiceResolver },
        data: {
          title: 'Bills',
          depth3: 'Detail'
        }
      },
      {
        path: 'registrations',
        component: RegistrationListComponent,
        data: {
          title: 'Registrations'
        }
      },
      {
        path: 'registrations/:surveyResultId',
        resolve: { site: SiteResolver },
        component: RegistrationDetailComponent,
        data: {
          title: 'Registration',
          depth3: 'Detail',
          leftNavItem: 'Registrations'
        }
      },
      {
        path: 'orders',
        component: NginOrderComponent,
        data: {
          title: 'Orders',
          leftNavItem: 'Registrations'
        }
      },
      {
        path: 'orders/:orderId',
        component: OrderDetailComponent,
        resolve: { order: OrderResolver },
        data: {
          title: 'Sale',
          depth3: 'Detail',
          leftNavItem: 'Registrations'
        }
      },
      {
        path: 'account',
        component: AccountPageComponent,
        loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
      },
      {
        path: 'dibs',
        component: DibsPageComponent,
        data: {
          title: 'Dibs'
        }
      },
      {
        path: 'messages',
        component: MessagesPageComponent,
        data: {
          title: 'Messages',
          hideLeftNav: true
        }
      },
      {
        path: 'messages/:messageId',
        component: MessagesPageComponent,
        data: {
          title: 'Messages',
          hideLeftNav: true
        }
      },
      {
        path: 'teams',
        redirectTo: 'my-teams'
      },
      {
        path: 'teams-redirect',
        redirectTo: 'my-teams'
      },
      {
        path: 'legacy-teams',
        redirectTo: 'my-teams'
      },
      {
        path: 'access_denied',
        component: AccessDeniedComponent
      },
      {
        path: 'invalid_guardian',
        component: InvalidComponent
      },
      {
        path: 'invalid_contact',
        component: InvalidComponent
      },
      {
        path: 'order-history',
        component: OrderHistoryComponent,
      },
      {
        path: 'video_streaming',
        component: VideoStreamingComponent
      },
      {
        path: 'welcome',
        component: WelcomeComponent,
        resolve: {
          orgs: MyOrganizationsResolver,
          teams: MyTeamsResolver
        },
        data: {
          title: 'Welcome',
          hideLeftNav: true
        }
      },
      {
        path: 'organizations',
        component: OrganizationsPageComponent,
        data: {
          title: 'Organizations',
          hideLeftNav: true
        }
      },
      {
        path: 'profiles',
        redirectTo: 'household'
      },
      {
        path: 'household',
        component: HouseholdComponent,
        data: {
          title: 'Household'
        }
      },
      {
        path: 'organizations-page/:personaId',
        canActivate: [GuardPersona],
        component: NewOrganizationsPageComponent,
        resolve: { siteMemberships: SiteMembershipResolver }
      },
      {
        path: 'memberships',
        component: MembershipTableComponent
      },
      {
        path: 'credentials',
        component: CredentialTableComponent
      }
    ]
  },
  { path: '**', redirectTo: 'user', pathMatch: 'full' }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
