import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { ApiService, Store } from '.'
import { FinancialSummary, Sale, SaleActivity } from '../models'


@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends Store<Sale[]>{
  constructor(private api: ApiService) {
    super(null)
  }

  public find(params: any, options?: any): void {
    this.mine(params, options).pipe(take(1)).subscribe(
      (data: Sale[]) => {
        this.setCurrent(data)
      }
    )
  }

  public get(saleNumber: string): Observable<Sale>{
    return this.api.findAs(
      `invoices/${saleNumber}.json`,
      Sale)
  }

  public significantActivity(id: number): Observable<SaleActivity[]> {
    return this.api.findAllAs(
      `invoices/${id}/activity`,
      { params: { view_mode: 'user', significance: 'high' }},
      SaleActivity)
  }

  public financialSummary(params: any, options?: any): Observable<FinancialSummary[]> {
    return this.api.findAllAs(
      'financial_summary/mine',
      { ...options, ...params }, FinancialSummary)
  }

  private mine(params: any, options?: any): Observable<Sale[]> {
    const defaults = { page: 1, per_page: 25 }
    return this.api.findAllAs(
      'invoices',
      {
        ...options,
        params: {...defaults, ...params}
      },
      Sale)
  }
}
