import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeFormFieldSelectModule } from 'se-fe-form-field-select'
import { SeFeFormServiceModule } from 'se-fe-form-service'
import { SuffixSelectComponent } from './suffix-select/suffix-select.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SeFeFormServiceModule,
    SeFeFormFieldModule,
    SeFeFormFieldSelectModule
  ],
  declarations: [
    SuffixSelectComponent
  ],
  exports: [
    SuffixSelectComponent
  ],
})
export class SharedUiSuffixSelectModule {}
