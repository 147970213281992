<div pl-grid="1/1@phone">
  <div pl-grid="tight fixed align-left">
    <div pl-grid-el pl-grid="1/1@phone" *ngIf="showMobileCallout">
      <se-fe-card class="myse-dashboard__full-card myse-mobile-callout">
        <se-fe-card-content>
          <button (click)="hideMobile()" class="myse-mobile-callout__close" [attr.aria-label]="'DASHBOARD.MOBILE_CALLOUT.button_aria_label' | translate">
            <se-fe-icon
              seFeDataName="remove"
              seFeDataSize="500"
              seFeDataColor="primary">
            </se-fe-icon>
          </button>
          <h3 class="myse-mobile-callout__title" [textContent]="'DASHBOARD.MOBILE_CALLOUT.title' | translate"></h3>
          <p class="myse-mobile-callout__description" [textContent]="'DASHBOARD.MOBILE_CALLOUT.description' | translate"></p>
          <div class="myse-mobile-callout__links">
            <a
              [href]="itunesUrl"
              (click)="hideMobile()"
              class="myse-mobile-callout__itunes-link"
              target="_blank"
              [textContent]="'DASHBOARD.MOBILE_CALLOUT.itunes' | translate">
            </a>
            <a
              [href]="playUrl"
              (click)="hideMobile()"
              class="myse-mobile-callout__play-link"
              target="_blank"
              [textContent]="'DASHBOARD.MOBILE_CALLOUT.play' | translate">
            </a>
          </div>
        </se-fe-card-content>
      </se-fe-card>
    </div>
  </div>
</div>
