<se-fe-card>
  <se-fe-card-header seFeDataTitle="Permissions" seFeDataHeavyBorder="true">
    <se-fe-button *ngIf="ready && canManageProfile" seFeCardHeaderActions (click)="addPermissions()">
      <se-fe-icon seFeDataName="plus" seFeDataSize="500" seFeDataColor="primary"></se-fe-icon>
      <span style="color: #00668f;"> Add</span>
    </se-fe-button>
  </se-fe-card-header>
  <se-fe-card-content *ngIf="!ready">
    <se-fe-spinner
      [seFeDataSize]="900"
      [seFeDataInverted]="false"
      [seFeDataCenter]="true">
    </se-fe-spinner>
  </se-fe-card-content>
  <se-fe-card-content *ngIf="ready">
    <se-fe-list class="guardian-list">
      <se-fe-list-item *ngFor="let accountItem of accountItems">
        <ng-container *ngTemplateOutlet="guardianListItem; context: { listItem: accountItem }"></ng-container>
      </se-fe-list-item>
      <se-fe-list-item *ngFor="let inviteItem of inviteItems">
        <ng-container *ngTemplateOutlet="guardianListItem; context: { listItem: inviteItem }"></ng-container>
      </se-fe-list-item>
    </se-fe-list>
  </se-fe-card-content>
</se-fe-card>
<se-fe-toast></se-fe-toast>

<ng-template #guardianListItem let-listItem="listItem">
  <se-fe-header [seFeDataTitle]="listItem.title" [seFeDataSubtitle]="canManageProfile && listItem.subtitle" seFeDataSize="600">
    <se-fe-avatar seFeHeaderVisual
      seFeDataType="persona"
      [seFeDataIdentity]="listItem.title"
      [seFeDataImgUrl]="listItem.imgUrl"
      seFeDataSize="300">
    </se-fe-avatar>
    <se-fe-icon-button seFeHeaderActions *ngIf="listItem.menuOptions"
      [seFeMenuTrigger]="listItem.menuOptions.name"
      seFeDataEmphasis="low"
      [seFeDataSnug]="true">
      <se-fe-icon seFeDataName="more" seFeDataSize="700"></se-fe-icon>
    </se-fe-icon-button>
  </se-fe-header>
  <div *ngIf="listItem.menuOptions"><se-fe-menu [seFeDataOptions]="listItem.menuOptions"></se-fe-menu></div>
</ng-template>

<se-po-add-guardian-modal #modal
  [guardians]="allGuardians"
  [profile]="profile"
  [profiles]="profiles"
  (listeneeAdded)="listeneeAdded()">
</se-po-add-guardian-modal>
