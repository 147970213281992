import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Sale, SaleActivity } from '../models'
import { NginV2ApiService } from './nginV2Api.service'

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private api: NginV2ApiService) {
    // noop
  }

  public index(user_id: number, sale_numbers: string): Observable<Sale[]> {
    return this.api.findAllAs(
      'commerce/orders',
      { params: { user_id, sale_numbers, per_page: 100 } },
      Sale
    )
  }

  public find(id: string): Observable<Sale> {
    return this.api.findAs(
      `commerce/orders/${id}`,
      Sale)
  }

  public significantActivity(id: number): Observable<SaleActivity[]> {
    return this.api.findAllAs(
      `commerce/orders/${id}/activity`,
      { params: { view_mode: 'user', significance: 'high' }},
      SaleActivity)
  }
}
