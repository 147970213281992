import { MappableModel } from '@se-po/shared-data-access-models'

export interface DibSession {
  id: number
  name: string
  status: string
}

export class DibSession extends MappableModel implements DibSession {
  public id: number
  public name: string
  public status: string
}
