import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { Team } from '../models'
import { TeamService } from '../services/team.service'

export const MyTeamsResolver: ResolveFn<Team[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: TeamService = inject(TeamService)
): Observable<Team[]> => service.findMine({ per_page: 30 })
