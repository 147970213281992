<se-fe-layout #layout>
  <!-- Adding the seFeSidePanelTrigger so that clicking in the se-bar does not close any page's side panel -->
  <se-bar seFeLayoutGlobalNav seFeSidePanelTrigger role="banner"></se-bar>

  <se-fe-left-nav
    *ngIf="persona?.full_name && navItems?.length"
    seFeLayoutLeftNav
    [seFeDataContextTitle]="persona.full_name"
    [seFeDataNavTree]="navItems"
    [seFeDataFooterLinks]="footerLinks"
    seFeDataTheme="light"
    (seFeNavigate)="handleNavigate($event)">
    <se-fe-avatar
      seFeLeftNavVisual
      seFeDataType="persona"
      [seFeDataIdentity]="persona.full_name"
      [seFeDataImgUrl]="personaCropImage"
      seFeDataSize="700">
    </se-fe-avatar>
  </se-fe-left-nav>

  <div seFeLayoutPageContent>
    <se-fe-toast></se-fe-toast>
    <div class="app-container" *ngIf="loaded">
      <router-outlet></router-outlet>
    </div>
    <app-spinner></app-spinner>
    <app-modal></app-modal>
  </div>
</se-fe-layout>
