import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { RecommendedAction } from '../models'
import { ApiService } from './api.service'

export class FinancialSummary {
  public state: string
  public description: string
  public href: string
  public sourceType: string

  public constructor(data?: any) {
    Object.assign(this, data || {})
  }
}

@Injectable({
  providedIn: 'root'
})
export class FinancialSummaryService {

  constructor(private api: ApiService,
    private translate: TranslateService) {
    // noop
  }

  public pastDueActions(): Observable<RecommendedAction[]> {
    return this.mine().pipe(map(
      (finSum: FinancialSummary[]) => finSum
          .filter(summary => summary.state?.indexOf('PastDue') >= 0)
          .map(p => ({
              title: this.translate.instant('RECOMMENDED_ACTIONS.PAYMENTS.title', { name: p.description }),
              action: this.translate.instant('RECOMMENDED_ACTIONS.PAYMENTS.action'),
              link: p.href,
              color: 'red',
              target: '_self'
            } as RecommendedAction))
    ))
  }

  public mine(): Observable<FinancialSummary[]> {
    return this.api.findAllAs(
      `financial_summary/mine`,
      {},
      FinancialSummary)
  }
}
