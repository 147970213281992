import { forwardRef, Injectable } from '@angular/core'
import { PersonaListeneeService, PersonaService } from '@se-po/shared-data-access-services'
import { catchError, first, Observable, of } from 'rxjs'
import { UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'

@Injectable({
  providedIn: 'root',
  useExisting: forwardRef(() => RemovePersonaModalSubmitService)
})
export abstract class RemovePersonaModalSubmitter {
  public abstract error: string
  public abstract submit(profile: UserProfile): Observable<any>
}

@Injectable({
  providedIn: 'root'
})
export class RemovePersonaModalSubmitService implements RemovePersonaModalSubmitter {
  public error: string

  constructor(
    private personaService: PersonaService,
    private personaListeneeService: PersonaListeneeService
  ) {
  }

  public submit(profile: UserProfile): Observable<any> {
    this.error = null
    return this.deleteProfile(profile)
      .pipe(
        first(),
        catchError(err => {
          this.error = err.error?.error?.messages?.pop() || 'This profile could not be removed. Retry?'
          return of(null)
        })
      )
  }

  private deleteProfile(profile: UserProfile): Observable<any> {
    // If the user is the profile owner, delete the profile.
    // Otherwise, remove the user's guardianship of the profile.
    if (profile.access === 'owner') {
      return this.personaService.destroy(profile.persona.id)
    } else {
      return this.personaListeneeService.destroy(profile.persona_listener_id)
    }
  }
}
