<div *ngIf="loaded && invites.length" pl-grid-el pl-grid="1/1@phone">
  <h2 class="myse-dashboard__section-title" [textContent]="'DASHBOARD.MEMBERSHIP_INVITES.title' | translate"></h2>
</div>
<div *ngIf="loaded && invites.length" pl-grid-el pl-grid="1/1@phone">
  <se-fe-card class="myse-dashboard__full-card">
    <se-fe-card-content style="margin: 0; padding: 0;">
      <se-fe-list>
        <se-fe-list-item
          *ngFor="let invite of invites">
          <se-fe-header size="600"
            [seFeDataTitle]="invite.persona.first_name + ', Accept ' + invite.affiliation_organization.name + ' Assignment'"
            [seFeDataSubtitle]="invite.sent_at_formatted">
            <button seFeHeaderActions
              class="pl-button--highlight"
              (click)="viewInviteDetails(invite)">
              {{ 'DASHBOARD.MEMBERSHIP_INVITES.button_details' | translate }}
            </button>
          </se-fe-header>
        </se-fe-list-item>
      </se-fe-list>
    </se-fe-card-content>
  </se-fe-card>
</div>
