import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { environment } from '@se-po/shared-environments'
import { SeFeAlertModule } from 'se-fe-alert'
import { SeFeImageUploaderModule } from 'se-fe-image-uploader'
import { SeFeModalModule } from 'se-fe-modal'
import { SeFeToastModule } from 'se-fe-toast'
import { PersonaImageModalComponent } from './persona-image-modal/persona-image-modal.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeAlertModule,
    SeFeImageUploaderModule,
    SeFeModalModule,
    SeFeToastModule,
    SeFeImageUploaderModule.forRoot(environment),
  ],
  declarations: [PersonaImageModalComponent],
  exports: [PersonaImageModalComponent],
})
export class SharedFeaturePersonaImageModalModule {}
