import { Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { Subject, Subscription } from 'rxjs'
import { debounceTime, distinctUntilChanged, filter, take } from 'rxjs/operators'
import { CentralServiceResources as cs } from 'se-resource-types'

import { Persona, Sale, SurveyResult } from '../models'
import { OrderService } from '../services/order.service'
import { PersonaService } from '../services/persona.service'
import { SpinnerService } from '../services/spinner.service'
import { SurveyResultService } from '../services/survey-result.service'

@Component({
  selector: 'app-registration-list',
  templateUrl: './registration-list.component.html'
})
export class RegistrationListComponent implements OnInit, OnDestroy {
  public filtered = false
  public loaded = false
  public loadingMore = false
  public pageMetadata: any = {}
  public personasById: { [key: number]: Persona } = {}
  public searchTerm: string
  public surveyResults: SurveyResult[]
  public myProfiles: cs.Households.UserProfilesResult

  private commerceOrders: Sale[] = []
  private searchTermChanged: Subject<string> = new Subject<string>()
  private surveyResultSubscription: Subscription

  constructor(private activatedRoute: ActivatedRoute,
    private orderService: OrderService,
    private surveyResultService: SurveyResultService,
    private personaService: PersonaService,
    private spinnerService: SpinnerService,
    private translate: TranslateService) {
    // noop
  }

  public search(term: string): void {
    this.searchTermChanged.next(term)
  }

  public loadMore(): void {
    const params: any = {}
    if (this.searchTerm && this.searchTerm.trim().length > 1) {
      params.search = this.searchTerm
    }
    this.loadingMore = true
    this.surveyResultService.findMore(params)
  }

  public ngOnDestroy(): void {
    this.searchTermChanged.complete()
    this.surveyResultSubscription.unsubscribe()
  }

  public ngOnInit(): void {
    this.spinnerService.start()
    this.myProfiles = this.activatedRoute.snapshot.data.userProfiles

    this.searchTermChanged.pipe(
      debounceTime(500),
      filter((term: string) => term.length !== 1),
      distinctUntilChanged()
    ).subscribe((val) => {
      this.filtered = !!val
      this.loadSearchResults(val)
    })
    // still need this for group/team personas - for now
    this.personaService.findAll({ extended_persona_types: 1 }).pipe(take(1))
      .subscribe((val) => {
        this.parsePersonas(val)
        this.setInitialLoadStatus()
      })

    this.surveyResultSubscription = this.surveyResultService.current.pipe(
      filter((result: SurveyResult[]) => !!result),
      distinctUntilChanged()
    ).subscribe((results) => {
      this.surveyResults = results
      this.fetchCommerceOrders()
      // eslint-disable-next-line @typescript-eslint/dot-notation
      this.pageMetadata = results ? this.surveyResults['metadata'] : {}
      this.loadingMore = false
      this.setInitialLoadStatus()
    })
    this.surveyResultService.findMine({})
  }

  public getPersonaNameById(id: number): string {
    const profile = this.myProfiles.profiles.find(p => p.persona.id === id)
    if (profile) return profile.persona.name || `${profile.persona.first_name} ${profile.persona.last_name}`

    // this will catch team/group personas not included in profiles above
    // @todo find a better solution for this
    const persona = this.personasById[id]
    return persona?.firstLast || ''
  }

  public setInitialLoadStatus(): void {
    this.loaded = Object.keys(this.personasById).length && !!this.surveyResults
    if (this.loaded) {
      this.spinnerService.stop()
    }
  }

  public buildRouterLinks(sr: SurveyResult): any {
    const links = {
      routerLink: `/user/registrations/${sr.id}`,
      routerLinkQueryParams: {} as any
    }

    if (sr.site_id) {
      links.routerLinkQueryParams.siteId = sr.site_id
    }

    return links
  }

  private loadSearchResults(term?: string): void {
    const params: any = {}
    if (term && term.length > 1) {
      params.search = term
    }
    this.surveyResultService.findMine(params)
  }

  private parsePersonas(personas: Persona[]): void {
    personas.forEach(p => {
      this.personasById[p.id] = p
    })
  }

  private fetchCommerceOrders(): void {
    if (!this.surveyResults.length) return

    const saleNumbers = this.surveyResults.map(sr => sr.order_reference?.sale_number).filter(Boolean)

    if (saleNumbers) {
      this.orderService.index(this.myProfiles.user.id, saleNumbers.join()).subscribe((orders) => {
        this.commerceOrders = orders
        this.buildActionLinks()
      })
    }
    else {
      this.buildActionLinks()
    }
  }

  private buildActionLinks(): void {
    if (!this.surveyResults) return

    this.surveyResults.forEach(sr => {
      if (sr.order_id) {
        sr.actionLinks = {
          text: this.translate.instant('REGISTRATIONS.order_details'),
          link: '/user/orders',
          params: { url: `/order/show_user/${sr.order_id}` }
        }
      }
      else if (this.showCommerceOrderLink(sr)) {
        sr.actionLinks = {
          text: this.translate.instant('REGISTRATIONS.order_details'),
          link: `/user/orders/${sr.order_reference.sale_number.toString()}` // eslint-disable-line
        }
      }
    })
  }

  private showCommerceOrderLink(surveyResult: SurveyResult): boolean {
    if (!surveyResult.order_reference?.sale_number) return false
    if (!this.commerceOrders) return false

    return this.commerceOrders.map(co => co.sale_number).includes(surveyResult.order_reference.sale_number)
  }
}
