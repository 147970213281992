import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { take } from 'rxjs/operators'
import { Sale, SaleActivity, SalePayment, StatusTagColors } from '../../models'
import { InvoiceService } from '../../services/invoice.service'
import { PaymentService } from '../../services/payment.service'

@Component({
  selector: 'app-bill-detail',
  templateUrl: './bill-detail.component.html',
  styleUrls: ['./bill-detail.component.scss']
})
export class BillDetailComponent implements OnInit {
  public bill: Sale
  public activity: SaleActivity[]
  public payments: SalePayment[]

  constructor(private activatedRoute: ActivatedRoute,
    private invoiceService: InvoiceService,
    private titleService: Title,
    private translate: TranslateService,
    private paymentService: PaymentService) {
      // noop
     }

  public ngOnInit(): void {
    this.bill = this.activatedRoute.snapshot.data.invoice

    this.titleService.setTitle(
      this.translate.instant(
        'ROUTE_TITLE.page_title_template',
        { title: this.translate.instant('SALE_DETAIL.HEADERS.sale', { sale_number: this.bill.sale_number }) }
      )
    )

    this.invoiceService.significantActivity(this.bill.id).pipe(take(1)).subscribe(
      (val: SaleActivity[]) => this.activity = val
    )

    this.paymentService.adjustmentCounts(this.bill.id).pipe(take(1)).subscribe(
      (val: SalePayment[]) => this.payments = val.sort((a,b) => a.due_date > b.due_date ? -1 : 1)
    )
  }

  public statusTagColor(order: any): string {
    return 'pl-tag--' && StatusTagColors[order.display_status]
  }

  public canBePaid(bill: Sale): boolean{
    return Number(bill.amount_outstanding) > 0
  }

  public makePayment(): void {
    this.paymentService.makePayment(this.bill.sale_number)
  }

}
