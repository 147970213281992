<se-fe-modal #addModal (seFeClose)="closeModal()">
    <div seFeModalHeader>
      <se-fe-modal-header seFeDataTitle="Grant Access">
      </se-fe-modal-header>
    </div>
    <div *ngIf="ready" seFeModalContent>
      <se-fe-alert
        *ngIf="formError || submitError || noInputError"
        style="margin-top: 12px;"
        seFeDataType="error"
        [seFeDismissible]="false">
        Invitation Not Sent
        <se-fe-alert-subheader *ngIf="formError">
          Access level needs to be selected before invitation can be sent.
        </se-fe-alert-subheader>
        <se-fe-alert-subheader *ngIf="submitError">
          Action can't be completed, please try again.
        </se-fe-alert-subheader>
        <se-fe-alert-subheader *ngIf="noInputError">
          Person needs to be selected before invitation can be sent.
        </se-fe-alert-subheader>
      </se-fe-alert>
      <div>
        Select who you would like {{ profile.user.self_persona.first_name }} to have permission to access:
      </div>
      <form [formGroup]="form" *ngIf="ready">
        <se-fe-list>
          <se-fe-list-item *ngIf="addableChildren.length">
            <div class="guardian-list-header">
              <se-fe-checkbox
                class="guardian-list-header"
                formControlName="selectAll"
                [seFeDataOptions]="checkboxOptions.selectAll">
              </se-fe-checkbox>
              <div class="guardian-access">
                Access Levels
                <button type="button" seFePopoverTrigger="parentGrantAccessPopover" class="guardian-popover">
                  <se-fe-icon
                    seFeDataName="info_circle"
                    seFeDataSize="500"
                    seFeDataColor="gray-700">
                  </se-fe-icon>
                </button>
                <se-fe-popover seFeDataName="parentGrantAccessPopover" [seFeDataFixedPosition]="true">
                  Learn more about&ensp;
                  <a
                    href="https://help.sportsengine.com/en/articles/9265109"
                    class="guardian-popover-link"
                    target="_blank">
                    access levels.
                  </a>
                </se-fe-popover>
              </div>
            </div>
          </se-fe-list-item>
          <se-fe-list-item *ngFor="let p of addableChildren">
            <div class="guardian-list-item">
              <div *ngIf="p.disabled" class="guardian-checkbox" [seFeTooltipTrigger]="p.id">
                <se-fe-checkbox [formControlName]="p.id.toString()" [seFeDataHiddenLabel]="true"
                  [seFeDataDisabled]="true" [seFeDataOptions]="checkboxOptions[p.id]"
                  style="margin-right: 12px;">
                </se-fe-checkbox>
                <se-po-persona-avatar class="disabled" [persona]="p.persona" headerSubtitle="">
                </se-po-persona-avatar>
                <se-fe-tooltip *ngIf="p.atGuardianLimit && !p.invitePending" [seFeDataName]="p.id">
                  {{ p.persona.first_name }} has reached the limit for guardianship access.
                </se-fe-tooltip>
                <se-fe-tooltip *ngIf="p.invitePending" [seFeDataName]="p.id">
                  This person has already been granted access to {{ p.persona.first_name }}
                </se-fe-tooltip>
                <div class="guardian-access">
                  <se-fe-chip [seFeDataText]="p.menuButtonText" [seFeDataSelected]="p.chipSelected" [seFeDataDisabled]="p.disabled"></se-fe-chip>
                </div>
              </div>
              <div *ngIf="!p.disabled" class="guardian-checkbox">
                <se-fe-checkbox [formControlName]="p.id.toString()" [seFeDataHiddenLabel]="true"
                  [seFeDataOptions]="checkboxOptions[p.id]"
                  style="margin-right: 12px;">
                </se-fe-checkbox>
                <se-po-persona-avatar [persona]="p.persona" headerSubtitle="">
                </se-po-persona-avatar>
                <div class="guardian-access">
                  <se-fe-chip [seFeDataText]="p.menuButtonText" [seFeDataSelected]="p.chipSelected" [seFeMenuTrigger]="p.permissionMenuOpts.name" [attr.aria-describedby]="'required-error' + p.id.toString()"></se-fe-chip>
                  <se-fe-form-control-errors
                    class="guardian-access-error"
                    [seFeDataId]="'required-error' + p.id.toString()"
                    [seFeDataMessages]="{ required: 'Please select an access level' }"
                    [seFeDataErrors]="getItemError(p.id)">
                  </se-fe-form-control-errors>
                </div>
              </div>
            </div>
          </se-fe-list-item>
        </se-fe-list>
      </form>
      <div *ngFor="let p of addableChildren">
        <se-fe-menu *ngIf="!p.disabled" [seFeDataOptions]="p.permissionMenuOpts"></se-fe-menu>
      </div>
    </div>
    <div seFeModalFooter>
      <se-fe-modal-footer #modalFooter [seFeDataFooterButtons]="modalButtons">
      </se-fe-modal-footer>
    </div>
  </se-fe-modal>
