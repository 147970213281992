import { Injectable } from '@angular/core'
import { nginV2Headers } from '@se-po/shared-data-access-models'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'
import { SurveyResult } from 'se-resource-types/dist/lib/Ngin'

@Injectable({
  providedIn: 'root'
})
export class SurveyResultService {

  private endpoint = 'survey_results'

  constructor(
    private api: SeFeApiService
  ) { }

  public findByPersonaId(personaId: string | number, perPage=100, params={}): Observable<SurveyResult[]> {
    return this.api.get<SurveyResult[]>(`${this.endpoint}?persona_id=${personaId}&per_page=${perPage}`, { params, headers: nginV2Headers })
      .pipe(map(response => response.result))
  }

}
