import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { Team } from '../models'
import { NginV2ApiService } from './nginV2Api.service'


const DEFAULT_PARAMS = {
  per_page: 10,
  page: 1,
  primary: true,
  originator_system: 'Ngin,TeamService,StatNgin',
  include_favorites: '1',
  with_retired: '1'
}
@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(private api: NginV2ApiService) {
    //noop
  }

  public findMine(params?: any, options?: any): Observable<Team[]> {
    return this.api.findAllAs(
      'v3/teams/mine',
      {
        ...options,
        params: {...DEFAULT_PARAMS, ...params},
      },
      Team)
  }

}
