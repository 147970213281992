import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedUiSuffixSelectModule } from '@se-po/shared-ui-suffix-select'
import { SeFeChipModule } from 'se-fe-chip'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeFormFieldTextModule } from 'se-fe-form-field-text'
import { SeFeIconModule, seFeIconPlusCircleOutline } from 'se-fe-icon'
import { SeFeTranslationsModule } from 'se-fe-translations'
import { NameComponent } from './name/name.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SeFeChipModule,
    SeFeFormFieldModule,
    SeFeFormFieldTextModule,
    SeFeIconModule.include([
      seFeIconPlusCircleOutline
    ]),
    SeFeTranslationsModule,
    SharedUiSuffixSelectModule
  ],
  declarations: [NameComponent],
  exports: [NameComponent]
})
export class SharedUiNameModule {}
