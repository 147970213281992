import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core'
import { FormBuilder, UntypedFormGroup } from '@angular/forms'
import { DobParser } from '@se-po/shared-data-access-parsers'
import { ContactInfoComponent } from '@se-po/shared-ui-contact-info'
import { PersonaInfoComponent } from '@se-po/shared-ui-persona-info'
import { SeFeFormService } from 'se-fe-form-service'
import { UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { EmailAddress, PhoneNumber } from 'se-resource-types/dist/lib/CentralService/Profiles'

@Component({
  selector: 'se-po-edit-persona',
  templateUrl: './edit-persona.component.html',
  styleUrls: ['./edit-persona.component.scss'],
})
export class EditPersonaComponent implements OnInit, OnChanges {
  @Input() public profile: UserProfile
  @Input() public showContactInfo: boolean
  @ViewChild('contactInfo') public contactInfo: ContactInfoComponent
  @ViewChild('personaInfo') public personaInfo: PersonaInfoComponent
  public ready = false
  public form: UntypedFormGroup
  public requiredFields: string[]
  public optionalFields: string[]
  public labels: any = {}
  private _email: EmailAddress
  private _phone: PhoneNumber

  constructor(
    private fb: FormBuilder,
    private formService: SeFeFormService
  ) { }

  public get isSelfPersona(): boolean {
    return this.profile?.relationship === 'self'
  }

  public get value(): any {
    let contactInfo = {}
    if (this.form.value.contactInfo) {
      const email = {...this._email, address: this.form.value.contactInfo.email }
      // eslint-disable-next-line id-blacklist
      const phone = { phone_type: 'cellPhone', ...this._phone, number: this.form.value.contactInfo.phone }
      contactInfo = { email, phone }
    }
    const info = { ...this.form.value.personaInfo, ...contactInfo }
    info.dateOfBirth = DobParser.mmToIso(info.dateOfBirth)
    return info
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const { currentValue, firstChange, previousValue } = changes.profile
    if (firstChange || currentValue !== previousValue) {
      this.setForm()
    }
  }

  public isValid(): boolean {
    if (!this.personaInfo?.form) return false

    return this.formService.isValid(this.personaInfo.form) && (!this.contactInfo?.form || this.formService.isValid(this.contactInfo.form))
  }

  public ngOnInit(): void {
    this.initForm()
  }

  private initForm(): void {
    this.form = this.fb.group({
      personaInfo: null,
      contactInfo: null
    })
    this.setForm()
  }

  private setForm(): void {
    this.ready = false

    if (!(this.profile && this.form)) return

    this.requiredFields = ['name', 'dateOfBirth']
    this.optionalFields = []
    // We should revist this logic once we are working with the new guardianship model
    // Instead of basing on the relationship, it would be if the persona is 21 and over.
    if (this.isSelfPersona) {
      this.optionalFields.push('gender')
      this.labels.name = 'Your Name'
    }
    else {
      this.requiredFields.push('gender')
      this.optionalFields.push('gradYear')
    }
    let contactInfo = null
    if (this.showContactInfo) {
      this._email = (this.profile.persona.email_addresses || []).find(e => !!e.address)
      this._phone = (this.profile.persona.phone_numbers || []).find(p => !!p.number)
      contactInfo = { phone: this._phone?.number, email: this._email?.address }
    }
    this.form.reset({
      personaInfo: {
        name: {
          first: this.profile.persona.first_name,
          last: this.profile.persona.last_name,
          suffix: this.profile.persona.suffix,
          preferred: this.profile.persona.nickname,
        },
        dateOfBirth: this.profile.persona.date_of_birth ? DobParser.isoToMm(this.profile.persona.date_of_birth) : '',
        gender: this.profile.persona.gender,
        gradYear: this.profile.persona.graduation_year,
      },
      contactInfo: { value: contactInfo, disabled: !this.showContactInfo }
    })
    this.ready = true
  }
}
