import { CurrencyPipe } from '@angular/common'
import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'displayCurrency'
})
export class DisplayCurrencyPipe implements PipeTransform {
  constructor(private currencyPipe: CurrencyPipe) {
  }
  public transform(
    value: any,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string, locale?: string
  ): string | null {
    if (isNaN(Number(value))) {
      return value.toString() || ''
    } else {
      return this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale)
    }
  }

}
