<se-fe-modal #addModal (seFeClose)="closeModal()">
  <div seFeModalHeader>
    <se-fe-modal-header [seFeDataTitle]="'Invite to Household'">
    </se-fe-modal-header>
  </div>
  <div *ngIf="ready" seFeModalContent>
    <div *ngIf="submitError">
      <se-fe-alert seFeDataType="error" [seFeDismissible]="false">
        {{ errors[errors.length - 1] }}
      </se-fe-alert>
    </div>
    <div>
      Invite someone to join your household and access the following profiles:
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()" seFeModalContent>
      <se-fe-list>
        <se-fe-list-item>
          <se-po-email seFeDataLabel="Email" formControlName="email">
          </se-po-email>
        </se-fe-list-item>
        <se-fe-list-item *ngFor="let p of sortedProfiles">
          <div style="display: flex;">
            <se-fe-checkbox [formControlName]="p.id.toString()" [seFeDataHiddenLabel]="true"
              [seFeDataDisabled]="p.id === profile.id" [seFeDataOptions]="checkboxOptions[p.id]"
              style="margin-right: 12px;">
            </se-fe-checkbox>
            <se-po-persona-avatar [persona]="p.persona" headerSubtitle="">
            </se-po-persona-avatar>
          </div>
        </se-fe-list-item>
      </se-fe-list>
    </form>
  </div>
  <div seFeModalFooter>
    <se-fe-modal-footer #modalFooter [seFeDataFooterButtons]="modalButtons">
    </se-fe-modal-footer>
  </div>
</se-fe-modal>
