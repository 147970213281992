import { Component, Input, OnChanges, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SeFeModalButton, SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { Sale, SaleActivity } from '../models'

const DISCOUNT_REDEEMED = 'discount redeemed'
const ORDER = 'Order'
const REMINDER_LABEL = 'Reminder Sent'
const SENT_LABELS = ['Bill Sent', 'Invoice Sent', 'Order Created']
const VOIDED_LABELS = ['Bill Voided', 'Order Voided']

@Component({
  selector: 'app-sale-activity-card',
  templateUrl: './sale-activity-card.component.html',
  styleUrls: ['./sale-activity-card.component.scss']
})
export class SaleActivityCardComponent implements OnChanges {

  @Input() public activity: SaleActivity[]
  @Input() public sale: Sale
  @ViewChild('dialogModal') public dialogModal: SeFeModalComponent
  @ViewChild('dialogFooter') public dialogFooter: SeFeModalFooterComponent

  public orderedActivity: SaleActivity[]
  public hasTransactions: boolean
  public hasNotes: boolean
  public dialogButtons: SeFeModalButton[] = [
    {
      primary: true,
      label: 'Done',
      action: () => {
        this.dialogModal.close()
      },
    }
  ]
  public dialogText: string
  private saleType: string

  constructor(private translate: TranslateService) {
    // noop
  }

  public ngOnChanges(): void {
    this.orderedActivity = this.activity.sort((a, b) => (a.created_at > b.created_at) ? -1 : 1)
    this.hasNotes = this.activity.some(a => a.notes)
    this.hasTransactions = this.activity.some(a => a.sale_transaction_id != null)

    this.saleType = this.sale.type === ORDER ? 'order' : 'bill'
  }

  public activityLabelKey(label: string): string {
    if (this.isSentLabel(label)) {
      return this.translate.instant(`SALE_DETAIL.ACTIVITY.${this.saleType}_sent`)
    }
    if (VOIDED_LABELS.includes(label)) {
      return this.translate.instant(`SALE_DETAIL.ACTIVITY.${this.saleType}_voided`)
    }
    return null
  }

  public triggerMessageDialog(message: string): void {
    this.dialogText = message
    this.dialogModal.open(null)
  }

  public noteLabelKey(label: string): string {
    return this.isSentLabel(label) || this.isReminderLabel(label) ?
      this.sale.type.toLowerCase() + '_message' :
      this.sale.type.toLowerCase() + '_note'
  }

  public isDiscountLabel(label: string): boolean {
    return label.includes(DISCOUNT_REDEEMED)
  }

  private isSentLabel(label: string): boolean {
    return SENT_LABELS.includes(label)
  }

  private isReminderLabel(label: string): boolean {
    return label === REMINDER_LABEL
  }
}
