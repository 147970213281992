<se-fe-page
  seFeDataWidth="full"
  seFeDataBackLink="/user/household"
  [seFeDataBackQueryParams]="backParams"
  seFeDataBackText="Household">
  <se-fe-header *ngIf="loaded" seFePageHeader [seFeDataTitle]="displayName" size="750"></se-fe-header>
  <div seFePageContent *ngIf="siteMemberships.length" class="center-cards" style="display: flex; flex-wrap: wrap;">
    <div *ngFor="let membership of siteMemberships">
      <se-po-organization-card
        [siteMembership]="membership" (orgRemoved)="handleRemove($event)">
      </se-po-organization-card>
    </div>
  </div>
  <se-fe-empty-state seFePageContent *ngIf="!siteMemberships.length" seFeDataTitle="No Organizations Found">
    <se-fe-icon seFeEmptyStateIcon seFeDataName="spot_whistle" seFeDataSize="950"></se-fe-icon>
  </se-fe-empty-state>
</se-fe-page>
