import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { ApiService } from '.'
import { ClientApproval } from '../models/client-approval'

@Injectable({
  providedIn: 'root'
})
export class ClientApprovalsService {

  constructor(private api: ApiService) {
    //noop
  }

  public findAll(params?: any): Observable<ClientApproval[]> {
    return this.api.findAllAs('oauth/client_approvals', { params: {...params}}, ClientApproval)
  }

  public destroy(id: number, params?: any): Observable<any> {
    return this.api.delete(`oauth/client_approvals/${id}`, { params: {...params}})
  }
}
