import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { environment } from '@se-po/shared-environments'
import { SeFeApiService } from 'se-fe-api'
import { SeFeModalButton, SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { PersonaEligibility } from 'se-resource-types/dist/lib/EligibilitySearchService'

@Component({
  selector: 'se-po-usav-launch',
  templateUrl: './usav-launch.component.html',
  styleUrls: ['./usav-launch.component.scss']
})
export class UsavLaunchComponent implements OnInit {

  @ViewChild('footer') public footer: SeFeModalFooterComponent
  @ViewChild('modal') public modal: SeFeModalComponent

  @Input() personaEligibilities: PersonaEligibility[]

  public form: FormGroup
  public modalButtons: SeFeModalButton[]
  public orgLogo: string
  public personaOptions: any

  constructor(
    private api: SeFeApiService,
    private formBuilder: FormBuilder
  ) { }

  public ngOnInit(): void {
    this.orgLogo = `${environment.uiThemes}/assets/latest/images/usa-volleyball-logo.png`
    this.form = this.formBuilder.group({
      persona: new FormControl()
    })
    const uniqueIds = Array.from(new Set(this.personaEligibilities.map((pe) => pe.persona_id)))
    this.personaOptions = uniqueIds.map((personaId) => {
      const pe = this.personaEligibilities.find((p) => p.persona_id === personaId)
      return {
        imgUrl: this.profileImage(pe),
        label: pe.name,
        value: pe.persona_id
      }
    })
    this.modalButtons = [
      {
        label: 'Cancel',
        action: () => this.close()
      },
      {
        primary: true,
        label: 'Launch',
        action: () => this.launchAcademy()
      }
    ]
  }

  public open(): void {
    this.modal.open(null)
  }

  public close(): void {
    this.modal.close()
  }

  private launchAcademy(): boolean {
    if (!this.form.value.persona) {
      this.footer.cancelSpinner()
      return
    }
    const requestUrl = `v3/safety/litmos/sso?personaId=${this.form.value.persona}&autoRedirect=false`
    this.api.get<any>(requestUrl, { withCredentials: true }).subscribe((data) => {
      if (data.result.url) {
        window.location.href = data.result.url
      }
    })
  }

  private profileImage(personaEligibility: PersonaEligibility): string {
    if (personaEligibility.profile_image) {
      const cropIcon = personaEligibility.profile_image.find((image: any) => image.image_type === 'crop_icon')
      if (cropIcon && cropIcon.url) {
        return cropIcon.url.replace(/_https/, '')
      }
    }
    return ''
  }

}
