import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { Observable, of } from 'rxjs'
import { Sale } from '../models'
import { OrderService } from '../services/order.service'

export const OrderResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: OrderService = inject(OrderService)
): Observable<Sale> => {
  const orderId = route.params.orderId
  return orderId ? service.find(orderId) : of(null)
}
