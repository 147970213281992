import { Component } from '@angular/core'


@Component({
  selector: 'se-po-name-docs',
  templateUrl: './name-docs.component.html',
  styleUrls: ['./name-docs.component.scss'],
})
export class NameDocsComponent {
  public models: string[]
  public headers: any = {
    NameComponent: [
      'Property',
      'Type',
      'Description',
    ],
    NameValueFields: [
      'Property',
      'Type',
      'Description',
    ]
  }
  public rows: any = {
    NameComponent: [
      {
        Property: '@Input() enablePreferred?',
        Type: 'boolean',
        Description: 'Enable preferred name field',
      },
      {
        Property: '@Input() enableSuffix?',
        Type: 'boolean',
        Description: 'Enable suffix field',
      }
    ],
    NameValueFields: [
      {
        Property: 'first',
        Type: 'string',
        Description: 'First Name',
      },
      {
        Property: 'last',
        Type: 'string',
        Description: 'Last Name',
      },
      {
        Property: 'preferred?',
        Type: 'string',
        Description: 'Nick Name',
      },
      {
        Property: 'suffix?',
        Type: 'string',
        Description: 'Name Suffix (Jr., Sr., II, III, etc.)',
      }
    ]
  }

  constructor() {
    this.models = Object.keys(this.headers)
  }
}
