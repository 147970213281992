<div class="pl-pane__container--horizontal">
  <div class="pl-page-header pl-pane--shrink">
    <div pl-grid="fixed tight align-middle">
      <div pl-grid-el pl-grid="shrink@phone" >
        <h1 class="pl-heading--xlarge" [textContent]="'DASHBOARD.title' | translate"></h1>
      </div>
    </div>
  </div>

  <div class="pl-pane--collapse">
    <div class="myse-dashboard">
      <div class="pl-centered-container--max-width">
        <div pl-grid="tight" class="myse-dashboard__grid">
          <div pl-grid-el pl-grid="1/1@phone" *ngIf="usavPersonaEligibilities.length">
            <se-fe-card>
              <se-fe-card-content>
                <se-po-usav-launch [personaEligibilities]="usavPersonaEligibilities"></se-po-usav-launch>
              </se-fe-card-content>
            </se-fe-card>
          </div>
          <div pl-grid-el pl-grid="1/1@phone">
            <app-dashboard-recommended-actions pl-grid="loose" class="myse-dashboard__section"></app-dashboard-recommended-actions>
          </div>
          <div pl-grid-el pl-grid="1/1@phone">
            <app-dashboard-membership-invites pl-grid="loose" class="myse-dashboard__section"></app-dashboard-membership-invites>
          </div>
          <div pl-grid-el pl-grid="1/1@phone">
            <app-dashboard-schedule pl-grid="loose" class="myse-dashboard__section"></app-dashboard-schedule>
          </div>
          <div pl-grid-el pl-grid="1/1@phone 1/2@tablet">
            <app-dashboard-teams pl-grid="loose" class="myse-dashboard__section"></app-dashboard-teams>
          </div>
          <div pl-grid-el pl-grid="1/1@phone 1/2@tablet">
            <app-dashboard-payments pl-grid="loose" class="myse-dashboard__section"></app-dashboard-payments>
          </div>
          <div pl-grid-el pl-grid="1/1@phone">
            <app-dashboard-mobile-callout pl-grid="loose" class="myse-dashboard__section"></app-dashboard-mobile-callout>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
