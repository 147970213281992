import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { Store } from './store'
import { SurveyResult } from '../models'
import { NginV2ApiService } from './nginV2Api.service'

@Injectable({
  providedIn: 'root'
})
export class SurveyResultService extends Store<SurveyResult[]>{

  constructor(private api: NginV2ApiService) {
    super(null)
  }

  public findMine(params: any, options?: any): void {
    this.mine(params, options).pipe(take(1)).subscribe(
      (data: SurveyResult[]) => {
        this.setCurrent(data)
      }
    )
  }

  public findMore(params: any): void {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const currentMetadata = this.subject.getValue()['metadata']
    if (currentMetadata.pagination.last_page) {
      return
    }

    const p = {
      ...params,
      per_page: currentMetadata.pagination.limit,
      page: currentMetadata.pagination.current_page as number + 1
    }

    this.mine(p).pipe(take(1)).subscribe(
      (data: SurveyResult[]) => {
        const appended = this.subject.getValue().concat(data)
        // eslint-disable-next-line @typescript-eslint/dot-notation
        appended['metadata'] = data['metadata']
        this.setCurrent(appended)
      }
    )
  }

  private mine(params: any, options?: any): Observable<SurveyResult[]> {
    const defaults = { page: 1, per_page: 10 }
    return this.api.findAllAs(
      'survey_results/mine',
      {
        ...options,
        params: {...defaults, ...params}
      },
      SurveyResult)
  }
}
