<se-po-profile-side-panel
  [(showSidePanel)]="!!personaId"
  (avatarClicked)="onUploadImage()"
  [profile]="profile"
  [menuOptions]="menuOptions"
  [editableAvatar]="!isGuardian && profile && profile['access'] !== 'viewer'">
  <div *ngIf="profile && !loading && isGuardian"
    profileSidePanelContent>

    <se-fe-banner class="side-panel-card" seFeDataTitle="{{ (translationKey + 'BANNER.OWNER.TITLE') | translate }}" [seFeShowIcon]="true"  [seFeDataDismissible]="false">
      <div seFeBannerAction>
        <a href="https://help.sportsengine.com/en/articles/9265109-what-is-the-difference-between-manager-guardians-and-viewer-guardians" target="_blank" seFeLink="low">{{ (translationKey + 'BANNER.INFO_LINK') | translate }}</a>
      </div>
      <div seFeBannerSubtitle>{{ (translationKey + 'BANNER.OWNER.SUBTITLE') | translate }}</div>
    </se-fe-banner>

    <div class="side-panel-card">
      <se-po-permissions-card *ngIf="mySeVersion === '3'"
        [isSelf]="false"
        [profiles]="profiles"
        [guardians]="guardians"
        (guardianUpdate)="onGuardianUpdate($event)"
        [isGuardian]="true"
        [profile]="profile">
      </se-po-permissions-card>
    </div>
  </div>
  <div *ngIf="profile && !loading && !isGuardian"
    profileSidePanelContent>

    <div class="side-panel-card" *ngIf="showBanner && profile.access !== 'owner' && accountOwner">
      <se-fe-banner seFeDataTitle="{{ (translationKey + 'BANNER.' + profile.access.toUpperCase() + '.TITLE') | translate }}" [seFeShowIcon]="true"  [seFeDataDismissible]="true" (seFeDismiss)="dismiss(profile.access)">
        <div style="display: flex; height: 35px; flex-direction: column; justify-content: center;" seFeBannerAction>
          <a href="https://help.sportsengine.com/en/articles/9265109-what-is-the-difference-between-manager-guardians-and-viewer-guardians" target="_blank" seFeLink="low">{{ (translationKey + 'BANNER.INFO_LINK') | translate }}</a>
        </div>
        <div seFeBannerSubtitle>{{ (translationKey + 'BANNER.' + profile.access.toUpperCase() + '.SUBTITLE') | translate: { name: accountOwner.self_persona.full_name } }}</div>
      </se-fe-banner>
    </div>
    <div class="side-panel-card">
      <se-po-household-contact-card
        (personaEdited)="onPersonaEdit()"
        [profile]="profile">
      </se-po-household-contact-card>
    </div>

    <!-- With the current guardian model, do not display permissions card for self profiles as we cannot assign access to them. -->
    <div class="side-panel-card">
      <se-po-guardian-permissions-card *ngIf="mySeVersion !== '3' && profile?.relationship !== 'self'"
        [profile]="profile"
        [profiles]="profiles">
      </se-po-guardian-permissions-card>

      <se-po-permissions-card *ngIf="mySeVersion === '3'"
        [accountItems]="accountItems"
        [isSelf]="isSelf"
        [profiles]="profiles"
        [guardians]="guardians"
        (guardianUpdate)="onGuardianUpdate($event)"
        [isGuardian]="isGuardian"
        [profile]="profile">
      </se-po-permissions-card>
    </div>

    <div class="side-panel-card">
      <se-po-overview-card
        [personaId]="profile?.persona?.id"
        [access]="profile?.access">
      </se-po-overview-card>
    </div>
  </div>
</se-po-profile-side-panel>
<se-po-persona-image-modal seFeSidePanelTrigger #personaImageModal
  (personaEdited)="onPersonaEdit()">
</se-po-persona-image-modal>
