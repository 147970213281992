import { forwardRef, Injectable } from '@angular/core'
import { HouseholdPersonaUpdateParams } from '@se-po/shared-data-access-models'
import { HouseholdPersonasService } from '@se-po/shared-data-access-services'
import { Observable } from 'rxjs'
import { CentralServiceResources as cs } from 'se-resource-types'

@Injectable({
  providedIn: 'root',
  useExisting: forwardRef(() => EditPersonaModalSubmitService)
})
export abstract class EditPersonaModalSubmitter {
  protected createPayload(formValue: any, persona: cs.Profiles.Persona): HouseholdPersonaUpdateParams {
    const payload = {
      first_name: formValue.name.first,
      last_name: formValue.name.last,
      nickname: formValue.name.preferred,
      suffix: formValue.name.suffix,
      gender: formValue.gender,
      date_of_birth: formValue.dateOfBirth,
    } as any
    if (formValue.phone) {
      payload.phone_numbers = (persona.phone_numbers || []).map(x => Object.assign({}, x))
      const index = payload.phone_numbers.findIndex((phone: cs.Profiles.PhoneNumber) => phone.id === formValue.phone.id)
      if (index !== -1) {
        // eslint-disable-next-line id-blacklist
        payload.phone_numbers[index].number = formValue.phone.number
      } else {
        payload.phone_numbers.push(formValue.phone)
      }
    }
    if (formValue.email) {
      payload.email_addresses = (persona.email_addresses || []).map(x => Object.assign({}, x))
      const index = payload.email_addresses.findIndex((email: cs.Profiles.EmailAddress) => email.id === formValue.email.id)
      if (index !== -1) {
        payload.email_addresses[index].address = formValue.email.address
      } else {
        payload.email_addresses.push(formValue.email)
      }
    }
    if ('gradYear' in formValue) payload.graduation_year = formValue.gradYear
    return payload
  }

  public abstract submit(formValue: any, persona: cs.Profiles.Persona): Observable<cs.Profiles.Persona>
}

@Injectable({
  providedIn: 'root'
})
export class EditPersonaModalSubmitService extends EditPersonaModalSubmitter {

  constructor(
    private householdPersonasService: HouseholdPersonasService
  ) {
    super()
  }

  public submit(formValue: any, persona: cs.Profiles.Persona): Observable<cs.Profiles.Persona> {
    const payload = this.createPayload(formValue, persona)
    return this.householdPersonasService.updatePersona(
      persona.id.toString(),
      payload
    )
  }
}
