<div class="pl-pane__container--horizontal pl-pane--full-screen">
  <app-nav-bar route="/user/bills"></app-nav-bar>

  <header class="pl-pane__header">
    <div pl-grid="1/1@phone" pl-grid-el>
      <div pl-grid="loose">
        <div pl-grid="1/1@phone grow@tablet" pl-grid-el>
          <h2 class="pl-heading--xlarge">
            <span [textContent]="'BILL_DETAIL.sale' | translate: { sale_number: bill.sale_number }" ></span>
            <span [class]="statusTagColor(bill)" style="margin-left: 8px">{{ 'SALE_DETAIL.STATUSES.' + bill.display_status | translate }}</span>
          </h2>
          <div pl-grid="1/1@phone grow@tablet" pl-grid-el>
            <p>
              <span [textContent]="bill.user_full_name"></span>'s
              <span [textContent]="'BILL_DETAIL.SUBTITLE.description' | translate"></span>
              <span [textContent]="bill.store_name"></span>
            </p>
          </div>
        </div>
        <div *ngIf="canBePaid(bill)"
          pl-grid="grow@phone" pl-grid-el
          class="pl-text--right">
          <se-fe-button seFeDataEmphasis="high" (click)="makePayment()">
            <span>{{ 'SALE_DETAIL.BUTTONS.make_a_payment' | translate }}</span>
          </se-fe-button>
        </div>
      </div>
    </div>
  </header>

  <section class="pl-pane__info pl-layout--on-gray">
    <div class="pl-centered-container--large">
      <div pl-grid="loose">
        <div pl-grid-el pl-grid="1/1@phone">
          <app-sale-info-card
            [sale]="bill"
            [activity]="activity">
          </app-sale-info-card>
        </div>

        <div pl-grid-el pl-grid="1/1@phone" *ngIf="payments">
          <app-payment-plan
            [sale]="bill"
            [payments]="payments">
          </app-payment-plan>
        </div>

        <div pl-grid-el pl-grid="1/1@phone">
          <app-sale-activity-card
            *ngIf="activity"
            [sale]="bill"
            [activity]="activity">
          </app-sale-activity-card>
        </div>

      </div>
    </div>
  </section>
</div>
