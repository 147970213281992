import { Component } from '@angular/core'

@Component({
  selector: 'app-dibs-page',
  templateUrl: './dibs-page.component.html'
})
export class DibsPageComponent {

  constructor() {
    // noop
  }

}
