import { Component, OnInit, Input } from '@angular/core'
import { Team, TeamPreferences } from '../models'
import { TranslateService } from '@ngx-translate/core'
import { TeamAlertPreferencesService } from '../services/team-alert-preferences.service'


@Component({
  selector: 'app-teams-reminders',
  templateUrl: './teams-reminders.component.html'
})
export class TeamsRemindersComponent implements OnInit {
  @Input() public team: Team

  public teamPreferences = new TeamPreferences()
  public loaded: boolean

  constructor(
    private translate: TranslateService,
    private teamAlertPreferencesService: TeamAlertPreferencesService
  ) {
    // noop
  }

  public ngOnInit() {
    this.rosterList()
    this.getPreferences()
  }

  public getPreferences(): void {
    this.teamAlertPreferencesService.loadPreferences(this.team).subscribe(
      val => {
        this.teamPreferences.teamAlertPreferences = val
        this.isLoaded()
      })
  }

  public trackOriginalStatus(pref): void {
    const group = pref.enabled ? this.teamPreferences.enabledPrefs : this.teamPreferences.disabledPrefs
    group.push(pref)
  }

  private rosterList(): void {
    this.team.trigger_roster_personas.forEach(rp => {
      this.teamPreferences.rosterPersonaList.push(rp)
    })
  }

  private isLoaded(): void {
    this.loaded = true
    this.teamPreferences.rosterPersonaList.forEach(rp => {
      this.teamPreferences.preferencesById[rp.persona.persona_id] = this.teamPreferences.teamAlertPreferences[rp.persona.persona_id].result
    })
    this.teamPreferences.preferences = this.teamPreferences.preferencesById.reduce((acc, val) => acc.concat(val), [])

    this.teamPreferences.preferences.forEach((pref) => {
     this.trackOriginalStatus(pref)
    })
  }
}
