import { TitleCasePipe } from '@angular/common'
import { HttpErrorResponse } from '@angular/common/http'
import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core'
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Observable, of, zip } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { SeFeModalButton, SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { SeFeToastService } from 'se-fe-toast'
import { CentralServiceResources as cs } from 'se-resource-types'
import { PersonaAccount, RelatedGuardiansResult, UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { CreateGuardianInviteSubmitter } from './create-guardian-invite-service'

@Component({
  selector: 'se-po-parent-grant-access-modal',
  templateUrl: './parent-grant-access-modal.component.html',
  styleUrls: ['./parent-grant-access-modal.component.scss'],
})
export class ParentGrantAccessModalComponent implements OnChanges {
  @ViewChild('addModal') public addModal: SeFeModalComponent
  @ViewChild('modalFooter') public modalFooter: SeFeModalFooterComponent
  @Output() public guardianAdded: EventEmitter<void> = new EventEmitter()
  @Input() public accountItems: any[] = []
  @Input() public inviteItems: any[] = []

  public form: UntypedFormGroup

  public accounts: PersonaAccount[]
  public addableChildren = []
  public enabledAddableChildren = []
  public checkboxOptions: {[key: string]: any[]} = {}
  public checkboxValues = {}
  public displayList = []
  public errors = []
  public formReady = false
  public initialValues: {[key: string | number]: string[]} = {}
  public modalButtons: SeFeModalButton[] = [
    {
      cancel: true,
      label: 'Cancel',
      action: () => {
        this.addModal.close()
      },
    },
    {
      primary: true,
      label: 'Send Invite',
      action: () => {
        this.submit()
      }
    },
  ]
  public profileReady = false
  public submitError = false
  public formError = false
  public noInputError = false

  private _profile: PersonaAccount
  private _profiles: UserProfile[]
  private _guardians: RelatedGuardiansResult[] = []
  private _allInvites: any[] = []

  constructor(
    public formBuilder: FormBuilder,
    protected guardianSubmitter: CreateGuardianInviteSubmitter,
    public toastService: SeFeToastService,
    private titleCasePipe: TitleCasePipe
  ) {
  }

  public get profile(): PersonaAccount {
    return this._profile
  }

  public get profiles(): UserProfile[] {
    return this._profiles
  }

  public get guardians(): RelatedGuardiansResult[] {
    return this._guardians
  }

  public get allInvites(): any[] {
    return this._allInvites
  }

  public get ready(): boolean {
    return this.profileReady && this.formReady
  }

  @Input() public set profiles(profiles: UserProfile[]) {
    // You can only invite guardians for profiles that you have manager/owner access over.
    // With the current guardian model, we do not allow users to assign access to self profiles.
    this._profiles = profiles.filter(profile => profile.access !== 'viewer' && profile.relationship !== 'self')
    this.rebuildForm()
  }

  @Input() public set profile(profile: PersonaAccount) {
    this.profileReady = false
    this._profile = profile
    this.rebuildForm()
    this.profileReady = true
  }

  @Input() public set guardians(guardians: RelatedGuardiansResult[]) {
    this._guardians = guardians
  }

  @Input() public set allInvites(allInvites: any[]) {
    this._allInvites = allInvites
  }

  public ngOnChanges(): void {
    this.formReady = false
    this.initForm()
    this.formReady = true
    this.createFormSubscriptions()
  }

  public initForm(): void {
    this.setInitialValues()
    this.form = this.formBuilder.group(this.initialValues)
  }

  public rebuildForm(): void {
    if (this.profile?.id && this.profiles?.length > 0 && (this.accountItems.length > 0 || this.inviteItems.length > 0)) {
      this.setInitialValues()
      if (this.form) {
        this.resetForm()
      }
    }
  }

  public setInitialValues(): void {
    this.initialValues = {}
    this.createAddableChildren()
    this.initialValues.selectAll = ['']
    this.checkboxOptions.selectAll = [{label: 'Select All', value: true}]
    this.addableChildren = this.addableChildren.sort((a, b) => Number(a.disabled) - Number(b.disabled))
    this.addableChildren.map(profile => {
      this.initialValues[profile.id] = this.profile.id === profile.id ? [profile.id.toString()] : ['']
      this.initialValues[`${profile.id}-access`] = null
      this.checkboxOptions[profile.id] = [{ label: profile.persona.full_name, value: profile.id.toString() }]
    })
  }

  public createAddableChildren(): void {
    this.addableChildren = this.profiles.filter(prof => !this.accountItems.some(item => prof.persona.id === item.personaId))
    this.addableChildren = this.addableChildren.map(child => this.createAddableChild(child))
    this.enabledAddableChildren = this.addableChildren.filter(item => !item.disabled)
  }

  public createAddableChild(child: any): any {
    const guardianCount = this.countGuardians(child.persona.id)
    const inviteCount = this.countInvitesforChild(child.persona.id)
    child.permissionMenuOpts = {
      name: `account-${child.id}`,
      sections: [
        {
          menuItems: [
            {
              text: 'Viewer',
              disabled: false,
              action: () => this.setAccessOptionValue(child.id, 'viewer', event)
            },
            {
              text: 'Manager',
              disabled: false,
              action: () => this.setAccessOptionValue(child.id, 'manager', event)
            }
          ],
        }
      ]
    }
    child.chipSelected = false
    // The counts do no include the current user, so we subtract 1 from the limit of 10 below
    child.atGuardianLimit = (guardianCount + inviteCount) > 8
    const invite = this.inviteItems.find(item => item.persona.id === child.persona.id)
    child.invitePending = !!invite
    child.disabled = child.invitePending || child.atGuardianLimit
    child.menuButtonText = child.invitePending ? this.titleCasePipe.transform(invite.access) : 'Access Level'
    return child
  }

  public countGuardians(id: string): number {
    let count = 0
    this.guardians.map(guardian => {
      guardian.profiles.map(prof => {
        if(prof.persona_id === id) count ++
      })
    })
    return count
  }

  public countInvitesforChild(id: string): number {
    let count = 0
    this.allInvites.map(invite => {
      if(invite.persona.id === id) count ++
    })
    return count
  }

  public createFormSubscriptions(): void {
    this.addableChildren.map(child => {
      const accessControl = this.form.get(`${child.id}-access`)
      const checkbox = this.form.get(child.id)
      checkbox.valueChanges.subscribe(value => {
        if(value) {
          if (accessControl.hasValidator(Validators.required)) return
          accessControl.addValidators(Validators.required)
        } else {
          accessControl.clearValidators()
          accessControl.setValue(null)
          this.form.get('selectAll').setValue('', { emitEvent: false })
        }
        accessControl.updateValueAndValidity()
        this.form.updateValueAndValidity()
      })
      accessControl.valueChanges.subscribe(value => {
        if(!value) {
          child.menuButtonText = 'Access Level'
          child.chipSelected = false
        } else {
          checkbox.setValue(child.id)
          this.form.updateValueAndValidity()
          child.menuButtonText = this.titleCasePipe.transform(value)
          child.chipSelected = true
        }
      })
    })
    this.form.get('selectAll').valueChanges.subscribe(value => {
      this.addableChildren.map(child => {
        const accessControl = this.form.get(`${child.id}-access`)
        const checkbox = this.form.get(child.id)
        if(child.disabled) return
        if (value) {
          checkbox.setValue(child.id)
        }
        else {
          checkbox.setValue('')
        }
        checkbox.updateValueAndValidity()
      })
    })
  }

  public setAccessOptionValue(id: string, opt: string, event?: Event) {
    const accessControl = this.form.get(`${id}-access`)
    accessControl.setValue(opt)
  }

  public open(): void {
    this.formError = false
    this.submitError = false
    this.noInputError = false
    this.addModal.open(null)
  }

  public getItemError(id: string): string[] {
    const control = this.form.get(`${id}-access`)
    if (this.form.valid) return []
    if(control.errors && control.touched) {
      return ['required']
    }
    return []
  }

  public submit(): void {
    this.formError = false
    this.submitError = false
    this.noInputError = false
    this.form.markAllAsTouched()
    this.form.markAsDirty()
    if (this.form.invalid) {
      this.formError = true
      this.modalFooter.cancelSpinner()
      return
    }
    const selectedPersonaIds = []

    this.profiles.forEach(p => {
      const controlName = p.id.toString()
      if ((this.form.get(controlName)?.value) === p.id.toString()) {
        const selectedAccess = this.form.get(`${p.id}-access`).value
        selectedPersonaIds.push({ id: p.persona.id, access: selectedAccess })
      }
    })

    if (selectedPersonaIds.length === 0) {
      this.noInputError = true
      this.modalFooter.cancelSpinner()
      return
    }

    zip(...selectedPersonaIds.map(item => this.submitOne(item.id, item.access))).subscribe(guardianships => {
      const successGuardianships = guardianships.filter(guardian => !!guardian)
      if (successGuardianships.length === guardianships.length) {
        this.success(this.profile.user.email_address.address)
      } else {
        this.submitError = true
        this.toastService.error('Action can\'t be completed, please try again.')
        this.modalFooter.cancelSpinner()
      }
    })
  }

  public submitOne(personaId: number, access: string): Observable<cs.Households.PersonaGuardianInvite> {
    return this.guardianSubmitter.create(
      access,
      this.profile.user.email_address.address,
      personaId,
      'mySe'
    ).pipe(
      catchError((err: HttpErrorResponse) => {
        this.modalFooter.cancelSpinner()
        return of(null)
      })
    )
  }

  public success(email: string): void {
    this.toastService.success(`Invitation(s) sent to ${email}`)
    this.addModal.close()
    this.guardianAdded.emit()
  }

  public closeModal(): void {
    this.resetForm()
    this.addModal.close()
  }

  public resetForm(): void {
    this.form.reset()
  }
}
