import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { RecommendedAction, Training } from '../models'
import { NginV2ApiService } from './nginV2Api.service'

@Injectable({
  providedIn: 'root'
})
export class AbuseTrainingService {

  constructor(private api: NginV2ApiService,
    private translate: TranslateService) {
    // noop
  }

  public getAll(options: any): Observable<Training[]> {
    return this.api.findAllAs('abuse_trainings', options, Training)
  }

  public getOutstanding(type: string): Observable<Training[]> {
    const options = {
      params: {
        abuse_training_type: type,
        status: 'outstanding'
      }
    }
    return this.getAll(options)
  }

  public getRecommendedActions(type: string): Observable<RecommendedAction[]> {
    return this.getOutstanding(type).pipe(map(trainings =>
      trainings.map(t => {
        let action: RecommendedAction
        if (t.is_for_self) {
          action = {
            title: this.translate.instant('RECOMMENDED_ACTIONS.APS.title_self', { type: t.type, org_name: t.site_name }),
            action: this.translate.instant('RECOMMENDED_ACTIONS.APS.action', { type: t.type }),
            link: t.training_url,
            color: 'red',
            target: '_blank'
          }
        }
        else {
          action = {
            title: this.translate.instant(
              'RECOMMENDED_ACTIONS.APS.title_sub',
              { type: t.type, org_name: t.site_name, name: t.persona_first_name }),
            action: this.translate.instant('RECOMMENDED_ACTIONS.APS.action', { type: t.type }),
            link: t.training_url,
            color: 'red',
            target: '_blank'
          }
        }
        return action
      })
    ))
  }
}
