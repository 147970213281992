import { Component, Input, ViewChild } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { AnalyticsService, HouseholdPersonasService, PersonaListeneeService } from '@se-po/shared-data-access-services'
import { Observable } from 'rxjs'
import { SeFeToastService } from 'se-fe-toast'
import { PersonaAccount, PersonaGuardianInvite, UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { AddGuardianModalComponent } from '../add-guardian-modal/add-guardian-modal.component'

@Component({
  selector: 'se-po-guardian-permissions-card',
  templateUrl: './guardian-permissions-card.component.html',
  styleUrls: ['./guardian-permissions-card.component.scss'],
})
export class GuardianPermissionsCardComponent {
  @Input() profiles: UserProfile[]
  @ViewChild('modal') public modal: AddGuardianModalComponent

  public accountItems: any[]
  public accountItemsReady = false
  public allGuardians: any[]
  public ready = false
  public checkboxOptions = {}
  public checkboxValues = {}
  public errors = []
  public form: UntypedFormGroup
  public formReady = false
  public initialValues = {}
  public inviteItems: any[]
  public inviteItemsReady = false

  private _profile: UserProfile

  constructor(
    public householdPersonasService: HouseholdPersonasService,
    public listeneeService: PersonaListeneeService,
    public toastService: SeFeToastService,
    private analyticsService: AnalyticsService
  ) {
    this.sortByOwner = this.sortByOwner.bind(this)
  }

  public get canManageProfile(): boolean {
    return ['manager', 'owner'].includes(this.profile?.access)
  }

  public get profile(): UserProfile {
    return this._profile
  }

  @Input() public set profile(profile: UserProfile) {
    this._profile = profile
    if (profile) {
      this.setAccountData()
    }
  }

  public setAccountData(): void {
    this.accountItemsReady = false
    this.inviteItemsReady = false
    this.ready = false
    this.createAccountListItems()
    this.createInviteListItems()
  }

  public createAccountListItems(): any {
    this.householdPersonasService.findPersonaAccounts(this.profile.persona.id).subscribe(accounts => {
      this.accountItems = accounts.map((account: PersonaAccount) => (
        {
          id: account.id,
          imgUrl: (account.user?.self_persona.profile_images || []).find(p => p.image_type === 'crop_icon')?.url,
          email: account.user?.email_address.address,

          ...this.canRemoveAccount(account) && {
            menuOptions: {
              name: `account-${account.id}`,
              sections: [
                {
                  menuItems: [
                    {
                      text: 'Remove',
                      disabled: false,
                      action: () => this.removeAccount(account)
                    }
                  ],
                }
              ]
            }
          },
          /**
           * With the current guardian model, we show Parent/Guardian for subtitle.
           * In future when we can assign Managers, we will show access levels Manager and Viewer
           */
          subtitle: account.access === 'owner' ? 'Account Owner' : 'Parent/Guardian',
          title: account.user?.self_persona?.full_name || account.user?.email_address
        }
      ))
      this.accountItems.sort(this.sortByOwner)
      this.accountItemsReady = true
      this.createAllGuardians()
    })
  }

  public createAllGuardians(): void {
    if (this.accountItemsReady && this.inviteItemsReady) {
      this.allGuardians = this.accountItems.concat(this.inviteItems)
      this.ready = true
    }
  }

  public createInviteListItems(): any {
    this.householdPersonasService.findPersonaGuardianInvites(this.profile.persona.id).subscribe(invites => {
      this.inviteItems = invites.map((invite: PersonaGuardianInvite) => (
        {
          id: invite.id,
          email: invite.email_address,
          ...this.canManageProfile && {
            menuOptions: {
              name: `invite-${invite.id}`,
              sections: [
                {
                  menuItems: [
                    {
                      text: 'Cancel',
                      disabled: false,
                      action: () => this.removeInvite(invite)
                    }
                  ],
                }
              ]
            }
          },
          subtitle: 'Parent/Guardian (Pending)',
          title: invite.email_address
        }
      ))
      this.inviteItemsReady = true
      this.createAllGuardians()
    })
  }

  public sortByOwner(a: any, b: any): number {
    const ownerA = a.subtitle === 'Account Owner'
    const ownerB = b.subtitle === 'Account Owner'
    return ownerA === ownerB ? 0 : ownerA ? -1 : 1
  }

  public canRemoveAccount(account: PersonaAccount): boolean {
    // You cannot remove an account from a profile if they own it or it is their self profile
    // The current user also must have manage or greater access
    return account.relationship !== 'self' && account.access !== 'owner' && this.canManageProfile
  }

  public removeAccount(account: PersonaAccount): void {
    const message = 'Guardian removed'
    this.removeGuardian(account.persona_listener_id).subscribe(res => {
      this.accountItems = this.accountItems.filter(item => item.id !== account.id)
      this.createAllGuardians()
      this.toastService.success(`${message} successfully.`)
    })
  }

  public removeInvite(invite: PersonaGuardianInvite): void {
    const message = 'Invitation canceled'
    this.removeGuardian(invite.persona_listener_id).subscribe(res => {
      this.inviteItems = this.inviteItems.filter(item => item.id !== invite.id)
      this.createAllGuardians()
      this.toastService.success(`${message} successfully.`)
    })
  }

  public removeGuardian(id: number): Observable<any> {
    return this.listeneeService.destroy(id)
  }

  public listeneeAdded(): void {
    this.createInviteListItems()
  }

  public addPermissions(): void {
    this.analyticsService.seEvent('HouseholdSidePanel.PermissionsCard.AddClick', 8)
    this.modal.open()
  }
}
