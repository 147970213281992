import { DatePipe } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SeFeMenuOptions, SeFeMenuSection } from 'se-fe-menu'
import { SeFeTranslationsLoader } from 'se-fe-translations'
import { RelatedGuardiansResult, UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { Persona } from 'se-resource-types/dist/lib/CentralService/Profiles'

@Component({
  selector: 'se-po-household-avatar',
  templateUrl: './household-avatar.component.html',
  styleUrls: ['./household-avatar.component.scss'],
})
export class HouseholdAvatarComponent implements OnInit {
  @Output() public chosen = new EventEmitter<UserProfile | RelatedGuardiansResult>()
  @Output() public merge = new EventEmitter<UserProfile[]>()
  @Output() public remove = new EventEmitter<UserProfile>()

  public defaultSubtitle: string | string[]
  public menuOptions: SeFeMenuOptions
  public imageUrl: string
  public isGuardian: boolean
  public ready: boolean
  public title: string
  public subtitle: string | string []
  private _profile: UserProfile | RelatedGuardiansResult
  private _profiles: UserProfile[] = []

  constructor(
    private datePipe: DatePipe,
    private seFeTranslationsLoader: SeFeTranslationsLoader,
    private translateService: TranslateService
  ) {
    // noop
  }

  public get persona(): Persona {
    if ('user' in this.profile) {
      return this.profile?.user?.self_persona
    } else {
      return this.profile?.persona
    }

  }

  public get profile(): UserProfile | RelatedGuardiansResult {
    return this._profile
  }

  public get profiles(): UserProfile[] {
    return this._profiles
  }

  @Input() public set profile(profile: UserProfile | RelatedGuardiansResult) {
    this._profile = profile
    // If user property is on a profile, it is of type PersonaAccount,
    // and thus a guardian over a profile and not one of the current user's profiles
    this.isGuardian = 'user' in this.profile
    if (this.ready) {
      this.setFields()
    }
  }

  @Input() public set profiles(profiles: UserProfile[]) {
    this._profiles = profiles

    if (this.ready) {
      this.menuOptions = this.createMenuOptions()
    }
  }

  public ngOnInit(): void {
    this.initTranslations()
  }

  public onActionClick(e): void {
    e.stopImmediatePropagation()
  }

  public onChosen(e): void {
    this.chosen.emit(this.profile)
  }

  public onKeydown(e: KeyboardEvent): void {
    if (e.code === 'Space' || e.code === 'Enter') {
      this.onChosen(e)
    }
  }

  private createDob(): string {
    return this.persona?.date_of_birth ? this.datePipe.transform(this.persona.date_of_birth, 'MMM d, y') : ''
  }

  private createGraduation(): string {
    if (!this.persona?.graduation_year) return ''

    if (Number(this.persona.graduation_year) < new Date().getFullYear()) {
      return `Graduated ${this.persona.graduation_year}`
    }
    else {
      return `Graduates ${this.persona.graduation_year}`
    }
  }

  private createGender(): string {
    if (!this.persona?.gender) return ''

    return this.translateService.instant(`SE_PO_SE_MY_SPORTSENGINE_UI_HOUSEHOLD_AVATAR.GENDER.${this.persona.gender}`)
  }

  private createSubtitle(): string | string[] {
    if ('user' in this.profile) {
      return this.profile.user?.email_address?.address
    }
    if (this.profile.relationship === 'self') {
      return 'Account Owner'
    }
    if (!this.persona.age || this.persona.age >= 18) {
      return this.persona.email_addresses?.[0]?.address
    }
    const dob = this.createDob()
    const graduation = this.createGraduation()
    const gender = this.createGender()
    const subtitle = [dob, graduation, gender].filter(x => !!x)
    return subtitle?.length && subtitle || ''
  }

  private initTranslations(): void {
    this.ready = false
    this.seFeTranslationsLoader.load('se-my-sportsengine/ui-household-avatar/assets/translations').then(() => {
      this.setFields()
      this.ready = true
    })
  }

  private createMenuOptions(): SeFeMenuOptions {
    const sections: SeFeMenuSection[] = [
      {
        menuItems: [
          {
            text: this.translateService.instant('SE_PO_SE_MY_SPORTSENGINE_UI_HOUSEHOLD_AVATAR.MENU_ITEMS.view'),
            action: () => this.chosen.emit(this.profile)
          }
        ]
      }
    ]

    // You can only opt to merge together non self profiles that you own
    if ('persona' in this.profile && this.profile.relationship === 'other') {
      const otherProfiles = this.profiles?.filter(p => p.id !== this.profile.id && p.relationship === 'other' && p.access === 'owner')
      if (this.profile.access === 'owner' && otherProfiles.length) {
        const mergeItems = []

        otherProfiles.forEach(otherProfile => {
          mergeItems.push({
            text: otherProfile.persona.full_name,
            action: () => this.merge.emit([otherProfile, this.profile as UserProfile])
          })
        })
        sections.push(
          {
            header: this.translateService.instant('SE_PO_SE_MY_SPORTSENGINE_UI_HOUSEHOLD_AVATAR.MENU_ITEMS.merge'),
            divider: false,
            menuItems: mergeItems
          }
        )
      }

      sections.push(
        {
          menuItems: [
            {
              text: this.translateService.instant('SE_PO_SE_MY_SPORTSENGINE_UI_HOUSEHOLD_AVATAR.MENU_ITEMS.remove'),
              action: () => this.remove.emit(this.profile as UserProfile)
            }
          ]
        }
      )
    }
    const menuItem: SeFeMenuOptions = {
      name: `householdAvatarMenu${this.persona.id}`,
      sections,
      maxHeight: '30vh',
      fixedPosition: true
    }

    return sections.length ? menuItem : null
  }

  private setFields(): void {
    if (!this.persona) return

    this.menuOptions = this.createMenuOptions()
    this.subtitle = this.createSubtitle()
    this.title = this.persona?.full_name
    this.setImageUrl()
  }

  private setImageUrl(): void {
    const profileImages = this.persona?.profile_images
    this.imageUrl = (profileImages || []).find(i => i.image_type === 'crop')?.url
  }
}
