import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedUiEmailModule } from '@se-po/shared-ui-email'
import { SeFeAlertModule } from 'se-fe-alert'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeFormFieldSelectModule } from 'se-fe-form-field-select'
import { SeFeIconModule, seFeIconInfoCircle } from 'se-fe-icon'
import { SeFeModalModule } from 'se-fe-modal'
import { SeFePopoverModule } from 'se-fe-popover'
import { SeFeToastModule } from 'se-fe-toast'
import { SeFeTooltipModule } from 'se-fe-tooltip'
import { NewGuardianModalComponent } from './new-guardian-modal/new-guardian-modal.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SeFeAlertModule,
    SeFeFormFieldModule,
    SeFeFormFieldSelectModule,
    SeFeIconModule.include([
      seFeIconInfoCircle
    ]),
    SeFeModalModule,
    SeFePopoverModule,
    SeFeToastModule,
    SeFeTooltipModule,
    SharedUiEmailModule
  ],
  declarations: [NewGuardianModalComponent],
  exports: [NewGuardianModalComponent],
})
export class SeMySportsengineFeatureNewGuardianModalModule {}
