import { CommonModule, DatePipe, TitleCasePipe } from '@angular/common'
import { NgModule } from '@angular/core'
import { SeFeAvatarModule } from 'se-fe-avatar'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeHeaderModule } from 'se-fe-header'
import { SeFeIconModule, seFeIconMenu } from 'se-fe-icon'
import { SeFeLinkModule } from 'se-fe-link'
import { SeFeMenuModule } from 'se-fe-menu'
import { SeFeSidePanelModule } from 'se-fe-side-panel'
import { ProfileSidePanelComponent } from './profile-side-panel/profile-side-panel.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeAvatarModule,
    SeFeButtonModule,
    SeFeHeaderModule,
    SeFeIconModule.include([
      seFeIconMenu
    ]),
    SeFeLinkModule,
    SeFeMenuModule,
    SeFeSidePanelModule
  ],
  declarations: [ProfileSidePanelComponent],
  exports: [ProfileSidePanelComponent],
  providers: [DatePipe, TitleCasePipe]
})
export class SharedUiProfileSidePanelModule {}
