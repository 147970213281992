<header class="pl-modal__header">
  <se-fe-avatar
    seFeDataType="org"
    [seFeDataIdentity]="clubName"
    [seFeDataImgUrl]="clubAvatarUrl"
    seFeDataSize="300">
  </se-fe-avatar>
  <span class="pl-heading--medium pl-text--bold">{{ clubName }}</span>
</header>
<section class="pl-modal__content">
  <div pl-grid="tight">
    <h2 pl-grid="1/1@phone 1/1@tablet 1/1@desktop"
      class="pl-color--highlight"
      [textContent]="'DASHBOARD.MEMBERSHIP_INVITES.MESSAGE_MODAL.welcome' | translate: { name: invite.persona.first_name }"
    ></h2>
    <div pl-grid="1/1@phone 1/1@tablet 1/1@desktop"
      [textContent]="'DASHBOARD.MEMBERSHIP_INVITES.MESSAGE_MODAL.welcome_subtext' | translate: { name:  clubName, owner: memDefOrgName }"
    ></div>

    <div *ngIf="invite.custom_message">{{ invite.custom_message }}</div>

    <div pl-grid="1/1@phone 1/1@tablet 1/1@desktop"
      [textContent]="'DASHBOARD.MEMBERSHIP_INVITES.MESSAGE_MODAL.body' | translate: { owner: memDefOrgName }"
    ></div>
    <div pl-grid="1/1@phone 1/1@tablet 1/1@desktop"
      [textContent]="'DASHBOARD.MEMBERSHIP_INVITES.MESSAGE_MODAL.body_subtext' | translate"
    ></div>
  </div>
</section>
