import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { HouseholdPersonaUpdateParams } from '@se-po/shared-data-access-models'
import { PhonePipe } from '@se-po/shared-data-access-pipes'
import { AnalyticsService, HouseholdPersonasService } from '@se-po/shared-data-access-services'
import { SeFeFormService } from 'se-fe-form-service'
import { SeFeModalButton, SeFeModalComponent } from 'se-fe-modal'
import { SeFeToastService } from 'se-fe-toast'
import { UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { Address, EmailAddress, Persona, PhoneNumber } from 'se-resource-types/dist/lib/CentralService/Profiles'

@Component({
  selector: 'se-po-household-contact-card',
  templateUrl: './household-contact-card.component.html',
  styleUrls: ['./household-contact-card.component.scss'],
})
export class HouseholdContactCardComponent implements OnInit {
  @Output() personaEdited = new EventEmitter<Persona>()
  @ViewChild('editModal') public editModal: SeFeModalComponent

  public editModalButtons: SeFeModalButton[] = [
    {
      cancel: true,
      label: 'Cancel',
      action: () => {
        this.editModal.close()
      },
    },
    {
      primary: true,
      label: 'Save',
      action: () => {
        this.submit()
      }
    }
  ]

  public form: UntypedFormGroup
  public initialValues = {
    address: null,
    primaryEmail: null,
    primaryPhone: null,
    secondaryEmail: null,
    secondaryPhone: null
  }
  public primaryEmailDisplay: string
  public primaryPhoneDisplay: string
  public secondaryEmailDisplay: string
  public secondaryPhoneDisplay: string
  public primaryEmail: EmailAddress
  public primaryPhone: PhoneNumber
  public secondaryEmail: EmailAddress
  public secondaryPhone: PhoneNumber
  public address: Address
  public ready = false
  public submitting = false

  private _profile: UserProfile

  constructor(
    private formBuilder: FormBuilder,
    private formService: SeFeFormService,
    private householdPersonasService: HouseholdPersonasService,
    private phonePipe: PhonePipe,
    public toastService: SeFeToastService,
    private analyticsService: AnalyticsService
  ) {
    //noop
  }

  public get canEdit(): boolean {
    return ['manager', 'owner'].includes(this.profile?.access)
  }

  public get profile(): UserProfile {
    return this._profile
  }

  @Input() public set profile(profile: UserProfile) {
    this._profile = profile
    this.setCardValues()
    this.setInitialFormValues(profile)
    if (this.form) {
      this.resetForm()
    }
  }

  public ngOnInit(): void {
    this.initForm(this.profile)
  }

  public setCardValues(): void {
    const emailAddresses = this.profile.persona.email_addresses
    const phoneNumbers = this.profile.persona.phone_numbers
    this.primaryEmailDisplay = emailAddresses?.[0]?.address || 'No Email Address Provided'
    this.primaryPhoneDisplay = phoneNumbers?.[0]?.number ? this.phonePipe.transform(phoneNumbers[0].number) : 'No Phone Number Provided'
    this.secondaryEmailDisplay = emailAddresses?.[1]?.address || 'No Email Address Provided'
    this.secondaryPhoneDisplay = phoneNumbers?.[1]?.number ? this.phonePipe.transform(phoneNumbers[1].number) : 'No Phone Number Provided'
    this.address = this.profile.persona.address || null
  }

  public initForm(profile: UserProfile): void {
    this.ready = false
    this.form = this.formBuilder.group({
      address: null,
      primaryEmail: '',
      primaryPhone: '',
      secondaryEmail: '',
      secondaryPhone: ''
    }
    )
    this.setInitialFormValues(profile)
    this.resetForm()
    this.ready = true
  }

  public setInitialFormValues(profile: UserProfile) {
    const persona = profile.persona
    this.initialValues = {
      address: persona.address ? [persona.address] : [null],
      primaryEmail: persona.email_addresses && persona.email_addresses.length > 0 ? [persona.email_addresses[0]?.address] : [''],
      primaryPhone: persona.phone_numbers && persona.phone_numbers.length > 0 ? [persona.phone_numbers[0]?.number] : [''],
      secondaryEmail: persona.email_addresses && persona.email_addresses.length > 1 ? [persona.email_addresses[1]?.address] : [''],
      secondaryPhone: persona.phone_numbers && persona.phone_numbers.length > 1 ? [persona.phone_numbers[1]?.number] : ['']
    }
    if (this.form) {
      this.updateValidators(profile)
    }
  }

  public openModal(): void {
    this.analyticsService.seEvent('HouseholdSidePanel.ContactCard.EditClick', 8)
    this.editModal.open(null)
  }

  public submit(): void {
    this.submitting = true
    if (this.formService.isValid(this.form)) {
      const personaId = this.profile.persona.id.toString()
      this.householdPersonasService
        .updatePersona(
          personaId,
          this.formatPayload(this.form.value, this.profile.persona)
        ).subscribe(
          res => {
            this.submitting = false
            this.editModal.close()
            this.personaEdited.emit(res)
            this.toastService.success(
              'Contact Info Updated'
            )
          })
    } else {
      this.submitting = false
      return
    }
  }

  public formatPayload(value: any, persona: Persona): HouseholdPersonaUpdateParams {
    const payload: HouseholdPersonaUpdateParams = {
      address: persona.address,
      phone_numbers: persona.phone_numbers ? persona.phone_numbers.map(p => ({ ...p })) : [],
      email_addresses: persona.email_addresses ? persona.email_addresses.map(e => ({ ...e })) : []
    }

    const emails = [value.primaryEmail, value.secondaryEmail]
    emails.forEach((email, index) => {
      if ((persona.email_addresses?.length > index) && email) {
        payload.email_addresses[index].address = email
      }
      else if (email) {
        payload.email_addresses.push({ id: null, address: email })
      }
      else if (persona.email_addresses?.[index]) {
        payload.email_addresses[index]._destroy = 1
      }
    })

    const phones = [value.primaryPhone, value.secondaryPhone]
    phones.forEach((phone, index) => {
      if ((persona.phone_numbers?.length > index) && phone) {
        // eslint-disable-next-line id-blacklist
        payload.phone_numbers[index].number = phone
      }
      else if (phone) {
        // eslint-disable-next-line id-blacklist
        payload.phone_numbers.push({ id: null, number: phone })
      }
      else if (persona.phone_numbers?.[index]) {
        payload.phone_numbers[index]._destroy = 1
      }
    })

    if (value.address?.street_1.length > 0) {
      payload.address = { ...value.address, id: persona.address?.id || null }
    } else if (payload.address) {
      payload.address._destroy = 1
    }
    return payload
  }

  public updateValidators(profile: UserProfile): void {
    const primaryEmailRequired = this.form.get('primaryEmail').hasValidator(Validators.required)
    const primaryPhoneRequired = this.form.get('primaryPhone').hasValidator(Validators.required)
    if (profile.relationship === 'self') {
      if (!primaryEmailRequired) {
        this.form.get('primaryEmail').addValidators(Validators.required)
        this.form.get('primaryEmail').updateValueAndValidity()
      }
      if (!primaryPhoneRequired) {
        this.form.get('primaryPhone').addValidators(Validators.required)
        this.form.get('primaryPhone').updateValueAndValidity()
      }
    } else if (primaryEmailRequired || primaryPhoneRequired) {
      this.form.get('primaryEmail').clearValidators()
      this.form.get('primaryEmail').updateValueAndValidity()
      this.form.get('primaryPhone').clearValidators()
      this.form.get('primaryPhone').updateValueAndValidity()
    }
  }

  public resetForm(): void {
    setTimeout(() => {
      this.form.reset({
        address: this.initialValues.address[0],
        primaryEmail: this.initialValues.primaryEmail[0],
        primaryPhone: this.initialValues.primaryPhone[0],
        secondaryEmail: this.initialValues.secondaryEmail[0],
        secondaryPhone: this.initialValues.secondaryPhone[0]
      })
    })
  }

  public closeModal(): void {
    this.resetForm()
  }
}
