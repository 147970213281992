import { Injectable } from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { BehaviorSubject } from 'rxjs'
import { SeAnalyticsService } from 'se-analytics'

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  public page: BehaviorSubject<any> = new BehaviorSubject({ })
  private readonly depth1 = 'SingleSignOn'

  constructor(
    private seAnalytics: SeAnalyticsService,
    private activatedRoute: ActivatedRoute
  ) {
    //noop
  }

  public pageView(depth1: string, depth2?: string, depth3?: string): void {
    if (!(depth2)) {
      this.page.next(this.buildPageAttributes(depth1))
    } else {
      this.page.next({ depth1, depth2, ...depth3 && { depth3 } })
    }

    try {
      this.seAnalytics.trackEvent('pageView' , {
        ...this.page.getValue()
      })
    } catch (e) {
      // tslint:disable-next-line:no-console
      console.error('mParticle pageView Error', e)
    }
  }

  public seEvent(action: string, eventType=1): void {
    try {
      this.seAnalytics.trackEvent('seEvent' ,
        {
          ...this.page.getValue(),
          action,
          event_type: eventType
        }
      )
    } catch (e) {
      console.error('mParticle seEvent Error', e)
    }
  }

  public trackError(action: string): void {
    this.seEvent(`Failed.${action}`)
  }

  public trackFormErrors(form: UntypedFormGroup): void {
    Object.keys(form.controls).forEach(key => {
      const control = form.controls[key]
      if (!control.errors) return

      const fieldName = key[0].toUpperCase() + key.substring(1)
      Object.keys(control.errors).forEach(errorType => {
        let message: string
        const errors = control.errors || {}
        if (errorType === 'required') {
          message = 'IsRequired'
        } else if (errors[errorType].message) {
          message = this.formatErrorMessage(errors[errorType].message)
        } else {
          message = 'IsInvalid'
        }
        this.trackError(`${fieldName}${message}`)
      })
    })
  }

  private formatErrorMessage(message: string): string {
    const words: string[] = message.split('.')[0].split(' ')
    return words.map(word => word[0].toUpperCase() + word.substring(1)).join('')
  }

  private buildPageAttributes(depth1: string): any {
    const data = this.getLowestChildRoute()?.snapshot?.data
    if (!data) return null

    return {
      depth1,
      ...data.title && { depth2: data.title },

      ...data.depth1 && { depth1: data.depth1 },
      ...data.depth2 && { depth2: data.depth2 },
      ...data.depth3 && { depth3: data.depth3 },
      ...data.depth4 && { depth4: data.depth4 },
      ...data.depth5 && { depth5: data.depth5 },
    }
  }

  /**
   * This is a recursive function to get the active route data.
   * The ActivatedRoute provider always provides the root to a service and getting
   * the snapshot data for active child routes requires searching to the bottom
   * of the pathFromRoot object tree.
   * @param ar optional - the ActivatedRoute to pass in and get its child ActivatedRoute. Defaults to root.
   */
  private getLowestChildRoute(ar?: ActivatedRoute): ActivatedRoute {
    if (!ar) {
      ar = this.activatedRoute.pathFromRoot[0]
    }

    if (ar.firstChild) {
      return this.getLowestChildRoute(ar.firstChild)
    } else {
      return ar
    }
  }

}
