export class MappableModel {
  public metadata: any = {}
  public errors: any

  public constructor(data?: any) {
    this.update(data || {})
  }

  public update(data: any) {
    Object.assign(this, data)
  }
}
