import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { PublicOrganization, Site } from '@se-po/shared-data-access-models'
import { OrganizationService, SiteService } from '@se-po/shared-data-access-services'
import { environment } from '@se-po/shared-environments'
import { switchMap, take, tap } from 'rxjs/operators'
import { SeFePaginationChange, SeFePaginationOptions } from 'se-fe-pagination'

@Component({
  selector: 'app-organizations-page',
  templateUrl: './organizations-page.component.html',
  styleUrls: ['./organizations-page.component.scss']
})
export class OrganizationsPageComponent implements OnInit {

  public organizations: PublicOrganization[] = []
  public orgSitesMenuLinks: any
  public perPage = 50
  public paginationOptions: SeFePaginationOptions = {
    name: 'orgsPagination',
    label: 'Organizations',
    currentPage: 1,
    total: 0,
    limit: this.perPage
  }
  public loading: boolean

  constructor(
    private organizationService: OrganizationService,
    private siteService: SiteService,
    private translate: TranslateService) {
      // noop
    }

    public ngOnInit(): void {
      this.loadOrgsAndSites()
    }

    public loadOrgsAndSites(): void {
      this.loading = true
      const params: any = { page: this.paginationOptions.currentPage, per_page: this.perPage, excluded_org_types: 'Team' }
      this.organizationService.mine(params).pipe(
        tap(r => {
          this.organizations = r.result
          // eslint-disable-next-line @typescript-eslint/dot-notation
          this.paginationOptions.total = r.metadata.pagination.total
        }),
        switchMap(r => this.siteService.findAll(r.result.map(o => o.id))),
        take(1))
      .subscribe(
        (sites: Site[]) => {
          const linkObject = {}
          sites.forEach(s => {
            const menuLinks = []

            if (s.provisioned === 'provisioned') {
              menuLinks.push({
                text: this.translate.instant('MY_ORGANIZATIONS.go_to_website'),
                action: () => { location.href = s.url },
              })
            }

            menuLinks.push({
              text: this.translate.instant('MY_ORGANIZATIONS.manage'),
              action: () => { location.href = `${environment.app}org/${s.boss_organization_id}` }
            })

            linkObject[s.boss_organization_id] = {
              minWidth: '150px',
              name: `${s.short_name}-menu`,
              sections: [{ menuItems: menuLinks }]
            }
          })
          this.orgSitesMenuLinks = linkObject
          this.loading = false
        }
      )
    }

    public handlePagination(data: SeFePaginationChange): void {
      this.paginationOptions.currentPage = data.currentPage
      this.perPage = data.limit
      this.loadOrgsAndSites()
    }
}
