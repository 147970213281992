import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SeMySportsengineUiPersonaEligibilityModule } from '@se-po/se-my-sportsengine-ui-persona-eligibility'
import { SharedUiProfileSidePanelModule } from '@se-po/shared-ui-profile-side-panel'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeCardModule } from 'se-fe-card'
import { SeFeSidePanelModule } from 'se-fe-side-panel'
import { PersonaEligibilitySidePanelComponent } from './persona-eligibility-side-panel/persona-eligibility-side-panel.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeButtonModule,
    SeFeCardModule,
    SeFeSidePanelModule,
    SeMySportsengineUiPersonaEligibilityModule,
    SharedUiProfileSidePanelModule
  ],
  declarations: [
    PersonaEligibilitySidePanelComponent
  ],
  exports: [
    PersonaEligibilitySidePanelComponent
  ]
})
export class SeMySportsengineUiPersonaEligibilitySidePanelModule {}
