<se-fe-modal #addModal seFeDataSize="large">
  <div seFeModalHeader>
    <se-fe-modal-header seFeDataTitle="Grant Access">
    </se-fe-modal-header>
  </div>
  <div *ngIf="ready" seFeModalContent>
    <se-fe-alert
      *ngIf="error"
      seFeDataType="error"
      [seFeDismissible]="false">
      Invitation Not Sent
      <se-fe-alert-subheader *ngIf="error">
        {{ error }}
      </se-fe-alert-subheader>
    </se-fe-alert>
    <div>
      Grant access to {{ profile.persona.full_name }}’s information and help manage their sports life by inviting someone.
    </div>
    <form [formGroup]="form" *ngIf="ready">
      <se-fe-list>
        <se-fe-list-item>
          <div class="guardian-list-item guardian-list-header">
            <se-fe-checkbox
              class="guardian-list-header"
              formControlName="selectAll"
              [seFeDataDisabled]="!enabledGuardiansListItems.length"
              [seFeDataOptions]="checkboxOptions.selectAll">
            </se-fe-checkbox>
            <div class="guardian-access">
              Access Levels
              <button type="button" seFePopoverTrigger="childGrantAccessPopover" class="guardian-popover">
                <se-fe-icon
                  seFeDataName="info_circle"
                  seFeDataSize="500"
                  seFeDataColor="gray-700">
                </se-fe-icon>
              </button>
              <se-fe-popover seFeDataName="childGrantAccessPopover" [seFeDataFixedPosition]="true">
                Learn more about&ensp;
                <a
                  href="https://help.sportsengine.com/en/articles/9265109"
                  class="guardian-popover-link"
                  target="_blank">
                  access levels.
                </a>
              </se-fe-popover>
            </div>
          </div>
        </se-fe-list-item>
        <se-fe-list-item *ngFor="let guardianListItem of guardianListItems">
          <div class="guardian-list-item guardian-list-fields">
            <div *ngIf="guardianListItem.disabled" class="guardian-list-item" [seFeTooltipTrigger]="guardianListItem.id">
              <se-fe-checkbox
                class="guardian-checkbox"
                [formControlName]="guardianListItem.id.toString()"
                [seFeDataHiddenLabel]="true"
                [seFeDataDisabled]="true"
                [seFeDataOptions]="checkboxOptions[guardianListItem.id]">
              </se-fe-checkbox>
              <ng-container *ngTemplateOutlet="guardianHeader; context: { guardianListItem: guardianListItem }"></ng-container>
              <se-fe-tooltip [seFeDataName]="guardianListItem.id">
                This person has already been granted access to {{ profile.persona.first_name }}
              </se-fe-tooltip>
            </div>
            <div *ngIf="!guardianListItem.disabled" class="guardian-list-item">
              <se-fe-checkbox
                class="guardian-checkbox"
                [formControlName]="guardianListItem.id.toString()"
                [seFeDataHiddenLabel]="true"
                [seFeDataOptions]="checkboxOptions[guardianListItem.id]">
              </se-fe-checkbox>
              <ng-container *ngTemplateOutlet="guardianHeader; context: { guardianListItem: guardianListItem }"></ng-container>
            </div>
            <div class="guardian-access">
              <se-fe-chip
                [seFeDataText]="guardianListItem.menuButtonText"
                [seFeDataSelected]="guardianListItem.chipSelected"
                [seFeMenuTrigger]="!guardianListItem.disabled && guardianListItem.permissionMenuOpts.name"
                [seFeDataDisabled]="guardianListItem.disabled"
                [attr.aria-describedby]="!!guardianListItem.errors ? 'required-error' + guardianListItem.id : ''">
              </se-fe-chip>
              <se-fe-menu *ngIf="!guardianListItem.disabled"
                [seFeDataOptions]="guardianListItem.permissionMenuOpts">
              </se-fe-menu>
              <se-fe-form-control-errors *ngIf="!!guardianListItem.errors"
                class="guardian-access-error"
                [seFeDataId]="'required-error' + guardianListItem.id"
                [seFeDataMessages]="{
                    required: 'Please select an access level'
                }"
                [seFeDataErrors]="['required']">
              </se-fe-form-control-errors>
            </div>
          </div>
        </se-fe-list-item>
        <se-fe-list-footer class="guardian-footer">
          <se-fe-button seFeDataEmphasis="medium" (click)="openNewGuardianModal()">
            <se-fe-icon seFeIconBefore seFeDataName="plus" seFeDataColor="primary" seFeDataSize="600"></se-fe-icon>
            Invite New
          </se-fe-button>
        </se-fe-list-footer>
      </se-fe-list>
    </form>
  </div>
  <div seFeModalFooter>
    <se-fe-modal-footer #modalFooter [seFeDataFooterButtons]="modalButtons">
    </se-fe-modal-footer>
  </div>
</se-fe-modal>
<se-po-new-guardian-modal #newGuardianModal (inviteSent)="inviteSent()"></se-po-new-guardian-modal>

<ng-template #guardianHeader let-guardianListItem="guardianListItem">
  <se-fe-header
    [ngClass]="{'guardian-title-disabled': guardianListItem.disabled}"
    seFeDataSize="500"
    [seFeDataTitle]="guardianListItem.persona.full_name">
    <se-fe-avatar seFeHeaderVisual
      class="guardian-avatar"
      seFeDataType="persona"
      [seFeDataIdentity]="guardianListItem.persona.full_name"
      [seFeDataImgUrl]="guardianListItem.imgUrl"
      seFeDataSize="200">
    </se-fe-avatar>
    <div seFeHeaderSubtitle class="guardian-subtitle">
      {{ guardianListItem.email }}
    </div>
  </se-fe-header>
</ng-template>
