<form [formGroup]="form" *ngIf="form && loaded">
  <se-fe-grid seFeGridOpts>
    <se-fe-grid-cell seFeGridOpts="1/1">
      <se-po-country-select
        formControlName="country"
        [seFeDataLabel]="'SE_PO_UI_ADDRESS.LABELS.country' | translate">
      </se-po-country-select>
    </se-fe-grid-cell>
    <se-fe-grid-cell seFeGridOpts="1/1">
      <se-fe-form-field
        [seFeDataConfig]="fieldConfigs['street_1']">
        <se-fe-form-field-text
          formControlName="street_1"
          [seFeDataComfy]="true"
          (change)="trimValue('street_1')">
        </se-fe-form-field-text>
      </se-fe-form-field>
    </se-fe-grid-cell>
    <se-fe-grid-cell seFeGridOpts="1/1">
      <se-fe-form-field
        [seFeDataConfig]="fieldConfigs['city']">
        <se-fe-form-field-text
          formControlName="city"
          [seFeDataComfy]="true"
          (change)="trimValue('city')">
        </se-fe-form-field-text>
      </se-fe-form-field>
    </se-fe-grid-cell>
    <se-fe-grid-cell seFeGridOpts="1/1">
      <se-fe-grid seFeGridOpts>
        <se-fe-grid-cell seFeGridOpts>
          <se-po-state-select
            formControlName="state_or_province"
            [country]="country"
            [seFeDataLabel]="stateLabel | translate">
          </se-po-state-select>
        </se-fe-grid-cell>
        <se-fe-grid-cell seFeGridOpts>
          <se-fe-form-field
            [seFeDataConfig]="fieldConfigs['zip']">
            <se-fe-form-field-text
              formControlName="zip"
              [seFeDataComfy]="true">
            </se-fe-form-field-text>
          </se-fe-form-field>
        </se-fe-grid-cell>
      </se-fe-grid>
    </se-fe-grid-cell>
  </se-fe-grid>
</form>
