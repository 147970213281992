import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  public transform(input: string): string {
    if (!input) return ''
    const value = input.toString().trim().replace(/^\+/, '')

    if (/\D/.exec(value)) return input

    let country: string
    let areaCode: string
    let phonePrefix: string
    let phoneBody: string

    switch (value.length) {
      case 10: // AAA####### -> AAA-###-####
        country = ''
        areaCode = value.slice(0, 3)
        phonePrefix = value.slice(3, 6)
        phoneBody = value.slice(6)
        break

      case 11: // +CAAA####### -> C AAA-###-####
        country = value.slice(0, 1)
        areaCode = value.slice(1, 4)
        phonePrefix = value.slice(4, 7)
        phoneBody = value.slice(7)
        break

      case 12: // +CCCAA####### -> CCC AA-###-####
        country = value.slice(0, 3)
        areaCode = value.slice(3, 5)
        phonePrefix = value.slice(5, 8)
        phoneBody = value.slice(8)
        break

      default:
        if (value.length > 12) {
          // +CCCAA####### -> CCC AA-###-####
          country = value.slice(0, 3)
          areaCode = value.slice(3, 5)
          phonePrefix = value.slice(5, 8)
          phoneBody = value.slice(8)
          break
        } else {
          return input
        }
    }

    return `${country} (${areaCode}) ${phonePrefix}-${phoneBody}`.trim()
  }
}
