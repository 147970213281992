import { Injectable } from '@angular/core'
import { DateTime } from 'luxon'

@Injectable({
  providedIn: 'root'
})
export class DateService {
  private readonly DEFAULT_TIMEZONE = 'America/New_York'

  public ageFromIso(isoDateOfBirth: string): number {
    if (!isoDateOfBirth) return null

    const dateOfBirth = DateTime.fromISO(isoDateOfBirth)
    if (!dateOfBirth.isValid) return null

    const yearDiff = -dateOfBirth.diffNow('years').years
    return Math.floor(yearDiff)
  }

  public grade(graduationYear: string): string {
    if (!graduationYear) return ''

    const now = DateTime.now()
    const currYear = now.year
    const fallSemester = this.fallSemesterBump(now)
    const gradYearVal = parseInt(graduationYear, 10)
    const grade = 12 - gradYearVal + currYear + fallSemester
    if (grade <= 0) return 'K/Pre-K'

    if (grade > 12) return '12+'

    return grade.toString()
  }

  public fallSemesterBump(date: DateTime): number {
    return date.month > 5 ? 1 : 0
  }

  public mmTomorrow(): string {
    return DateTime.now().plus({day: 1}).toFormat('yyyy-MM-dd')
  }

  // Jan 3 - 8, 2018
  // Jan 3 - Feb 14, 2018
  // Dec 21, 2017 - Jan 4, 2018
  public shortRange(startDateStr: string, endDateStr: string): string {
    const startDateTime: DateTime = this.normalizeDateTime(startDateStr)
    const endDateTime: DateTime = this.normalizeDateTime(endDateStr)

    // Handle cases when we don't have two dates
    if (!startDateTime.isValid && !endDateTime.isValid) return ''
    if (!endDateTime.isValid) return this.formatFullDate(startDateTime)
    if (!startDateTime.isValid) return this.formatFullDate(endDateTime)

    const separator = ' - '
    const sameYear = startDateTime.year === endDateTime.year
    const sameMonth = startDateTime.month === endDateTime.month

    if (sameYear) {
      if (sameMonth) return startDateTime.toFormat('MMM d') + separator + endDateTime.toFormat('d, yyyy')
      else return startDateTime.toFormat('MMM d') + separator + this.formatFullDate(endDateTime)
    }
    else {
      return this.formatFullDate(startDateTime) + separator + this.formatFullDate(endDateTime)
    }
  }

  public normalizeDateTime(dateTimeStr: string): DateTime {
    return (typeof dateTimeStr === 'string') ? DateTime.fromISO(dateTimeStr.substring(0, 10)) : null
  }

  public formatFullDate(datetime: DateTime): string {
    return datetime.toFormat('MMM d, yyyy')
  }

  public formatDateTime(dateTime: string, timezone?: string, fmt?: string): string {
    const format = fmt || 'MMM d, yyyy'
    const tz = timezone || this.DEFAULT_TIMEZONE
    return DateTime.fromISO(dateTime).setZone(timezone).toFormat(format)
  }
}
