import { Injectable } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { forkJoin, Observable, of } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { ApiService } from '.'
import { Persona, PersonaEligibility, RecommendedAction } from '../models'

const MAX_ELIGIBILITY_RULESETS = 100

@Injectable({
  providedIn: 'root'
})
export class EligibilityCredentialSearchService {

  constructor(private api: ApiService,
    private translate: TranslateService) {
    // noop
   }

  public personasEligibilityFilter(options: any): Observable<PersonaEligibility[]> {
    return this.api.create<PersonaEligibility[]>('eligibility/filter', options).pipe(take(1))
  }

  public getPersonaEligibilites(personas: Persona[]): Observable<RecommendedAction[]> {
    const personaIds = [...new Set(personas.map(p => p.id.toString()))]
    const opt = {
      persona_ids: personaIds,
      credential_sets: [
        {
          eligibility_status: ['incomplete', 'expired'],
          eligibility_status_not: ['eligible']
        }
      ],
      per_page: MAX_ELIGIBILITY_RULESETS,
      hydrate: {
        'PersonaEligibility.eligibility_rule_set_organization': true
      }
    }
    return forkJoin({
      personaEligibilities: this.personasEligibilityFilter(opt),
      personas: of(personas)
    }).pipe(
      map(result => {
        const data = result
        const linkedRuleSetIds = data.personaEligibilities.flatMap(
          pe => pe.eligibility_credentials.map(ec => ec.linked_eligibility_rule_set_id)
        )
        data.personaEligibilities = data.personaEligibilities.filter(
          pe => !linkedRuleSetIds.includes(pe.eligibility_rule_set_id) && pe.status !== 'expired'
        )
        return data
      }),
      map(data => {
        const actions: RecommendedAction[] = []
        data.personaEligibilities.forEach(pe => {

          const org = pe.eligibility_rule_set_organization
          const userCount = pe.unfinished_user_task_count
          const adminCount = pe.unfinished_admin_task_count
          const membershipName = pe.eligibility_rule_set_name

          if (!org) return
          if (!userCount && !adminCount && pe.status !== 'eligible') return

          const personaId = pe.persona_id

          const persona: Persona = data.personas.find(p => p.id === personaId)
          const actorContext: string = userCount || !adminCount ? 'user' : 'admin'
          const personaContext: string = persona?.isSelf ? 'self' : 'other'
          const count: number = userCount || adminCount
          const context: string = actorContext + '_' + personaContext

          const options = {
            count,
            name: persona.fullName,
            org_id: org.id,
            org_name: org.name,
            persona_id: personaId,
            membership_name: membershipName
          }

          const title: string = this.translate.instant(
            `RECOMMENDED_ACTIONS.ELIGIBILITY.title_${context}${count > 1 ? '_plural' : ''}`, options)
          const link = `/user/memberships?personFilters=${personaId}&eligibilityRuleSetId=${pe.eligibility_rule_set_id}`
          actions.push({
            sortString: `${persona.isSelf ? '' + title : title}`,
            title,
            action: this.translate.instant(`RECOMMENDED_ACTIONS.ELIGIBILITY.action_${actorContext}`),
            link,
            color: 'red',
            persona_id: personaId.toString(),
            target: '_self'
          })
        })

        return actions
          .filter(a => !!a)
          .sort((a, b) => a.sortString > b.sortString ? 1 : -1)
      })
  )}
}
