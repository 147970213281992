import { MappableModel } from '@se-po/shared-data-access-models'
import { MembershipServiceResources as ms } from 'se-resource-types'

export class MembershipInvite extends MappableModel implements ms.MembershipInvite {
  public id: string
  public membership_id: string
  public membership_definition_id: string
  public persona_id: number
  public affiliation_organization_id: number
  public status: 'pending' | 'accepted' | 'revoked'
  public created_by_user_id: number
  public custom_message: string
  public expires_at: string
  public created_at: string
  public updated_at: string
  public accepted_at: string
  public sent_by_org_id: number
  public sent_by_persona_id: number
  public email_address: string
  public last_sent_at: string
  public dismissed: boolean
  public membership_definition: ms.MembershipDefinition
  public membership_ids: string[]
  public clare_sale_group: string
  public persona: ms.MembershipPersona
  public affiliation_organization: ms.MembershipInviteAffiliationOrganization
  public membership_definition_organization: ms.MembershipInviteAffiliationOrganization
  public sent_at_formatted: string
}
