<div *ngIf="loaded && actions.length" pl-grid-el pl-grid="1/1@phone">
  <div class="myse-dashboard__full-card pl-recommended-actions">
    <div *ngFor="let action of actions" class="pl-recommended-action">
      <div class="pl-recommended-action__indicator" pl-grid-el pl-grid="shrink@phone">
        <div class="indicator-dot-container">
          <span class="indicator-dot"></span>
        </div>
      </div>
      <div class="pl-recommended-action__content">
        <div class="pl-recommended-action__text">
          <span class="pl-recommended-action__title" [textContent]="action.title"></span>
        </div>
        <div class="pl-recommended-action__buttons">
          <se-fe-button seFeDataEmphasis="high" (click)="recommendedActionClick(action)">
            <span>{{ action.action }}</span>
          </se-fe-button>
        </div>
      </div>
    </div>
  </div>
</div>
