<form [formGroup]="form" *ngIf="form">
  <se-fe-form-field *ngIf="loaded"
    [seFeDataConfig]="{
      label: seFeDataLabel,
      errorMessages: {
        required: 'SE_PO_UI_GRADUATION_SELECT.ERRORS.required' | translate
      }
    }">
    <se-fe-form-field-suffix
      [seFeDataSuffix]="grade"
      seFeDataSize="5">
      <se-fe-form-field-select
        [formControlName]="ngControl.name"
        [seFeDataOptions]="graduationYearOptions"
        [seFeDataComfy]="true">
      </se-fe-form-field-select>
    </se-fe-form-field-suffix>
    <div seFeHelp *ngIf="control['helpText']">
      {{ control['helpText'] }}
    </div>
  </se-fe-form-field>
</form>
