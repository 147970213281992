import { NgModule } from '@angular/core'

import { DisplayCurrencyPipe } from './display-currency.pipe'
import { OrderByPipe } from './order-by.pipe'

@NgModule({
  declarations: [
    DisplayCurrencyPipe,
    OrderByPipe
  ],
  imports: [],
  exports: [
    DisplayCurrencyPipe,
    OrderByPipe
  ],
  providers: [
    DisplayCurrencyPipe,
    OrderByPipe
  ],
})
export class PipesModule { }
