import { Component, Input, OnChanges, OnInit } from '@angular/core'
import { Sale, SalePayment } from '../../models'

@Component({
  selector: 'app-payment-plan',
  templateUrl: './payment-plan.component.html',
  styleUrls: ['./payment-plan.component.scss']
})
export class PaymentPlanComponent implements OnInit, OnChanges {

  @Input() public sale: Sale
  @Input() public payments: SalePayment[]
  public title: string
  public sortedPayments: SalePayment[]

  constructor() {
    // noop
  }

  public ngOnInit(): void {
    this.sortedPayments = this.payments.sort(
      (a: SalePayment, b: SalePayment) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime())
  }

  public ngOnChanges(): void {
    this.title = this.payments.length > 1 ? 'payment_plan' : 'payment'
  }

  public showAutopay(payment: SalePayment): boolean {
    return this.sale.payment_plan_payment_method && payment.status === 'unpaid' && Number(payment.amount) > 0
  }
}
