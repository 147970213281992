<div [formGroup]="form" *ngIf="form">
  <se-fe-form-field *ngIf="loaded"
    [seFeDataConfig]="{
      label: seFeDataLabel,
      hiddenLabel,
      errorMessages: {
        inviteExists: 'SE_PO_UI_EMAIL.ERRORS.inviteExists' | translate,
        email: 'SE_PO_UI_EMAIL.ERRORS.email' | translate,
        required: 'SE_PO_UI_EMAIL.ERRORS.required' | translate
      }
    }">
    <se-fe-form-field-text
      [formControlName]="ngControl.name"
      seFeDataInputMode="email"
      seFeDataAutoComplete="email"
      [seFeDataComfy]="true">
      <se-fe-icon class="email-icon" seFeFormFieldTextBefore
        seFeDataName="envelope_filled"
        seFeDataSize="500">
      </se-fe-icon>
    </se-fe-form-field-text>
  </se-fe-form-field>
</div>
