import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { filter, first, map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'

import { UserPreference } from '@se-po/shared-data-access-models'

@Injectable({
  providedIn: 'root'
})

export class UserPreferenceService {
  public endpoint = 'user_preferences'
  private _userPrefs: UserPreference[]
  private _userPrefsSubject: BehaviorSubject<UserPreference[]> = new BehaviorSubject<UserPreference[]>(null)

  constructor(
    private api: SeFeApiService
  ) {
    // noop
  }

  public mine(): Observable<UserPreference[]> {
    return this.api.get<UserPreference[]>(this.endpoint).pipe(
      first(),
      map(response => response.result)
    )
  }

  public loadMine(): void {
    if (this._userPrefs) {
      this._userPrefsSubject.next(this._userPrefs)
      return
    }

    this.mine()
      .pipe(first())
      .subscribe(
        (data: any) => {
          this._userPrefs = data || []
          this._userPrefsSubject.next(this._userPrefs)
        }
      )
  }

  public mySeVersion(): Observable<string> {
    this.loadMine()

    return this._userPrefsSubject.pipe(
      filter(userPrefs => !!userPrefs),
      map(userPrefs => {
        const mySeVersionPref = userPrefs.find(userPref => userPref.key === 'myse_version')
        return mySeVersionPref ? String(mySeVersionPref.value) : ''
      })
    )
  }
}
