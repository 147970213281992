export class PhoneParser {
  /**
   * format a ten digit number as (xxx) xxx-xxxx
   * anything other than a 10 digit number returns the input
   */
  public static format10(phone: string): string {
    const digits = PhoneParser.toN(phone)
    if (digits.length === 10) {
      return `(${digits.substring(0, 3)}) ${digits.substring(3, 6)}-${digits.substring(6, 10)}`
    }
    else if (digits.length === 11) {
      return `${digits.substring(0, 3)} ${digits.substring(3, 7)} ${digits.substring(7, 11)}`
    }
    else if (digits.length === 12) {
      return `+${digits.substring(0, 2)} ${digits.substring(2, 6)} ${digits.substring(6, 12)}`
    }
    return phone || ''
  }

  public static toN(phone: string): string {
    return (phone || '').replace(/[^0-9]/g, '')
  }
}
