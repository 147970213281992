import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core'
import { ColorContrastService } from '@se-po/shared-data-access-services'
import { SiteMembership } from '@se-po/shared-data-access-models'
import { SeFeMenuOptions } from 'se-fe-menu'

interface MembershipCardStyles {
    primaryColor: string
    menuButtonClass: string
}

@Component({
  selector: 'se-po-organization-card',
  templateUrl: './organization-card.component.html',
  styleUrls: ['./organization-card.component.scss'],
})

export class OrganizationCardComponent implements OnInit {
  @Input('siteMembership') public siteMembership: SiteMembership
  @Output() orgRemoved: EventEmitter<string> = new EventEmitter<string>()

  public loading = true
  public backgroundImageUrl = ''
  public membershipCardStyles: MembershipCardStyles
  public ready = false
  public simpleMenuOptions: SeFeMenuOptions

  constructor(
    private colorService: ColorContrastService
  ) {}

  public ngOnInit(): void {
    this.loadData()
    this.simpleMenuOptions = {
      name: `org-${this.siteMembership.id}-menu`,
      sections: [
        {
          menuItems: [
            {
              text: 'Manage Communication Perferences',
              href: '/user/account/communication-prefs',
              target: '_blank',
            },
            {
              text: 'Remove Organization',
              disabled: false,
              action: () => this.emitOrgRemoval(this.siteMembership.id.toString()),
            }
          ],
        },
      ],
    }
    this.ready = true
  }

  public loadData(): void {
    this.setAvatarBackgroundImage(this.siteMembership)
    const color: string = this.primaryColor(this.siteMembership)
    this.membershipCardStyles = {
      primaryColor: color,
      menuButtonClass: this.colorService.colorIsLight(color) ? 'light' : 'dark'
    }
  }

  public emitOrgRemoval(id: string): void {
    this.orgRemoved.emit(id)
  }

  public primaryColor(membership: SiteMembership): string {
    const colorAttrs = [
      'site_link_color',
      'site_accent_color',
      'site_hover_color',
      'site_background_color'
      // NOTE: highlight_color and button_highlight_text are irrelevant, so don't even check those ;)
    ]
    const colors = []
    for (const colorAttr of colorAttrs) {
      const hex: string = membership[colorAttr]
      colors.push(
        {
          hex,
          chroma: this.colorService.hexToChroma(hex)
        }
      )
    }
    for (const color of colors) {
      if (color.chroma > 0.1) {
        return `#${String(color.hex)}`
      }
    }
    colors.sort(c => c.chroma)
    const primary = colors.pop()
    return primary.hex ? `#${String(primary.hex)}` : '#1272AF'
  }



  public setAvatarBackgroundImage(membership: SiteMembership): void {
    if (membership.site_logo_graphic) {
      this.backgroundImageUrl = 'url(' + membership.site_logo_graphic.medium_icon_url + ')'
    }
  }
}
