<se-po-profile-side-panel
  [(showSidePanel)]="showSidePanel"
  [profile]="profile"
  [headerLink]="'user/household?id=' + profile?.persona?.id"
  (closePanel)="close()">
  <se-po-persona-eligibility *ngIf="profile"
    profileSidePanelContent
    [personaId]="profile.persona.id"
    [contextOrgId]="organizationId"
    [eligibilityRuleSetIds]="[eligibilityRuleSetId]">
  </se-po-persona-eligibility>
</se-po-profile-side-panel>
