export class DobParser {

  public static validMm(mmddyyyy?: string | null): boolean {
    const [month, day, year] = (mmddyyyy || '').split('/')
    if (!year || !month || !day) return false
    const y = parseInt(year, 10)
    const m = parseInt(month, 10)
    const d = parseInt(day, 10)
    const currentYear = new Date().getFullYear()
    const validRange = 200 // +/- 200 years
    if (y < currentYear - validRange || y > currentYear + validRange) return false
    if (m < 1 || m > 12) return false
    if (d < 1 || d > 31) return false
    if (m === 2 && d > 29) return false
    if (m === 2 && currentYear % 4 !== 0 && d > 28) return false
    if ([4, 6, 9, 11].includes(m) && d > 30) return false
    return true
  }

  public static mmToIso(mmddyyyy?: string | null): string | null {
    if (!DobParser.validMm(mmddyyyy)) return null
    const [month, day, year] = (mmddyyyy || '').split('/')
    if (!year || !month || !day) return null
    return `${year}-${month}-${day}`
  }

  public static isoToMm(yyyymmdd?: string | null): string | null {
    const [year, month, day] = (yyyymmdd || '').split('-')
    if (!year || !month || !day) { return null }
    return `${month}/${day}/${year}`
  }

  public static isoParts(yyyymmdd?: string | null): string[] | null {
    const [year, month, day] = (yyyymmdd || '').split('-')
    if (!year || !month || !day) { return null }
    return [year, month, day]
  }

  /**
   * formats partial date input as the user types
   */
  public static formatAsMm(dobVal: string): string {
    let fmt = (dobVal || '').replace(/\/\//g, '/')
    const trailingSlash = fmt.charAt(fmt.length - 1) === '/'
    // eslint-disable-next-line prefer-const
    let [m, d, y] = fmt.split('/')
    if ((y || trailingSlash) && d && d.length < 2) {
      const dnum = parseInt(d, 10)
      if (dnum < 10) d = `0${dnum}`
    }
    if ((d || trailingSlash) && m && m.length < 2) {
      const mnum = parseInt(m, 10)
      if (mnum < 10) m = `0${mnum}`
    }

    if (m && d && (y || trailingSlash)) {
      fmt = `${m}/${d}/${y || ''}`
    }
    else if (m && (d || trailingSlash)) {
      fmt = `${m}/${d || ''}`
      if (d.length === 2) fmt += '/'
    }
    else if (m) {
      fmt = m
      if (m.length === 2) fmt += '/'
    }
    return fmt
  }
}
