import { Component, Input, OnChanges, SimpleChanges } from '@angular/core'
import { Router } from '@angular/router'
import { AnalyticsService } from '@se-po/shared-data-access-services'

interface Tab {
  title: string
  hasResults?: boolean
  resolver?: (hasResults: boolean) => void
  viewAll?: string
}

interface Tabs {
  memberships: Tab
  registrations?: Tab
  teams: Tab
  organizations: Tab
  selected?: string
}

@Component({
  selector: 'se-po-overview-card',
  templateUrl: './overview-card.component.html',
  styleUrls: ['./overview-card.component.scss'],
})
export class OverviewCardComponent implements OnChanges {

  @Input() public personaId: string | number
  @Input() public access: 'viewer' | 'manager' | 'owner'

  public tabs: Tabs
  private tabDefaults: Tabs = {
    memberships: { viewAll: 'memberships', title: 'Memberships' },
    registrations: { viewAll: 'registrations', title: 'Registrations' },
    teams: { viewAll: 'my-teams', title: 'Teams' },
    organizations: { viewAll: 'organizations-page', title: 'Organizations' }
  }

  constructor(
    private router: Router,
    private analyticsService: AnalyticsService
  ) {
    this.tabs = { ...this.tabDefaults }
  }

  public get canAccess(): boolean {
    return this.access && ['owner', 'manager'].includes(this.access)
  }

  public get tabKeys(): string[] {
    const keys = Object.keys(this.tabs)
    return keys.slice(0, keys.length - 1)
  }

  public async ngOnChanges(changes: SimpleChanges): Promise<void> {
    const { currentValue, firstChange, previousValue } = changes.personaId
    if (firstChange || currentValue !== previousValue) {
      // Do not show membership/registration tab if user only has viewer access
      this.tabs = { ...this.tabDefaults }
      const resolvers = [
        new Promise((resolve) => this.tabs.memberships.resolver = resolve),
        new Promise((resolve) => this.tabs.registrations.resolver = resolve),
        new Promise((resolve) => this.tabs.teams.resolver = resolve),
        new Promise((resolve) => this.tabs.organizations.resolver = resolve)
      ]
      if (!this.canAccess) {
        delete this.tabs.memberships
        delete this.tabs.registrations
        resolvers.splice(0, 2)
      }
      const values = await Promise.all(resolvers)
      let firstTabWithResults = values.findIndex((value) => value)
      this.tabs.selected = null
      firstTabWithResults = firstTabWithResults > -1 ? firstTabWithResults : 0
      this.tabs.selected = this.tabKeys[firstTabWithResults]
    }
  }

  public onKeydown(tab: string, e: KeyboardEvent): void {
    if (e.code === 'Enter') {
      this.switchTab(tab)
    }
  }

  public switchTab(tab: string): void {
    this.analyticsService.seEvent(`HouseholdSidePanel.OverviewCard.${tab}Click`, 8)
    this.tabs.selected = tab
  }

  public membershipsHasResults(hasResults: boolean): void {
    this.tabs.memberships.hasResults = hasResults
    this.tabs.memberships.resolver(hasResults)
  }

  public registrationsHasResults(hasResults: boolean): void {
    this.tabs.registrations.hasResults = hasResults
    this.tabs.registrations.resolver(hasResults)
  }

  public teamsHasResults(hasResults: boolean): void {
    this.tabs.teams.hasResults = hasResults
    this.tabs.teams.resolver(hasResults)
  }

  public organizationsHasResults(hasResults: boolean): void {
    this.tabs.organizations.hasResults = hasResults
    this.tabs.organizations.resolver(hasResults)
  }

  public viewAll(): void {
    const route = ['user', this.tabs[this.tabs.selected].viewAll]
    if (this.tabs.selected === 'organizations') route.push(this.personaId.toString())
    const membershipQueryParams = {
      personFilters: this.personaId,
      statusFilters: 'eligible,ineligible'
    }
    this.router.navigate(
      route,
      { ...this.tabs.selected === 'memberships' && { queryParams: membershipQueryParams } }
    )
  }

}
