<se-fe-card *ngIf="sale">
  <se-fe-card-header [seFeDataTitle]="'SALE_DETAIL.HEADERS.' + title | translate" [seFeDataHeavyBorder]="true">
    <div se-fe-card-header-actions>
      <div [textContent]="sale.payment_plan_payment_method ? ('SALE_DETAIL.PAYMENT_PLAN.payment_method' | translate: {
        method: sale.payment_plan_account_type,
        number: sale.payment_plan_payment_method.acct_num
      }) : ''"></div>
    </div>
  </se-fe-card-header>
  <se-fe-card-content>
    <div *ngFor="let payment of sortedPayments; last as lastItem">
      <div pl-grid="loose fixed">
        <div pl-grid-el pl-grid="grow@phone">
          <strong test-id="due-date"
            [textContent]="payment.due_date | date: 'MMM d, yyyy'">
          </strong>
          <span *ngIf="payment.is_paid && payment.amount_refunded === '0.00'" class="pl-tag--success" style="margin-left: 8px">{{ 'SALE_DETAIL.TAGS.paid_tag' | translate }}</span>
          <span *ngIf="payment.amount_refunded === '0.00' && showAutopay(payment)" class="pl-tag--highlight" style="margin-left: 8px">{{ 'SALE_DETAIL.TAGS.autopay_tag' | translate }}</span>
          <span *ngIf="payment.amount === payment.amount_refunded && payment.status !== 'canceled' && payment.amount_refunded > '0.00'" class="refund" style="margin-left: 8px">{{ 'SALE_DETAIL.TAGS.refunded_tag' | translate }}</span>
          <span *ngIf="payment.amount_refunded > '0.00' && !payment.fully_refunded" class="refund" style="margin-left: 8px">{{ 'SALE_DETAIL.TAGS.partial_refunded_tag' | translate }}</span>
          <span *ngIf="payment.status === 'canceled'" class="pl-tag--neutral" style="margin-left: 8px">{{ 'SALE_DETAIL.TAGS.canceled_tag' | translate }}</span>
          <span *ngIf="payment.status === 'disputed'" class="pl-tag--warn" style="margin-left: 8px">{{ 'SALE_DETAIL.TAGS.disputed_tag' | translate }}</span>
          <span *ngIf="payment.status === 'chargeback'" class="pl-tag--neutral" style="margin-left: 8px">{{ 'SALE_DETAIL.TAGS.chargeback_tag' | translate }}</span>
          <span *ngIf="payment.status === 'past-due'" class="pl-tag--alert" style="margin-left: 8px">{{ 'SALE_DETAIL.TAGS.past_due_tag' | translate }}</span>              
        </div>

        <div pl-grid-el pl-grid="shrink@tablet 1/1@phone">
          <div pl-grid-el pl-grid="hide-only@phone"
            class="pl-text--right">
            <span *ngIf="payment.originalAmountStrikethrough"
              class="pl-text--xsmall pl-text--strikethrough-light"
              [textContent]="payment.original_amount | displayCurrency: sale.currencySymbol">
            </span>
            &nbsp;
            <strong
              [ngClass]="{'pl-text--strikethrough': payment.chargeback }"
              [textContent]="payment.amount | displayCurrency: sale.currencySymbol">
            </strong>
          </div>
          <div pl-grid-el pl-grid="hide@tablet">
            <span *ngIf="payment.originalAmountStrikethrough"
              class="pl-text--xsmall pl-text--strikethrough-light"
              [textContent]="payment.original_amount | displayCurrency: sale.currencySymbol">
            </span>
            &nbsp;
            <strong
              [ngClass]="{'pl-text--strikethrough': payment.chargeback }"
              [textContent]="payment.amount | displayCurrency: sale.currencySymbol">
            </strong>
          </div>
          <div *ngIf="payment.is_paid">
            <small pl-grid-el pl-grid="hide-only@phone"
              class="pl-text--right"
              [textContent]="'SALE_DETAIL.PAYMENT_PLAN.paid' | translate: {
                date: (payment.paid_at | date: 'MMM d, yyyy') }">
            </small>
            <small pl-grid-el pl-grid="hide@tablet"
              [textContent]="'SALE_DETAIL.PAYMENT_PLAN.paid' | translate: {
                date: (payment.paid_at | date: 'MMM d, yyyy')}">
            </small>
          </div>
          <div *ngIf="payment.status === 'past-due'">
            <small pl-grid-el pl-grid="hide-only@phone"
              class="pl-text--right"
              [textContent]="'SALE_DETAIL.PAYMENT_PLAN.past_due' | translate: {
                days: payment.due_date_days_past }">
            </small>
            <small pl-grid-el pl-grid="hide@tablet"
              [textContent]="'SALE_DETAIL.PAYMENT_PLAN.past_due' | translate: {
                days: payment.due_date_days_past }">
            </small>
          </div>
          <div *ngIf="payment.status === 'unpaid'">
            <small pl-grid-el pl-grid="hide-only@phone"
              class="pl-text--right" [textContent]="payment.status"></small>
            <small pl-grid-el pl-grid="hide@tablet" [textContent]="payment.status"></small>
          </div>
        </div>
      </div>
      <hr *ngIf="!lastItem" class="pl-divider" />
    </div>
  </se-fe-card-content>
</se-fe-card>
