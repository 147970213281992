<div [formGroup]="form" *ngIf="form">
  <se-fe-form-field *ngIf="loaded"
    [seFeDataConfig]="{
      label: seFeDataLabel,
      hiddenLabel,
      errorMessages: {
        required: 'SE_PO_UI_PHONE.ERRORS.required' | translate,
        phone10To12: 'SE_PO_UI_PHONE.ERRORS.phone' | translate
      }
    }">
    <se-fe-form-field-text
      [formControlName]="ngControl.name"
      seFeDataPlaceholder="(###) ###-####"
      seFeDataInputMode="tel"
      seFeDataAutoComplete="tel-national"
      [seFeDataComfy]="true">
      <se-fe-icon class="phone-icon" seFeFormFieldTextBefore
        seFeDataName="phone_fill"
        seFeDataSize="500">
      </se-fe-icon>
    </se-fe-form-field-text>
  </se-fe-form-field>
</div>
