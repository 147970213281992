import { AfterViewInit, Component, Input, ViewChild } from '@angular/core'
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms'
import { SUFFIX_OPTIONS } from '@se-po/shared-data-access-models'
import { BaseValueAccessorDirective } from '@se-po/shared-ui-form-directives'
import { SeFeFormFieldSelectComponent } from 'se-fe-form-field-select'

@Component({
  selector: 'se-po-suffix-select',
  templateUrl: './suffix-select.component.html',
  styleUrls: ['./suffix-select.component.scss'],
  hostDirectives: [BaseValueAccessorDirective]
})
export class SuffixSelectComponent implements AfterViewInit {
  @Input() public hiddenLabel = false
  @Input() public seFeDataLabel?: string
  @Input() public seFeDataPlaceholder: string

  @ViewChild(SeFeFormFieldSelectComponent) public field: SeFeFormFieldSelectComponent

  public labelFor = ''
  public ngControl = BaseValueAccessorDirective.ngControl(this) // get the forwarded control
  public readonly suffixOptions = SUFFIX_OPTIONS

  public get form(): UntypedFormGroup | UntypedFormArray {
    return this.ngControl?.control?.parent
  }

  public ngAfterViewInit(): void {
    // In order to avoid ExpressionChangedAfterItHasBeenCheckedError issues,
    // we are getting the labelFor from the select field within a setTimeout
    // when the child fields exist
    setTimeout(() => {
      this.labelFor = this.field.labelFor
    })
  }
}
