import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PhonePipe } from '@se-po/shared-data-access-pipes'
import { SharedUiAddressModule } from '@se-po/shared-ui-address'
import { SharedUiContactInfoModule } from '@se-po/shared-ui-contact-info'
import { SharedUiEmailModule } from '@se-po/shared-ui-email'
import { SharedUiPhoneModule } from '@se-po/shared-ui-phone'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeCardModule } from 'se-fe-card'
import { SeFeChipModule } from 'se-fe-chip'
import { SeFeGridModule } from 'se-fe-grid'
import { SeFeHeaderModule } from 'se-fe-header'
import { SeFeIconModule, seFeIconEnvelopeFilled, seFeIconPhoneFill } from 'se-fe-icon'
import { SeFeModalModule } from 'se-fe-modal'
import { SeFeStylesModule } from 'se-fe-styles'
import { SeFeToastModule } from 'se-fe-toast'
import { HouseholdContactCardComponent } from './household-contact-card/household-contact-card.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SeFeButtonModule,
    SeFeCardModule,
    SeFeChipModule,
    SeFeGridModule,
    SeFeHeaderModule,
    SeFeIconModule.include([seFeIconEnvelopeFilled, seFeIconPhoneFill]),
    SeFeModalModule,
    SeFeStylesModule,
    SeFeToastModule,
    SharedUiAddressModule,
    SharedUiContactInfoModule,
    SharedUiEmailModule,
    SharedUiPhoneModule
  ],
  declarations: [HouseholdContactCardComponent],
  exports: [HouseholdContactCardComponent],
  providers: [PhonePipe]
})
export class SeMySportsengineFeatureHouseholdContactCardModule { }
