<se-fe-card>
  <se-fe-card-header
    [seFeDataTitle]="'SALE_DETAIL.TYPES.' + saleSummaryTitle | translate"
    [seFeDataSubtitle]="sale.description"
    [seFeDataHeavyBorder]="true">
  </se-fe-card-header>
  <se-fe-card-content>
    <div *ngFor="let saleItemRegister of saleItemRegisters" pl-grid="tight">
      <div pl-grid-el pl-grid="nowrap"
        test-id="sale-item">
        <span *ngIf="item(saleItemRegister).full_name === 'Bill Amount'"
          class="pl-text--light"
          [textContent]="'SALE_DETAIL.SUMMARY.bill_amount' | translate">
        </span>
        <span *ngIf="item(saleItemRegister).full_name !== 'Bill Amount'"
          class="pl-text--light"
          [textContent]="item(saleItemRegister).full_name">
        </span>
      </div>

      <div *ngIf="saleItemRegister.quantity > 1" pl-grid="shrink@phone" pl-grid-el>
        <span class="pl-text--right">{{ saleItemRegister.price | displayCurrency:sale.currency_symbol }}</span>
      </div>
      <div *ngIf="saleItemRegister.quantity > 1" pl-grid="shrink@phone" pl-grid-el>
        <span class="pl-text--right">x{{ saleItemRegister.quantity }}</span>
      </div>
      <div pl-grid="shrink@phone" pl-grid-el>
        <span class="pl-text--right">{{ saleItemRegister.amount | displayCurrency:sale.currency_symbol }}</span>
      </div>
    </div>

    <div *ngIf="showSubTotalAmount" test-id="subtotal">
      <hr class="pl-divider"/>
      <div pl-grid="align-middle">
        <div pl-grid-el pl-grid="1/2@phone">
          <strong [textContent]="'SALE_DETAIL.SUMMARY.subtotal' | translate"></strong>
        </div>
        <div pl-grid-el pl-grid="1/2@phone" class="pl-text--right">
          <strong>{{ subtotalRegister.amount | displayCurrency:sale.currency_symbol }}</strong>
        </div>
      </div>
    </div>

    <div *ngFor="let discountRegister of discountRegisters"
      pl-grid="align-middle tight"
      test-id="discounted-amount">
      <div pl-grid-el pl-grid="1/2@phone">
        <span class="pl-text--light" [textContent]="'SALE_DETAIL.SUMMARY.discounted' | translate"></span>
      </div>
      <div pl-grid-el pl-grid="1/2@phone" class="pl-text--right">
        {{ discountRegister.amount | displayCurrency:sale.currency_symbol }}
      </div>
    </div>

    <div *ngFor="let feeRegister of feeRegisters"
      pl-grid="align-middle tight"
      test-id="processing-fees">
      <div pl-grid-el pl-grid="1/2@phone">
        <span class="pl-text--light" [textContent]="'SALE_DETAIL.SUMMARY.online_processing_fee' | translate"></span>
      </div>
      <div *ngIf="feeRegister.amount" pl-grid-el pl-grid="1/2@phone" class="pl-text--right">
        {{ feeRegister.amount | displayCurrency:sale.currency_symbol }}
      </div>
      <div *ngIf= "!feeRegister.amount"
        pl-grid-el pl-grid="1/2@phone"
        class="pl-text--right"
        [textContent]="'SALE_DETAIL.SUMMARY.calculated_at_checkout' | translate">
      </div>
    </div>

    <div *ngFor="let salesTaxRegister of salesTaxRegisters"
      pl-grid="align-middle tight"
      test-id="sales-tax">
      <div pl-grid-el pl-grid="1/2@phone">
        <span class="pl-text--light" [textContent]="'SALE_DETAIL.SUMMARY.sales_tax' | translate"></span>
      </div>
      <div *ngIf="salesTaxRegister.amount" pl-grid-el pl-grid="1/2@phone" class="pl-text--right">
        {{ salesTaxRegister.amount | displayCurrency:sale.currency_symbol }}
      </div>
      <div *ngIf="!salesTaxRegister.amount"
        pl-grid-el pl-grid="1/2@phone"
        class="pl-text--right"
        [textContent]="'SALE_DETAIL.SUMMARY.calculated_at_checkout' | translate">
      </div>
    </div>
    <hr class="pl-divider"/>

    <div pl-grid="fixed" test-id="total">
      <div pl-grid-el pl-grid="1/2@phone">
        <strong [textContent]="'SALE_DETAIL.SUMMARY.total' | translate"></strong>
      </div>
      <div pl-grid-el pl-grid="1/2@phone" class="pl-text--right">
        <strong [textContent]="totalRegister?.amount | displayCurrency:sale.currency_symbol"></strong>
      </div>
    </div>

    <div *ngIf="creditRegisters?.length"
      pl-grid="align-middle tight"
      test-id="total-credited-amount">
      <div pl-grid-el pl-grid="1/2@phone">
        <span class="pl-text--light" [textContent]="'SALE_DETAIL.SUMMARY.credited' | translate"></span>
        <span>&nbsp;</span>
        <span *ngIf="!showAdjustments" class="pl-link pl-help-text"
          (click)="toggleShowAdjustmentsDetail()"
          [textContent]="'SALE_DETAIL.SUMMARY.show_details' | translate">
        </span>
        <span *ngIf="showAdjustments" class="pl-link pl-help-text"
          (click)="toggleShowAdjustmentsDetail()"
          [textContent]="'SALE_DETAIL.SUMMARY.hide_details' | translate">
        </span>
      </div>
      <div pl-grid-el pl-grid="1/2@phone"
        class="pl-text--right">{{ totalCreditedRegister.amount | displayCurrency:sale.currency_symbol }}
      </div>
    </div>

    <div *ngIf="showAdjustments" test-id="credited-amounts">
      <div pl-grid-el pl-grid="1/1@phone">
        <div pl-grid="align-middle tight"
          class="pl-text--xsmall"
          style="margin:0 24px"
          *ngFor="let creditRegister of creditRegisters">
          <div pl-grid-el pl-grid="1/1@phone">
            <span [textContent]="'SALE_DETAIL.SUMMARY.amount_on_date' | translate: {
                amount: (-creditRegister.amount | displayCurrency:sale.currency_symbol),
                date: (creditRegister.created_at | date: 'MMM d, yyyy')
               }">
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="paymentRegisters.length"
      pl-grid="align-middle tight"
      test-id="total-paid-amount">
      <div pl-grid-el pl-grid="1/2@phone">
        <span class="pl-text--light" [textContent]="'SALE_DETAIL.SUMMARY.paid' | translate"></span>
        <span>&nbsp;</span>
        <span *ngIf="!showPaymentDetail" class="pl-link pl-help-text"
          (click)="toggleShowPaymentDetail()"
          [textContent]="'SALE_DETAIL.SUMMARY.show_details' | translate">
        </span>
        <span *ngIf="showPaymentDetail" class="pl-link pl-help-text"
          (click)="toggleShowPaymentDetail()"
          [textContent]="'SALE_DETAIL.SUMMARY.hide_details' | translate">
        </span>
      </div>
      <div pl-grid-el pl-grid="1/2@phone"
        class="pl-text--right">{{ totalPaidRegister.amount | displayCurrency:sale.currency_symbol }}
      </div>
    </div>

    <div *ngIf="showPaymentDetail">
      <div pl-grid-el pl-grid="1/1@phone">
        <div class="pl-text--xsmall"
          pl-grid="align-middle tight"
          style="margin:0 24px"
          *ngFor="let paymentRegister of paymentRegisters">
          <div pl-grid-el pl-grid="1/1@phone">
            <span
              [textContent]="'SALE_DETAIL.SUMMARY.amount_on_date' | translate: {
                amount: (-paymentRegister.amount | displayCurrency:sale.currency_symbol),
                date: (paymentRegister.created_at | date: 'MMM d, yyyy')
              }">
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="refundAdjustments.length" pl-grid="align-middle tight">
      <div pl-grid-el pl-grid="1/2@phone">
        <span class="pl-text--light" [textContent]="'SALE_DETAIL.SUMMARY.refund_adjustments' | translate"></span>
        <span>&nbsp;</span>
        <span *ngIf="!showRefundsDetail" class="pl-link pl-help-text" (click)="toggleShowRefundsDetail()"
          [textContent]="'SALE_DETAIL.SUMMARY.show_details' | translate">
        </span>
        <span *ngIf="showRefundsDetail" class="pl-link pl-help-text" (click)="toggleShowRefundsDetail()"
          [textContent]="'SALE_DETAIL.SUMMARY.hide_details' | translate">
        </span>
      </div>
      <div pl-grid-el pl-grid="1/2@phone" class="pl-text--right">{{ totalRefundsAmount |
        displayCurrency:sale.currency_symbol }}
      </div>
    </div>
    
    <div *ngIf="showRefundsDetail">
      <div pl-grid-el pl-grid="1/1@phone">
        <div class="pl-text--xsmall" pl-grid="align-middle tight" style="margin:0 24px"
          *ngFor="let refund of refundAdjustments">
          <div pl-grid-el pl-grid="1/1@phone">
            <span [textContent]="'SALE_DETAIL.SUMMARY.amount_on_date' | translate: {
                  amount: (refund.amount | displayCurrency:sale.currency_symbol),
                  date: (refund.created_at | date: 'MMM d, yyyy')
                }">
            </span>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="voided"
      pl-grid="align-middle tight"
      test-id="voided-amount">
      <div pl-grid-el pl-grid="1/2@phone">
        <span class="pl-text--light" [textContent]="'SALE_DETAIL.SUMMARY.voided' | translate"></span>
      </div>
      <div pl-grid-el pl-grid="1/2@phone" class="pl-text--right">
        {{ -totalRegister.amount | displayCurrency:sale.currency_symbol }}
      </div>
    </div>

    <hr *ngIf="showOutstandingAmount" class="pl-divider"/>

    <div *ngIf="showOutstandingAmount" pl-grid="fixed" test-id="outstanding-amount">
      <strong pl-grid-el pl-grid="1/2@phone" [textContent]="'SALE_DETAIL.SUMMARY.outstanding' | translate"></strong>
      <strong pl-grid-el pl-grid="1/2@phone" class="pl-text--right">
        {{ amountOutstandingRegister.amount | displayCurrency:sale.currency_symbol }}
      </strong>
    </div>
  </se-fe-card-content>
</se-fe-card>
