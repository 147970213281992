import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedUiEditPersonaModule } from '@se-po/shared-ui-edit-persona'
import { SeFeModalModule } from 'se-fe-modal'
import { SeFeToastModule } from 'se-fe-toast'
import { EditPersonaModalComponent } from './edit-persona-modal/edit-persona-modal.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeModalModule,
    SeFeToastModule,
    SharedUiEditPersonaModule
  ],
  declarations: [EditPersonaModalComponent],
  exports: [EditPersonaModalComponent],
})
export class SharedFeatureEditPersonaModalModule {}
