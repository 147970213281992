// tslint:disable
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'

import { SeEnvironmentUrlService } from 'se-fe-auth-support'
import { Metadata } from 'se-resource-types/dist/lib/common'
import { Mapper } from './mapper.service'

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  protected defaultOptions: any = {}

  constructor(private http: HttpClient,
    private urlService: SeEnvironmentUrlService) {
      // noop
    }

  public get(path: string, options?: any): Observable<any> {
    return this.http.get(this.uri(path), this.getOptions(options))
  }

  public post(path: string, body: any, options?: any): Observable<any> {
    return this.http.post(this.uri(path), body, this.getOptions(options))
  }

  public put(path: string, body: any, options?: any): Observable<any> {
    return this.http.put(this.uri(path), body, this.getOptions(options))
  }

  public delete(path: string, options?: any): Observable<any> {
    return this.http.delete(this.uri(path), this.getOptions(options))
  }

  public findCurrent<T>(url: string, subject: BehaviorSubject<T>, klass: new(...args: any[]) => T): void {
    this.get(url)
      .subscribe(
        (data: any) => {
          const model = Mapper.as<T>(data.result, klass)
          subject.next(model)
        }
      )
  }

  public find<T>(url: string, options?: any): Observable<T> {
    return this.get(url, options)
      .pipe(first())
      .pipe(map(data => {
        const r = data.result
        r.metadata = data.metadata
        return r
    }))
  }

  public create<T>(url: string, body: any, options?: any): Observable<T> {
    return this.post(url, body, options)
      .pipe(first())
      .pipe(map(data => {
        const r = data.result
        r.metadata = data.metadata
        return r
    }))
  }

  public findAs<T>(url: string, klass: new(...args: any[]) => T): Observable<T> {
    return this.get(url)
      .pipe(first())
      .pipe(map(data => {
        const r = Mapper.as<T>(data.result, klass)
        // eslint-disable-next-line @typescript-eslint/dot-notation
        r['metadata'] = data.metadata
        return r
    }))
  }

  public findAllAs<T>(url: string, options: any, klass: new(...args: any[]) => T): Observable<T[]> {
    return this.get(url, options)
      .pipe(first())
      .pipe(map(data => {
        const r = Mapper.asArray<T>(data.result, klass)
        // eslint-disable-next-line @typescript-eslint/dot-notation
        r['metadata'] = data.metadata as Metadata
        return r
      }))
  }

  protected uri(path: string): string {
    return `${this.urlService.getApiUrl()}${encodeURI(path)}`
  }

  private getOptions(options: any = {}): any {
    options = { ...this.defaultOptions, ...options }
    options.withCredentials = true
    options.headers = options.headers || { }
    options.headers.Accept = options.headers.Accept || 'application/json'
    return options
  }

}
