import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'

const MAIL_HOME = 'mail_folder/show'
const MESSAGE_SHOW = 'message/show/'
@Component({
  selector: 'app-messages-page',
  templateUrl: './messages-page.component.html'
})
export class MessagesPageComponent implements OnInit {
  public route: string = MAIL_HOME

  constructor(
    private window: Window,
    private activatedRoute: ActivatedRoute
  ) {
    // noop
  }

  public ngOnInit(): void {
    const messageId: string = this.activatedRoute?.snapshot?.params?.messageId
    if (messageId) {
      this.route = `${MESSAGE_SHOW}${messageId}`
    }
  }

  public back(): void {
    this.window.history.back()
  }
}
