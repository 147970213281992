import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'
import { CentralServiceResources as cs } from 'se-resource-types'

@Injectable({
  providedIn: 'root'
})
export class HouseholdProfilesService {
  public endpoint = 'v3/households/profiles'
  private _selfPersonaId: string | number
  private _selfPersonaSubject: BehaviorSubject<cs.Profiles.Persona> = new BehaviorSubject<cs.Profiles.Persona>(null)

  constructor(
    private api: SeFeApiService
  ) {
    // noop
  }

  public get selfPersona(): Observable<cs.Profiles.Persona> {
    return this._selfPersonaSubject.asObservable()
  }

  public find(id: string | number): Observable<cs.Households.UserProfile> {
    return this.api.get<any>(`${this.endpoint}/${id}`).pipe(
      first(),
      map(response => response.result)
    )
  }

  public mine(access?: string): Observable<cs.Households.UserProfilesResult> {
    const params = { ...access && { access } }
    return this.api.get<cs.Households.UserProfilesResult>(`${this.endpoint}/mine`, { params })
      .pipe(
        first(),
        map(response => response.result)
      )
  }

  public findMyProfiles(access?: string): Observable<cs.Households.UserProfile[]> {
    return this.mine(access).pipe(map(result => result.profiles))
  }

  public findUserProfileDemographics(id: string | number): Observable<cs.Households.UserProfileDemographics> {
    return this.api.get<cs.Households.UserProfileDemographics>(`${this.endpoint}/${id}/demographics`).pipe(
      first(),
      map(response => response.result)
    )
  }

  public loadSelfPersona(): void {
    let findProfile
    if (this._selfPersonaId) {
      findProfile = this.find(this._selfPersonaId)
    }
    else {
      findProfile = this.findMyProfiles('manager').pipe(
        map(profiles => (profiles || []).find(p => p.access === 'owner' && p.relationship === 'self') || null)
      )
    }
    findProfile.subscribe({
      next: (data: cs.Households.UserProfile) => {
        this._selfPersonaSubject.next(data?.persona)
        this._selfPersonaId = data?.persona?.id
      },
      error: error => {
        this._selfPersonaSubject.next(null)
      }
    })
  }
}
