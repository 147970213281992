import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SeFeToastService } from 'se-fe-toast'
import { MembershipServiceResources as ms } from 'se-resource-types'

import { AnalyticsService } from '@se-po/shared-data-access-services'
import { environment } from '@se-po/shared-environments'
import { MembershipInviteModalComponent } from '../../dashboard/membership-invite-modal/membership-invite-modal.component'
import { MembershipInvite } from '../../models/membership-invite'
import { ModalService } from '../../services'
import { MembershipInviteService } from '../../services/membership-invite.service'

@Component({
  selector: 'app-dashboard-membership-invites',
  templateUrl: './dashboard-membership-invites.component.html',
  styleUrls: ['./dashboard-membership-invites.component.scss']
})
export class DashboardMembershipInvitesComponent implements OnInit {
  public loaded = false
  public invites: MembershipInvite[] = []

  constructor(
    private analyticsService: AnalyticsService,
    private membershipInviteService: MembershipInviteService,
    private modalService: ModalService,
    private toast: SeFeToastService,
    private translateService: TranslateService,
  ) { }

  public ngOnInit(): void {
    this.load()
  }

  public viewInviteDetails(invite: ms.MembershipInvite): void {
    this.modalService.open({
      component: MembershipInviteModalComponent,
      properties: { invite },
      options: {
        title: this.translateService.instant('DASHBOARD.MEMBERSHIP_INVITES.MESSAGE_MODAL.title'),
        actions: [
          {
            action: () => this.modalService.close(),
            label: this.translateService.instant('DASHBOARD.MEMBERSHIP_INVITES.MESSAGE_MODAL.cancel'),
            cancel: true,
          },
          {
            // DISMISS
            action: () => this.dismiss(invite),
            label: this.translateService.instant('DASHBOARD.MEMBERSHIP_INVITES.MESSAGE_MODAL.dismiss_assignment'),
            secondary: true,
          },
          {
            // ACCEPT ASSIGNMENT
            action: () => {
              this.analyticsService.seEvent(`AcceptClubAssignmentRequest.${invite.affiliation_organization.id}`, 8)
              window.location.href = `${environment.returnUrls.memberships}invite/${invite.id}/member-info`
            },
            label: this.translateService.instant('DASHBOARD.MEMBERSHIP_INVITES.MESSAGE_MODAL.accept_assignment'),
            primary: true,
          }
        ],
        size: 'large'
      }
    })
  }

  public dismiss(invite: ms.MembershipInvite): void {
    this.membershipInviteService.dismiss(invite.id)
      .subscribe(
        result => {
          this.toast.success(this.translateService.instant('DASHBOARD.MEMBERSHIP_INVITES.assignment_dismissed'))
          this.analyticsService.seEvent(`DismissClubAssignmentRequest.${invite.affiliation_organization.id}`, 8)
          this.clearInvite(invite)
          this.modalService.close()
        },
        err => {
          this.toast.error(this.translateService.instant('DASHBOARD.MEMBERSHIP_INVITES.assignment_dismissed_error'))
        }
      )
  }

  private clearInvite(invite: ms.MembershipInvite): void {
    this.invites = this.invites.filter(i => i.id !== invite.id)
  }

  private load(): void {
    this.membershipInviteService.findMine()
      .subscribe((r) => {
        this.invites = r
        this.loaded = true
      })
  }
}
