import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { RsvpView } from '../../view-models'

@Component({
  selector: 'app-rsvp-list',
  templateUrl: './rsvp-list.component.html',
  styleUrls: ['./rsvp-list.component.scss']
})

export class RsvpListComponent implements OnInit {

  @Input('rsvps') public rsvps: RsvpView[]
  @Input('hasAllRsvps') public hasAllRsvps: boolean
  @Input('headerAvatars') public headerAvatars: []
  @Input('additionalAvatars') public additionalAvatars: string

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onRsvp = new EventEmitter()

  public RSVP_RESPONSE_STATUSES: any = {
    yes: {
      icon: 'check_circle_outline',
      color: 'green'
    },
    maybe: {
      icon: 'question_circle_outline',
      color: 'orange'
    },
    no: {
      icon: 'remove_circle_outline',
      color: 'red'
    }
  }

  public rsvpListOpen: boolean
  public rsvpAccordionTitle: string
  public rsvpIcons = {}

  constructor() {
    //noop
   }

  public ngOnInit(): void {
    this.rsvpListOpen = !this.hasAllRsvps
    this.setRSVPAccordionHeader()
    this.setShownIcons(this.rsvps)
  }

  public setShownIcons(rsvps: RsvpView[]): void {
    rsvps.forEach(rsvp => {
      this.setIcon(rsvp)
    })
  }

  public setIcon(rsvp: RsvpView): any {
    if (rsvp.response === 'no_response') return

    this.rsvpIcons[rsvp.id] =  {
      icon: this.RSVP_RESPONSE_STATUSES[rsvp.response].icon,
      color: this.RSVP_RESPONSE_STATUSES[rsvp.response].color
    }
  }

  public setRSVPAccordionHeader(): void {
    if (this.rsvpListOpen) {
      this.rsvpAccordionTitle = 'Are You Going?'
    } else {
      this.rsvpAccordionTitle = 'RSVPS'
    }
  }

  public toggleAccordion(): void {
    this.rsvpListOpen = !this.rsvpListOpen
    this.setRSVPAccordionHeader()
  }

  public emitEvent(event): void {
    this.onRsvp.emit(event)
    this.setIcon(event.newValue)
  }

}
