<div class="pl-pane__container--horizontal">
  <section class="pl-pane__header pl-pane--collapse-bottom">
    <div pl-grid="tight">
      <div pl-grid="tight">
        <div pl-grid="expand@phone align-middle">
          <h1 class="pl-heading--xlarge">{{ 'ACCOUNT.title' | translate }}</h1>
        </div>
        <ul class="pl-tabs">
          <li class="pl-tabs__item">
            <a routerLink="/user/account/general"
              class="pl-tabs__link"
              routerLinkActive #rlaGeneral="routerLinkActive"
              queryParamsHandling="preserve"
              [attr.aria-selected]="rlaGeneral.isActive">{{ 'ACCOUNT.TABS.general' | translate }}</a>
          </li>
          <li class="pl-tabs__item">
            <a routerLink="/user/account/communication-prefs"
              class="pl-tabs__link"
              routerLinkActive #rlaComPref="routerLinkActive"
              queryParamsHandling="preserve"
              [attr.aria-selected]="rlaComPref.isActive">{{ 'ACCOUNT.TABS.communication_preferences' | translate }}</a>
          </li>
          <li class="pl-tabs__item">
            <a routerLink="/user/account/clients"
              class="pl-tabs__link"
              routerLinkActive #rlaClients="routerLinkActive"
              queryParamsHandling="preserve"
              [attr.aria-selected]="rlaClients.isActive">{{ 'ACCOUNT.TABS.clients' | translate }}</a>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <div class="pl-pane pl-pane--collapse pl-layout--on-gray">
    <router-outlet></router-outlet>
  </div>
</div>
