import { Component, ComponentFactoryResolver, ComponentRef, ViewChild, ViewContainerRef } from '@angular/core'
import { SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { ModalData, ModalService } from '../../services'

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

  @ViewChild('modal') public modal: SeFeModalComponent
  @ViewChild('container', { read: ViewContainerRef }) public entry: ViewContainerRef
  @ViewChild('footer') public footer: SeFeModalFooterComponent
  public component: ComponentRef<any>
  public title = ''
  public footerOptions: [] = []
  public size

  constructor(modalService: ModalService,
    private resolver: ComponentFactoryResolver) {

    modalService.options.subscribe((data: ModalData) => {
      this.entry.clear()
      const factory = this.resolver.resolveComponentFactory(data.component)
      const ref = this.entry.createComponent(factory)
      Object.assign(ref.instance, data.properties)
      modalService.componentRef = ref
      this.title = data.options.title
      this.footerOptions = data.options.actions
      this.size = data.options.size || 'medium'
      this.modal.open(null)
    })

    modalService.cancel.subscribe(() => {
      this.entry.clear()
      modalService.componentRef = null
      this.modal.close()
    })

    modalService.spinner.subscribe((spinning) => {
      if (!spinning) {
        this.footer.cancelSpinner()
      }
    })

    modalService.titleUpdate.subscribe((newTitle) => {
      this.title = newTitle
    })
  }
}
