import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core'
import { PublicOrganization, SiteMembership } from '@se-po/shared-data-access-models'
import { OrganizationService, SiteMembershipService } from '@se-po/shared-data-access-services'
import { Observable, Subscription, of } from 'rxjs'
import { SeFeMenuOptions } from 'se-fe-menu'

@Component({
  selector: 'se-po-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss'],
})
export class OrganizationListComponent implements OnChanges, OnDestroy {

  @Input() public personaId: string | number
  @Input() public access: 'viewer' | 'manager' | 'owner'
  @Output() public hasResults: EventEmitter<boolean> = new EventEmitter()

  public listItems: any[]
  public organizationIds: number[] = []
  public loaded = false
  private orgSubscription: Subscription

  constructor(
    private siteMembershipService: SiteMembershipService,
    private organizationService: OrganizationService
  ) { }

  public ngOnChanges(changes: SimpleChanges): void {
    const { currentValue, firstChange, previousValue } = changes.personaId
    if (firstChange || currentValue !== previousValue) {
      this.setListItems()
    }
  }

  ngOnDestroy() {
    if (this.orgSubscription) {
      this.orgSubscription.unsubscribe()
    }
  }

  private async setListItems(): Promise<void> {
    this.loaded = false
    const siteMemberships = await this.getSiteMemberships()
    this.listItems = siteMemberships.slice(0, 5).map((siteMembership: SiteMembership, index: number) => {
      const listItem = {
        // users with viewer access should not be able to cancel site memberships or manage communication preferences
        ...this.access && this.access !== 'viewer' && { menuOptions: {
          name: `${siteMembership.organization_name}-${index}`,
          sections: [{
            menuItems: [
              {
                href: '/user/account/communication-prefs',
                text: 'Manage Communication Preferences'
              },
              {
                action: () => this.cancelSiteMembership(siteMembership),
                text: 'Remove Organization'
              }
            ]
          }]
        } as SeFeMenuOptions },
        organization_id: siteMembership.organization_id,
        title: siteMembership.organization_name,
        url: siteMembership.site_url
      }
      this.organizationIds.push(siteMembership.organization_id)
      return listItem
    })
    this.orgSubscription = this.getOrgsForSites().subscribe(organizations => {
      organizations.forEach(org => {
        const matchedItem = this.listItems.find(item => item.organization_id === org.id)
        matchedItem.image = org.logo.small_logo_url
      })
    })
    this.loaded = true
    this.hasResults.emit(!!this.listItems.length)
  }

  private async getSiteMemberships(): Promise<SiteMembership[]> {
    if (!this.personaId) {
      return Promise.resolve([])
    }
    return this.siteMembershipService.findAll(this.personaId).toPromise()
  }

  private getOrgsForSites(): Observable<PublicOrganization[]> {
    if (!this.organizationIds.length) {
      return of([])
    }
    return this.organizationService.indexPublic(this.organizationIds)
  }

  private async cancelSiteMembership(siteMembership: SiteMembership): Promise<void> {
    if (!confirm(`This will cancel your membership to ${siteMembership.organization_name}.`)) {
      return
    }

    try {
      await this.siteMembershipService.cancel(siteMembership).toPromise()
    } catch { /* noop */ }
    this.setListItems()
  }

}
