import { Component, Input, OnInit } from '@angular/core'
import { Router } from '@angular/router'

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html'
})
export class NavBarComponent {
  @Input() public route?: string

  constructor(private window: Window,
    private router: Router) {
    // noop
   }

  public navigate(): void {
    if (this.route) {
      void this.router.navigate([this.route])
    } else {
      this.window.history.back()
    }
  }
}
