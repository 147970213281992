import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'

import { SeFeApiService } from 'se-fe-api'
import { Persona } from 'se-resource-types/lib/UserService'
import { ApiResponse } from 'se-resource-types/lib/common'

@Injectable({
  providedIn: 'root'
})
export class UserPersonaService {

  constructor(private api: SeFeApiService) {
    // noop
  }

  public getSelf(): Observable<ApiResponse<Persona[]>> {
    return this.api.get<Persona[]>('personas/mine', { params: { type: 'self'}} )
  }
}
