import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core'
import { SeFeMenuOptions } from 'se-fe-menu'
import { PaginationMetadata, PaginationSettings } from '../../models'

const PER_PAGE_OPTIONS = [25, 50, 100]

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnChanges {
  @Input() public settings: PaginationSettings
  @Output() public settingsChange: EventEmitter<PaginationSettings> = new EventEmitter<PaginationSettings>()
  @Input() public metadata: PaginationMetadata
  @Input() public label: string

  public pluralLabel: string
  public start: number
  public end: number
  public total: number
  public menuOptions: SeFeMenuOptions = {
    name: 'pagination-menu',
    sections: [
      {
        menuItems: PER_PAGE_OPTIONS.map(n => ({ text: `${n} per page`, action: () => this.setPerPage(n) }))
      }
    ]
  }

  constructor() {
    // noop
  }

  public ngOnChanges(): void {
    this.start = this.metadata.offset + 1
    this.end = this.start + this.metadata.limit - 1
    this.total = this.metadata.total
    this.pluralLabel = this.total === 1 ? `${this.label}` : `${this.label}s`
  }

  public forward(): void {
    if (this.metadata.last_page) return
    const newSettings: PaginationSettings = { ...this.settings }
    newSettings.page++
    this.settingsChange.emit(newSettings)
  }

  public back(): void {
    if (this.metadata.first_page) return
    const newSettings: PaginationSettings = { ...this.settings }
    newSettings.page--
    this.settingsChange.emit(newSettings)
  }

  private setPerPage(num: number): void {
    const newSettings: PaginationSettings = { ...this.settings }
    newSettings.per_page = num
    this.settingsChange.emit(newSettings)
  }

}
