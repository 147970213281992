import { Component, Input, OnInit } from '@angular/core'
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { COUNTRY_OPTIONS } from '@se-po/shared-data-access-models'
import { BaseValueAccessorDirective } from '@se-po/shared-ui-form-directives'
import { SeFeSelectOption } from 'se-fe-form-field-select'
import { SeFeTranslationsLoader } from 'se-fe-translations'

@Component({
  selector: 'se-po-country-select',
  templateUrl: './country-select.component.html',
  styleUrls: ['./country-select.component.scss'],
  hostDirectives: [BaseValueAccessorDirective]
})
export class CountrySelectComponent implements OnInit {
  @Input() public seFeDataLabel?: string

  public countryOptions: SeFeSelectOption[] = []
  public loaded: boolean
  public ngControl = BaseValueAccessorDirective.ngControl(this) // get the forwarded control

  constructor(private seFeTranslationsLoader: SeFeTranslationsLoader,
              private translateService: TranslateService
  ) { }

  public get form(): UntypedFormGroup | UntypedFormArray {
    return this.ngControl?.control?.parent
  }

  public ngOnInit(): void {
    this.loaded = false
    this.initTranslations()
  }

  public initTranslations(): void {
    this.seFeTranslationsLoader.load('shared/ui-country-select/assets/translations').then(loaded => {
      COUNTRY_OPTIONS.forEach(val => {
        this.countryOptions.push({
          label: this.translateService.instant(`SE_PO_UI_COUNTRY_SELECT.COUNTRY.${val}`),
          value: val
        })
      })
      this.loaded = true
    })
  }
}
