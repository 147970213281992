import { DateTime } from 'luxon'

export class EventTimeRange {

  public getTimeRange(event): string {
    if (event.allDay) {
      return 'All Day'
    }
    else if (event.timeTbd) {
      return 'TBD'
    }
    const startTime = this.trimHour(DateTime.fromISO(event.startDateTime))
    const endTime = this.trimHour(DateTime.fromISO(event.endDateTime))
    const range = this.formatRange(startTime, endTime)

    return range
  }

  private formatRange(startTime: string, endTime: string): string {
    let startString = startTime
    const endString = endTime

    // The following compares the 'A' and 'P' in 'AM' or 'PM'
    if (startTime[startTime.length - 2] === endTime[endTime.length - 2]) {
      startString = startTime.substring(0, startTime.length - 3)
    }

    const range = startString + '-' + endString
    return range

  }

  private trimHour(time: DateTime): string {
    let trimmed
    if (time.minute !== 0) {
      return time.toLocaleString(DateTime.TIME_SIMPLE)
    }
    if (time.hour === 12) {
      trimmed = (time.hour.toString() + ' PM')
      return trimmed
    }
    if(time.hour === 0) {
      trimmed = ((time.hour + 12).toString() + ' AM')
      return trimmed
    }

    if (time.hour > 12) {
      trimmed = ((time.hour - 12).toString() + ' PM')
    } else {
      trimmed = (time.hour.toString() + ' AM')
    }

    return trimmed

  }
}
