<se-fe-spinner
  *ngIf="!loaded"
  [seFeDataSize]="900"
  [seFeDataCenter]="true">
</se-fe-spinner>
<se-fe-list *ngIf="loaded">
  <se-fe-list-item *ngFor="let listItem of listItems">
    <se-fe-header [seFeDataSize]="600">
      <se-fe-avatar seFeHeaderVisual seFeDataType="org"
        [seFeDataIdentity]="listItem.title"
        [seFeDataAriaLabel]="listItem.title"
        [seFeDataImgUrl]="listItem.image"
        seFeDataSize="200"
        style="margin-top: 4px;">
      </se-fe-avatar>
      <div seFeHeaderTitle class="organization-list-title">
        <a seFeLink="low" target="_blank" [href]="listItem.url">
          {{ listItem.title }}
          <se-fe-icon seFeDataName="launch" seFeDataSize="500" seFeDataColor="primary"></se-fe-icon>
        </a>
      </div>
      <div *ngIf="listItem.menuOptions" seFeHeaderActions>
        <se-fe-icon-button
          [seFeMenuTrigger]="listItem.menuOptions.name"
          seFeDataEmphasis="low">
          <se-fe-icon seFeDataName="more" seFeDataSize="700"></se-fe-icon>
        </se-fe-icon-button>
        <se-fe-menu [seFeDataOptions]="listItem.menuOptions"></se-fe-menu>
      </div>
    </se-fe-header>
  </se-fe-list-item>
</se-fe-list>
<se-fe-empty-state *ngIf="loaded && !listItems.length" seFeDataTitle="No Organizations Found">
  <se-fe-icon seFeEmptyStateIcon seFeDataName="spot_whistle" seFeDataSize="950"></se-fe-icon>
</se-fe-empty-state>
