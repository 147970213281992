import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedUiPersonaAvatarModule } from '@se-po/shared-ui-persona-avatar'
import { SeFeAlertModule } from 'se-fe-alert'
import { SeFeCardModule } from 'se-fe-card'
import { SeFeChipModule } from 'se-fe-chip'
import { SeFeDocsExampleModule } from 'se-fe-docs-example'
import { SeFeDocsTableModule } from 'se-fe-docs-table'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeFormFieldOptionsModule } from 'se-fe-form-field-options'
import { SeFeHeaderModule } from 'se-fe-header'
import { SeFeIconModule, seFeIconEnvelopeThin } from 'se-fe-icon'
import { SeFeListModule } from 'se-fe-list'
import { SeFeMenuModule } from 'se-fe-menu'
import { SeFeModalModule } from 'se-fe-modal'
import { SeFePageModule } from 'se-fe-page'
import { SeFePopoverModule } from 'se-fe-popover'
import { SeFeTooltipModule } from 'se-fe-tooltip'

import { ParentGrantAccessModalComponent } from './parent-grant-access-modal/parent-grant-access-modal.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeAlertModule,
    SeFeCardModule,
    SeFeChipModule,
    SeFeIconModule.include([seFeIconEnvelopeThin]),
    SeFeDocsExampleModule,
    SeFeDocsTableModule,
    SeFeFormFieldModule,
    SeFeFormFieldOptionsModule,
    SeFeListModule,
    SeFeMenuModule,
    SeFeModalModule,
    SeFeHeaderModule,
    SeFePageModule,
    SeFePopoverModule,
    SeFeTooltipModule,
    SharedUiPersonaAvatarModule
  ],
  declarations: [
    ParentGrantAccessModalComponent
  ],
  exports: [
    ParentGrantAccessModalComponent
  ],
})
export class SeMySportsengineFeatureParentGrantAccessModalModule {}
