import { DatePipe } from '@angular/common'
import { Component, Input, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { SeFeTranslationsLoader } from 'se-fe-translations'
import { UserServiceResources as us } from 'se-resource-types'
import { Persona } from 'se-resource-types/dist/lib/CentralService/Profiles'
import { OrgPersona } from 'se-resource-types/dist/lib/Ngin'

@Component({
  selector: 'se-po-persona-avatar',
  templateUrl: './persona-avatar.component.html',
  styleUrls: ['./persona-avatar.component.scss'],
})
export class PersonaAvatarComponent implements OnInit {
  @Input() avatarDataSize = '300'
  @Input() headerDataSize = '500'
  @Input() headerLink: string
  @Input() headerSubtitle: string | string[]
  @Input() showGraduation = false
  @Input() subtitleLink: string

  public defaultSubtitle: string | string[]
  public imageUrl: string
  public ready = false
  public title: string

  private _persona: Persona | us.Persona | OrgPersona

  constructor(
    private datePipe: DatePipe,
    private seFeTranslationsLoader: SeFeTranslationsLoader,
    private translateService: TranslateService
  ) {
    // noop
  }

  public get gender(): string {
    if (!this.ready || !this.persona?.gender) return ''

    return this.translateService.instant(`SE_PO_SHARED_UI_PERSONA_AVATAR.GENDER.${this.persona.gender}`)
  }

  public get dob(): string {
    return this.persona?.date_of_birth ? this.datePipe.transform(this.persona.date_of_birth, 'MMM d, y') : ''
  }

  public get graduation(): string {
    if (!this.ready || !('graduation_year' in this.persona) || !this.persona.graduation_year) return ''

    return this.translateService.instant('SE_PO_SHARED_UI_PERSONA_AVATAR.graduation', { year: this.persona.graduation_year })
  }

  public get persona(): Persona | us.Persona | OrgPersona {
    return this._persona
  }

  public get subtitle(): string | string[] {
    const subtitle = this.headerSubtitle === undefined ? this.defaultSubtitle : this.headerSubtitle
    if (subtitle) {
      return subtitle?.length && subtitle || ' '
    }
    else {
      return ''
    }
  }

  @Input() public set persona(persona: Persona | us.Persona | OrgPersona) {
    this._persona = persona
    this.setFields()
  }

  public ngOnInit(): void {
    this.initTranslations()
  }

  private initTranslations(): void {
    this.seFeTranslationsLoader.load('shared/ui-persona-avatar/assets/translations').then(() => {
      this.ready = true
      this.setFields()
    })
  }

  private setFields(): void {
    if (!this.persona) return

    this.defaultSubtitle = [this.dob, this.showGraduation && this.graduation, this.gender].filter(x => !!x)
    this.title = 'name' in this.persona ? this.persona.name : `${this.persona?.first_name} ${this.persona?.last_name}`
    this.setImageUrl()
  }

  private setImageUrl(): void {
    const profileImages = 'profile_image' in this.persona ? this.persona.profile_image : this.persona.profile_images
    this.imageUrl = (profileImages || []).find(i => i.image_type === 'crop')?.url
  }
}
