<form [formGroup]="form" *ngIf="form">
  <se-fe-form-field *ngIf="loaded"
    [seFeDataConfig]="{
        label: seFeDataLabel,
        errorMessages: {
          required: 'SE_PO_UI_STATE_SELECT.ERRORS.required' | translate
        }
      }">
    <se-fe-form-field-select
      [formControlName]="ngControl.name"
      [seFeDataOptions]="stateOptions"
      [seFeDataComfy]="true">
    </se-fe-form-field-select>
  </se-fe-form-field>
</form>
