<se-fe-spinner
  *ngIf="!loaded"
  [seFeDataSize]="900"
  [seFeDataCenter]="true">
</se-fe-spinner>
<se-fe-list *ngIf="loaded">
  <se-fe-list-item *ngFor="let listItem of listItems">
    <se-fe-header [seFeDataSubtitle]="listItem.subtitle" [seFeDataSize]="600">
      <div seFeHeaderTitle class="registration-list-title">
        <a seFeLink="low" [href]="listItem.url">
          {{ listItem.title }}
        </a>
      </div>
    </se-fe-header>
  </se-fe-list-item>
</se-fe-list>
<se-fe-empty-state *ngIf="loaded && !listItems.length" seFeDataTitle="No Registrations Found">
  <se-fe-icon seFeEmptyStateIcon seFeDataName="spot_whistle" seFeDataSize="950"></se-fe-icon>
</se-fe-empty-state>
