import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SharedUiPersonaAvatarModule } from '@se-po/shared-ui-persona-avatar'
import { SeFeAlertModule } from 'se-fe-alert'
import { SeFeHeaderModule } from 'se-fe-header'
import { SeFeModalModule } from 'se-fe-modal'
import { SeFeToastModule } from 'se-fe-toast'
import { RemovePersonaModalComponent } from './remove-persona-modal/remove-persona-modal.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeAlertModule,
    SeFeHeaderModule,
    SeFeModalModule,
    SeFeToastModule,
    SharedUiPersonaAvatarModule
  ],
  declarations: [RemovePersonaModalComponent],
  exports: [RemovePersonaModalComponent],
})
export class SharedFeatureRemovePersonaModalModule {}
