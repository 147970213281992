import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { PublicOrganization } from '@se-po/shared-data-access-models'
import { environment } from '@se-po/shared-environments'
import { Persona, Team } from '../models'
import { SpinnerService, PersonaService } from '../services'

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public destinations: any[] = []
  public groupedDestinations: {
    teams: Team[]
    orgs: PublicOrganization[]
    me: Persona[]
  }
  public organizations: PublicOrganization[]
  public personaSelf: Persona
  public personas: Persona[]
  public teams: Team[] = []
  public userHasChildren = false

  constructor(private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private router: Router,
    private personaService: PersonaService,
    private spinnerService: SpinnerService) {
    // noop
   }

  public ngOnInit(): void {
    this.spinnerService.start()
    this.destinations = []
    this.personaSelf = this.activatedRoute.snapshot.data.personaSelf
    this.organizations = this.activatedRoute.snapshot.data.orgs
    this.teams = this.activatedRoute.snapshot.data.teams

    this.personaService.getGuarded().subscribe(
      (personas: Persona[]) => {
        this.personas = personas
        this.setAccountDestination(this.personas)
        this.setOrgDestinations(this.organizations)
        this.setTeamDestinations(this.teams)
        this.displayDestinations()
        this.spinnerService.stop()
      }
    )
  }


  // PERSONAS -- The account destination will be first for parents, and last for non-parents
  private setAccountDestination(personas: Persona[]): void {
    if (!personas || personas.length === 0) return
    this.userHasChildren = personas.length > 1
    this.destinations.push({
      persona: this.personaSelf,
      title: this.translate.instant('INTERSTITIAL.my_account'),
      description: this.personaSelf.fullName,
      href: '/user',
      title_group: this.translate.instant('INTERSTITIAL.my_account')
    })
  }

  // ORGS -- orgs get inserted after the account destination for parents, but before for non-parents
  private setOrgDestinations(orgs: PublicOrganization[]) {
    orgs.forEach(org => {
      this.destinations.push({
        org,
        title: org?.name,
        href: `${environment.app}org/${org.id}`,
        target: '_self',
        title_group: this.translate.instant('INTERSTITIAL.my_orgs')
      })
    })
  }

  private setTeamDestinations(teams: Team[]) {
    teams.forEach(team => {
      this.destinations.push({
        team,
        title: team.name,
        description: team.program_primary_text,
        href: team.url,
        target: '_self',
        title_group: this.translate.instant('INTERSTITIAL.my_teams')
      })
    })
  }

  private displayDestinations(): void {
    this.groupedDestinations = {
      teams: this.destinations.filter(t => t.title_group === this.translate.instant('INTERSTITIAL.my_teams')),
      orgs: this.destinations.filter(t => t.title_group === this.translate.instant('INTERSTITIAL.my_orgs')),
      me: this.destinations.filter(t => t.title_group === this.translate.instant('INTERSTITIAL.my_account'))
    }

    if (this.destinations.length <= 1) {
      void this.router.navigate(['/user'])
    }
  }
}
