<se-fe-header seFeDataSize="500" [seFeDataTitle]="rsvp.identity" [seFeDataSubtitle]="rsvp.note">
  <se-fe-avatar class="rsvpListAvatar" seFeHeaderVisual
    seFeDataType="persona"
    [seFeDataIdentity]="rsvp.identity"
    seFeDataSize="200">
  </se-fe-avatar>
  <div seFeHeaderActions style="display: flex;">
    <se-fe-button-group>
      <div class="rsvpListButton">
        <se-fe-icon-button class="rsvpOptionsButton" (click)="rsvpListAction('yes', rsvp)">
          <se-fe-icon
            [seFeDataName]="rsvp.response === 'yes'? 'check_circle' : 'check_circle_outline'"
            seFeDataSize="700"
            [seFeDataColor]="rsvp.response === 'yes'? 'green' : 'gray-700'"
            [seFeDataMargin]="false"
            [seFeDataAriaLabel]="'yes'">
          </se-fe-icon>
        </se-fe-icon-button>
        <span class="rsvpOptionText">YES</span>
      </div>
      <div class="rsvpListButton">
        <se-fe-icon-button class="rsvpOptionsButton" (click)="rsvpListAction('maybe', rsvp)">
          <se-fe-icon
            [seFeDataName]="rsvp.response === 'maybe'? 'question_circle' : 'question_circle_outline'"
            seFeDataSize="700"
            [seFeDataColor]="rsvp.response === 'maybe'? 'orange' : 'gray-700'"
            [seFeDataMargin]="false"
            [seFeDataAriaLabel]="'maybe'">
          </se-fe-icon>
        </se-fe-icon-button>
        <span class="rsvpOptionText">MAYBE</span>
      </div><div class="rsvpListButton">
        <se-fe-icon-button class="rsvpOptionsButton" (click)="rsvpListAction('no', rsvp)">
          <se-fe-icon
            [seFeDataName]="rsvp.response === 'no'? 'remove_circle' : 'remove_circle_outline'"
            seFeDataSize="700"
            [seFeDataColor]="rsvp.response === 'no'? 'red' : 'gray-700'"
            [seFeDataMargin]="false"
            [seFeDataAriaLabel]="'no'">
          </se-fe-icon>
        </se-fe-icon-button>
        <span class="rsvpOptionText">NO</span>
      </div>
      </se-fe-button-group>
      <se-fe-icon-button [seFeMenuTrigger]="noteMenuOptions.name">
        <se-fe-icon
          [seFeDataName]="'more_verticle'"
          seFeDataSize="800"
          seFeDataColor="gray-500">
        </se-fe-icon>
      </se-fe-icon-button>
      <se-fe-menu [seFeDataOptions]="noteMenuOptions"></se-fe-menu>
  </div>
</se-fe-header>
