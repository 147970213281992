import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { take } from 'rxjs/operators'
import { CalendarServiceResources as cs } from 'se-resource-types'
import { RsvpListItem } from '../models'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})
export class RsvpService {

  constructor(private api: ApiService) { }

  public setRsvp(rsvp: RsvpListItem): Observable<cs.RsvpBody> {
    const data = {
      team_id: rsvp.teamId,
      event_id: rsvp.eventId,
      persona_id: rsvp.personaId,
      response: rsvp.response,
      note: rsvp.note,
    }

    return this.api.post('v3/rsvp', data).pipe(take(1))
  }

  public deleteNote(rsvp: RsvpListItem): Observable<cs.RsvpBody> {
    rsvp.note = ''
    const data = {
      team_id: rsvp.teamId,
      event_id: rsvp.eventId,
      persona_id: rsvp.personaId,
      response: rsvp.response,
      note: rsvp.note
    }
    return this.api.post('v3/rsvp', data).pipe(take(1))
  }
}
