import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'
import { CentralServiceResources as cs } from 'se-resource-types'

@Injectable({
  providedIn: 'root'
})
export class HouseholdGuardianInvitesService {
  private endpoint = 'v3/households/guardian_invites'

  constructor(
    private api: SeFeApiService
  ) {
      // noop
  }

  public create(access: string, to: string, persona_id: number, initiator: string): Observable<cs.Households.PersonaGuardianInvite> {
    return this.api.post<cs.Households.PersonaGuardianInvite>(this.endpoint, { access, to, persona_id, initiator }).pipe(
      map(response => response.result)
    )
  }

  public acceptInvite(id: string | number, activationCode: string): Observable<cs.Households.UserGuardianInvite> {
    return this.api.put<cs.Households.UserGuardianInvite>(`${this.endpoint}/${id}/accept`, { activation_code: activationCode }).pipe(
      map(response => response.result)
    )
  }

  public resend(id: string | number): Observable<cs.Households.PersonaGuardianInvite> {
    return this.api.get<cs.Households.PersonaGuardianInvite>(`${this.endpoint}/${id}/resend_invite`).pipe(
      first(),
      map(response => response.result)
    )
  }

  public cancel(id: string | number): Observable<any> {
    return this.api.delete(`${this.endpoint}/${id}`).pipe(
      first(),
      map(response => response)
    )
  }
}
