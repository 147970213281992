import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { AnalyticsService } from '@se-po/shared-data-access-services'
import { environment } from '@se-po/shared-environments'
import { Subscription, combineLatest, map } from 'rxjs'
import { SeFeToastService } from 'se-fe-toast'
import { Event, Persona } from '../../models'
import { CalendarService, PersonaService, RsvpService } from '../../services'
import { EventView, RsvpView } from '../../view-models'


@Component({
  selector: 'app-dashboard-schedule',
  templateUrl: './dashboard-schedule.component.html',
  styleUrls: ['./dashboard-schedule.component.scss']
})
export class DashboardScheduleComponent implements OnInit, OnDestroy {
  public loaded = false
  public failed = false
  public days: { day: string; events: EventView[] }[]
  public events: Event[]
  private calendarSubscription: Subscription
  private personas: Persona[]

  constructor(
    private analyticsService: AnalyticsService,
    private calendarService: CalendarService,
    private rsvpService: RsvpService,
    private translateService: TranslateService,
    private toast: SeFeToastService,
    private personaService: PersonaService,
    private elementRef: ElementRef
  ) { }

  public ngOnDestroy(): void {
    this.calendarSubscription.unsubscribe()
  }

  public ngOnInit(): void {
    this.calendarService.reset()
    this.calendarSubscription = combineLatest([this.calendarService.current, this.personaService.getGuarded()])
    .pipe(map((data: any[]) => ({ events: data[0] as Event[], personas: data[1] as Persona[] })))
    .subscribe(
      (data: { events: Event[]; personas: Persona[] }) => {
        if (data.events == null) return
        this.personas = data.personas
        this.events = data.events
        this.days = this.calendarService.initDays(this.events, this.personas, false)
        this.loaded = true
      },
      (error: any) => {
        this.loaded = true
        this.failed = true
      }
    )
    this.calendarService.mine({ per_page: 3 })

    let scriptTags = customElements.get('se-fe-play-chip')
    const elRoot = this.elementRef.nativeElement.getRootNode()

    if (!customElements.get('se-fe-play-chip')) {
      scriptTags = elRoot.querySelector(
        `script[src="${environment.seFeAngularServices}/se-fe-play-chip/se-fe-play-chip.js"]`
      )
    }

    if (!scriptTags) {
      const script = document.createElement('script')
      script.src = `${environment.seFeAngularServices}/se-fe-play-chip/se-fe-play-chip.js`
      elRoot.body.appendChild(script)
    }
  }

  public onRsvp(rsvpChange: any): void {
    const newRsvp: RsvpView = rsvpChange.newValue
    const capitalizedEventType = `${newRsvp.eventType.charAt(0).toUpperCase()}${newRsvp.eventType.slice(1)}`
    const cardType = `${capitalizedEventType}Card`

    this.analyticsService.seEvent(`${cardType}.RSVP.${newRsvp.response}`, 8)

    this.rsvpService.setRsvp(newRsvp).subscribe(
      res => {
        this.toast.success(
          this.translateService.instant('SCHEDULE.rsvp_success', {
            identity: newRsvp.identity,
            response: newRsvp.response
          })
        )
      },
      err => this.toast.error(
        this.translateService.instant('SCHEDULE.rsvp_error')
      )
    )
  }

  public load(): void {
    this.ngOnInit()
  }

  public fireAnalytics() {
    this.analyticsService.seEvent('UpcomingGamesAndEvents.SeeAll.Click', 8)
  }

}
