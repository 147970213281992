import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeFormFieldTextModule } from 'se-fe-form-field-text'
import { SeFeIconModule, seFeIconPhoneFill } from 'se-fe-icon'
import { SeFeTranslationsModule } from 'se-fe-translations'
import { PhoneComponent } from './phone/phone.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SeFeFormFieldModule,
    SeFeFormFieldTextModule,
    SeFeIconModule.include([
      seFeIconPhoneFill
    ]),
    SeFeTranslationsModule
  ],
  declarations: [
    PhoneComponent
  ],
  exports: [
    PhoneComponent
  ]
})
export class SharedUiPhoneModule {}
