import { forwardRef, Injectable } from '@angular/core'
import { PersonaService } from '@se-po/shared-data-access-services'
import { catchError, first, Observable, of } from 'rxjs'
import { UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'

@Injectable({
  providedIn: 'root',
  useExisting: forwardRef(() => MergePersonaModalSubmitService)
})
export abstract class MergePersonaModalSubmitter {
  public abstract error: string
  public abstract submit(keepProfile: UserProfile, mergeProfile: UserProfile, description: string): Observable<any>
}

@Injectable({
  providedIn: 'root'
})
export class MergePersonaModalSubmitService implements MergePersonaModalSubmitter {
  public error: string

  constructor(
    private personaService: PersonaService
  ) {
  }

  public submit(keepProfile: UserProfile, mergeProfile: UserProfile, description: string): Observable<any> {
    this.error = null
    return this.personaService.merge(keepProfile.persona.id, mergeProfile.persona.id, description)
      .pipe(
        first(),
        catchError(err => {
          this.error = 'The profiles could not be merged. Retry?'
          return of(null)
        })
      )
  }
}
