<se-fe-modal #imageModal
  (seFeAfterClose)="clearModal()">

  <div seFeModalHeader>
    <se-fe-modal-header
      seFeDataTitle="Upload Profile Photo">
    </se-fe-modal-header>
  </div>

  <div seFeModalContent *ngIf="personaId">
    <se-fe-alert *ngIf="error" seFeDataType="error">
      {{ error }}
    </se-fe-alert>
    <se-fe-image-uploader #imageUploader
      (uploadSuccess)="handleAttachment($event)"
      (uploadCleared)="handleAttachment($event)"
      (uploadFailed)="onUploadFailure()"
      seFeAcceptFormat="image/jpeg,image/png"
      seFeDataCentered=true
      seFeDataCircular=true
      seFeDataCrop=true
      seFeDataCropFormat="jpeg"
      seFeDataCropHeight="1000"
      seFeDataCropWidth="1000">
    </se-fe-image-uploader>
  </div>

  <div seFeModalFooter>
    <se-fe-modal-footer #imageModalFooter
      [seFeDataFooterButtons]="imageModalButtons">
    </se-fe-modal-footer>
  </div>

</se-fe-modal>
<se-fe-toast></se-fe-toast>
