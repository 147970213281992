import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedUiEmailModule } from '@se-po/shared-ui-email'
import { SharedUiPersonaAvatarModule } from '@se-po/shared-ui-persona-avatar'
import { SeFeAlertModule } from 'se-fe-alert'
import { SeFeAvatarModule } from 'se-fe-avatar'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeCardModule } from 'se-fe-card'
import { SeFeFormFieldOptionsModule } from 'se-fe-form-field-options'
import { SeFeHeaderModule } from 'se-fe-header'
import { SeFeIconModule, seFeIconMore, seFeIconPlus } from 'se-fe-icon'
import { SeFeListModule } from 'se-fe-list'
import { SeFeMenuModule } from 'se-fe-menu'
import { SeFeModalModule } from 'se-fe-modal'
import { SeFeSpinnerModule } from 'se-fe-spinner'
import { SeFeToastModule } from 'se-fe-toast'
import { AddGuardianModalComponent } from './add-guardian-modal/add-guardian-modal.component'
import { GuardianPermissionsCardComponent } from './guardian-permissions-card/guardian-permissions-card.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SeFeAlertModule,
    SeFeAvatarModule,
    SeFeCardModule,
    SeFeButtonModule,
    SeFeFormFieldOptionsModule,
    SeFeHeaderModule,
    SeFeIconModule.include([seFeIconMore, seFeIconPlus]),
    SeFeListModule,
    SeFeMenuModule,
    SeFeModalModule,
    SeFeSpinnerModule,
    SeFeToastModule,
    SharedUiEmailModule,
    SharedUiPersonaAvatarModule,
  ],
  declarations: [GuardianPermissionsCardComponent, AddGuardianModalComponent],
  exports: [GuardianPermissionsCardComponent, AddGuardianModalComponent],
})
export class SeMySportsengineFeatureGuardianPermissionsCardModule { }
