import { Injectable } from '@angular/core'
import { DibSession } from '../models'
import { Observable } from 'rxjs'
import { NginV2ApiService } from './nginV2Api.service'

@Injectable({
  providedIn: 'root'
})
export class DibSessionService {

  constructor(private api: NginV2ApiService) {
    // noop
  }

  public findAll(): Observable<DibSession[]> {
    return this.api.findAllAs('dib_sessions/mine', {} , DibSession)
  }
}
