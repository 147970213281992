import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { PublicOrganization } from '@se-po/shared-data-access-models'
import { OrganizationService } from '@se-po/shared-data-access-services'
import { Observable, map } from 'rxjs'

export const MyOrganizationsResolver: ResolveFn<PublicOrganization[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: OrganizationService = inject(OrganizationService)
): Observable<PublicOrganization[]> => service.mine().pipe(map(data => data.result))
