import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { Observable, of } from 'rxjs'
import { Sale } from '../models'
import { InvoiceService } from '../services/invoice.service'

export const InvoiceResolver: ResolveFn<Sale> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: InvoiceService = inject(InvoiceService)
): Observable<Sale> => {
  const saleNumber = route.params.saleNumber
  return saleNumber ? service.get(saleNumber) : of(null)
}
