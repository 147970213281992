import { Component, Input, OnInit } from '@angular/core'
import { AbstractControl, UntypedFormArray, UntypedFormGroup, Validators } from '@angular/forms'
import { BaseValueAccessorDirective } from '@se-po/shared-ui-form-directives'
import { SeFeTranslationsLoader } from 'se-fe-translations'

@Component({
  selector: 'se-po-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
  hostDirectives: [BaseValueAccessorDirective]
})
export class EmailComponent implements OnInit {
  @Input() public hiddenLabel = false
  @Input() public seFeDataLabel?: string

  public loaded = false
  public ngControl = BaseValueAccessorDirective.ngControl(this) // get the forwarded control

  constructor(
    private seFeTranslationsLoader: SeFeTranslationsLoader)
  { }

  public get control(): AbstractControl {
    return this.ngControl?.control
  }

  public get form(): UntypedFormGroup | UntypedFormArray {
    return this.control?.parent
  }

  public ngOnInit(): void {
    this.initTranslations()
  }

  public initValidation(): void {
    this.control.addValidators(Validators.email)
    this.control.updateValueAndValidity()
  }

  public initTranslations(): void {
    this.seFeTranslationsLoader.load('shared/ui-email/assets/translations').then(() => {
      this.loaded = true
      this.initValidation()
    })
  }
}
