<fieldset *ngIf="loaded" [formGroup]="form" class="name-fieldset">
  <legend class="name-legend">
    {{ seFeDataLabel }}
  </legend>
  <se-fe-form-control-label
    [seFeDataLabel]="'SE_PO_UI_NAME.LABELS.first' | translate"
    [seFeDataLabelFor]="firstLabelFor"
    [seFeDataLabelHidden]="true"
    [seFeDataRequired]="true">
  </se-fe-form-control-label>
  <se-fe-form-field-text #first
    class="first-field"
    formControlName="first"
    [seFeDataPlaceholder]="'SE_PO_UI_NAME.LABELS.first' | translate"
    [seFeDataComfy]="true"
    (change)="trimValue('first')">
  </se-fe-form-field-text>
  <div class="name-fields">
    <se-fe-form-control-label
      [seFeDataLabel]="'SE_PO_UI_NAME.LABELS.last' | translate"
      [seFeDataLabelFor]="lastLabelFor"
      [seFeDataLabelHidden]="true"
      [seFeDataRequired]="true">
    </se-fe-form-control-label>
    <se-fe-form-field-text #last
      [class]="showSuffix ? 'last-field show-suffix' : 'last-field'"
      formControlName="last"
      [seFeDataPlaceholder]="'SE_PO_UI_NAME.LABELS.last' | translate"
      [seFeDataComfy]="true"
      (change)="trimValue('last')">
    </se-fe-form-field-text>

    <se-po-suffix-select *ngIf="showSuffix"
      class="suffix-field"
      formControlName="suffix"
      hiddenLabel="true"
      [seFeDataLabel]="'SE_PO_UI_NAME.LABELS.suffix' | translate"
      [seFeDataPlaceholder]="'SE_PO_UI_NAME.LABELS.suffix' | translate">
    </se-po-suffix-select>
  </div>

  <se-fe-form-control-errors
    *ngIf="errors.length"
    [seFeDataMessages]="errorMessages"
    [seFeDataErrors]="errors"
    [seFeDataId]="errorId">
  </se-fe-form-control-errors>

  <se-fe-chip-group class="add-fields-chip-group"
    *ngIf="(enablePreferred || enableSuffix) && !(showPreferred && showSuffix)">
    <se-fe-chip *ngIf="enablePreferred && !showPreferred"
      [seFeDataText]="'SE_PO_UI_NAME.CHIPS.preferred' | translate"
      seFeDataStyle="primary"
      seFeDataIconLeft="plus_circle_outline"
      (seFeClick)="showPreferred = true">
    </se-fe-chip>
    <se-fe-chip *ngIf="enableSuffix && !showSuffix"
      [seFeDataText]="'SE_PO_UI_NAME.CHIPS.suffix' | translate"
      seFeDataStyle="primary"
      seFeDataIconLeft="plus_circle_outline"
      (seFeClick)="showSuffix = true">
    </se-fe-chip>
  </se-fe-chip-group>

  <se-fe-form-field *ngIf="showPreferred"
    class="preferred-field"
    [seFeDataConfig]="{ label: ('SE_PO_UI_NAME.LABELS.preferred' | translate) }">
    <se-fe-form-field-text
      formControlName="preferred"
      [seFeDataComfy]="true"
      (change)="trimValue('preferred')">
    </se-fe-form-field-text>
  </se-fe-form-field>
</fieldset>
