<se-fe-form-field *ngIf="loaded"
  [seFeDataConfig]="{ label: seFeDataLabel, required: isRequired }">
  <se-fe-datepicker class="dob-datepicker"
    [formControl]="control"
    [seFeDataSuffix]="age"
    [seFeDataExcludeDates]="datepickerLimit"
    [seFeDataYearRange]="{ future: 0, past: 100 }"
    [seFeDataMessages]="errorMessages"
    seFeDataSuffixSize=3
    [seFeDataComfy]="true">
  </se-fe-datepicker>
</se-fe-form-field>
