import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeCardModule } from 'se-fe-card'
import { SeFeSpinnerModule } from 'se-fe-spinner'
import { SeFeTabGroupModule, SeFeTabsModule } from 'se-fe-tabs'

import { SharedFeatureMembershipListModule } from '@se-po/shared-feature-membership-list'
import { SharedFeatureOrganizationListModule } from '@se-po/shared-feature-organization-list'
import { SharedFeatureRegistrationListModule } from '@se-po/shared-feature-registration-list'
import { SharedFeatureTeamListModule } from '@se-po/shared-feature-team-list'
import { OverviewCardComponent } from './overview-card/overview-card.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeButtonModule,
    SeFeCardModule,
    SeFeSpinnerModule,
    SeFeTabGroupModule,
    SeFeTabsModule,
    SharedFeatureMembershipListModule,
    SharedFeatureOrganizationListModule,
    SharedFeatureRegistrationListModule,
    SharedFeatureTeamListModule
  ],
  declarations: [
    OverviewCardComponent
  ],
  exports: [
    OverviewCardComponent
  ],
})
export class SharedUiOverviewCardModule {}
