import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'

import { EditPersonaComponent } from './edit-persona/edit-persona.component'
import { SeFeSpinnerModule } from 'se-fe-spinner'
import { SharedUiPersonaInfoModule } from '@se-po/shared-ui-persona-info'
import { SharedUiContactInfoModule } from '@se-po/shared-ui-contact-info'

@NgModule({
  declarations: [EditPersonaComponent],
  exports: [EditPersonaComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SeFeSpinnerModule,
    SharedUiContactInfoModule,
    SharedUiPersonaInfoModule,
  ],
})
export class SharedUiEditPersonaModule { }
