import { Component } from '@angular/core'

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html'
})
export class AccessDeniedComponent {

  constructor() {
      // noop
     }
}
