import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { take } from 'rxjs/operators'

import { InvoiceService } from '../../services/invoice.service'
import { FinancialSummary, Money, Persona } from '../../models'

@Component({
  selector: 'app-dashboard-payments',
  templateUrl: './dashboard-payments.component.html'
})
export class DashboardPaymentsComponent implements OnInit {
  public loaded = false

  public personaSelf: Persona
  public bills: FinancialSummary[] = []

  constructor(private invoiceService: InvoiceService,
              private activatedRoute: ActivatedRoute,
              private translate: TranslateService) {
  }

  public ngOnInit(): void {
    this.loadPayments()
  }

  private loadPayments(): void {
    this.personaSelf = this.activatedRoute.snapshot.data.personaSelf
    const params: any = { user_id: this.personaSelf?.user.id }

    this.invoiceService.financialSummary(params).pipe(take(1)).subscribe((result) => {
        this.bills = result
        this.formatPayments()
        this.loaded = true
      })
  }

  private formatPayments(): void {
    this.bills.forEach((payment) => {
      payment.money = new Money(payment.amount)

      if (payment.state.indexOf(this.translate.instant('DASHBOARD.PAYMENTS.past_due_state')) >= 0) {
        payment.tags = [{
          value: this.translate.instant('DASHBOARD.PAYMENTS.past_due'),
          color: this.translate.instant('DASHBOARD.PAYMENTS.color')
        }]
      }
      payment.view_sale = payment.state.indexOf(this.translate.instant('DASHBOARD.PAYMENTS.auto_pay')) >= 0
    })
  }

  private summaryText(payment: FinancialSummary): string {
    if (payment.view_sale) {
      return payment.sourceType === 'Invoice' ? 'DASHBOARD.PAYMENTS.view_bill' : 'DASHBOARD.PAYMENTS.view_order'
    } else {
      return 'DASHBOARD.PAYMENTS.pay_now'
    }
  }
}
