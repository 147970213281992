import { HttpErrorResponse } from '@angular/common/http'
import { Component, EventEmitter, Output, ViewChild } from '@angular/core'
import { HouseholdProfileImageService } from '@se-po/shared-data-access-services'
import { SeFeModalButton, SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { SeFeToastService } from 'se-fe-toast'

@Component({
  selector: 'se-po-persona-image-modal',
  templateUrl: './persona-image-modal.component.html',
  styleUrls: ['./persona-image-modal.component.scss'],
})
export class PersonaImageModalComponent {
  @ViewChild('imageModal') public imageModal: SeFeModalComponent
  @ViewChild('imageModalFooter') public imageModalFooter: SeFeModalFooterComponent
  @Output() public personaEdited = new EventEmitter<number>()

  public error = null
  public imageModalButtons: SeFeModalButton[] = [
    {
      cancel: true,
      label: 'Cancel',
      action: () => {
        this.close()
      },
    },
    {
      primary: true,
      label: 'Save',
      action: () => {
        this.updatePersona(this.newImage)
      },
    },
  ]
  public newImage: File
  public personaId: number

  constructor(
    private householdProfileImageService: HouseholdProfileImageService,
    private toastService: SeFeToastService,
  ) { }

  public open(personaId: number): void {
    this.personaId = personaId
    this.imageModal.open(null)
  }

  public clearModal(): void {
    this.personaId = null
    this.error = null
  }

  public close(): void {
    this.imageModal.close()
  }

  public onUploadFailure() {
    this.error = 'Invalid image, please try again.'
    this.imageModalFooter.cancelSpinner()
    this.toastService.error(this.error)
  }

  public handleAttachment(event: any): void {
    this.error = null
    this.newImage = event
  }

  public updatePersona(file: any): void {
    if (!file || !file.base64) return this.imageModalFooter.cancelSpinner()

    const body = {
      profile_image: {
        data: file.base64,
        filetype: file.type,
        filename: file.name,
        file: {}
      }
    }
    this.householdProfileImageService.save(this.personaId.toString(), body).subscribe({
      next: (res) => {
        this.personaEdited.emit(this.personaId)
        this.close()
      },
      error: (err: HttpErrorResponse) => {
        this.onUploadFailure()
      }
    })
  }


}
