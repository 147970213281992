import { Component, Input, OnInit } from '@angular/core'
import { environment } from '@se-po/shared-environments'

@Component({
  selector: 'se-po-persona-eligibility',
  templateUrl: './persona-eligibility.component.html',
  styleUrls: ['./persona-eligibility.component.scss']
})
export class PersonaEligibilityComponent implements OnInit {
  public static scriptsLoaded = false

  @Input()
  public personaId: number

  @Input()
  public contextOrgId: number

  @Input()
  public eligibilityRuleSetIds: string[]

  private personaEligibilityListProps: string

  public ngOnInit(): void {
    this.personaEligibilityListProps = JSON.stringify({
      persona_id: this.personaId,
      eligibility_rule_set_ids: this.eligibilityRuleSetIds,
      context_organization_id: this.contextOrgId
    })
    this.includeScripts()
  }

  private includeScripts(): void {
    if (PersonaEligibilityComponent.scriptsLoaded) {
      setTimeout(() => this.loadComponent())
    }
    else {
      this.injectScript()
      this.injectStyles()
      PersonaEligibilityComponent.scriptsLoaded = true
    }
  }

  private injectScript(): void {
    const script = window.document.createElement('script')
    script.async = true
    script.src = `${environment.eligibilityListUi}/eligibility-list-element.js`
    script.addEventListener('load', this.scriptLoaded.bind(this))
    window.document.body.appendChild(script)
  }

  private scriptLoaded(): void {
    this.loadComponent()
  }

  private injectStyles(): void {
    const stylesLink = window.document.createElement('link')
    stylesLink.rel = 'stylesheet'
    stylesLink.href = `${environment.eligibilityListUi}/styles.css`
    window.document.head.appendChild(stylesLink)
  }

  private loadComponent(): void {
    const contents = window.document.getElementById('persona-eligibility-list')
    const list = window.document.createElement('persona-eligibility-list')
    list.setAttribute('context', this.personaEligibilityListProps)
    contents.replaceChildren(list)
  }

}
