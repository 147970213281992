import { Component, OnInit } from '@angular/core'
import { AnalyticsService } from '@se-po/shared-data-access-services'
import { Observable, forkJoin } from 'rxjs'
import { mergeMap, take } from 'rxjs/operators'
import { Persona, RecommendedAction } from '../../models'
import { PersonaService } from '../../services'
import { AbuseTrainingService } from '../../services/abuse-training.service'
import { EligibilityCredentialSearchService } from '../../services/eligibility-credential-search.service'
import { FinancialSummaryService } from '../../services/financial-summary.service'

@Component({
  selector: 'app-dashboard-recommended-actions',
  templateUrl: './dashboard-recommended-actions.component.html',
  styleUrls: ['./dashboard-recommended-actions.component.scss']
})
export class DashboardRecommendedActionsComponent implements OnInit {

  public loaded = false
  public actions: RecommendedAction[] = []
  public personas: Persona[]

  constructor(private financialSummaryService: FinancialSummaryService,
    private eligibilityCredentialSearchService: EligibilityCredentialSearchService,
    private window: Window,
    private abuseTrainingService: AbuseTrainingService,
    private analyticsService: AnalyticsService,
    private personaService: PersonaService) { }

  public ngOnInit(): void {
    const personaEligibilities: Observable<RecommendedAction[]> = this.personaService.getGuarded()
      .pipe(
        mergeMap(personas => this.eligibilityCredentialSearchService.getPersonaEligibilites(personas))
      )

    forkJoin([
      this.financialSummaryService.pastDueActions(),
      personaEligibilities,
      this.abuseTrainingService.getRecommendedActions('aps'),
      this.abuseTrainingService.getRecommendedActions('safesport')
    ]).pipe(take(1)).subscribe(
      (actions: RecommendedAction[][]) => {
        // flatten
        this.actions = actions.reduce((acc, val) => acc.concat(val), [])
        this.loaded = true
      }
    )
  }

  public recommendedActionClick(action: RecommendedAction): void {
    this.analyticsService.seEvent('AlertCompleteRequirementsClick', 8)
    this.navigateToAction(action)
  }

  public navigateToAction(action: RecommendedAction): void {
    this.window.open(action.link, action.target)
  }
}
