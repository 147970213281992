import { Component, OnDestroy, OnInit, Input, OnChanges } from '@angular/core'
import { DateTime } from 'luxon'


@Component({
  selector: 'app-date-stamp',
  templateUrl: './date-stamp.component.html',
  styleUrls: ['./date-stamp.component.scss']
})
export class DateStampComponent implements OnInit, OnChanges {

  @Input('dateString') public dateString: string

  public weekDay: string
  public month: string
  public monthDay: string

  constructor( ) {
      //noop
    }

  public ngOnInit(): void {
    if (this.validateInput(this.dateString)) {
      this.initDate()
    }
  }

  public ngOnChanges(): void {
    if (this.validateInput(this.dateString)) {
      this.initDate()
    }
  }

  public initDate(): void {
    const formattedDate = this.dateString.split('-')
    const luxDay = parseInt(formattedDate[2], 10)
    const luxMonth = parseInt(formattedDate[1], 10)
    const luxYear = parseInt(formattedDate[0], 10)

    const num = DateTime.local(luxYear, luxMonth, luxDay).day.toString()
    this.monthDay = num.length === 1 ? '0' + num : num
    this.weekDay = DateTime.local(luxYear, luxMonth, luxDay).weekdayShort.toUpperCase()
    this.month = DateTime.local(luxYear, luxMonth, luxDay).monthShort.toUpperCase()

  }

  private validateInput(date: string): boolean {
    const regex = /[0-9]{4}-[0-9]{2}-[0-9]{2}/i
    return regex.test(date)
}

}
