import { MappableModel } from '@se-po/shared-data-access-models'

export class TeamPreferences extends MappableModel {
  public enabledPrefs: any[] = []
  public disabledPrefs: any[] = []
  public teamAlertPreferences: any[] = []
  public rosterPersonaList: any[] = []
  public preferencesById: any[] = []
  public preferences: any[] = []
}
