import { forwardRef, Injectable } from '@angular/core'
import { HouseholdGuardianInvitesService } from '@se-po/shared-data-access-services'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { CentralServiceResources as cs } from 'se-resource-types'

@Injectable({
  providedIn: 'root',
  useExisting: forwardRef(() => CreateGuardianInvitesService),
})

export abstract class CreateGuardianInviteSubmitter {
  public abstract error: string
  public abstract create(access: string, to: string, persona_id: number, initiator: string): Observable<cs.Households.PersonaGuardianInvite>
}

@Injectable({
    providedIn: 'root',
  })

  export class CreateGuardianInvitesService implements CreateGuardianInviteSubmitter {
    public error: string
    private endpoint = 'v3/households/guardian_invites'

    constructor(
      private inviteService: HouseholdGuardianInvitesService
    ) {
        // noop
    }

    public create(access: string, to: string, persona_id: number, initiator: string): Observable<cs.Households.PersonaGuardianInvite> {
      return this.inviteService.create(access, to, persona_id, initiator).pipe(
        map(result => result)
      )
    }
  }
