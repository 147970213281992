import {
  CommonResources as cr,
  UserServiceResources as us,
} from 'se-resource-types'

import { UserEmailAddress } from './user-email-address'
import { MappableModel } from '@se-po/shared-data-access-models'

export class User extends MappableModel implements us.User {
  public email_addresses: UserEmailAddress[]
  public external_identity_provider: boolean
  public first_name: string
  public id: number
  public is_activated: boolean
  public last_name: string
  public password_last_updated_at: string
  public pending_email_addresses: UserEmailAddress[]
  public phone_number: { id: number; phone: string; validated: boolean }
  public phone_numbers: us.PhoneNumber[]
  public profile_images: cr.ProfileImage[]
  public uri: string
  public user_name: string
  public uuid: string

  public get primaryPhoneValidated(): string {
    return this.phone_number?.validated ? this.phone_number.phone : undefined
  }

  public get primaryPhoneUnvalidated(): string {
    return (this.phone_number && !this.phone_number.validated) ? this.phone_number.phone : undefined
  }

  public get primaryEmail(): string {
    const addr = this.email_addresses?.find(ea => ea.is_primary && ea.is_validated)
    return addr ? addr.address : undefined
  }

  public get pendingPrimaryEmail(): UserEmailAddress {
    const addr = this.pending_email_addresses?.find(ea => ea.is_primary)
    return addr || undefined
  }

  public get pendingOtherEmails(): UserEmailAddress[] {
    const emails = this.pending_email_addresses?.filter(ea => !ea.is_primary)
    return emails || []
  }

  public get otherEmails(): UserEmailAddress[] {
    const emails = this.email_addresses?.filter(ea => !ea.is_primary && !ea.markedForDeletion && !ea.markedForPrimary)
    return emails || []
  }
}
