import { Injectable } from '@angular/core'
import { PublicOrganization, nginV2Headers } from '@se-po/shared-data-access-models'
import { Observable, of } from 'rxjs'
import { first, map, tap } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'
import { ApiResponse } from 'se-resource-types/dist/lib/common'

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  public endpoint = 'organization_public'
  private featureToggleCache = new Map<string, any[]>()

  constructor(private api: SeFeApiService) {
    // noop
   }

  public findPublic(id: string, params?: any, options = {}): Observable<PublicOrganization> {
    return this.api.get<PublicOrganization>(`${this.endpoint}/${id}`, { params: { ...options, ...params }, headers: nginV2Headers })
      .pipe(
        first(),
        map(data => data.result)
      )
  }

  public indexPublic(ids: number[]): Observable<PublicOrganization[]> {
    const joinedIds = ids ? ids.join(',') : ''
    return this.api.get<PublicOrganization[]>('organizations_public', { params: { filter_by_ids: joinedIds }, headers: nginV2Headers })
      .pipe(
        first(),
        map(data => data.result)
      )
  }

  public mine(param?: any): Observable<ApiResponse<PublicOrganization[]>> {
    return this.api.get<PublicOrganization[]>('v3/organizations/mine_public', { params: { ...param } })
      .pipe(
        first()
      )
  }

  public getFeatureToggles(orgId: string, cache= true): Observable<any[]> {
    if (cache && this.featureToggleCache.has(orgId)) {
      return of(this.featureToggleCache.get(orgId))
    }
    return this.api.get<any[]>(`organizations/${orgId}/feature_instances?context_type=Organization`, { headers: nginV2Headers })
      .pipe(
        map(response => response.result),
        tap(result => this.featureToggleCache.set(orgId, result)))
  }

  public hasFeatureToggleEnabled(orgId: string, featureToggleKey: string): Observable<boolean> {
    return this.getFeatureToggles(orgId).pipe(
      map(toggles => {
        const toggle = toggles.find(t => t.key === featureToggleKey)
        return toggle && toggle.enabled
      })
    )
  }
}
