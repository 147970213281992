import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SeFeAvatarModule } from 'se-fe-avatar'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeHeaderModule } from 'se-fe-header'
import {
  SeFeIconModule,
  seFeIconMore
} from 'se-fe-icon'
import { SeFeMenuModule } from 'se-fe-menu'
import { SeFeTranslationsModule } from 'se-fe-translations'
import { HouseholdAvatarComponent } from './household-avatar/household-avatar.component'


@NgModule({
  imports: [
    CommonModule,
    SeFeAvatarModule,
    SeFeButtonModule,
    SeFeIconModule.include([
      seFeIconMore
    ]),
    SeFeHeaderModule,
    SeFeMenuModule,
    SeFeTranslationsModule
  ],
  declarations: [HouseholdAvatarComponent],
  exports: [HouseholdAvatarComponent],
})
export class SeMySportsengineUiHouseholdAvatarModule {}
