import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PersonaEligibilityComponent } from './persona-eligibility/persona-eligibility.component'

@NgModule({
  imports: [CommonModule],
  declarations: [PersonaEligibilityComponent],
  exports: [PersonaEligibilityComponent],
})
export class SeMySportsengineUiPersonaEligibilityModule {}
