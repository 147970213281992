<div (click)="onClick($event)">
  <se-fe-modal #editPersonaModal>
    <div seFeModalHeader>
      <se-fe-modal-header
        seFeDataTitle="Edit {{ profile?.persona?.first_name }}'s Profile">
      </se-fe-modal-header>
    </div>
    <div seFeModalContent class="modal-content">
      <se-po-edit-persona #editInfo
        [profile]="profile"
        [showContactInfo]="showContactInfo"
      ></se-po-edit-persona>
    </div>
    <div seFeModalFooter>
      <se-fe-modal-footer #editPersonaFooter
        [seFeDataFooterButtons]="editPersonaButtons"
      ></se-fe-modal-footer>
    </div>
  </se-fe-modal>
</div>
<se-fe-toast></se-fe-toast>
