import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Persona } from '../models'
import { Mapper, UserPersonaService } from '../services'

export const PersonaSelfResolver: ResolveFn<Persona> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: UserPersonaService = inject(UserPersonaService)
): Observable<Persona> => service.getSelf().pipe(map(p =>
    Mapper.as<Persona>((p.result?.length && p.result[0]) || { }, Persona)
  ))
