<se-fe-modal #modal>
  <div seFeModalHeader>
    <se-fe-modal-header
      seFeDataTitle="Merge Confirmation"
      (seFeButtonAction)="modal.close()">
    </se-fe-modal-header>
  </div>
  <div seFeModalContent>
    <se-fe-grid seFeGridOpts>
      <se-fe-grid-cell seFeGridOpts="shrink">
        <se-fe-avatar
          seFeDataType="persona"
          [seFeDataIdentity]="mergeProfile?.persona?.full_name"
          [seFeDataImgUrl]="mergeAvatarUrl"
          seFeDataSize="500">
        </se-fe-avatar>
      </se-fe-grid-cell>
      <se-fe-grid-cell seFeGridOpts>
        <div class="merge-content">
          When merged, {{ mergeProfile?.persona?.full_name }} will be combined with {{ keepProfile?.persona?.full_name }} and will show as {{ keepProfile?.persona?.full_name }} on all rosters, lists and directories.
        </div>
        <div class="merge-content">
          {{ mergeProfile?.persona?.full_name }}’s profile will be deleted, which may impact their eligibility for participation. This action cannot be undone.
        </div>
      </se-fe-grid-cell>
      <se-fe-grid-cell *ngIf="mergeError" seFeGridOpts="1/1">
        <se-fe-alert class="merge-alert"
          seFeDataType="error">
          {{ mergeError }}
          <se-fe-button
            seFeDataEmphasis="low"
            (click)="mergePersonas()">
            Retry
          </se-fe-button>
        </se-fe-alert>
      </se-fe-grid-cell>
    </se-fe-grid>
  </div>

  <div seFeModalFooter>
    <se-fe-modal-footer
      [seFeDataFooterButtons]="options">
    </se-fe-modal-footer>
  </div>
</se-fe-modal>

<se-fe-modal #modal2>
  <se-fe-modal-header
    seFeDataTitle="Merge Confirmation"
    (seFeButtonAction)="modal2.close()">
  </se-fe-modal-header>
  <div seFeModalContent>
    Are you sure you want to merge and delete {{ mergeProfile?.persona?.full_name }}'s profile?
  </div>
  <div seFeModalFooter>
    <se-fe-modal-footer
      [seFeDataFooterButtons]="options2">
    </se-fe-modal-footer>
  </div>
</se-fe-modal>

<se-fe-toast></se-fe-toast>
