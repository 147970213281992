import { Component, Injector, Input, forwardRef } from '@angular/core'
import { FormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormGroup, Validators } from '@angular/forms'
import { PersonaInfo, PersonaInfoConfig, PersonaInfoLabels, } from '@se-po/shared-data-access-models'
import { CustomValidators } from '@se-po/shared-data-access-validators'
import { FormGroupValueAccessorDirective } from '@se-po/shared-ui-form-directives'

@Component({
  selector: 'se-po-persona-info',
  templateUrl: './persona-info.component.html',
  styleUrls: ['./persona-info.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonaInfoComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PersonaInfoComponent),
      multi: true,
    },
  ]
})
export class PersonaInfoComponent extends FormGroupValueAccessorDirective {
  @Input() public labels: PersonaInfoLabels = {}
  @Input() public accountAgeRequired?: boolean
  @Input() public showDateOfBirthLearnMore: boolean

  public ready = false
  public submitting = false
  public form: UntypedFormGroup
  public fieldConfig: PersonaInfoConfig
  private _optionaFields: string[]
  private _requiredFields: string[]

  constructor(
    protected injector: Injector,
    private fb: FormBuilder
  ) {
    super(injector)
  }


  public get requiredFields(): string[] {
    return this._requiredFields
  }

  public get optionalFields(): string[] {
    return this._optionaFields
  }

  public get labelName(): string {
    return this.labels?.name || "Participant's Full Name"
  }

  public get labelGender(): string {
    return this.labels?.gender || 'Participant Gender'
  }

  public get labelDateOfBirth(): string {
    return this.labels?.dateOfBirth || 'Birthday'
  }

  public get labelGradYear(): string {
    return this.labels?.gradYear || 'Graduation Year'
  }

  @Input() public set optionalFields(f: string[]) {
    this._optionaFields = f
  }

  @Input() public set requiredFields(f: string[]) {
    this._requiredFields = f
  }

  public initForm(): void {
    this.form = this.fb.group({
      name: [null],
      gender: [''],
      dateOfBirth: [''],
      gradYear: ['']
    })
    this.ready = true
  }

  public setForm(value: Partial<PersonaInfo>): void {
    setTimeout(() => {
      this.setEnabled()
      this.setValidators()
      this.form.reset({
        name: {
          first: value?.name?.first || '',
          last: value?.name?.last || '',
          preferred: value?.name?.preferred || '',
          suffix: value?.name?.suffix || ''
          },
        gender: value?.gender || '',
        dateOfBirth: value?.dateOfBirth || '',
        gradYear: value?.gradYear || ''
      })
      this.ready = true
    })
  }

  public setEnabled(): void {
    const controlNames = ['name', 'gender', 'dateOfBirth', 'gradYear']
    controlNames.forEach(controlName => {
      const control = this.form.get(controlName)
      const isEnabled = this.isEnabled(controlName)
      if (control.enabled && !isEnabled) {
        control.disable()
      }
      else if (control.disabled && isEnabled) {
        control.enable()
      }
    })
  }

  public setValidators(): void {
    Object.keys(this.form.controls).forEach(controlName => {
      const control = this.form.get(controlName)
      control.clearValidators()
      const validators = this.isRequired(controlName) && this.isEnabled(controlName) ? [Validators.required] : []
      if (controlName === 'dateOfBirth' && this.isEnabled(controlName) && this.accountAgeRequired) {
        validators.push(CustomValidators.dobValid)
        validators.push(CustomValidators.dob13)
      }
      if (controlName === 'gradYear' && this.isEnabled(controlName)) {
        validators.push(CustomValidators.gradYear.bind(this, this.form.get('dateOfBirth')))
      }
      if (validators.length) {
        control.addValidators(validators)
      }
    })
  }

  public isEnabled(f: string): boolean {
    const defaults = {
      name: true,
      namePreferred: true,
      nameSuffix: true,
      gender: true,
      dateOfBirth: true,
      gradYear: false,
    }
    if (!this.optionalFields && !this.requiredFields) return defaults[f]

    return (this.optionalFields && this.optionalFields.includes(f)) ||
      (this.requiredFields && this.requiredFields.includes(f))
  }

  private isRequired(f: string): boolean {
    const defaults = {
      name: true,
      namePreferred: false,
      nameSuffix: false,
      gender: false,
      dateOfBirth: false,
      gradYear: false,
    }
    if (!this.requiredFields) return !!defaults[f]
    return this.requiredFields.includes(f)
  }
}
