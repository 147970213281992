
import { MappableModel } from '@se-po/shared-data-access-models'
import { NginResources as nr } from 'se-resource-types'

export class SurveyResult extends MappableModel implements nr.SurveyResult {
  public id: number
  public survey_id: number
  public survey_name: string
  public survey_type: nr.SurveyType
  public site_id: number
  public site_name: string
  public organization_id: number
  public organization_name: string
  public persona_id: number
  public order_id: number
  public user_id: number
  public status: 'Active' | 'Inactive'
  public placed_at: string
  public updated_at: string
  public user: nr.SurveyResultUser
  public attachments?: nr.SurveyResultAttachments
  public completed: boolean

  // these are needed for the registration list but do not exist on the shared model
  public actionLinks?: any
  public order_reference?: any
}
