import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'
import { CentralServiceResources as cs } from 'se-resource-types'

@Injectable({
  providedIn: 'root'
})
export class HouseholdUsersService {
  public endpoint = 'v3/households/users'

  constructor(
    private api: SeFeApiService
  ) {
    // noop
  }

  public findUserProfiles(id: string | number): Observable<cs.Households.UserProfile[]> {
    return this.api.get<cs.Households.UserProfilesResult>(`${this.endpoint}/${id}/profiles`).pipe(
      first(),
      map(response => response.result.profiles)
    )
  }
}
