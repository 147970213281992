<div pl-grid-el pl-grid="1/1@phone">
  <h2 class="myse-dashboard__section-title" [textContent]="'DASHBOARD.PAYMENTS.title' | translate"></h2>
</div>
<div pl-grid-el pl-grid="1/1@phone">
  <!-- Skeleton -->
  <div *ngIf="!loaded"
    class="myse-dashboard-skeleton--section-card myse-dashboard__full-card se-card">
  </div>
  <!-- Empty State -->
  <se-fe-card *ngIf="loaded && !bills.length" class="myse-dashboard__full-card myse-dashboard__compact-card">
    <se-fe-card-content>
      <se-fe-empty-state seFeDataTitle="{{ 'DASHBOARD.PAYMENTS_EMPTY.title' | translate }}" seFeDataSize="500">
        {{ 'DASHBOARD.PAYMENTS_EMPTY.description' | translate }}
      </se-fe-empty-state>
    </se-fe-card-content>
  </se-fe-card>

  <se-fe-card *ngIf="loaded && bills.length"
    class="myse-dashboard__full-card js-payment-list">
    <se-fe-card-content style="margin:0; padding: 0;">
      <se-fe-list>
        <se-fe-list-item
          *ngFor="let payment of bills | slice:0:3"
          class="ba-dashboard__list-item"
          style="padding-left: 24px; padding-right: 24px;">
          <se-fe-header size="600"
            [seFeDataTitle]="payment.description | titlecase"
            [seFeDataSubtitle]="(payment.view_sale ? 'DASHBOARD.PAYMENTS.scheduled' : 'DASHBOARD.PAYMENTS.payment_due') |
              translate:{ due_date: (payment.dueDate | date) }">
              <div seFeHeaderTitleExtra *ngFor="let tag of payment.tags">
                <span [class]="'pl-tag--' + tag.color">{{ tag.value }}</span>
              </div>
            <div seFeHeaderActions>
              <div class="myse-payment-summary">
                <a class="pl-link"
                  [attr.href]="payment.href"
                  [textContent]="summaryText(payment) | translate">
                </a>
                <div class="myse-payment-summary__amount"
                  [attr.aria-label]="payment.money.amountString">
                  <span class="myse-payment-summary__currency">{{ payment.money.currency }}</span>
                  <span class="myse-payment-summary__dollars">{{ payment.money.dollars }}</span>
                  <span class="myse-payment-summary__cents">{{ payment.money.cents | number: '2.0-0'}}</span>
                </div>
              </div>
            </div>
          </se-fe-header>
        </se-fe-list-item>
      </se-fe-list>
    </se-fe-card-content>
  </se-fe-card>
</div>
