import { Component, Input, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { DateService } from '@se-po/shared-data-access-services'
import { BaseValueAccessorDirective } from '@se-po/shared-ui-form-directives'
import { DateTime } from 'luxon'
import { Subscription } from 'rxjs'
import { SeFeTranslationsLoader } from 'se-fe-translations'

@Component({
  selector: 'se-po-graduation-select',
  templateUrl: './graduation-select.component.html',
  styleUrls: ['./graduation-select.component.scss'],
  hostDirectives: [BaseValueAccessorDirective]
})
export class GraduationSelectComponent implements OnInit, OnDestroy {
  @Input() public seFeDataLabel?: string

  public grade: string
  public oldestGraduationYear = DateTime.now().year - 100
  public newestGraduationYear = DateTime.now().year + 20
  public graduationYearDifference = this.newestGraduationYear - this.oldestGraduationYear
  public graduationYearOptions = Array.from({ length: this.graduationYearDifference }, (x, i) => this.oldestGraduationYear + i)
    .map(x => x.toString()).reverse()
  public loaded: boolean
  public ngControl = BaseValueAccessorDirective.ngControl(this) // get the forwarded control

  private _subscriptions: Subscription[] = []

  constructor(private dateService: DateService,
              private seFeTranslationsLoader: SeFeTranslationsLoader,
              private translateService: TranslateService
  ) { }

  public get control(): AbstractControl {
    return this.ngControl?.control
  }

  public get form(): UntypedFormGroup | UntypedFormArray {
    return this.control?.parent
  }

  public ngOnInit(): void {
    this.loaded = false
    this.initTranslations()
  }

  public initCalculations(): void {
    this.calculateGrade(this.ngControl.value)
    this._subscriptions.push(this.control.valueChanges.subscribe(val => {
      this.calculateGrade(val)
    }))
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
    this._subscriptions.length = 0
  }

  public initTranslations(): void {
    this.seFeTranslationsLoader.load('shared/ui-graduation-select/assets/translations').then(_ => {
      this.loaded = true
      this.initCalculations()
    })
  }

  private calculateGrade(graduationYear: string) {
    if (!graduationYear) {
      this.grade = ''
      return ''
    }

    const gradeVal = this.dateService.grade(graduationYear)
    this.grade = this.translateService.instant('SE_PO_UI_GRADUATION_SELECT.FORM_SUFFIXES.grade', { grade: gradeVal })
  }
}
