<se-fe-modal [seFeDataSize]="size" #modal>
  <div seFeModalHeader>
    <se-fe-modal-header
      [seFeDataTitle]="title"
      (seFeButtonAction)="modal.close()">
    </se-fe-modal-header>
  </div>
  <div seFeModalContent>
    <ng-template #container>
    </ng-template>
  </div>
  <div seFeModalFooter>
    <se-fe-modal-footer #footer
    [seFeDataFooterButtons]="footerOptions">
    </se-fe-modal-footer>
  </div>
</se-fe-modal>
