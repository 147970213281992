<se-fe-card>
  <se-fe-card-header [seFeDataTitle]="'SALE_DETAIL.ACTIVITY.activity' | translate" [seFeDataHeavyBorder]="true">
    <div se-fe-card-header-actions>
      <div [textContent]="'SALE_DETAIL.ACTIVITY.activity_card_data_highlight' | translate:{
        amountOutstanding: sale.amount_outstanding | displayCurrency:sale.currency_symbol
      }"></div>
    </div>
  </se-fe-card-header>
  <se-fe-card-content>
    <div class="pl-pane--collapse">
      <div class="pl-data-table__container">
        <table id="tbl-sale-payments" class="pl-data-table">
          <thead>
            <tr>
              <th [textContent]="'SALE_DETAIL.LABELS.date' | translate"></th>
              <th [textContent]="'SALE_DETAIL.LABELS.activity' | translate"></th>
              <th *ngIf="hasTransactions"
                [textContent]="'SALE_DETAIL.LABELS.transaction' | translate"
                test-id="transaction-title">
              </th>
              <th *ngIf="hasNotes"
                [textContent]="'SALE_DETAIL.LABELS.notes' | translate"
                test-id="notes-title">
              </th>
              <th [textContent]="'SALE_DETAIL.LABELS.amount' | translate" class="pl-text--right"></th>
              <th [textContent]="'SALE_DETAIL.LABELS.balance' | translate" class="pl-text--right"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let a of activity; index as i">
              <td>{{ a.created_at | date: 'MMM d, yyyy' }}</td>
              <td>
                <span *ngIf="activityLabelKey(a.label)"
                  [textContent]="activityLabelKey(a.label)"></span>
                <span *ngIf="!activityLabelKey(a.label)"
                  [textContent]="a.label"></span>
              </td>
              <td *ngIf="hasTransactions">
                <span *ngIf="a.sale_transaction_id"
                  [textContent]="a.sale_transaction_id">
                </span>
              </td>
              <td *ngIf="hasNotes">
                <span *ngIf="!isDiscountLabel(a.label)">
                  <a *ngIf="a.notes"
                    class="pl-link"
                    id="paymentNotes{{i}}"
                    test-id="notes-column"
                    (click)="triggerMessageDialog(a.notes)"
                    [textContent]="'SALE_DETAIL.ACTIVITY.' + noteLabelKey(a.label) | translate">
                  </a>
                </span>
                <span *ngIf="isDiscountLabel(a.label)" [textContent]="a.notes"></span>
              </td>
              <td class="pl-text--right">{{ a.amount | displayCurrency:sale.currency_symbol }}</td>
              <td class="pl-text--right">{{ a.amount_outstanding | displayCurrency:sale.currency_symbol }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </se-fe-card-content>
</se-fe-card>

<se-fe-modal #dialogModal>
  <div seFeModalContent>
    <div>
      {{ dialogText }}
    </div>
  </div>
  <div seFeModalFooter>
    <se-fe-modal-footer dialogFooter
      [seFeDataFooterButtons]="dialogButtons">
    </se-fe-modal-footer>
  </div>
</se-fe-modal>

