import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { SeFeAvatarModule } from 'se-fe-avatar'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeFormFieldOptionsModule } from 'se-fe-form-field-options'
import { SeFeHeaderModule } from 'se-fe-header'
import { SeFeIconModule, seFeIconRemove } from 'se-fe-icon'
import { SeFeLinkModule } from 'se-fe-link'
import { SeFeModalModule } from 'se-fe-modal'
import { UsavLaunchComponent } from './usav-launch/usav-launch.component'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SeFeAvatarModule,
    SeFeButtonModule,
    SeFeFormFieldOptionsModule,
    SeFeHeaderModule,
    SeFeIconModule.include([seFeIconRemove]),
    SeFeLinkModule,
    SeFeModalModule
  ],
  declarations: [
    UsavLaunchComponent
  ],
  exports: [
    UsavLaunchComponent
  ]
})
export class SeMySportsengineUiUsavLaunchModule {}
