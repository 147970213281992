import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'

import { Persona } from 'se-resource-types/dist/lib/UserService'
import { nginV2Headers } from '@se-po/shared-data-access-models'

@Injectable({
  providedIn: 'root'
})
export class PersonaService {
  public endpoint = 'ngin/v2/user_personas'

  constructor(
    private api: SeFeApiService
  ) {
    // noop
  }

  public find(personaId: string, params={}): Observable<Persona> {
    const url = `${this.endpoint}/${personaId}`
    return this.api.get<Persona>(url, { params }).pipe(
      map(response => response.result)
    )
  }

  public findAll(params={}): Observable<Persona[]> {
    return this.api.get<Persona[]>(this.endpoint, { params }).pipe(
      map(response => response.result)
    )
  }

  public savePersona(personaId: string, payload: any): Observable<Persona> {
    return this.api.put<Persona>(`${this.endpoint}/${personaId}`, payload).pipe(
      map(response => response.result))
  }

  public merge(keepPersonaId: number, mergePersonaId: number, description: string): Observable<Persona> {
    return this.api.post<Persona>(
      `${this.endpoint}/merge?description=${description}`,
      { keep_id: keepPersonaId, merge_id: mergePersonaId },
      { headers: nginV2Headers }).pipe(
          first(),
          map(response => response.result)
        )
  }

  public destroy(id: number): Observable<any> {
    return this.api.delete(`${this.endpoint}/${id}`)
      .pipe(first())
  }
}
