import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { environment } from '@se-po/shared-environments'
import { SeFeToastService } from 'se-fe-toast'
import { Persona } from '../../models'

@Component({
  selector: 'app-sync-schedule',
  templateUrl: './sync-schedule.component.html',
  styleUrls: ['./sync-schedule.component.scss']
})
export class SyncScheduleComponent implements OnInit {

  public selected = 'google'
  public googleUrl: string

  private personaSelf: Persona
  private calUrl: string

  constructor(
    private window: Window,
    private activatedRoute: ActivatedRoute,
    private seToast: SeFeToastService,
    private translate: TranslateService
  ) {
    // noop
  }

  public ngOnInit(): void {
    this.personaSelf = this.activatedRoute.firstChild?.snapshot?.data?.personaSelf
    this.calUrl = `${environment.webCal}?uuid=${this.personaSelf?.user.uuid}&v=${Date.now()}&src=myse`
    this.googleUrl = `http://www.google.com/calendar/render?cid=${this.calUrl}`
  }

  public onSubmit(): void {
    if (this.selected === 'google') {
      this.window.open(this.googleUrl, '_blank')
    } else {
      this.copyTextToClipboard(this.calUrl)
    }
  }

  private copyTextToClipboard(text) {
    void navigator.clipboard.writeText(text).then(() => {
      this.seToast.success(
        this.translate.instant('SCHEDULE_SYNC.copied_alert')
      )
    })
  }
}
