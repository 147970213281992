export class Training {
  public is_for_self: boolean
  public persona_first_name: string
  public site_name: string
  public training_url: string
  public type: string

  public constructor(data?: any) {
    Object.assign(this, data || {})
  }
}
