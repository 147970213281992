import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/internal/operators/tap'
import { take } from 'rxjs/operators'

import { User } from '../models/user'

import { ApiService } from './api.service'
import { Store } from './store'

@Injectable({
  providedIn: 'root'
})
export class UserService extends Store<User>{

  constructor(private api: ApiService) {
    super(null)
  }

  public getCurrent(): void {
    this.api.findAs<User>('users/mine', User).subscribe(
      (user) => { this.setCurrent(user) }
    )
  }

  public addEmail(email: string, primary: boolean): Observable<any> {
    const data = {
      address: email,
      is_primary: primary
    }
    return this.api.post('users/email_addresses', data).pipe(take(1))
  }

  public deleteEmail(id: number): Observable<any> {
    return this.api.delete(`users/email_addresses/${id}`).pipe(take(1))
  }

  public verifyEmail(id: number): Observable<any> {
    return this.api.post(`users/email_addresses/${id}/remind`, {}).pipe(take(1))
  }
}
