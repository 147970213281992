import { MappableModel } from '@se-po/shared-data-access-models'
import { environment } from '@se-po/shared-environments'

interface TeamOrgDetails {
  id: number
  name: string
  description: string
}

export class Team extends MappableModel {
  public actionLinks?: any
  public id: number
  public logo: any
  public name: string
  public org_details: TeamOrgDetails
  public originator_id: string
  public originator_system: string
  public originator_type: string
  public primary_color: string
  public program_primary_text: string
  public program_secondary_text: string
  public sport: string
  public subtitle: string
  public title: string
  public trigger_roster_personas: any[]

  public get url(): string {
    if (this.originator_system === 'Ngin') {
      if (this.originator_type === 'team_instance') return `${environment.app}teams/team-instance-redirect/${this.originator_id}`
    }
    return `${environment.app}teams/${this.id}`
  }

  public get imgUrl(): string {
    return this.logo?.image_urls?.large
  }

  public get sportIcon(): string {
    return (this.sport || '').toLowerCase()
  }
}
