<ng-content></ng-content>
<se-fe-icon *ngIf="status === 'none'"
  seFeDataName="caret_up_down"
  seFeDataSize="200"
  seFeDataMargin="false">
</se-fe-icon>
<se-fe-icon *ngIf="status === 'asc'"
  seFeDataName="caret_up"
  seFeDataSize="300"
  seFeDataMargin="false">
</se-fe-icon>
<se-fe-icon *ngIf="status === 'desc'"
  seFeDataName="caret_down"
  seFeDataSize="300"
  seFeDataMargin="false">
</se-fe-icon>
