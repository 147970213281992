<se-fe-modal #modal>

  <div seFeModalHeader>
    <se-fe-modal-header
      seFeDataTitle="Grant Access">
    </se-fe-modal-header>
  </div>

  <div seFeModalContent>
    <se-fe-alert
      seFeDataType="error"
      *ngIf="error">
      {{ error }}
    </se-fe-alert>
    <div>
      <p>Grant access to {{ persona?.first_name }}'s information and help manage their sports life by inviting someone.</p>
    </div>
    <form
      *ngIf="form"
      [formGroup]="form"
      novalidate>
      <div class="form-field">
        <se-po-email
          formControlName="email"
          seFeDataLabel="Email">
        </se-po-email>
      </div>
      <div class="form-field">
        <div class="access-label">
          <se-fe-form-control-label
            seFeDataLabel="Access Level"
            seFeDataLabelFor="access">
          </se-fe-form-control-label>
          <button type="button" seFePopoverTrigger="newGuardianAccessPopover" class="access-popover">
            <se-fe-icon
              seFeDataName="info_circle"
              seFeDataSize="500"
              seFeDataColor="gray-700">
            </se-fe-icon>
          </button>
          <se-fe-popover seFeDataName="newGuardianAccessPopover" [seFeDataFixedPosition]="true">
            Learn more about&ensp;
            <a
              href="https://help.sportsengine.com/en/articles/9265109"
              class="access-popover-link"
              target="_blank">
              access levels.
            </a>
          </se-fe-popover>
        </div>
        <se-fe-form-field-select
          formControlName="access"
          [seFeDataOptions]="accessFieldOptions"
          seFeDataPlaceholder="Select"
          [seFeDataAllowClear]="false">
        </se-fe-form-field-select>
        <se-fe-form-control-errors *ngIf="form.get('access').touched && form.get('access').invalid"
          seFeDataId="new-guardian-modal-error-id"
          [seFeDataMessages]="{
            required: 'This field is required'
          }"
          [seFeDataErrors]="['required']">
        </se-fe-form-control-errors>
      </div>
    </form>
  </div>

  <div seFeModalFooter>
    <se-fe-modal-footer #footer
      [seFeDataFooterButtons]="modalButtons">
    </se-fe-modal-footer>
  </div>

</se-fe-modal>
<se-fe-toast></se-fe-toast>
