import { TitleCasePipe } from '@angular/common'
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core'
import { FormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms'
import { NewGuardianModalComponent } from '@se-po/se-my-sportsengine-feature-new-guardian-modal'
import { Observable, Subscription, zip } from 'rxjs'
import { SeFeFormService } from 'se-fe-form-service'
import { SeFeMenuOptions } from 'se-fe-menu'
import { SeFeModalButton, SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { SeFeToastService } from 'se-fe-toast'
import { CentralServiceResources as cs } from 'se-resource-types'
import { RelatedGuardiansResult, UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { Persona } from 'se-resource-types/dist/lib/CentralService/Profiles'
import { ChildGrantAccessModalSubmitter } from './child-grant-access-modal-submit.service'

interface GuardianListItem {
  id: string | number
  chipSelected: boolean
  disabled: boolean
  email: string
  errors: ValidationErrors
  imgUrl: string
  menuButtonText: string
  permissionMenuOpts: SeFeMenuOptions
  persona: Persona
}

@Component({
  selector: 'se-po-child-grant-access-modal',
  templateUrl: './child-grant-access-modal.component.html',
  styleUrls: ['./child-grant-access-modal.component.scss'],
})
export class ChildGrantAccessModalComponent implements OnDestroy {
  @ViewChild('addModal') public addModal: SeFeModalComponent
  @ViewChild('modalFooter') public modalFooter: SeFeModalFooterComponent
  @ViewChild('newGuardianModal') public newGuardianModal: NewGuardianModalComponent
  @Output() public guardianAdded: EventEmitter<void> = new EventEmitter()
  @Input() public guardians: RelatedGuardiansResult[]
  @Input() public inviteItems = []
  @Input() public profile: UserProfile

  public form: UntypedFormGroup
  public error = null
  public checkboxOptions: {[key: string]: any[]} = {}
  public guardianListItems: GuardianListItem[] = []
  public enabledGuardiansListItems: GuardianListItem[] = []
  public initialValues: {[key: string | number]: string}  = {}
  public modalButtons: SeFeModalButton[] = [
    {
      cancel: true,
      label: 'Cancel',
      action: () => {
        this.addModal.close()
      },
    },
    {
      primary: true,
      label: 'Send Invite',
      action: () => {
        this.submit()
      }
    },
  ]
  public ready = false

  private _formSubscriptions: Subscription[] = []

  constructor(
    private formBuilder: FormBuilder,
    private seFeFormService: SeFeFormService,
    private seFeToastService: SeFeToastService,
    public submitter: ChildGrantAccessModalSubmitter,
    private titleCasePipe: TitleCasePipe
  ){
    // noop
  }

  public ngOnDestroy(): void {
    this.formUnsubscribe()
  }

  public open(): void {
    this.error = null
    this.rebuildForm()
    if(this.enabledGuardiansListItems.length) this.addModal.open(null)
    else this.openNewGuardianModal()
  }

  public rebuildForm(): void {
    this.ready = false
    this.setInitialValues()
    this.form = this.formBuilder.group(this.initialValues)
    this.createFormSubscriptions()
    this.ready = true
  }

  public setInitialValues(): void {
    this.initialValues = {}
    this.guardianListItems = this.createGuardianListItems()
    this.enabledGuardiansListItems = this.guardianListItems.filter(item => !item.disabled)
    this.initialValues.selectAll = ''
    this.checkboxOptions.selectAll = [{label: 'Select All', value: true}]
    this.guardianListItems.map(guardianListItem => {
      this.initialValues[guardianListItem.id] = this.profile.id === guardianListItem.id ? guardianListItem.id.toString() : ''
      this.initialValues[`${guardianListItem.id}-access`] = null
      this.checkboxOptions[guardianListItem.id] = [{ label: guardianListItem.persona.full_name, value: guardianListItem.id.toString() }]
    })
  }

  public createGuardianListItems(): GuardianListItem[] {
    let guardianListItems = []
    this.guardians.forEach(guardian => {
      const existingGuardian = (guardian.profiles.find(p => p.persona.id === this.profile.persona.id)) ||
        this.inviteItems.find(inviteItem => inviteItem.email === guardian.user?.email_address?.address)
      if (existingGuardian?.access === 'owner') return

      const guardianListItem: GuardianListItem = {
        id: guardian.id,
        chipSelected: false,
        disabled: !!existingGuardian,
        email: guardian.user?.email_address?.address,
        errors: null,
        imgUrl: (guardian.user.self_persona.profile_images || []).find(i => i.image_type === 'crop')?.url,
        menuButtonText: existingGuardian?.access ? this.titleCasePipe.transform(existingGuardian.access) : 'Access Level',
        permissionMenuOpts: {
          name: `account-${guardian.id}`,
          sections: [
            {
              menuItems: [
                {
                  text: 'Viewer',
                  action: () => this.setAccessOptionValue(guardian.id, 'viewer')
                },
                {
                  text: 'Manager',
                  action: () => this.setAccessOptionValue(guardian.id, 'manager')
                }
              ],
            }
          ]
        },
        persona: guardian.user.self_persona
      }
      guardianListItems.push(guardianListItem)
    })
    guardianListItems = guardianListItems.sort((a, b) => Number(a.disabled) - Number(b.disabled))
    return guardianListItems
  }

  public createFormSubscriptions(): void {
    // each time we recreate the form, we should unsubscribe from form subscriptions and then
    // create subscriptions for the new controls
    this.formUnsubscribe()
    this.guardianListItems.forEach(guardianListItem => {
      this.createListItemSubscriptions(guardianListItem)
    })
    this._formSubscriptions.push(this.form.get('selectAll').valueChanges.subscribe(value => {
      this.guardianListItems.map(guardianListItem => {
        if (guardianListItem.disabled) return

        const checkbox = this.form.get(guardianListItem.id.toString())
        if (value) {
          checkbox.setValue(guardianListItem.id)
        }
        else {
          checkbox.setValue('')
        }
        checkbox.updateValueAndValidity()
      })
    }))
  }

  public formUnsubscribe(): void {
    this._formSubscriptions.forEach(subscription => subscription.unsubscribe())
    this._formSubscriptions.length = 0
  }

  public createListItemSubscriptions(guardianListItem: GuardianListItem): void {
    const accessControl = this.form.get(`${guardianListItem.id}-access`)
    const checkboxControl = this.form.get(guardianListItem.id.toString())
    this._formSubscriptions.push(checkboxControl.valueChanges.subscribe(value => {
      const hasValidator = accessControl.hasValidator(Validators.required)
      if (value && !hasValidator) {
        accessControl.addValidators(Validators.required)
      }
      else if (!value && hasValidator) {
        accessControl.clearValidators()
        accessControl.setValue(null)
        this.form.get('selectAll').setValue('', { emitEvent: false })
      }
      accessControl.updateValueAndValidity()
    }))
    this._formSubscriptions.push(accessControl.valueChanges.subscribe(value => {
      if (value) {
        if (!checkboxControl.value) {
          checkboxControl.setValue(guardianListItem.id)
          checkboxControl.updateValueAndValidity()
        }
        guardianListItem.menuButtonText = this.titleCasePipe.transform(value)
        guardianListItem.chipSelected = true
      }
      else {
        guardianListItem.menuButtonText = 'Access Level'
        guardianListItem.chipSelected = false
      }
      guardianListItem.errors = accessControl.touched && accessControl.errors
    }))
  }

  public setAccessOptionValue(id: string | number, opt: string): void {
    this.form.get(`${id}-access`).setValue(opt)
  }

  public openNewGuardianModal(): void {
    this.newGuardianModal.open(this.profile.persona)
  }

  public submit(): void {
    if (!this.seFeFormService.isValid(this.form)) {
      this.guardianListItems.forEach(guardianListItem => {
        const accessControl = this.form.get(`${guardianListItem.id.toString()}-access`)
        guardianListItem.errors = accessControl.errors
      })
      this.error = 'Access level needs to be selected before invitation can be sent.'
      this.modalFooter.cancelSpinner()
      return
    }

    const selectedGuardians = []
    this.guardianListItems.forEach(guardianListItem => {
      const controlName = guardianListItem.id.toString()
      if ((this.form.get(controlName)?.value) === guardianListItem.id.toString()) {
        const selectedAccess = this.form.get(`${guardianListItem.id}-access`).value
        selectedGuardians.push({ email: guardianListItem.email, access: selectedAccess })
      }
    })

    if (!selectedGuardians.length) {
      this.error = 'Person needs to be selected before invitation can be sent.'
      this.modalFooter.cancelSpinner()
      return
    }

    zip(...selectedGuardians.map(item => this.submitOne(item.email, item.access))).subscribe(guardianships => {
      const successGuardianships = guardianships.filter(guardianship => !!guardianship)
      if (successGuardianships.length === guardianships.length) {
        guardianships.forEach(guardianship => this.success(guardianship.email_address))
      } else {
        this.error = this.submitter.error
        this.seFeToastService.error(this.error)
        this.modalFooter.cancelSpinner()
      }
    })
  }

  public submitOne(email: string, access: string): Observable<cs.Households.PersonaGuardianInvite> {
    return this.submitter.submit(
      access,
      email,
      this.profile.persona.id,
    )
  }

  public success(email: string): void {
    this.seFeToastService.success(`An invitation was sent to ${email}`)
    this.inviteSent()
    this.addModal.close()
  }

  public inviteSent(): void {
    this.guardianAdded.emit()
  }
}
