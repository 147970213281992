import { SeFeToastService, SeFeToastTypes } from 'se-fe-toast'
import { Component, Input, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { RsvpService } from '../../services/rsvp.service'

@Component({
  selector: 'app-rsvp-modal',
  templateUrl: './rsvp-modal.component.html',
  styleUrls: ['./rsvp-modal.component.scss']
})
export class RsvpModalComponent implements OnInit {

  @Input() public rsvp: any
  public note: string
  public selected: string

  constructor(private rsvpService: RsvpService,
    private toast: SeFeToastService,
    private translate: TranslateService) {
    // noop
   }

  public ngOnInit(): void {
    this.note = this.rsvp?.note
    this.selected = this.rsvp?.response || 'yes'
  }

  public onSubmit(): void {
    this.rsvp.note = this.note
    this.rsvp.response = this.selected
    this.rsvpService.setRsvp(this.rsvp).subscribe(
      res => {
        this.toast.success(
          this.translate.instant('SCHEDULE.rsvp_success', {
            identity: this.rsvp.identity,
            response: this.rsvp.response
          })
        )
      },
      err => this.toast.error(
        this.translate.instant('SCHEDULE.rsvp_error')
      )
    )
  }

}
