import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeFormFieldSelectModule } from 'se-fe-form-field-select'
import { SeFeFormServiceModule } from 'se-fe-form-service'
import { SeFeTranslationsModule } from 'se-fe-translations'
import { CountrySelectComponent } from './country-select/country-select.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SeFeFormServiceModule,
    SeFeFormFieldModule,
    SeFeFormFieldSelectModule,
    SeFeTranslationsModule
  ],
  declarations: [
    CountrySelectComponent
  ],
  exports: [
    CountrySelectComponent
  ]
})
export class SharedUiCountrySelectModule {}
