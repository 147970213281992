import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'
import { SeMySportsengineUiPersonaEligibilitySidePanelModule } from '@se-po/se-my-sportsengine-ui-persona-eligibility-side-panel'
import { SeMySportsengineUiUsavLaunchModule } from '@se-po/se-my-sportsengine-ui-usav-launch'
import { SeFeAvatarModule } from 'se-fe-avatar'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeCardModule } from 'se-fe-card'
import { SeFeChipModule } from 'se-fe-chip'
import { SeFeEmptyStateModule } from 'se-fe-empty-state'
import { SeFeFormFieldOptionsModule } from 'se-fe-form-field-options'
import { SeFeGridModule } from 'se-fe-grid'
import { SeFeHeaderModule } from 'se-fe-header'
import { seFeIconDotFill, SeFeIconModule, seFeIconRefresh, seFeIconSpotSearch, seFeIconSpotWhistle } from 'se-fe-icon'
import { SeFeIndicatorDotModule } from 'se-fe-indicator-dot'
import { SeFeLinkModule } from 'se-fe-link'
import { SeFeListModule } from 'se-fe-list'
import { SeFeMenuModule } from 'se-fe-menu'
import { SeFePageModule } from 'se-fe-page'
import { SeFeSidePanelModule } from 'se-fe-side-panel'
import { SeFeSpinnerModule } from 'se-fe-spinner'
import { SeFeTableModule } from 'se-fe-table'
import { MembershipTableComponent } from './membership-table/membership-table.component'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SeFeAvatarModule,
    SeFeButtonModule,
    SeFeCardModule,
    SeFeChipModule,
    SeFeEmptyStateModule,
    SeFeFormFieldOptionsModule,
    SeFeGridModule,
    SeFeHeaderModule,
    SeFeIconModule.include([
      seFeIconDotFill,
      seFeIconRefresh,
      seFeIconSpotSearch,
      seFeIconSpotWhistle
    ]),
    SeFeIndicatorDotModule,
    SeFeLinkModule,
    SeFeListModule,
    SeFeMenuModule,
    SeFePageModule,
    SeFeSidePanelModule,
    SeFeSpinnerModule,
    SeFeTableModule,
    SeMySportsengineUiPersonaEligibilitySidePanelModule,
    SeMySportsengineUiUsavLaunchModule
  ],
  declarations: [
    MembershipTableComponent
  ],
  exports: [
    MembershipTableComponent
  ]
})
export class SeMySportsengineFeatureMembershipTableModule {}
