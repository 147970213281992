<div pl-grid="tight">
  <div pl-grid-el pl-grid="1/1@phone">
    <p [textContent]="'SCHEDULE_SYNC.sub_title' | translate"></p>
  </div>
  <fieldset pl-grid-el pl-grid="1/1@phone">
    <div class="pl-detailed-option__group">
      <div class="pl-detailed-option">
        <input
          type="radio"
          id="google-calendar"
          name="detailed-radio"
          class="pl-detailed-option__input"
          [(ngModel)]="selected"
          value="google">
        <label for="google-calendar" class="pl-detailed-option__label">
          <div class="pl-detailed-option__icon">
            <se-fe-icon
              seFeDataName="calendar"
              seFeDataSize="700"
              seFeDataMargin="false">
            </se-fe-icon>
          </div>
          <div class="pl-detailed-option__info">
            <p class="pl-detailed-option__heading" [textContent]="'SCHEDULE_SYNC.CALENDARS.google' | translate"></p>
            <p class="pl-detailed-option__details" [textContent]="'SCHEDULE_SYNC.google_copy' | translate"></p>
          </div>
        </label>
      </div>
      <div class="pl-detailed-option">
        <input
          type="radio"
          id="other-calendar"
          name="detailed-radio"
          class="pl-detailed-option__input"
          [(ngModel)]="selected"
          value="other">
        <label for="other-calendar" class="pl-detailed-option__label">
          <div class="pl-detailed-option__icon">
            <se-fe-icon
              seFeDataName="calendar"
              seFeDataSize="700"
              seFeDataMargin="false">
            </se-fe-icon>
          </div>
          <div class="pl-detailed-option__info">
            <p class="pl-detailed-option__heading" [textContent]="'SCHEDULE_SYNC.CALENDARS.other' | translate"></p>
            <p class="pl-detailed-option__details" [textContent]="'SCHEDULE_SYNC.other_copy' | translate"></p>
          </div>
        </label>
      </div>
    </div>
  </fieldset>
</div>
