<se-fe-page seFeDataWidth="full">
  <se-fe-header seFePageHeader seFeDataSize="750" seFeDataTitle="Credentials">
  </se-fe-header>
  <se-fe-card seFePageContent class="launch-banner" *ngIf="usavPersonaEligibilities.length">
    <se-fe-card-content>
      <se-po-usav-launch [personaEligibilities]="usavPersonaEligibilities"></se-po-usav-launch>
    </se-fe-card-content>
  </se-fe-card>
  <se-fe-card seFePageContent class="launch-banner" *ngIf="showSoccerLaunchBanner">
    <se-fe-card-content>
      <se-fe-header seFeDataTitle="U.S. Soccer Learning Center" [seFeDataSize]="500">
        <se-fe-avatar seFeHeaderVisual seFeDataType="org" seFeDataIdentity="US Soccer" [seFeDataImgUrl]="soccerLogo"
          seFeDataSize="500">
        </se-fe-avatar>
        <a seFeHeaderActions href="https://learning.ussoccer.com/" target="_blank" se-fe-button>Launch</a>
      </se-fe-header>
    </se-fe-card-content>
  </se-fe-card>
  <se-fe-card seFePageContent>
    <se-fe-card-content>
      <se-fe-chip-group *ngIf="organizationId" class="chip-filters">
        <se-fe-chip [seFeDataText]="orgFilterTitle" [seFeMenuTrigger]="orgMenuOptions.name">
          <se-fe-avatar seFeChipAvatar seFeDataType="org" [seFeDataIdentity]="chipOrgOpts.identity"
            [seFeDataImgUrl]="chipOrgOpts.imgURL" seFeDataSize="100">
          </se-fe-avatar>
        </se-fe-chip>
        <se-fe-menu [seFeDataOptions]="orgMenuOptions">
          <div seFeMenuContent class="credential-menu-content">
            <label>Organization</label>
            <form *ngIf="organizationFormReady" [formGroup]="organizationForm">
              <se-fe-radios class="org-radios" formControlName="organization" [seFeDataOptions]="organizationOptions">
                <ng-template let-option>
                  <div class="checkbox-row">
                    <se-fe-avatar class="checkbox-row-item" seFeDataType="org" [seFeDataIdentity]="option.label"
                      [seFeDataImgUrl]="option.imgURL" seFeDataSize="100">
                    </se-fe-avatar>
                    {{ option.label }}
                  </div>
                </ng-template>
              </se-fe-radios>
            </form>
          </div>
        </se-fe-menu>
        <se-fe-chip [seFeDataText]="personFilterTitle" [seFeMenuTrigger]="personMenuOptions.name"
          [seFeDataSelected]="displayPersonFilterCount > 1">
          <se-fe-avatar seFeChipAvatar *ngIf="showChipAvatar" seFeDataType="persona"
            [seFeDataIdentity]="chipPersonaOpts.identity" [seFeDataImgUrl]="chipPersonaOpts.imgURL" seFeDataSize="100">
          </se-fe-avatar>
          <se-fe-indicator-dot *ngIf="displayPersonFilterCount > 1" seFeChipIndicatorDot
            [seFeDataValue]="displayPersonFilterCount"></se-fe-indicator-dot>
        </se-fe-chip>
        <se-fe-menu [seFeDataOptions]="personMenuOptions">
          <div seFeMenuContent class="credential-menu-content">
            <label>Person</label>
            <form *ngIf="filtersFormReady" [formGroup]="filtersForm" (ngSubmit)="applyFilters()" novalidate>
              <se-fe-list formGroupName="personFilters">
                <se-fe-list-item *ngFor="let person of personas" class="checkbox-row">
                  <se-fe-checkbox class="checkbox-row-item" [formControlName]="person.id.toString()"
                    [seFeDataHiddenLabel]="true" [seFeDataOptions]="personOptions[person.id].checkboxOpts">
                  </se-fe-checkbox>
                  <se-fe-avatar class="checkbox-row-item" seFeDataType="persona"
                    [seFeDataIdentity]="personOptions[person.id].checkboxOpts.label"
                    [seFeDataImgUrl]="personOptions[person.id].image" seFeDataSize="200"
                    [seFeDataAriaLabel]="personOptions[person.id].checkboxOpts.label">
                  </se-fe-avatar>
                  <span>{{ personOptions[person.id].checkboxOpts.label }}</span>
                </se-fe-list-item>
              </se-fe-list>
              <se-fe-button seFeDataEmphasis="high" [seFeDataFullWidth]="true" seFeDataType="submit" class="apply-filter-button">
                Apply Filters
              </se-fe-button>
              <se-fe-button [seFeDataFullWidth]="true" (click)="clearFilter('personFilters')">
                Clear All Filters
              </se-fe-button>
            </form>
          </div>
        </se-fe-menu>
        <se-fe-chip [seFeDataText]="statusFilterTitle" [seFeMenuTrigger]="statusMenuOptions.name"
          [seFeDataSelected]="displayStatusFilterCount > 1">
          <se-fe-indicator-dot *ngIf="displayStatusFilterCount > 1" seFeChipIndicatorDot
            [seFeDataValue]="displayStatusFilterCount"></se-fe-indicator-dot>
        </se-fe-chip>
        <se-fe-menu [seFeDataOptions]="statusMenuOptions" class="credential-menu">
          <div seFeMenuContent class="credential-menu-content">
            <label>Status</label>
            <form [formGroup]="filtersForm" (ngSubmit)="applyFilters()" novalidate>
              <se-fe-checkboxes class="credential-filter-checkbox" formControlName="statusFilters"
                [seFeDataOptions]="statusCheckOptions">
              </se-fe-checkboxes>
              <se-fe-button seFeDataEmphasis="high" [seFeDataFullWidth]="true" seFeDataType="submit" class="apply-filter-button">
                Apply Filters
              </se-fe-button>
              <se-fe-button [seFeDataFullWidth]="true" (click)="clearFilter('statusFilters')">
                Clear All Filters
              </se-fe-button>
            </form>
          </div>
        </se-fe-menu>
      </se-fe-chip-group>
      <se-fe-table-wrapper *ngIf="organizationId">
        <table seFeTable seFeTableSort title="Credential Table">
          <ng-container seFeColumnDef="credential">
            <th seFeDataRef="credential" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
              Credential
            </th>
            <td seFeCell *seFeCellDef="let row">{{ row.name }}</td>
          </ng-container>
          <ng-container seFeColumnDef="status">
            <th seFeDataRef="status" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
              Status
            </th>
            <td seFeCell *seFeCellDef="let row">
              <se-fe-chip [seFeDataText]="row.chip.text" [seFeDataStyle]="row.chip.color"
                seFeDataIconLeft="dot_fill"></se-fe-chip>
            </td>
          </ng-container>
          <ng-container seFeColumnDef="validityDates">
            <th seFeDataRef="validityDates" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
              Valid Date Range
            </th>
            <td seFeCell *seFeCellDef="let row">{{ row.validityDates }}</td>
          </ng-container>
          <ng-container seFeColumnDef="person">
            <th seFeDataRef="person" seFeTableSortHeader seFeHeaderCell *seFeHeaderCellDef>
              Person
            </th>
            <td seFeCell *seFeCellDef="let row">
              <se-fe-grid seFeGridOpts="snug middle">
                <se-fe-grid-cell seFeGridOpts="shrink">
                  <se-fe-avatar class="credential-table-avatar" seFeDataType="persona" [seFeDataIdentity]="row.personaName"
                    [seFeDataImgUrl]="row.profileImage" seFeDataSize="200">
                  </se-fe-avatar>
                </se-fe-grid-cell>
                <se-fe-grid-cell seFeGridOpts="grow">
                  <a seFeLink="low" [href]="'user/household?id=' + row.personaId">
                    {{ row.personaName }}
                  </a>
                </se-fe-grid-cell>
              </se-fe-grid>
            </td>
          </ng-container>
          <tr seFeHeaderRow *seFeHeaderRowDef="displayedColumns"></tr>
          <tr seFeRow *seFeRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </se-fe-table-wrapper>
      <se-fe-spinner class="credential-table-spinner" *ngIf="loading" [seFeDataSize]="900" [seFeDataCenter]="true">
      </se-fe-spinner>
      <se-fe-empty-state seFeDataTitle="Looks like you don't have any credentials yet!"
        *ngIf="!loading && listItems && !listItems.length">
        <se-fe-icon seFeEmptyStateIcon seFeDataName="spot_whistle" seFeDataSize="950"></se-fe-icon>
      </se-fe-empty-state>
      <se-fe-empty-state seFeDataTitle="Select an organization to view your credentials"
        *ngIf="!organizationId && organizationOptions">
        <se-fe-icon seFeEmptyStateIcon seFeDataName="spot_search" seFeDataSize="950"></se-fe-icon>
        <form *ngIf="organizationFormReady" [formGroup]="organizationForm" novalidate>
          <se-fe-form-field-select formControlName="organization" seFeDataPlaceholder="Organization"
            [seFeDataOptions]="organizationOptions" [seFeDataComfy]="true" [seFeDataAllowClear]="false"
            seFeDataAriaLabel="Organization">
          </se-fe-form-field-select>
        </form>
      </se-fe-empty-state>
      <se-fe-empty-state [seFeDataTitle]="refreshEmptyStateTitle"
        *ngIf="listItems && listItems.length && !dataSource.data.length">
        <se-fe-icon seFeEmptyStateIcon seFeDataName="spot_search" seFeDataSize="950"></se-fe-icon>
        Try adjusting your filters to find what you're looking for.
        <se-fe-button seFeEmptyStateAction (click)="clearAllFilters()">
          <se-fe-icon seFeDataName="refresh" seFeDataSize="600" seFeDataColor="primary"></se-fe-icon>
          <span class="refresh">Clear Filters</span>
        </se-fe-button>
      </se-fe-empty-state>
    </se-fe-card-content>
  </se-fe-card>
</se-fe-page>
