<se-fe-header seFeDataTitle="USA Volleyball Academy" [seFeDataSize]="500">
  <se-fe-avatar seFeHeaderVisual
    seFeDataType="org"
    seFeDataIdentity="USA Volleyball"
    [seFeDataImgUrl]="orgLogo"
    seFeDataSize="500">
  </se-fe-avatar>
  <se-fe-button seFeHeaderActions seFeDataEmphasis="high" (click)="open()">Launch</se-fe-button>
</se-fe-header>
<se-fe-modal #modal>
  <div seFeModalContent>
    <se-fe-header seFeDataTitle="USA Volleyball Academy" [seFeDataSize]="500">
      <div seFeHeaderSubtitle>
        <a seFeLink target="_blank" href="https://help.usavolleyballacademy.org/">Need Help?</a>
      </div>
      <se-fe-avatar seFeHeaderVisual
        seFeDataType="org"
        seFeDataIdentity="USA Volleyball"
        [seFeDataImgUrl]="orgLogo"
        seFeDataSize="500">
      </se-fe-avatar>
      <se-fe-icon-button seFeHeaderActions (click)="close()">
        <se-fe-icon seFeDataName="remove" seFeDataSize="700" seFeDataAriaLabel="Clsoe"></se-fe-icon>
      </se-fe-icon-button>
    </se-fe-header>
    <div class="launch-text">
      <p>The USA Volleyball Academy is a Learning Management System where you can complete necessary coursework (ex: junior officials or scorer training).
        We will be sure to sync any credentials from the USA Volleyball Academy to your SportsEngine profile.</p>
    </div>
    <div>
      <label class="launch-label">Select Profile:</label>
      <form [formGroup]="form">
        <se-fe-radios
          class="launch-radios"
          formControlName="persona"
          [seFeDataOptions]="personaOptions">
          <ng-template let-option>
            <div class="persona-row">
              <se-fe-avatar
                class="persona-row-item"
                seFeDataType="persona"
                [seFeDataIdentity]="option.label"
                [seFeDataImgUrl]="option.imgURL"
                seFeDataSize="100">
              </se-fe-avatar>
              {{ option.label }}
            </div>
          </ng-template>
        </se-fe-radios>
      </form>
    </div>
  </div>
  <div seFeModalFooter>
    <se-fe-modal-footer
      #footer
      [seFeDataFooterButtons]="modalButtons">
    </se-fe-modal-footer>
  </div>
</se-fe-modal>
