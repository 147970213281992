import { BossResources as boss } from 'se-resource-types'

export const FULL_ACCESS: boss.AdminInvitePermissionType = 'Full Access'
export const LIMITED_ACCESS: boss.AdminInvitePermissionType = 'Limited Access'
export const NO_ACCESS: boss.AdminInvitePermissionType = 'No Access'

export interface SeAddPersonOptions {
  orgId: number | string
  title?: string
  allowGrant?: boolean
  defaultNoGrant?: boolean
  freeOrg?: boolean
  verifyProduct?: boolean
  affiliatePermissionOrganization?: string
  thirdNorth?: boolean
}

export interface SeAddGuardianOptions {
  personaId: number | string
  fullName: string
}

export interface SeOrgMessageOptions {
  orgId: number | string
  excludeIds?: (number | string)[]
  groupRecipientIds?: (number | string)[]
  personaId?: number | string
  recipientIds?: (number | string)[]
  title?: string
}

export const COUNTRY_OPTIONS = ['US', 'CA']

export const DISABILITY_OPTIONS = ['yes', 'no', 'unspecified']

export const FILE_OPTIONS =
  '.doc,.docx,.json,.odt,.pdf,.ppt,.pptx,.xls,.xlsx,.gif,.jpeg,.jpg,.png,.csv,.json,.txt'

export const GENDER_OPTIONS = ['male', 'female', 'non-binary']

export const GRANT_OPTIONS = [
  {
    label: "No, don't grant access",
    value: false,
    help: 'Typically for participants, parents/guardians, coaches, etc.',
  },
  {
    label: 'Yes, grant access',
    value: true,
    help: "Typically for Registrars, Treasurers, etc — they'll be added to your Admins list and be invited to sign in to HQ",
  },
]

export const MILITARY_OPTIONS = ['none', 'veteran', 'active', 'unspecified']

export const PERMISSION_TYPE_OPTIONS = [
  {
    label: FULL_ACCESS,
    value: FULL_ACCESS,
    help: 'Access to manage admins, HQ tools, your website, registrations, and financials',
    help_free_org: 'Access to manage admins, HQ tools, and financials',
  },
  {
    label: LIMITED_ACCESS,
    value: LIMITED_ACCESS,
  },
]

export const PERMISSION_TYPE_OPTIONS_WITH_NO_ACCESS = [
  ...PERMISSION_TYPE_OPTIONS,
  {
    label: NO_ACCESS,
    value: null,
  },
]

export const AFFILIATE_ROLE_OPTIONS = [
  {
    label: 'Manage',
    value: 'governingSeasonAdmin',
  },
  {
    label: 'View Only',
    value: 'governingSeasonView',
  },
]

export const PERMISSION_DETAIL_OPTIONS = [
  {
    value: 'org_admin',
    label: 'HQ Tools',
    description:
      'Access to all non-financial administrative tools in HQ. Admins with this permission can:',
    abilities: [
      'Invite and manage members',
      'Send communications',
      'Manage registrations',
    ],
  },
  {
    value: 'website_admin',
    label: 'Website',
    description:
      'Access to all website pages and content. Admins with this permission can:',
    abilities: [
      'Create, edit and delete pages and content',
      'Grant page permissions',
      'Manage website tools and settings',
    ],
  },
  {
    value: 'financial_client_admin',
    label: 'Financials',
    description: 'Access to all financial tools and information.',
    abilities: [
      'Send invoices',
      'Manage bank accounts',
      'Issue refunds and record payments',
      'Edit payment plans',
      'View all financial data',
    ],
  },
  {
    value: 'super_reg',
    label: 'Registration Builder',
    description: 'Access to all registration builder tools. Admins with this permission can:',
    abilities: [
      'Create, update, and delete registrations'
    ],
  },
  {
    value: 'compliance',
    label: 'Compliance',
    description: 'Access to manage screening and compliance for your members.',
    abilities: [],
  }
]

export const STATE_OPTIONS = [
  {
    country: 'US',
    states: [
      'AL',
      'AK',
      'AS',
      'AZ',
      'AR',
      'AA',
      'AE',
      'AP',
      'CA',
      'CO',
      'CT',
      'DE',
      'DC',
      'FL',
      'GA',
      'GU',
      'HI',
      'ID',
      'IL',
      'IN',
      'IA',
      'KS',
      'KY',
      'LA',
      'ME',
      'MH',
      'MD',
      'MA',
      'MI',
      'FM',
      'MN',
      'MS',
      'MO',
      'MT',
      'NE',
      'NV',
      'NH',
      'NJ',
      'NM',
      'NY',
      'NC',
      'ND',
      'MP',
      'OH',
      'OK',
      'OR',
      'PW',
      'PA',
      'PR',
      'RI',
      'SC',
      'SD',
      'TN',
      'TX',
      'UT',
      'VT',
      'VI',
      'VA',
      'WA',
      'WV',
      'WI',
      'WY'
    ]
  },
  {
    country: 'CA',
    states: [
      'AB',
      'BC',
      'MB',
      'NB',
      'NL',
      'NT',
      'NS',
      'NU',
      'ON',
      'PE',
      'QC',
      'SK',
      'YT'
    ]
  }
]

export const SUFFIX_OPTIONS: string[] = [
  '',
  'Jr.',
  'Sr.',
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
  'X',
]
