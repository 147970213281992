import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { RsvpView } from '../../view-models/rsvp-view'
import { EventCard } from './event-card-types'
import { EventTimeRange } from './event-time-range'

enum RsvpState {
  Yes = 'yes',
  Maybe = 'maybe',
  No = 'no',
  NoResponse = 'noResponse'
}

@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit, OnChanges {

  @Input('dataEvent') public event: EventCard

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onRsvp = new EventEmitter()

  public range: string
  public titleLinkType: string
  public fallbackTeamColor = '#00668f'
  public cardAccentColor = ''
  public rsvps: RsvpView[] = []
  public rsvpListOpen: boolean
  public rsvpAccordionTitle: string
  public shownAvatars: any[]
  public showCount: boolean
  public avatarsNotShown: string
  public playChipOpts: any
  private eventTimeRange = new EventTimeRange()

  constructor(private route: ActivatedRoute) {
    //noop
  }

  public ngOnInit(): void {
    this.cardAccentColor = this.getTeamColor()
    this.range = this.eventTimeRange.getTimeRange(this.event)
    this.rsvps = this.getRsvps()
    this.shownAvatars = this.getShownAvatars()
    this.rsvpListOpen = !this.hasAllRsvps()
    this.setRSVPAccordionHeader()
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.event && changes.event.previousValue !== changes.event.currentValue) {
      this.playChipOpts = this.playChipOptions()
    }
  }

  public getTeamColor(): string {

    if (!this.event.teamColor) {
      return this.fallbackTeamColor
    }

    return this.event.teamColor
  }

  public setRSVPAccordionHeader(): void {
    this.rsvpAccordionTitle = this.rsvpListOpen ? 'Are You Going?' : 'RSVPs'
  }

  public hasAllRsvps(): boolean {
    if (this.rsvps && this.rsvps.length > 0) {
      return this.rsvps.every((rsvp) => rsvp.response && this.rsvpHasResponse(rsvp.response))
    } else {
      return false
    }
  }

  public toggleAccordion(): void {
    this.rsvpListOpen = !this.rsvpListOpen
    this.setRSVPAccordionHeader()
  }

  public emitEvent(event): void {
    this.onRsvp.emit(event)
  }

  public getShownAvatars(): any[] {
    let shown = []
    this.rsvps.forEach(val => shown.push(Object.assign({}, val)))

    if (shown.length > 4) {
      this.showCount = true
      shown = shown.slice(0, 3)
      this.avatarsNotShown = '+' + (this.rsvps.length - 3).toString() // create the number for the general avatar
    }
    return shown
  }

  public playChipOptions(): any {
    const playVideo = this.event.videos.find(vid => vid.originator_system === 'SE Play')
    const watchURL = playVideo?.url || ''
    const videoStatus = playVideo?.status

    return JSON.stringify({
      start_date_time: this.event.startDateTime,
      end_date_time: this.event.endDateTime,
      event_id: this.event.id,
      video_status: videoStatus,
      watch_url: watchURL,
      route: this.setChipRouteVal(),
      can_edit: true,
      small_chip: false,
      target: '_blank'
    })
  }

  public setChipRouteVal(): string {
    const currentPath = this.route.snapshot.url.join('/')
    return currentPath === 'schedule' ? 'my_se_schedule' : 'my_se_home'
  }

  private getRsvps(): RsvpView[] {
    const rsvpIds = []
    const rsvps = []
    this.event.rsvps.forEach(rsvp => {
      if (!rsvpIds.includes(rsvp.id)) {
        rsvps.push(rsvp)
        rsvpIds.push(rsvp.id)
      }
    })
    return rsvps
  }

  private rsvpHasResponse(response: string): boolean {
    const lower = response.toLowerCase()
    return (lower === RsvpState.Yes || lower === RsvpState.Maybe || lower === RsvpState.No)
  }

}
