<se-fe-modal #removePersonaModal
  seFeDataSize="small">
  <div seFeModalContent>
    <section class="modal-section modal-header">
      <se-fe-header
        seFeDataSize="600">
        <div seFeHeaderTitle>
          Remove profile?
        </div>
      </se-fe-header>
    </section>
    <section class="modal-section">
      You are about to remove this profile:
    </section>
    <section class="modal-section">
      <se-po-persona-avatar
        [persona]="profile?.persona"
        headerDataSize="600"
        headerSubtitle=''>
      </se-po-persona-avatar>
    </section>
    <section class="modal-section">
      This will permanently remove this profile from your household.
    </section>

    <section *ngIf="submitter.error" class="modal-section">
      <se-fe-alert class="modal-error"
        seFeDataType="error">
        {{ submitter.error }}
      </se-fe-alert>
    </section>
</div>
<div seFeModalFooter>
  <se-fe-modal-footer #removePersonaFooter
    [seFeDataFooterButtons]="options">
  </se-fe-modal-footer>
</div>
</se-fe-modal>
<se-fe-toast></se-fe-toast>
