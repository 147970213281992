import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SeMySportsengineFeatureNewGuardianModalModule } from '@se-po/se-my-sportsengine-feature-new-guardian-modal'
import { SharedUiPersonaAvatarModule } from '@se-po/shared-ui-persona-avatar'
import { SeFeAlertModule } from 'se-fe-alert'
import { SeFeAvatarModule } from 'se-fe-avatar'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeCardModule } from 'se-fe-card'
import { SeFeChipModule } from 'se-fe-chip'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeFormFieldOptionsModule } from 'se-fe-form-field-options'
import { SeFeHeaderModule } from 'se-fe-header'
import { SeFeIconModule, seFeIconEnvelopeThin, seFeIconInfoCircle, seFeIconPlus } from 'se-fe-icon'
import { SeFeListModule } from 'se-fe-list'
import { SeFeMenuModule } from 'se-fe-menu'
import { SeFeModalModule } from 'se-fe-modal'
import { SeFePopoverModule } from 'se-fe-popover'
import { SeFeTooltipModule } from 'se-fe-tooltip'
import { ChildGrantAccessModalComponent } from './child-grant-access-modal/child-grant-access-modal.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeAlertModule,
    SeFeAvatarModule,
    SeFeButtonModule,
    SeFeCardModule,
    SeFeChipModule,
    SeFeIconModule.include([
      seFeIconEnvelopeThin,
      seFeIconInfoCircle,
      seFeIconPlus
    ]),
    SeFeFormFieldModule,
    SeFeFormFieldOptionsModule,
    SeFeHeaderModule,
    SeFeListModule,
    SeFeMenuModule,
    SeFeModalModule,
    SeFePopoverModule,
    SeFeTooltipModule,
    SharedUiPersonaAvatarModule,
    SeMySportsengineFeatureNewGuardianModalModule
  ],
  declarations: [ChildGrantAccessModalComponent],
  exports: [ChildGrantAccessModalComponent],
})
export class SeMySportsengineFeatureChildGrantAccessModalModule {}
