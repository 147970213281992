import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms'
import { FieldValidationService } from './field-validation.service'

export class AddressValidationService {

  public readonly fieldValidation: FieldValidationService
  public readonly requiredFields: string[] = ['country', 'street_1', 'city', 'state_or_province', 'zip']

  private readonly caZipRegex = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
  private readonly usZipRegex = /^[0-9]{5}(?:-[0-9]{4})?$/
  private readonly zipRegex = /^([0-9]{5}(?:-[0-9]{4})?|[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d)$/i

  constructor(public form: UntypedFormGroup) {
    this.fieldValidation = new FieldValidationService(this.form)
    this.init()
  }

  public get anyAddressFields(): boolean {
    return this.requiredFields.some(field => !!this.form.get(field).value)
  }

  public get requireAll(): boolean {
    return this.formRequired(this.form) || this.anyAddressFields
  }

  public init(): void {
    this.initListeners()
    if(this.formRequired(this.form)) {
      this.makeEverythingRequired()
    }
  }

  public zipValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const valid = this.validZip(control.value)
      return valid ? null : { invalidZip: true }
    }
  }

  public validZip(zip: string): boolean {
    const country = this.form.get('country').value
    let regex
    if(country === 'CA') {
      regex = this.caZipRegex
    } else if(country === 'US') {
      regex = this.usZipRegex
    } else {
      regex = this.zipRegex
    }
    return zip === '' || regex.test(zip)
  }

  private initListeners(): void {
    this.requiredFields.forEach(formField => {
      this.form.get(formField).valueChanges
        .subscribe(this.valueChangeFor(formField).bind(this))
    })
    this.form.statusChanges.subscribe(status => {
      this.statusChange(status)
    })
  }

  private statusChange(status: string): void {
    if(this.anyAddressFields) return
    if (this.formRequired(this.form) && status === 'VALID') {
      this.makeEverythingRequired()
    } else if (!this.formRequired(this.form) && status === 'INVALID') {
      // setting emitEvent to false so that each change in field validation
      // does not trigger extra status change events
      this.makeNothingRequired(false)
      this.form.updateValueAndValidity()
    }
  }

  private valueChangeFor(field: string): any {
    return (): void => {
      if (!this.requireAll) {
        if (this.fieldValidation.isRequired(field)) this.makeNothingRequired()
        return
      }

      if (!this.fieldValidation.isRequired(field)) {
        this.makeEverythingRequired()
      }
    }
  }

  private makeEverythingRequired(): void {
    this.requiredFields.forEach(f => {
      if (!this.fieldValidation.isRequired(f)) {
        if (f === 'zip') {
          this.fieldValidation.setValidators(f, [Validators.required, this.zipValidator()])
        } else {
          this.fieldValidation.setValidators(f, Validators.required)
        }
      }
    })
  }

  private makeNothingRequired(emitEvent=true): void {
    this.requiredFields.forEach(f => {
      if (this.fieldValidation.isRequired(f)) {
        if (f === 'zip') {
          this.fieldValidation.setValidators(f, this.zipValidator(), emitEvent)
        } else {
          this.fieldValidation.clearValidators(f, emitEvent)
        }
      }
    })
  }

  private formRequired(form: UntypedFormGroup): boolean {
    return form.hasValidator(Validators.required)
  }
}
