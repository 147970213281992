import { Component, Input, OnInit } from '@angular/core'
import { MembershipServiceResources as ms } from 'se-resource-types'

@Component({
  selector: 'app-membership-invite-modal',
  templateUrl: './membership-invite-modal.component.html',
  styleUrls: ['./membership-invite-modal.component.scss']
})
export class MembershipInviteModalComponent implements OnInit {

  @Input() public invite: ms.MembershipInvite
  public memDefOrgName: string
  public clubName: string
  public clubAvatarUrl: string
  public clubId: number

  public ngOnInit(): void {
    this.memDefOrgName = this.invite.membership_definition_organization.name
    this.clubName = this.invite.affiliation_organization.name
    this.clubAvatarUrl = this.invite.affiliation_organization.logo.is_default ? '' :
      this.invite.affiliation_organization.logo.url
    this.clubId = this.invite.affiliation_organization.id
  }
}
