<div class="pl-pane__container--horizontal">

  <div class="x-my-schedule-header pl-pane--collapse pl-pane--shrink pl-pane--sticky-top">
    <div class="pl-page-header pl-pane">
      <div pl-grid="tight spaced align-middle">
        <div pl-grid-el pl-grid="shrink@phone">
          <h1 class="pl-heading--xlarge" [textContent]="'SCHEDULE.title' | translate">upcomin evnts</h1>
        </div>
        <div pl-grid-el pl-grid="shrink@phone">
          <se-fe-button (click)="syncCalendar()" [seFeDataEmphasis]="'low'">Sync Schedule</se-fe-button>
        </div>
      </div>
    </div>
  </div>

  <section
    [ngClass]="{'myse-infinite-scroll--ready': loaded}"
    class="pl-pane__info js-schedule-scroll myse-infinite-scroll">

    <div class="pl-centered-container--max-width-medium">
      <div pl-grid="tight@tablet" class="js-event-container">
        <!-- skeleton -->
        <div *ngIf="!loaded" pl-grid-el pl-grid="1/1@phone">
          <div class="myse-dashboard-skeleton--section-card myse-dashboard__full-card se-card"></div>
        </div>
        <div *ngIf="!loaded" pl-grid-el pl-grid="1/1@phone">
          <div class="myse-dashboard-skeleton--section-card myse-dashboard__full-card se-card"></div>
        </div>
        <div *ngIf="!loaded" pl-grid-el pl-grid="1/1@phone">
          <div class="myse-dashboard-skeleton--section-card myse-dashboard__full-card se-card"></div>
        </div>
        <div pl-grid="loose"
          class="pl-text--right"
          *ngIf="loaded && !(isFirstPage && isLastPage)"
          style="margin-bottom: 8px">
          <div pl-grid-el pl-grid="1/1@phone">
            <span class="pl-text--light"
              *ngIf="events.length > 0"
              [textContent]="'SCHEDULE.paginate_label' | translate: {
                amount: events.length,
                start: firstDay() | date: 'MMM d',
                end: lastDay() | date: 'MMM d'
              }">
            </span>
            &nbsp;
            <span>
                <se-fe-icon-button [seFeDataIsDisabled]="isFirstPage" (click)="previousPage()">
                  <se-fe-icon
                    seFeDataName="chevron_left"
                    seFeDataSize="700"
                    seFeDataAriaLabel="'PAGINATION.previous' | translate"
                    seFeDataMargin="false">
                  </se-fe-icon>
                </se-fe-icon-button>
              <se-fe-icon-button [seFeDataIsDisabled]="isLastPage" (click)="nextPage()">
                <se-fe-icon
                  seFeDataName="chevron_right"
                  seFeDataSize="700"
                  seFeDataAriaLabel="'PAGINATION.next' | translate"
                  seFeDataMargin="false">
                </se-fe-icon>
              </se-fe-icon-button>
            </span>
          </div>
        </div>

        <!-- sliding window of events -->
        <ng-container *ngIf="loaded">
          <div
            *ngFor="let day of days; last as isLast"
            pl-grid-el
            pl-grid="1/1@phone"
            class="myse-event__day-container">
            <div class="myse-event__day-label">
              <app-date-stamp [dateString]="day.day"></app-date-stamp>
            </div>
            <div class="myse-event__day-grouping">
              <div
                *ngFor="let event of day.events"
                class="event-class-for-event">
                  <se-fe-empty-state *ngIf="event.emptyMessage" seFeDataTitle="{{ event.emptyMessage }}">
                  </se-fe-empty-state>
                  <app-event-card *ngIf="!event.emptyMessage" [dataEvent]="event" (onRsvp)="onRsvp($event)"></app-event-card>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <div *ngIf="isLastPage && loaded" pl-grid="loose">
        <div pl-grid-el pl-grid="1/1@phone" class="myse-infinite-scroll__empty-state">
          <se-fe-empty-state seFeDataTitle="{{ 'SCHEDULE.no_more_events_title' | translate }}" seFeDataSize="500">
            {{ 'SCHEDULE.no_more_events_description' | translate }}
          </se-fe-empty-state>
        </div>
      </div>
      <div pl-grid="loose" class="pl-text--right" *ngIf="loaded && !(isFirstPage && isLastPage)">
        <div pl-grid-el pl-grid="1/1@phone">
          <span class="pl-text--light"
            *ngIf="events.length > 0"
            [textContent]="'SCHEDULE.paginate_label' | translate: {
              amount: events.length,
              start: firstDay() | date: 'MMM d',
              end: lastDay() | date: 'MMM d'
            }">
          </span>
          &nbsp;
          <span>
            <se-fe-icon-button [seFeDataIsDisabled]="isFirstPage" (click)="previousPage()">
              <se-fe-icon
                seFeDataName="chevron_left"
                seFeDataSize="700"
                seFeDataAriaLabel="'PAGINATION.previous' | translate"
                seFeDataMargin="false">
              </se-fe-icon>
            </se-fe-icon-button>
            <se-fe-icon-button [seFeDataIsDisabled]="isLastPage" (click)="nextPage()">
              <se-fe-icon
                seFeDataName="chevron_right"
                seFeDataSize="700"
                seFeDataAriaLabel="'PAGINATION.next' | translate"
                seFeDataMargin="false">
              </se-fe-icon>
            </se-fe-icon-button>
          </span>
        </div>
      </div>
    </div>
  </section>
</div>
