import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { SiteMembership } from '@se-po/shared-data-access-models'
import { SiteMembershipService } from '@se-po/shared-data-access-services'
import { Observable, of } from 'rxjs'

export const SiteMembershipResolver: ResolveFn<SiteMembership[]> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: SiteMembershipService = inject(SiteMembershipService)
): Observable<SiteMembership[]> => {
  const personaId = route.params.personaId
  return personaId ? service.findAll(personaId) : of([])
}
