import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { SeMySportsengineFeatureGuardianPermissionsCardModule } from '@se-po/se-my-sportsengine-feature-guardian-permissions-card'
import { SeMySportsengineFeatureHouseholdContactCardModule } from '@se-po/se-my-sportsengine-feature-household-contact-card'
import { SeMySportsengineFeaturePermissionsCardModule } from '@se-po/se-my-sportsengine-feature-permissions-card'
import { SharedFeaturePersonaImageModalModule } from '@se-po/shared-feature-persona-image-modal'
import { SharedUiOverviewCardModule } from '@se-po/shared-ui-overview-card'
import { SharedUiProfileSidePanelModule } from '@se-po/shared-ui-profile-side-panel'
import { SeFeBannerModule } from 'se-fe-banner'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeLinkModule } from 'se-fe-link'
import { SeFeMenuModule } from 'se-fe-menu'
import { HouseholdSidePanelComponent } from './household-side-panel/household-side-panel.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeBannerModule,
    SeFeButtonModule,
    SeFeLinkModule,
    SeFeMenuModule,
    SeMySportsengineFeatureGuardianPermissionsCardModule,
    SeMySportsengineFeatureHouseholdContactCardModule,
    SeMySportsengineFeaturePermissionsCardModule,
    SharedFeaturePersonaImageModalModule,
    SharedUiOverviewCardModule,
    SharedUiProfileSidePanelModule,
    TranslateModule
  ],
  declarations: [HouseholdSidePanelComponent],
  exports: [HouseholdSidePanelComponent]
})
export class SeMySportsengineFeatureHouseholdSidePanelModule {}
