import { Component, EventEmitter, Input, Output } from '@angular/core'
import { UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'

interface Dupe {
  dismissed: boolean
  name: string
  pluralName: string
  profiles: UserProfile[]
}

@Component({
  selector: 'se-po-duplicate-banner',
  templateUrl: './duplicate-banner.component.html',
  styleUrls: ['./duplicate-banner.component.scss']
})
export class DuplicateBannerComponent {
  @Output() public merge = new EventEmitter<UserProfile[]>()

  public duplicates: Dupe[] = []
  public showBanner = false

  private _profiles: UserProfile[]

  public get profiles(): UserProfile[] {
    return this._profiles
  }

  @Input() public set profiles(profiles: UserProfile[]) {
    // User can only merge together profiles that they own
    this._profiles = profiles.filter(p => p.access === 'owner')
    this.checkForDupes()
  }

  public checkForDupes(): any {
    const oldDupes = this.duplicates
    const dupes: Dupe[] = []

    this.profiles.forEach(profile => {
      if (this.isDuplicate(profile)) {
        const dupe = dupes.find(d => d.name.toLowerCase() === profile.persona.full_name.toLowerCase())
        if (dupe) {
          dupe.profiles.push(profile)
        }

        else {
          const suffixes = ['s', 'sh', 'ch', 'x', 'z']
          const pluralSuffix = suffixes.some(suffix => profile.persona.first_name.endsWith(suffix)) ? 'es' : 's'
          dupes.push({
            name: profile.persona.full_name,
            pluralName: `${profile.persona.first_name}${pluralSuffix}`,
            profiles: [profile],
            dismissed: oldDupes.find(d => d.name.toLowerCase() === profile.persona.full_name.toLowerCase())?.dismissed || false
          })
        }
      }
   })
   this.duplicates = dupes
   // Do not show duplicates that the user already tried to hide
   this.showBanner = !!this.duplicates.find(d => !d.dismissed)
  }

  public isDuplicate(profile: UserProfile): boolean {
    return this.profiles.some(p =>
      profile.persona.full_name.toLowerCase() === p.persona.full_name.toLowerCase() && profile.id !== p.id)
  }

  public onMerge(duplicate: Dupe): void {
    const selfDupe = duplicate.profiles.find(p=> p.relationship === 'self')
    const keepProfile = selfDupe || duplicate.profiles[0]
    const mergeProfile = selfDupe ? duplicate.profiles.filter(p => p.id !== selfDupe.id )[0] : duplicate.profiles[1]
    this.merge.emit([keepProfile, mergeProfile])
  }

  public dismissBanner(duplicate: Dupe) {
    this.duplicates.find(dupe => dupe.name === duplicate.name).dismissed = true
    this.showBanner = !!this.duplicates.find(d => !d.dismissed)
  }
}
