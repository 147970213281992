import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'
import { ApiResponse } from 'se-resource-types/dist/lib/common'
import { CurrentUser, User } from 'se-resource-types/dist/lib/UserService'

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private user: BehaviorSubject<CurrentUser> = new BehaviorSubject<CurrentUser>(
    null
  )

  constructor(private api: SeFeApiService) {
    // noop
  }

  public get current(): Observable<CurrentUser> {
    return this.user.asObservable()
  }

  public findCurrent(): void {
    this.getUser()
      .pipe(first())
      .subscribe(
        (data: any) => {
          this.user.next((data.result && data.result.user) || {})
        },
        (error) => {
          this.user.next({} as CurrentUser)
        }
      )
  }

  public getUser(): Observable<ApiResponse<CurrentUser>> {
    return this.api.get<CurrentUser>('oauth/me')
  }

  public mine(): Observable<User> {
    return this.api.get<User>('users/mine').pipe(
      map(response => response.result)
    )
  }
}
