<se-fe-card>
  <se-fe-card-header class="overview-header"
    seFeDataTitle="Overview">
    <div seFeCardHeaderActions>
      <se-fe-button *ngIf="tabs?.[tabs.selected]?.hasResults" (click)="viewAll()">View {{ tabs?.[tabs.selected]?.title }}</se-fe-button>
    </div>
  </se-fe-card-header>
  <se-fe-card-content>
    <se-fe-tab-group class="overview-tab-group">
      <se-fe-tab-item *ngFor="let key of tabKeys" class="overview-card-tab">
        <a [seFeTab]="tabs.selected === key" (click)="switchTab(key)" (keydown)="onKeydown(key, $event)">{{ key | titlecase }}</a>
      </se-fe-tab-item>
    </se-fe-tab-group>
    <se-fe-spinner *ngIf="!tabs.selected" [seFeDataSize]="900" [seFeDataCenter]="true" class="overview-spinner"></se-fe-spinner>
    <div *ngIf="canAccess" [ngClass]="{ 'overview-hidden-tab': tabs.selected !== 'memberships' }">
      <se-po-membership-list [personaId]="personaId" (hasResults)="membershipsHasResults($event)"></se-po-membership-list>
    </div>
    <div *ngIf="canAccess" [ngClass]="{ 'overview-hidden-tab': tabs.selected !== 'registrations' }">
      <se-po-registration-list [personaId]="personaId" (hasResults)="registrationsHasResults($event)"></se-po-registration-list>
    </div>
    <div [ngClass]="{ 'overview-hidden-tab': tabs.selected !== 'teams' }">
      <se-po-team-list [personaId]="personaId" (hasResults)="teamsHasResults($event)"></se-po-team-list>
    </div>
    <div [ngClass]="{ 'overview-hidden-tab': tabs.selected !== 'organizations' }">
      <se-po-organization-list [personaId]="personaId" [access]="access" (hasResults)="organizationsHasResults($event)"></se-po-organization-list>
    </div>
  </se-fe-card-content>
</se-fe-card>
