import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core'
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { HouseholdGuardianInvitesService } from '@se-po/shared-data-access-services'
import { SeFeFormFieldConfig } from 'se-fe-form-field'
import { SeFeSelectOption } from 'se-fe-form-field-select'
import { SeFeFormService } from 'se-fe-form-service'
import { SeFeModalButton, SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { SeFeToastService } from 'se-fe-toast'
import { Persona } from 'se-resource-types/dist/lib/CentralService/Profiles'

@Component({
  selector: 'se-po-new-guardian-modal',
  templateUrl: './new-guardian-modal.component.html',
  styleUrls: ['./new-guardian-modal.component.scss'],
})
export class NewGuardianModalComponent implements OnInit {
  @Output() public inviteSent: EventEmitter<void> = new EventEmitter()
  @ViewChild('modal') public modal: SeFeModalComponent
  @ViewChild('footer') public footer: SeFeModalFooterComponent

  public modalButtons: SeFeModalButton[] = [
    {
      cancel: true,
      label: 'Cancel',
      action: () => {
        this.modal.close()
      },
    },
    {
      primary: true,
      label: 'Send Invite',
      action: () => {
        this.submit()
      },
    },
  ]

  public accessFieldOptions: SeFeSelectOption[] = [
    {
      label: 'Manage',
      value: 'manager'
    },
    {
      label: 'View',
      value: 'viewer'
    }
  ]

  public error: string
  public form: UntypedFormGroup
  public persona: Persona

  constructor(
    private formBuilder: FormBuilder,
    private formService: SeFeFormService,
    private guardianInviteService: HouseholdGuardianInvitesService,
    private toastService: SeFeToastService
  ) { }


  public ngOnInit(): void {
    this.createForm()
  }

  public createForm(): void {
    this.form = this.formBuilder.group(
      {
        email: ['', Validators.required],
        access: ['', Validators.required]
      }
    )
  }

  public open(persona: Persona): void {
    this.form.reset()
    this.error = null
    this.persona = persona
    this.modal.open(null)
  }

  public submit(): void {
    this.error = null
    if (this.formService.isValid(this.form)){
      this.guardianInviteService.create(
        this.form.value.access,
        this.form.value.email,
        this.persona.id,
        'mySe'
      ).subscribe({
        next: result => {
          this.toastService.success(
            `Invite has been sent to ${this.form.value.email}`
          )
          this.modal.close()
          this.inviteSent.emit()
        },
        error: err => {
          const message = `Error sending invite to ${this.form.value.email}`
          this.error = err?.error?.error?.messages?.[0] || message
          this.toastService.error(message)
          this.footer.cancelSpinner()
        }
      })
    }
    else {
      this.footer.cancelSpinner()
    }
  }
}
