import { AfterViewInit, Component, DoCheck, forwardRef, Injector, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core'
import {
  AbstractControl,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { CustomValidators } from '@se-po/shared-data-access-validators'
import { FormGroupValueAccessorDirective } from '@se-po/shared-ui-form-directives'
import { Subscription } from 'rxjs'
import { SeFeFormFieldTextComponent } from 'se-fe-form-field-text'
import { SeFeTranslationsLoader } from 'se-fe-translations'

@Component({
  selector: 'se-po-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss'],
  providers:[
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NameComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NameComponent),
      multi: true,
    }
  ]
})
export class NameComponent extends FormGroupValueAccessorDirective implements DoCheck, OnInit, OnDestroy, AfterViewInit {
  public static timestamp = Date.now()
  public static errorCounter = 1

  @Input() public enablePreferred = false
  @Input() public enableSuffix = false

  @ViewChildren(SeFeFormFieldTextComponent) public fields: QueryList<SeFeFormFieldTextComponent>

  public errorId: string
  public firstLabelFor: string
  public lastLabelFor: string
  public form: UntypedFormGroup
  public errors: ValidationErrors
  public errorMessages = {}
  public showPreferred = false
  public showSuffix = false

  private _subscriptions: Subscription[] = []

  constructor(
    protected injector: Injector,
    private formBuilder: FormBuilder,
    private seFeTranslationsLoader: SeFeTranslationsLoader,
    private translateService: TranslateService
  ) {
    super(injector)
  }

  public ngOnInit(): void {
    super.ngOnInit()
    this.errorId = `name-error-${ NameComponent.timestamp }-${ NameComponent.errorCounter++ }`
    this.initTranslations()
  }

  public ngAfterViewInit(): void {
    super.ngAfterViewInit()
    // In order to avoid ExpressionChangedAfterItHasBeenCheckedError issues,
    // we are getting the labelFor from the text fields within a setTimeout
    // when the child fields exist
    this._subscriptions.push(this.fields.changes.subscribe(() => {
      setTimeout(() => {
        this.firstLabelFor = this.fields.first.labelFor
        this.lastLabelFor = this.fields.get(1).labelFor
      })
    }))
  }

  public ngDoCheck(): void {
    this.updateErrors()
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
    this._subscriptions.length = 0
  }

  public updateErrors(): void {
    if(!this.form.errors) {
      this.errors = []
      if(this.fields && this.fields.length === 2) {
        this.fields.first.helpErrorIds = ''
        this.fields.get(1).helpErrorIds = ''
      }
      return
    }
    // Only display the form group errors messages
    // if one of the form controls is in an error state
    const erroredControlNames = this.erroredControlNames()
    this.errors = erroredControlNames.length > 0 ? Object.keys(this.form.errors) : []
    // Associate errored form field inputs to the error message for accessibility.
    // helpErrorIds defines the aria-describedby on se-fe-form-field-text's input
    if(this.fields && this.fields.length === 2) {
      this.fields.first.helpErrorIds = erroredControlNames.includes('first') ? this.errorId : ''
      this.fields.get(1).helpErrorIds = erroredControlNames.includes('last') ? this.errorId : ''
    }
  }

  public erroredControlNames(): string[] {
    const parent = (this.control as any)._parent // access private property
    return Object.entries(this.form.controls).reduce((acc, [controlName, control]) => {
      const invalidAndTouchedAndDirty = control.invalid && control.touched && control.dirty
      const invalidAndSubmitted = control.invalid && parent?.submitted
      if(invalidAndTouchedAndDirty || invalidAndSubmitted) acc.push(controlName)
      return acc
    }, [])
  }

  public initTranslations(): void {
    this.seFeTranslationsLoader.load('shared/ui-name/assets/translations').then(() => {
      const validations = ['firstRequired', 'firstLastRequired', 'lastRequired']
      const errorMessages = {}
      validations.forEach(val => {
        errorMessages[val] = this.translateService.instant(`SE_PO_UI_NAME.ERRORS.${val}`)
      })
      this.errorMessages = errorMessages
      this.loaded = true
    })
  }

  public initForm(): void {
    this.form = this.formBuilder.group({
      first: ['', Validators.required],
      last: ['', Validators.required],
      preferred: [''],
      suffix: ['']
    }, { validators: CustomValidators.firstLastName })
  }

  public setForm(value: any): void {
    this.form.setValue(
      {
        first: value?.first || '',
        last: value?.last || '',
        preferred: value?.preferred || '',
        suffix: value?.suffix || ''
      }
    )
    this.updateControlState('preferred', this.enablePreferred)
    this.updateControlState('suffix', this.enableSuffix)
    this.showPreferred = this.enablePreferred && !!value?.preferred
    this.showSuffix = this.enableSuffix && !!value?.suffix
  }

  public validate(control: AbstractControl): ValidationErrors {
    return this.form.valid ? null : this.form.errors
  }

  private updateControlState(controlName: string, enabled: boolean) {
    const control: AbstractControl = this.form.get(controlName)
    if (enabled && control.disabled) {
      control.enable()
    } else if (!enabled && control.enable) {
      control.disable()
    }
  }
}
