import { Component, OnInit } from '@angular/core'
import { SeUserSettingsService } from 'se-user-settings'

@Component({
  selector: 'app-dashboard-mobile-callout',
  templateUrl: './dashboard-mobile-callout.component.html',
  styleUrls: ['./dashboard-mobile-callout.component.scss']
})
export class DashboardMobileCalloutComponent implements OnInit {

  public showMobileCallout = false
  public itunesUrl = 'https://itunes.apple.com/us/app/sport-ngin/id499597400?mt=8&ct=HQDashboard&pt=319609'
  public playUrl = 'https://play.google.com/store/apps/details?id=com.sportngin.android&hl=en&utm_campaign=HQDashboard'

  constructor(private userSettings: SeUserSettingsService) { }

  public ngOnInit(): void {
    this.showMobileCallout = !this.userSettings.get('myse-dashboard-hide-mobile')
  }

  public hideMobile(): void {
    this.showMobileCallout = !this.userSettings.set('myse-dashboard-hide-mobile', true)
  }

}
