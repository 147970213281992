import { Component, OnInit } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { take } from 'rxjs/operators'
import { Sale, SaleActivity, SalePayment, StatusTagColors } from '../models'
import { OrderService } from '../services/order.service'
import { PaymentService } from '../services/payment.service'

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html'
})
export class OrderDetailComponent implements OnInit {

  public order: Sale
  public payments: SalePayment[]
  public activity: SaleActivity[]
  public showMakePaymentButton = false

  constructor(private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService,
    private orderService: OrderService,
    private paymentService: PaymentService) {
    // noop
  }

  public ngOnInit(): void {
    this.order = this.activatedRoute.snapshot.data.order
    this.showMakePaymentButton = Number(this.order.amount_outstanding) > 0

    this.titleService.setTitle(
      this.translate.instant(
        'ROUTE_TITLE.page_title_template',
        { title: this.translate.instant('SALE_DETAIL.HEADERS.sale', { sale_number: this.order.sale_number }) }
      )
    )

    this.orderService.significantActivity(this.order.id).pipe(take(1)).subscribe(
      (val: SaleActivity[]) => this.activity = val
    )

    this.paymentService.adjustmentCounts(this.order.id).pipe(take(1)).subscribe(
      (val: SalePayment[]) => this.payments = val.sort((a,b) => a.due_date > b.due_date ? -1 : 1)
    )
  }

  public statusTagColor(order: any): string {
    return 'pl-tag--' && StatusTagColors[order.display_status]
  }

  public makePayment(): void {
    this.paymentService.makePayment(this.order.sale_number)
  }

}
