import { SeFeMenuOptions } from 'se-fe-menu'
import { MappableModel } from '@se-po/shared-data-access-models'

export class UserEmailAddress extends MappableModel {
  public address: string
  public id: number
  public is_primary: boolean
  public is_validated: boolean

  public markedForDeletion: boolean
  public markedForPrimary: boolean
  public menuOptions?: SeFeMenuOptions

  public get elementId(): string {
    return `email-${this.id}`
  }
}
