<se-fe-side-panel class="side-panel"
  #sidePanel
  [seFeDataLoaded]="!!profile"
  [(seFeDataVisible)]="showSidePanel"
  (seFeClose)="close()">
  <div *ngIf="menuOptions" seFeSidePanelActions>
    <se-fe-icon-button [seFeMenuTrigger]="menuOptions.name">
      <se-fe-icon
        seFeDataName="more"
        seFeDataSize="700"
        seFeDataAriaLabel="more">
      </se-fe-icon>
    </se-fe-icon-button>
  </div>
  <se-fe-header seFeSidePanelHeader *ngIf="profile"
    [seFeDataTitle]="!headerLink && persona?.full_name"
    [seFeDataSubtitle]="profileSubtitle">
    <se-fe-avatar *ngIf="editableAvatar"
      seFeHeaderVisual
      seFeDataType="persona"
      [seFeDataIdentity]="persona?.full_name"
      [seFeDataImgUrl]="profileImageUrl"
      (seFeClick)="onAvatarClick()">
    </se-fe-avatar>
    <se-fe-avatar *ngIf="!editableAvatar"
      seFeHeaderVisual
      seFeDataType="persona"
      [seFeDataIdentity]="persona?.full_name"
      [seFeDataImgUrl]="profileImageUrl">
    </se-fe-avatar>
    <a seFeHeaderTitle *ngIf="headerLink" seFeLink="low" [href]="headerLink">{{ persona?.full_name }}</a>
  </se-fe-header>
  <div seFeSidePanelContent>
    <div *ngIf="menuOptions">
      <se-fe-menu
        [seFeDataOptions]="menuOptions">
      </se-fe-menu>
    </div>
    <ng-content select="[profileSidePanelContent]"></ng-content>
  </div>
</se-fe-side-panel>
