<div *ngIf="loaded && form"
  [formGroup]="form"
  novalidate>
  <se-po-email
    formControlName="email"
    [seFeDataLabel]="'SE_PO_UI_CONTACT_INFO.LABELS.email' | translate">
  </se-po-email>
  <div class="phone">
    <se-po-phone
      formControlName="phone"
      [helpId]="phoneHelpId"
      [seFeDataLabel]="'SE_PO_UI_CONTACT_INFO.LABELS.phone' | translate">
    </se-po-phone>
  </div>
  <se-fe-form-control-help *ngIf="showHelp"
    [seFeDataId]="phoneHelpId">
    <button type="button"
      class="popover-link"
      [seFePopoverTrigger]="popoverName"
      seFeLink>
      {{ "SE_PO_UI_CONTACT_INFO.POPOVER.link" | translate }}
    </button>
    <se-fe-popover [seFePopoverName]="popoverName">
      <p>
        {{ "SE_PO_UI_CONTACT_INFO.POPOVER.paragraph1" | translate }}
      </p>
      <p [innerHTML]="'SE_PO_UI_CONTACT_INFO.POPOVER.paragraph2' | translate"></p>
    </se-fe-popover>
  </se-fe-form-control-help>
</div>
