import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { SeFeDocsExampleComponent } from 'se-fe-docs-example'
import {
  CentralServiceResources as cs
} from 'se-resource-types'
import { EditPersonaModalComponent } from '../../../lib/edit-persona-modal/edit-persona-modal.component'

const exampleHtml = `
<button (click)="editPersonaModal.open(profile)">Edit Persona</button>
<se-po-edit-persona-modal #editPersonaModal
  (personaEdited)="onPersonaEdited()">
</se-po-edit-persona-modal>
<div *ngIf="persona">
  <p>First name: {{ persona.first_name }}</p>
  <p>Last name: {{ persona.last_name }}</p>
  <p>Suffix: {{ persona.suffix }}</p>
  <p>Preferred name: {{ persona.nickname }}</p>
  <p>Gender: {{ persona.gender }}</p>
  <p>Graduation year: {{ persona.graduation_year }}</p>
  <p>Date of birth: {{ persona.date_of_birth }}</p>
  <p>Email: {{ email?.address }}</p>
  <p>Phone: {{ phone?.number }}</p>
</div>
`

const exampleTypescript = `
import { Component, OnInit, ViewChild } from '@angular/core'
import { EditPersonaModalComponent } from '../../lib/edit-persona-modal/edit-persona-modal.component'
import { UserServiceResources as us } from 'se-resource-types'

export class EditPersonaModalSelfExampleComponent implements OnInit {
  @ViewChild('editPersonaModal') public editPersonaModal: EditPersonaModalComponent
  public files = [
    { src: exampleHtml, name: 'Template', lang: 'html' },
    { src: exampleTypescript, name: 'Typescript', lang: 'typescript' },
  ]

  public email: cs.Profiles.EmailAddress
  public phone: cs.Profiles.PhoneNumber
  private _profile: cs.Households.UserProfile

  constructor() {
    super()
  }

  public get profile(): cs.Households.UserProfile {
    return this._profile
  }

  public get persona(): cs.Profiles.Persona {
    return this._profile.persona
  }

  @Input() public set profile(profile: cs.Households.UserProfile) {
    this._profile = profile
    this.email = this.persona.email_addresses.find(e => !!e.address)
    this.phone = (this.persona?.phone_numbers || []).find(p => !!p.number)
  }

  public ngOnInit(): void {
    this.profile = {
      id: '5',
      relationship: 'self',
      access: 'manager',
      persona: {
        id: 5,
        first_name: 'Carl',
        last_name: 'McMansion',
        date_of_birth: '2000-01-21',
        gender: 'male',
        graduation_year: '2020',
        email_addresses: [{ address: 'test@test.com', id: 10 }],
        // eslint-disable-next-line id-blacklist
        phone_numbers: [{ number: '5555555555', id: 9 }],
      }
    }
  }

  public onPersonaEdited(): void {
    console.log('persona edited')
  }
}
`

@Component({
  selector: 'se-po-edit-persona-modal-self-example',
  template: `<se-fe-docs-example [example]="this">${exampleHtml}</se-fe-docs-example>`,
  styleUrls: ['../edit-persona-modal-docs.component.scss']
})
export class EditPersonaModalSelfExampleComponent extends SeFeDocsExampleComponent implements OnInit {
  @ViewChild('editPersonaModal') public editPersonaModal: EditPersonaModalComponent
  public files = [
    { src: exampleHtml, name: 'Template', lang: 'html' },
    { src: exampleTypescript, name: 'Typescript', lang: 'typescript' },
  ]

  public email: cs.Profiles.EmailAddress
  public phone: cs.Profiles.PhoneNumber
  private _profile: cs.Households.UserProfile

  constructor() {
    super()
  }

  public get profile(): cs.Households.UserProfile {
    return this._profile
  }

  public get persona(): cs.Profiles.Persona {
    return this._profile.persona
  }

  @Input() public set profile(profile: cs.Households.UserProfile) {
    this._profile = profile
    this.email = this.persona.email_addresses.find(e => !!e.address)
    this.phone = (this.persona?.phone_numbers || []).find(p => !!p.number)
  }

  public ngOnInit(): void {
    this.profile = {
      id: '5',
      relationship: 'self',
      access: 'manager',
      persona: {
        id: 5,
        first_name: 'Carl',
        last_name: 'McMansion',
        date_of_birth: '2000-01-21',
        gender: 'male',
        graduation_year: '2020',
        email_addresses: [{ address: 'test@test.com', id: 10 }],
        // eslint-disable-next-line id-blacklist
        phone_numbers: [{ number: '5555555555', id: 9 }],
      }
    }
  }

  public onPersonaEdited(): void {
    console.log('persona edited')
  }
}
