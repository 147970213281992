// tslint:disable
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { SeEnvironmentUrlService } from 'se-fe-auth-support'
import { ApiService } from './api.service'

@Injectable({
  providedIn: 'root'
})
export class NginV2ApiService extends ApiService {

  constructor(http: HttpClient, urlService: SeEnvironmentUrlService) {
    super(http, urlService)
    this.defaultOptions = { headers: { Accept: 'application/vnd.ngin-api.v2, application/json'}}
  }

}
