import { AfterViewInit, Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core'
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms'
import { PhoneParser } from '@se-po/shared-data-access-parsers'
import { CustomValidators } from '@se-po/shared-data-access-validators'
import { BaseValueAccessorDirective } from '@se-po/shared-ui-form-directives'
import { Subscription } from 'rxjs'
import { SeFeFormFieldTextComponent } from 'se-fe-form-field-text'
import { SeFeTranslationsLoader } from 'se-fe-translations'

@Component({
  selector: 'se-po-phone',
  templateUrl: './phone.component.html',
  styleUrls: ['./phone.component.scss'],
  hostDirectives: [BaseValueAccessorDirective]
})
export class PhoneComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(SeFeFormFieldTextComponent) field: QueryList<SeFeFormFieldTextComponent>

  @Input() public helpId: string
  @Input() public hiddenLabel = false
  @Input() public seFeDataLabel?: string

  public loaded = false
  public ngControl = BaseValueAccessorDirective.ngControl(this) // get the forwarded control

  private _subscriptions: Subscription[] = []

  constructor(
    private seFeTranslationsLoader: SeFeTranslationsLoader)
  { }

  public get control(): AbstractControl {
    return this.ngControl?.control
  }

  public get form(): UntypedFormGroup | UntypedFormArray {
    return this.control?.parent
  }

  public ngOnInit(): void {
    this.initTranslations()
  }

  public ngAfterViewInit(): void {
    /* In order to avoid ExpressionChangedAfterItHasBeenCheckedError issues,
       we are updating the helpErrorIds within a setTimeout when the field exists.

       The helpErrorIds attr defines the aria-describedby on se-fe-form-field-text's input
       so that we know that the input is described by the control help.
       This is needed for accessibility
    */
    this._subscriptions.push(this.field.changes.subscribe(val => {
      setTimeout(() => {
        const helpIds = this.field.first.helpErrorIds
        this.field.first.helpErrorIds = helpIds ? helpIds + ' ' + this.helpId : this.helpId
      })
    }))
  }

  public ngOnDestroy(): void {
    this._subscriptions.forEach(subscription => subscription.unsubscribe())
    this._subscriptions.length = 0
  }

  public initValidation(): void {
    this.control.addValidators(CustomValidators.phone10To12)
    this.control.updateValueAndValidity()
  }

  public initTranslations(): void {
    this.seFeTranslationsLoader.load('shared/ui-phone/assets/translations').then(() => {
      this.loaded = true
      this.initValidation()
      this.initPhoneFormatter()
    })
  }

  private initPhoneFormatter(): void {
    this.control.setValue(PhoneParser.format10(this.control.value))
    this._subscriptions.push(
      this.control.valueChanges.subscribe((phoneVal: string) => {
        const formattedPhone = PhoneParser.format10(phoneVal)
        if (formattedPhone !== phoneVal) {
          this.control.setValue(formattedPhone, { emitEvent: false })
        }
      })
    )
  }
}
