import { AbstractControl, UntypedFormGroup, Validators } from '@angular/forms'

export class FieldValidationService {
  constructor(public form: UntypedFormGroup) {
    // noop
  }

  public setRequired(field: string): void {
    this.form.get(field).setValidators([Validators.required])
    this.form.get(field).updateValueAndValidity()
  }

  public setRequiredTrue(field: string): void {
    this.form.get(field).setValidators([Validators.requiredTrue])
    this.form.get(field).updateValueAndValidity()
  }

  public setValidators(field: string, validators: any, emitEvent=true): void {
    this.form.get(field).setValidators(validators)
    this.form.get(field).updateValueAndValidity({ emitEvent })
  }

  public isRequired(field: string): boolean {
    const ctrl = this.form.get(field)
    if (!ctrl.validator) return false

    const validator = ctrl.validator({} as AbstractControl)
    return validator && validator.required
  }

  public clearValidators(field: string, emitEvent=true): void {
    if (!this.isRequired(field)) return

    this.form.get(field).setValidators(null)
    this.form.get(field).updateValueAndValidity({ emitEvent })
  }
}
