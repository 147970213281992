import { Injectable } from '@angular/core'
import { nginV2Headers, SiteMembership } from '@se-po/shared-data-access-models'
import { map, Observable } from 'rxjs'
import { SeFeApiService } from 'se-fe-api'

@Injectable({
  providedIn: 'root',
})
export class SiteMembershipService {

  constructor(
    private api: SeFeApiService
  ) { }

  public cancel(membership: SiteMembership, params={}): Observable<SiteMembership> {
    return this.api.put<SiteMembership>(
      `site_memberships/${membership.id}`,
      { status: 'declined'},
      { params , headers: nginV2Headers }
    ).pipe(map(response => response.result))
  }

  public findAll(personaId: string | number, params={}): Observable<SiteMembership[]> {
    return this.api.get<SiteMembership[]>(
      `site_memberships?persona_id=${personaId}`,
      { params , headers: nginV2Headers }
    ).pipe(map(response => response.result))
  }
}
