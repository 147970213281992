import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeLinkModule } from 'se-fe-link'
import { SeFeSpinnerModule } from 'se-fe-spinner'
import { SeFeFormControlHelpModule } from 'se-fe-form-control-help'
import { SeFeIconModule, seFeIconSpotJersey } from 'se-fe-icon'
import { SeFePopoverModule } from 'se-fe-popover'
import { SharedUiGenderSelectModule } from '@se-po/shared-ui-gender-select'
import { SharedUiDobSelectModule } from '@se-po/shared-ui-dob-select'
import { SharedUiGraduationSelectModule } from '@se-po/shared-ui-graduation-select'
import { SharedUiNameModule } from '@se-po/shared-ui-name'

import { PersonaInfoComponent } from './persona-info/persona-info.component'
import { SeFeFormServiceModule } from 'se-fe-form-service'

@NgModule({
  declarations: [
    PersonaInfoComponent
  ],
  exports: [
    PersonaInfoComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SeFeButtonModule,
    SeFeFormControlHelpModule,
    SeFeFormServiceModule,
    SeFeIconModule.include([seFeIconSpotJersey]),
    SeFeLinkModule,
    SeFePopoverModule,
    SeFeSpinnerModule,
    SharedUiDobSelectModule,
    SharedUiGenderSelectModule,
    SharedUiGraduationSelectModule,
    SharedUiNameModule,
  ],
})
export class SharedUiPersonaInfoModule { }
