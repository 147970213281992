import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { SeFeMenuOptions } from 'se-fe-menu'
import { RsvpView } from '../../view-models'

@Component({
  selector: 'app-rsvp-list-item',
  templateUrl: './rsvp-list-item.component.html',
  styleUrls: ['./rsvp-list-item.component.scss']
})
export class RsvpListItemComponent implements OnInit{

  @Input('rsvp') public rsvp: RsvpView

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onRsvp = new EventEmitter()

  public noteMenuOptions: SeFeMenuOptions
  public rsvpYes = false
  public rsvpMaybe = false
  public rsvpNo = false

  constructor() {
    //noop
  }

  public ngOnInit(): void {
    this.setNoteMenuOptions()
  }

  public setNoteMenuOptions(): void {
    const noteMenuName = 'noteMenu-' + this.rsvp.id
    this.noteMenuOptions = {
      name: noteMenuName,
      sections: [
        {
          menuItems: this.rsvp.actionLinks
        },
      ]
    }
  }

  public rsvpListAction(event: string, listItem: RsvpView): void {
    const oldListItem: RsvpView = { ...listItem }
    listItem.response = event

    this.onRsvp.emit({ newValue: listItem, oldValue: oldListItem })
  }
}
