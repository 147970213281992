import { CommonModule } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SharedUiCountrySelectModule } from '@se-po/shared-ui-country-select'
import { SharedUiStateSelectModule } from '@se-po/shared-ui-state-select'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeFormFieldTextModule } from 'se-fe-form-field-text'
import { SeFeFormServiceModule } from 'se-fe-form-service'
import { SeFeGridModule } from 'se-fe-grid'
import { SeFeTranslationsModule } from 'se-fe-translations'
import { AddressComponent } from './address/address.component'

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SeFeFormFieldModule,
    SeFeFormFieldTextModule,
    SeFeFormServiceModule,
    SeFeGridModule,
    SharedUiCountrySelectModule,
    SharedUiStateSelectModule,
    SeFeTranslationsModule
  ],
  declarations: [
    AddressComponent
  ],
  exports: [
    AddressComponent
  ]
})
export class SharedUiAddressModule {}
