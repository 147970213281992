import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class ColorContrastService {
  private readonly RED_LIGHTNESS = 0.299
  private readonly GREEN_LIGHTNESS = 0.587
  private readonly BLUE_LIGHTNESS = 0.114

  constructor() {
    // noop
  }

  public hexToLuma(hex: string): number {
    const rgb = this.hexToRGB(hex)
    if (!rgb) return undefined

    return (rgb.r * this.RED_LIGHTNESS + rgb.g * this.GREEN_LIGHTNESS + rgb.b * this.BLUE_LIGHTNESS) / 255
  }

  public hexToChroma(hex: string): number {
    const rgb = this.hexToRGB(hex)
    if (!rgb) return undefined

    const max = Math.max(rgb.r, rgb.g, rgb.b) / 255
    const min = Math.min(rgb.r, rgb.g, rgb.b) / 255
    return max - min
  }

  public hexToRGB(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    if (result) {
      return { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) }
    }

    return undefined
  }

  public colorIsLight(color: string): boolean {
    return this.hexToLuma(color) > 0.5
  }
}
