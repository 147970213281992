import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'
import { Site } from '@se-po/shared-data-access-models'
import { SiteService } from '@se-po/shared-data-access-services'
import { Observable, of } from 'rxjs'

export const SiteResolver: ResolveFn<Site> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  service: SiteService = inject(SiteService)
): Observable<Site> => {
  const siteId = route.queryParams.siteId
  return siteId ? service.find(siteId) : of(null)
}
