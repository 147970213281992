import { ComponentRef, Injectable, Type } from '@angular/core'
import { Subject } from 'rxjs'

export interface ModalData {
  component: Type<any>
  options: any
  properties?: any
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  public titleUpdate: Subject<string> = new Subject()
  public options: Subject<any> = new Subject()
  public cancel: Subject<void> = new Subject()
  public spinner: Subject<boolean> = new Subject()
  private _componentRef: any

  constructor() {
    // noop
  }

  public get component(): any {
    return this._componentRef?.instance
  }

  public set componentRef(cr: ComponentRef<any>) {
    this._componentRef = cr
  }

  public open(data: ModalData): void {
    this.options.next(data)
  }

  public close(): void {
    this.cancel.next()
  }

  public stopSpinner(): void {
    this.spinner.next(false)
  }

  public updateTitle(title: string): void {
    this.titleUpdate.next(title)
  }
}
