import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { first, map } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'
import { CentralServiceResources as cs } from 'se-resource-types'

@Injectable({
  providedIn: 'root'
})
export class HouseholdRelatedGuardiansService {
  public endpoint = 'v3/households/related_guardians'

  constructor(
    private api: SeFeApiService
  ) {
    // noop
  }

  public mine(access?: string): Observable<cs.Households.RelatedGuardiansResult[]> {
    const params = { ...access && { access } }
    return this.api.get<cs.Households.RelatedGuardiansResult[]>(`${this.endpoint}/mine`, { params })
      .pipe(
        first(),
        map(response => response.result)
      )
  }
}
