import { HttpErrorResponse } from '@angular/common/http'
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { FormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { PersonaListenee } from '@se-po/shared-data-access-models'
import { PersonaListeneeService } from '@se-po/shared-data-access-services'
import { Observable, of, zip } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { SeFeModalButton, SeFeModalComponent, SeFeModalFooterComponent } from 'se-fe-modal'
import { SeFeToastService } from 'se-fe-toast'
import { PersonaAccount, UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'

@Component({
  selector: 'se-po-add-guardian-modal',
  templateUrl: './add-guardian-modal.component.html',
  styleUrls: ['./add-guardian-modal.component.scss'],
})
export class AddGuardianModalComponent implements OnInit {
  @ViewChild('addModal') public addModal: SeFeModalComponent
  @ViewChild('modalFooter') public modalFooter: SeFeModalFooterComponent
  @Output() public listeneeAdded: EventEmitter<void> = new EventEmitter()
  @Input() public guardians: any[] = []

  public form: UntypedFormGroup

  public accounts: PersonaAccount[]
  public checkboxOptions = {}
  public checkboxValues = {}
  public displayList = []
  public errors = []
  public formReady = false
  public initialValues = {}
  public modalButtons: SeFeModalButton[] = [
    {
      cancel: true,
      label: 'Cancel',
      action: () => {
        this.addModal.close()
      },
    },
    {
      primary: true,
      label: 'Send Invite',
      action: () => {
        this.submit()
      }
    },
  ]
  public profileReady = false
  public sortedProfiles = []
  public submitError = false

  private _profile: UserProfile
  private _profiles: UserProfile[]

  constructor(
    private formBuilder: FormBuilder,
    public listeneeService: PersonaListeneeService,
    public toastService: SeFeToastService
  ) {
    this.sortBySelectedProfile = this.sortBySelectedProfile.bind(this)
    this.inviteExistsValidator = this.inviteExistsValidator.bind(this)
  }

  public get profile(): UserProfile {
    return this._profile
  }

  public get profiles(): UserProfile[] {
    return this._profiles
  }

  public get ready(): boolean {
    return this.profileReady && this.formReady
  }

  @Input() public set profiles(profiles: UserProfile[]) {
    // You can only invite guardians for profiles that you have manager/owner access over.
    // With the current guardian model, we do not allow users to assign access to self profiles.
    this._profiles = profiles.filter(profile => profile.access !== 'viewer' && profile.relationship !== 'self')
    if (this.profile?.id && this.profiles?.length > 0) {
      const p = this.profiles
      this.sortedProfiles = p.sort(this.sortBySelectedProfile)
      this.setInitialValues()
      if (this.form) {
        this.resetForm()
      }
    }
  }

  @Input() public set profile(profile: UserProfile) {
    this.profileReady = false
    this._profile = profile
    if (this.profile?.id && this.profiles?.length > 0) {
      const p = this.profiles
      this.sortedProfiles = p.sort(this.sortBySelectedProfile)
      this.setInitialValues()
      if (this.form) {
        this.resetForm()
      }
    }
    this.profileReady = true
  }

  public ngOnInit(): void {
    this.formReady = false
    this.initForm()
    this.formReady = true
  }

  public initForm(): void {
    this.setInitialValues()
    this.form = this.formBuilder.group(this.initialValues)
  }

  public setInitialValues(): void {
    this.initialValues = { email: ['', [Validators.required, this.inviteExistsValidator]] }
    this.profiles.map(profile => {
      this.initialValues[profile.id] = this.profile.id === profile.id ? [profile.id.toString()] : ['']
      this.checkboxOptions[profile.id] = [{ label: profile.persona.full_name, value: profile.id.toString() }]
    })
  }

  public sortBySelectedProfile(a: UserProfile, b: UserProfile): number {
    return (a.id === this.profile.id) === (b.id === this.profile.id) ? 0 : a.id === this.profile.id ? -1 : 1
  }

  public open(): void {
    this.submitError = false
    this.addModal.open(null)
  }

  public submit(): void {
    this.submitError = false
    this.form.markAllAsTouched()
    this.form.markAsDirty()
    if (this.form.invalid) {
      const control = this.form.get('email')
      control.markAsTouched()
      control.markAsDirty()
      this.modalFooter.cancelSpinner()
      return
    }
    const selectedPersonaIds = []

    this.profiles.forEach(p => {
      const controlName = p.id.toString()
      if ((this.form.get(controlName)?.value) === p.id.toString()) {
        selectedPersonaIds.push(p.persona.id)
      }
    })

    zip(...selectedPersonaIds.map(personaId => this.submitOne(personaId))).subscribe(guardians => {
      const successGuardians = guardians.filter(guardian => !!guardian)
      if (successGuardians.length === guardians.length) {
        this.success(this.form.get('email').value)
      } else {
        this.modalFooter.cancelSpinner()
      }
    })
  }

  public submitOne(personaId: number): Observable<PersonaListenee> {
    const newEmail = this.form.get('email').value
    return this.listeneeService.create({
      email: newEmail,
      listener_type: 'guardian',
      initiator: 'my_se',
      persona_id: personaId
    }).pipe(
      catchError((err: HttpErrorResponse) => {
        this.submitOneError(err)
        return of(null)
      })
    )
  }

  public success(email: string) {
    this.toastService.success(`An invitation was sent to ${email}`)
    this.addModal.close()
    this.listeneeAdded.emit()
  }

  public submitOneError(response: HttpErrorResponse) {
    this.submitError = true
    this.errors = [...new Set(this.errors.concat(response.error.error.messages))]
  }

  public closeModal(): void {
    this.addModal.close()
    this.resetForm()
  }

  public resetForm(): void {
    this.form.reset()
    this.form.get(this.profile.id.toString()).setValue(this.profile.id.toString())
  }

  public inviteExistsValidator(control: FormControl): { [key: string]: any } | null {
    if (this.guardians) {
      const email = control.value
      const allEmails = this.guardians.map(item => item.email)
      if (allEmails.includes(email)) {
        return { inviteExists: true }
      } else {
        return null
      }
    } else {
      return null
    }
  }
}
