<div class="pl-pane__container--horizontal">
  <header class="pl-page-header pl-pane--shrink">
    <h1 class="pl-heading--xlarge">
      {{ 'REGISTRATIONS.registrations' | translate }}
    </h1>
  </header>
  <div *ngIf="loaded" class="pl-pane--collapse">
    <div class="pl-pane--shrink pl-pane--condense-bottom">
      <div pl-grid="tight fixed">
        <div pl-grid-el pl-grid="1/1@phone 1/2@tablet 1/3@desktop">
          <div class="pl-field-group">
            <div class="pl-search-field pl-field-group__item--expand">
              <input
                id="registration_search"
                name="registration_search"
                [(ngModel)]="searchTerm"
                (ngModelChange)="search($event)"
                placeholder="{{ 'REGISTRATIONS.search_placeholder' | translate }}"
                class="pl-search-field__input"
                attr.aria-label="{{ 'REGISTRATIONS.search_placeholder' | translate }}" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!surveyResults?.length && !filtered" >
      <se-fe-empty-state seFeDataTitle="{{ 'REGISTRATIONS.EMPTY.header' | translate }}">
        <se-fe-icon seFeEmptyStateIcon seFeDataName="question_thin" seFeDataSize="950" seFeDataColor="gray-900"></se-fe-icon>
        {{ 'REGISTRATIONS.EMPTY.message' | translate }}
      </se-fe-empty-state>
    </div>

    <div *ngIf="!surveyResults?.length && filtered" >
      <se-fe-empty-state seFeDataTitle="{{ 'REGISTRATIONS.NO_RESULTS.header' | translate }}">
        <se-fe-icon seFeEmptyStateIcon seFeDataName="question_thin" seFeDataSize="950" seFeDataColor="gray-900"></se-fe-icon>
        {{ 'REGISTRATIONS.NO_RESULTS.message' | translate }}
      </se-fe-empty-state>
    </div>

    <div class="pl-pane pl-pane--collapse-top">
      <div id="action-links-container"
        pl-grid="tight fixed align-center"
        *ngIf="surveyResults && surveyResults.length">
        <div pl-grid-el pl-grid="1/1@phone">
          <div class="pl-list-grid" style="margin-top: 1px;">
            <se-fe-list>
              <se-fe-list-item *ngFor="let sr of surveyResults">
                <se-fe-header size="600">
                  <a [routerLink]="buildRouterLinks(sr).routerLink" [queryParams]="buildRouterLinks(sr).routerLinkQueryParams"  class="pl-link" seFeHeaderTitle>{{ sr.survey_name }}</a>
                  <div seFeHeaderSubtitle>{{ 'REGISTRATIONS.details' | translate: {
                    id: sr.id,
                    persona: getPersonaNameById(sr.persona_id),
                    date: (sr.placed_at | date),
                    site: sr.site_name
                  } }}</div>
                  <div seFeHeaderActions>
                    <a *ngIf="sr.actionLinks && sr.actionLinks.params" [routerLink]="sr.actionLinks.link" [queryParams]="sr.actionLinks.params">{{ sr.actionLinks.text }}</a>
                    <a *ngIf="sr.actionLinks && !sr.actionLinks.params" [routerLink]="sr.actionLinks.link">{{ sr.actionLinks.text }}</a>
                  </div>
                </se-fe-header>
              </se-fe-list-item>
            </se-fe-list>
          </div>
        </div>

        <div pl-grid-el pl-grid="shrink@phone"
          *ngIf="pageMetadata.pagination && !pageMetadata.pagination.last_page">
          <se-fe-button [seFeDataIsLoading]="loadingMore" seFeDataEmphasis="low" (click)="loadMore()">
            <span>{{ 'REGISTRATIONS.see_older_registrations' | translate }}</span>
          </se-fe-button>
        </div>
      </div>
    </div>
  </div>
</div>
