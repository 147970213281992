<div class="org-card" *ngIf="ready">
  <div class="org-color-box" [style.background-color]="membershipCardStyles.primaryColor">
    <div class="menu-cutout">
      <se-fe-icon-button [seFeMenuTrigger]="simpleMenuOptions.name"> 
        <se-fe-icon seFeDataName="more" seFeDataSize="700" seFeDataColor="gray-900"></se-fe-icon>
      </se-fe-icon-button>
    </div>
    <div [ngClass]="{'blur': siteMembership.site_logo_graphic}" class="blur-background" [style.background-color]="membershipCardStyles.primaryColor" [style.background-image]="backgroundImageUrl">

    </div>
    <se-fe-avatar
      class="card-avatar"
      seFeDataType="org"
      [seFeDataIdentity]="siteMembership.organization_name"
      [seFeDataImgUrl]="siteMembership.site_logo_graphic?.medium_logo_url"
      seFeDataSize="500"
      seFeDataAriaLabel="siteMembership.organization_name">
    </se-fe-avatar>
  </div>
  <div class="card-title">
    <div class="card-title-content">{{ siteMembership.organization_name }}</div>
  </div>
  <div class="card-link">
    <a seFeLink="low" [href]="siteMembership.site_url" target="blank">Go to Website</a>
  </div>
</div>
<se-fe-menu *ngIf="ready" [seFeDataOptions]="simpleMenuOptions"></se-fe-menu>
