import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { SeFeFormFieldModule } from 'se-fe-form-field'
import { SeFeFormFieldSelectModule } from 'se-fe-form-field-select'
import { SeFeFormFieldSuffixModule } from 'se-fe-form-field-suffix'
import { SeFeTranslationsModule } from 'se-fe-translations'

import { GraduationSelectComponent } from './graduation-select/graduation-select.component'

import { HttpClientModule } from '@angular/common/http'

@NgModule({
  declarations: [
    GraduationSelectComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SeFeFormFieldModule,
    SeFeFormFieldSelectModule,
    SeFeFormFieldSuffixModule,
    SeFeTranslationsModule
  ],
  exports: [
    GraduationSelectComponent
  ]
})
export class SharedUiGraduationSelectModule {}
