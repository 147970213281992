<div *ngIf="showBanner">
  <div *ngFor="let duplicate of duplicates" class="dupe-banner">
    <se-fe-banner *ngIf="!duplicate.dismissed"
      seFeDataTitle="2 {{ duplicate.pluralName }} found in your household - are they the same person?"
      seFeDataType="warning"
      [seFeShowIcon]="false">
      <span seFeBannerSubtitle>
        We recommend merging these into a single profile
      </span>
      <se-fe-button seFeBannerAction
        class="first-button"
        seFeDataEmphasis="high"
        (click)="onMerge(duplicate)">
        Yes
      </se-fe-button>
      <se-fe-button seFeBannerAction
        seFeDataEmphasis="medium"
        (click)="dismissBanner(duplicate)">
        No
      </se-fe-button>
    </se-fe-banner>
  </div>
</div>
