<div pl-grid-el pl-grid="1/1@phone">
  <h2 class="myse-dashboard__section-title" [textContent]="'DASHBOARD.SCHEDULE.title' | translate"></h2>
  <a
    (click)="fireAnalytics()"
    [routerLink]="['schedule']"
    class="myse-dashboard__section-link"
    [textContent]="'EVENT_LIST.see_all' | translate"
    >
  </a>
</div>

<div pl-grid-el pl-grid="1/1@phone">
  <div *ngIf="!loaded && !failed"
    class="myse-dashboard-skeleton--section-card myse-dashboard__full-card se-card"></div>
  <se-fe-card *ngIf="failed" class="myse-dashboard__full-card myse-dashboard__compact-card">
    <se-fe-card-content>
      <se-fe-empty-state seFeDataTitle="{{ 'DASHBOARD.SCHEDULE_FAILED.title' | translate }}" seFeDataSize="500">
        <se-fe-button seFeEmptyStateAction seFeDataEmphasis="high" (click)="load()">{{ 'DASHBOARD.SCHEDULE_FAILED.action' | translate }}</se-fe-button>
      </se-fe-empty-state>
    </se-fe-card-content>
  </se-fe-card>

  <se-fe-card *ngIf="loaded && !failed && events.length === 0"
    class="myse-dashboard__full-card myse-dashboard__compact-card">
    <se-fe-card-content>
      <se-fe-empty-state seFeDataTitle="{{ 'DASHBOARD.SCHEDULE_EMPTY.title' | translate }}" seFeDataSize="500">
        {{ 'DASHBOARD.SCHEDULE_EMPTY.description' | translate }}
      </se-fe-empty-state>
    </se-fe-card-content>
  </se-fe-card>

  <ng-container *ngIf="loaded && !failed && events.length > 0">
    <div
      *ngFor="let day of days; last as isLast"
      pl-grid-el
      pl-grid="1/1@phone"
      class="myse-event__day-container">
      <div class="myse-event__day-label">
        <app-date-stamp [dateString]="day.day"></app-date-stamp>
      </div>
      <div class="myse-event__day-grouping">
        <div
          *ngFor="let event of day.events"
          class="event-class-for-event">
          <se-fe-empty-state *ngIf="event.emptyMessage" seFeDataTitle="{{ event.emptyMessage }}" seFeDataSize="500">
          </se-fe-empty-state>
          <app-event-card *ngIf="!event.emptyMessage" [dataEvent]="event" (onRsvp)="onRsvp($event)"></app-event-card>
        </div>
      </div>
    </div>
  </ng-container>
</div>


