import { Component, OnInit } from '@angular/core'
import { PersonaEligibility } from '../../models'
import { PersonaService } from '../../services'
import { EligibilityCredentialSearchService } from '../../services/eligibility-credential-search.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public usavPersonaEligibilities: PersonaEligibility[] = []

  constructor(
    private eligibilityCredentialSearchService: EligibilityCredentialSearchService,
    private personaService: PersonaService
  ) {
    // noop
   }

   public async ngOnInit(): Promise<void> {
    const personas = await this.personaService.getGuarded().toPromise()
    this.usavPersonaEligibilities = await this.eligibilityCredentialSearchService.personasEligibilityFilter({
      all_organization_ids: [22518],
      persona_ids: personas.map((persona) => persona.id)
    }).toPromise()
   }

}
