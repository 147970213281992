<se-fe-page>
  <h3 seFePageHeader class="myse-interstitial__continue" style="padding-bottom: 0px;">
    <a href="https://www.sportsengine.com">{{ 'INTERSTITIAL.continue_link' | translate }}
      <se-fe-icon
        seFeDataName="chevron_right"
        seFeDataSize="400"
        seFeDataMargin="false">
      </se-fe-icon>
    </a>
  </h3>
  <div seFePageContent class="pl-centered-container--max-width-medium" style="width: 600px;">
    <div>
      <div ng-attr-pl-grid="loose">
        <div pl-grid-el pl-grid="1/1@phone" style="padding-left:12px;">
          <img src="https://s3.amazonaws.com/sportngin-snap-staging/ui_themes/assets/latest/images/sportsengine-logo-color-black.svg" border="0" alt="SportsEngine" style="height:25px; width:150px;"/>
          <h1 class="myse-interstitial__greeting"
          [textContent]="'INTERSTITIAL.greeting' | translate: { user: personaSelf.first_name }"></h1>
        </div>
        <div *ngIf="groupedDestinations?.me?.length && userHasChildren" class="myse-interstitial-item__list" pl-grid-el pl-grid="1/1@phone">
          <div class="myse-interstitial-item__text">
            <h3 class="myse-interstitial-item__title">{{ 'INTERSTITIAL.my_account' | translate }}</h3>
          </div>
          <div *ngFor="let card of groupedDestinations.me" style="padding-bottom:12px;">
            <se-fe-card>
              <se-fe-card-content>
                <div class="myse-interstitial-item">
                  <div class="myse-interstitial-item__avatar">
                    <se-fe-avatar
                      seFeDataType="persona"
                      [seFeDataIdentity]="card.persona?.firstLast"
                      [seFeDataImgUrl]="card.persona?.cropImage"
                      seFeDataSize="300">
                    </se-fe-avatar>
                  </div>
                  <div class="myse-interstitial-item__text">
                    <h3 class="myse-interstitial-item__description">{{ card.description }}</h3>
                  </div>
                  <div class="myse-interstitial-item__actions">
                    <a
                      class="pl-button--highlight myse-interstitial-item__action"
                      routerLink='/user'
                      [textContent]="'INTERSTITIAL.continue' | translate">
                    </a>
                  </div>
                </div>
              </se-fe-card-content>
            </se-fe-card>
          </div>
        </div>

        <div *ngIf="groupedDestinations?.orgs?.length" class="myse-interstitial-item__list" pl-grid-el pl-grid="1/1@phone">
          <div class="myse-interstitial-item__text">
            <h3 class="myse-interstitial-item__title">{{ 'INTERSTITIAL.my_orgs' | translate }}</h3>
          </div>
          <div *ngFor="let card of groupedDestinations.orgs" style="padding-bottom:12px;">
            <se-fe-card>
              <se-fe-card-content>
                <div class="myse-interstitial-item">
                  <div class="myse-interstitial-item__avatar">
                    <se-fe-avatar
                      seFeDataType="org"
                      [seFeDataIdentity]="card.org?.name"
                      [seFeDataImgUrl]="card.org?.logo?.is_default ? '' : card.org?.logo?.large_logo_url"
                      seFeDataSize="300">
                    </se-fe-avatar>
                  </div>
                  <div class="myse-interstitial-item__text">
                    <h3 class="myse-interstitial-item__description">{{ card.title }}</h3>
                  </div>
                  <div class="myse-interstitial-item__actions">
                    <a
                      class="pl-button--highlight myse-interstitial-item__action"
                      [attr.target]="card.target"
                      [href]="card.href"
                      [textContent]="'INTERSTITIAL.continue' | translate">
                    </a>
                  </div>
                </div>
              </se-fe-card-content>
            </se-fe-card>
          </div>
        </div>

        <div *ngIf="groupedDestinations?.teams?.length" class="myse-interstitial-item__list" pl-grid-el pl-grid="1/1@phone">
          <div class="myse-interstitial-item__text">
            <h3 class="myse-interstitial-item__title">{{ 'INTERSTITIAL.my_teams' | translate }}</h3>
          </div>
          <div *ngFor="let card of groupedDestinations.teams" style="padding-bottom:12px;">
            <se-fe-card>
              <se-fe-card-content>
                <div class="myse-interstitial-item">
                  <div class="myse-interstitial-item__avatar">
                    <se-fe-avatar
                      *ngIf="card.team?.sportIcon"
                      seFeDataType="team"
                      [seFeDataIdentity]="card.team?.name"
                      [seFeDataImgUrl]="card.team?.imgUrl"
                      [seFeDataTeamColor]="'#' + card.team?.primary_color"
                      [seFeDataSport]="card.team?.sportIcon === 'other' ? undefined : card.team?.sportIcon"
                      seFeDataSize="300"
                    ></se-fe-avatar>
                  </div>
                  <div class="myse-interstitial-item__text">
                    <h3 class="myse-interstitial-item__description">{{ card.title }}</h3>
                    <h3 class="myse-interstitial-item__title">{{ card.team?.org_details?.name }}</h3>
                  </div>
                  <div class="myse-interstitial-item__actions">
                    <a
                      class="pl-button--highlight myse-interstitial-item__action"
                      [attr.target]="card.target"
                      [href]="card.href"
                      [textContent]="'INTERSTITIAL.continue' | translate">
                    </a>
                  </div>
                </div>
              </se-fe-card-content>
            </se-fe-card>
          </div>
        </div>

        <div *ngIf="groupedDestinations?.me?.length && !userHasChildren" class="myse-interstitial-item__list" pl-grid-el pl-grid="1/1@phone">
          <div class="myse-interstitial-item__text">
            <h3 class="myse-interstitial-item__title">{{ 'INTERSTITIAL.my_account' | translate }}</h3>
          </div>
          <div *ngFor="let card of groupedDestinations.me" style="padding-bottom:12px;">
            <se-fe-card>
              <se-fe-card-content>
                <div class="myse-interstitial-item">
                  <div class="myse-interstitial-item__avatar">
                    <se-fe-avatar
                      seFeDataType="persona"
                      [seFeDataIdentity]="card.persona.fullName"
                      [seFeDataImgUrl]="card.persona.cropImage"
                      seFeDataSize="300">
                    </se-fe-avatar>
                  </div>
                  <div class="myse-interstitial-item__text">
                    <h3 class="myse-interstitial-item__description">{{ card.description }}</h3>
                  </div>
                  <div class="myse-interstitial-item__actions">
                    <a
                      class="pl-button--highlight myse-interstitial-item__action"
                      routerLink='/user'
                      [textContent]="'INTERSTITIAL.continue' | translate">
                    </a>
                  </div>
                </div>
              </se-fe-card-content>
            </se-fe-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</se-fe-page>
