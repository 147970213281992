import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeChipModule } from 'se-fe-chip'
import { SeFeEmptyStateModule } from 'se-fe-empty-state'
import { SeFeHeaderModule } from 'se-fe-header'
import { seFeIconDotFill, SeFeIconModule, seFeIconMore, seFeIconSpotWhistle } from 'se-fe-icon'
import { SeFeListModule } from 'se-fe-list'
import { SeFeMenuModule } from 'se-fe-menu'
import { SeFeSpinnerModule } from 'se-fe-spinner'
import { MembershipListComponent } from './membership-list/membership-list.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeButtonModule,
    SeFeChipModule,
    SeFeEmptyStateModule,
    SeFeHeaderModule,
    SeFeIconModule.include([
      seFeIconDotFill,
      seFeIconMore,
      seFeIconSpotWhistle
    ]),
    SeFeListModule,
    SeFeMenuModule,
    SeFeSpinnerModule
  ],
  declarations: [
    MembershipListComponent
  ],
  exports: [
    MembershipListComponent
  ]
})
export class SharedFeatureMembershipListModule {}
