import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { SeFeApiService } from 'se-fe-api'

@Injectable({
  providedIn: 'root',
})
export class PhoneNumberService {
  public endpoint = 'v3/ngin/phone_numbers'

  constructor(private api: SeFeApiService) {
    // noop
  }

  public create(phone: string): Observable<any> {
    const url = `${this.endpoint}`
    return this.api.post<any>(
      url,
      { phone }
    ).pipe(
      map(response => response.result)
    )
  }

  public get(params?: any, options?: any): Observable<any> {
    return this.api.get(`v3/ngin/phone_numbers`, { ...options }).pipe(take(1))
  }

  public delete(phone_id: string, options?: any): Observable<any> {
    return this.api.delete(`v3/ngin/phone_numbers/${phone_id}`, { ...options }).pipe(take(1))
  }

  public verify(id: number, code: string): Observable<any> {
    const url = `${this.endpoint}/${id}/verify?code=${code}`
    return this.api.get<any>(
      url
    ).pipe(
      map(response => response.result)
    )
  }
}
