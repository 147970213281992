import { Component, Input, OnChanges } from '@angular/core'
import { Sale, SaleActivity, SaleItem, SaleRegister } from '../models'

@Component({
  selector: 'app-sale-info-card',
  templateUrl: './sale-info-card.component.html'
})
export class SaleInfoCardComponent implements OnChanges {
  @Input() public sale: Sale
  @Input() public activity: SaleActivity[]
  public saleSummaryTitle: string
  public saleItemRegisters: SaleRegister[] = []
  public feeRegisters: SaleRegister[] = []
  public paymentRegisters: SaleRegister[] = []
  public creditRegisters: SaleRegister[] = []
  public discountRegisters: SaleRegister[] = []
  public salesTaxRegisters: SaleRegister[] = []
  public subtotalRegister: SaleRegister
  public totalRegister: SaleRegister
  public amountOutstandingRegister: SaleRegister
  public totalPaidRegister: SaleRegister
  public totalCreditedRegister: SaleRegister
  public refundAdjustments: SaleActivity[] = []
  public totalRefundsAmount = 0

  public showAdjustments = false
  public showPaymentDetail = false
  public showRefundsDetail = false
  public isInvoice: boolean
  public settledOrVoided: boolean
  public voided: boolean
  public showOutstandingAmount: boolean
  public showSubTotalAmount: boolean

  constructor() {
    // noop
  }

  public ngOnChanges(): void {
    this.saleSummaryTitle = this.sale.type === 'Invoice' ? 'Bill' : this.sale.type
    this.settledOrVoided = ['settled', 'voided'].includes(this.sale.status)
    this.voided = this.sale.display_status === 'voided'
    this.isInvoice = this.sale.type === 'Invoice'

    this.setRegisters()
    this.addFeeRegister()
    this.addSalesTaxRegister()
    this.refundAdjustments = []
    this.totalRefundsAmount = 0
    this.setRefundsAdjustments()

    this.showOutstandingAmount = this.paymentRegisters.length > 0 ||
      this.creditRegisters.length > 0 ||
      this.voided

    this.showSubTotalAmount = this.feeRegisters.length > 0 ||
      this.discountRegisters.length > 0 ||
      this.salesTaxRegisters.length > 0

  }

  public item(register: SaleRegister): SaleItem {
    return this.sale.items.find(i => i.name === register.description)
  }

  public toggleShowAdjustmentsDetail(): void {
    this.showAdjustments = !this.showAdjustments
  }

  public toggleShowPaymentDetail(): void {
    this.showPaymentDetail = !this.showPaymentDetail
  }

  public toggleShowRefundsDetail(): void {
    this.showRefundsDetail = !this.showRefundsDetail
  }

  public showCheckoutFeeSummary(): boolean {
    return !this.settledOrVoided &&
      this.sale.customer_pays_fee &&
      !this.feeRegisters.length
  }

  public addFeeRegister(): void {
    if (this.showCheckoutFeeSummary()) {
      this.feeRegisters.push(new SaleRegister({
        register_type: 'fee'
      }))
    }
  }

  public addSalesTaxRegister(): void {
    if (this.showTaxSummary()) {
      this.salesTaxRegisters.push(new SaleRegister({
        register_type: 'sales_tax'
      }))
    }
  }

  public setRegisters(): void {
    this.saleItemRegisters = this.filterRegistersByRegisterType('sale_item')
    this.feeRegisters = this.filterRegistersByRegisterType('fee')
    this.paymentRegisters = this.filterRegistersByRegisterType('payment')
    this.creditRegisters = this.filterRegistersByRegisterType('credit')
    this.discountRegisters = this.filterRegistersByRegisterType('discount')
    this.salesTaxRegisters = this.filterRegistersByRegisterType('sales_tax')
    this.subtotalRegister = this.findRegisterByRegisterType('subtotal')
    this.totalRegister = this.findRegisterByRegisterType('total')
    this.amountOutstandingRegister = this.findRegisterByRegisterType('amount_outstanding')
    this.totalPaidRegister = this.findRegisterByRegisterType('total_paid')
    this.totalCreditedRegister = this.findRegisterByRegisterType('total_credited')
  }

  public showTaxSummary(): boolean {
    return !this.settledOrVoided &&
      this.sale.taxable &&
      !this.salesTaxRegisters.length
  }

  public filterRegistersByRegisterType(registerType: string): SaleRegister[] {
    return this.sale.registers.filter(register => register.register_type === registerType)
  }

  public findRegisterByRegisterType(registerType: string): SaleRegister {
    return this.sale.registers.find(register => register.register_type === registerType)
  }

  public setRefundsAdjustments(): void {
    if (!this.activity) {
      this.activity = []
    }
    this.activity.forEach(refund => {
      if(refund.label === 'Refund Adjustment Applied') {
        this.refundAdjustments.push(refund)
        this.totalRefundsAmount += Number(refund.amount)
      }
    })
  }
}
