import { Injectable } from '@angular/core'
import { nginV2Headers } from '@se-po/shared-data-access-models'
import { map, Observable } from 'rxjs'
import { SeFeApiService } from 'se-fe-api'
import { Team } from 'se-resource-types/dist/lib/TeamService'


const DEFAULT_PARAMS = {
  per_page: 10,
  page: 1,
  primary: true,
  originator_system: 'Ngin,TeamService,StatNgin',
  include_favorites: '1',
  with_retired: '1'
}

@Injectable({
  providedIn: 'root'
})
export class TeamService {

  constructor(
    private api: SeFeApiService
  ) { }

  public mine(params={}): Observable<any> {
    return this.api.get<Team[]>(
      'v3/teams/mine',
      { params: { ...DEFAULT_PARAMS, ...params }, headers: nginV2Headers }
    ).pipe(map(response => response.result))
  }

  public async mineWithProgramDetails(params={}): Promise<Team[]> {
    const teams = await this.mine(params).toPromise()
    const requests = teams.map((team: Team) => this.getProgram(team))
    const programs = await Promise.all(requests)
    return teams.map((team: Team, index: number) => ({ ...team, program: programs[index] }))
  }

  private getProgramUrl(team: Team): string {
    const programLink = team._links.find((link) => link.rel === team.program_originator_type)
    if (programLink) {
      return programLink.href.split('/').slice(3).join('/')
    }
  }

  private async getProgram(team: Team): Promise<any> {
    return new Promise(async (resolve) => {
      let program: any
      const programUrl = this.getProgramUrl(team)
      if (programUrl) {
        try {
          program = await this.api.get(programUrl).pipe(map(response => response.result)).toPromise()
        } catch { /* noop */ }
      }
      resolve(program)
    })
  }

}
