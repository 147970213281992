import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SeFeAvatarModule } from 'se-fe-avatar'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeEmptyStateModule } from 'se-fe-empty-state'
import { SeFeHeaderModule } from 'se-fe-header'
import { seFeIconLaunch, SeFeIconModule, seFeIconMore, seFeIconSpotWhistle } from 'se-fe-icon'
import { SeFeLinkModule } from 'se-fe-link'
import { SeFeListModule } from 'se-fe-list'
import { SeFeMenuModule } from 'se-fe-menu'
import { SeFeSpinnerModule } from 'se-fe-spinner'
import { OrganizationListComponent } from './organization-list/organization-list.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeAvatarModule,
    SeFeButtonModule,
    SeFeEmptyStateModule,
    SeFeHeaderModule,
    SeFeIconModule.include([
      seFeIconLaunch,
      seFeIconMore,
      seFeIconSpotWhistle
    ]),
    SeFeLinkModule,
    SeFeListModule,
    SeFeMenuModule,
    SeFeSpinnerModule
  ],
  declarations: [
    OrganizationListComponent
  ],
  exports: [
    OrganizationListComponent
  ]
})
export class SharedFeatureOrganizationListModule {}
