import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { ActivationEnd, NavigationEnd, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { AnalyticsService, HouseholdProfilesService, UserPreferenceService } from '@se-po/shared-data-access-services'
import { firstValueFrom } from 'rxjs'
import { SeFeLayoutComponent, SeFeLeftNavEvent, SeFeLeftNavRouterService } from 'se-fe-left-nav'
import { loadSeFeTheme } from 'se-fe-themes'

import { environment } from '@se-po/shared-environments'

import { SeFeTranslationsLoader } from 'se-fe-translations'
import { Persona } from 'se-resource-types/dist/lib/CentralService/Profiles'
import { DibSession } from './models'
import { DibSessionService, ModalService, SpinnerService } from './services'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('layout') private layout: SeFeLayoutComponent

  public loaded = false
  public navItems: any[] = []
  public footerLinks: any[] = []
  public path: string
  public persona: Persona
  public personaCropImage: string
  public showLeftNav = false

  private showOrderHistory = false
  private dibs: DibSession[]

  constructor(
    private el: ElementRef,
    private translate: TranslateService,
    private router: Router,
    private routerService: SeFeLeftNavRouterService,
    private seFeTranslationsLoader: SeFeTranslationsLoader,
    private analyticsService: AnalyticsService,
    private titleService: Title,
    private dibSessionService: DibSessionService,
    private householdProfilesService: HouseholdProfilesService,
    private spinnerService: SpinnerService,
    private window: Window,
    private userPreferenceService: UserPreferenceService,
    protected modalService: ModalService,
  ) {
    loadSeFeTheme({
      shadowRoot: this.el.nativeElement.shadowRoot,
      supportedThemes: 'light',
      url: 'https://se-fe-angular-services.ui.sportngin.com',
    })
  }

  public async ngOnInit(): Promise<void> {
    this.spinnerService.start()
    this.loadSeBar()
    this.householdProfilesService.selfPersona.subscribe(persona => {
      this.persona = persona
      this.personaCropImage = (persona?.profile_images || []).find(i => i.image_type === 'crop')?.url
    })
    this.householdProfilesService.loadSelfPersona()
    this.path = this.window.location.pathname

    await Promise.all([
      this.seFeTranslationsLoader.load(),
      firstValueFrom(this.dibSessionService.findAll()),
      firstValueFrom(this.userPreferenceService.mine()),
    ]).then(result => {
      const [__t, dibs, userPreferences] = result
      this.dibs = dibs
      this.showOrderHistory = userPreferences.find(p => p.key === 'enable_order_history')?.value === true
      this.loaded = true
      this.spinnerService.stop()
    }).finally(() => this.buildLeftNav())

    this.router.events.subscribe((val) => {
      if (val instanceof ActivationEnd && !val.snapshot.children.length) {
        const routeConfig = val.snapshot.routeConfig

        this.modalService.close()
        this.showHideNav(val)

        if (routeConfig.data?.title) {
          this.titleService.setTitle(this.translate.instant('ROUTE_TITLE.page_title_template', { title: routeConfig.data.title }))
        }
        else {
          this.titleService.setTitle(this.translate.instant('ROUTE_TITLE.page_title_default'))
        }
        this.setLeftNavSelected(routeConfig.data)
      }
      if (val instanceof NavigationEnd) {
        if (!this.router.getCurrentNavigation().extras?.state?.skipPageView) {
          this.analyticsService.pageView('MySE')
        }
      }
    })
  }

  public handleNavigate(e: SeFeLeftNavEvent): void {
    this.routerService.handleNavigate(e)
    this.layout.closeLeftNav()
  }

  private showHideNav(val: ActivationEnd): void {
    const routeConfig = val.snapshot.routeConfig
    const hideParam: boolean = val.snapshot.queryParams?.hide_nav === '1' || false
    this.showLeftNav = !routeConfig.data?.hideLeftNav && !hideParam

    if (hideParam) {
      this.window.document.querySelector('se-bar').setAttribute('style', 'display: none')
    } else {
      this.window.document.querySelector('se-bar').removeAttribute('style')
    }
  }

  // The SeFeLeftNavRouterService (used in this app) will correctly select items matching the current url path.
  // If you add routes that break this pattern, you will need to add a `key` below, in the nav data AND a matching
  // data.leftNavKey property to the route config. The "Dashboard" item below is an example of this.
  private buildLeftNav(): void {
    this.navItems = [
      { label: this.translate.instant('DASHBOARD.title'), key: 'Dashboard', attributes: { href: '/user' } },
      { label: this.translate.instant('USER.household'), attributes: { href: '/user/household' } },
      { label: this.translate.instant('SCHEDULE.schedule'), attributes: { href: '/user/schedule' } },
      { label: this.translate.instant('TEAMS.teams'), attributes: { href: '/user/my-teams' } },
    ]

    if (this.showOrderHistory) {
      this.navItems.push(
        { label: this.translate.instant('ORDERS.title'), attributes: { href: '/user/order-history' } },
      )
    }

    this.navItems.push(
      { label: this.translate.instant('USER.bills'), attributes: { href: '/user/bills' } },
      { label: this.translate.instant('REGISTRATIONS.registrations'), attributes: { href: '/user/registrations' } },
      { label: this.translate.instant('MEMBERSHIPS.title'), attributes: { href: '/user/memberships' } },
      { label: this.translate.instant('CREDENTIALS.title'), attributes: { href: '/user/credentials' } }
    )

    if (this.dibs.length) {
      this.navItems.push({
        label: this.translate.instant('DIBS.dibs'),
        attributes: { href: '/user/dibs' }
      })
    }

    this.navItems.push(
      { label: this.translate.instant('VIDEO.title'), attributes: { href: '/user/video_streaming' } },
      { label: this.translate.instant('ACCOUNT.account_settings'), attributes: { href: '/user/account' } }
    )

    this.footerLinks.push({
      label: 'Need Help',
      attributes: { href: 'https://help.sportsengine.com/en/collections/3502726-for-parents', target: 'blank' },
      icon: 'question_circle_outline'
    })

    this.routerService.setNavTree(this.navItems)
  }

  private setLeftNavSelected(data: any) {
    const match = data?.leftNavItem || data?.title

    this.navItems.forEach(nav => nav.isSelected = (match === nav.gaLabel))
    document.dispatchEvent(
      new CustomEvent('se-left-nav.triggerTreeChanges')
    )
  }

  private loadSeBar(): void {
    const seBarScript = document.createElement('script')
    seBarScript.src = environment.seBar
    document.head.appendChild(seBarScript)
  }
}
