import { forwardRef, Injectable } from '@angular/core'
import { HouseholdGuardianInvitesService } from '@se-po/shared-data-access-services'
import { catchError, first, Observable, of } from 'rxjs'
import { CentralServiceResources as cs } from 'se-resource-types'

@Injectable({
  providedIn: 'root',
  useExisting: forwardRef(() => ChildGrantAccessModalSubmitService)
})
export abstract class ChildGrantAccessModalSubmitter {
  public abstract error: string
  public abstract submit(access: string, email: string, personaId: number): Observable<cs.Households.PersonaGuardianInvite>
}

@Injectable({
  providedIn: 'root'
})
export class ChildGrantAccessModalSubmitService implements ChildGrantAccessModalSubmitter {
  public error: string

  constructor(
    private householGuardianInvitesService: HouseholdGuardianInvitesService
  ) {
  }

  public submit(access: string, email: string, personaId: number): Observable<cs.Households.PersonaGuardianInvite> {
    this.error = null
    return this.householGuardianInvitesService.create(access, email, personaId, 'mySE').pipe(
        first(),
        catchError(err => {
          this.error = 'Action can\'t be completed, please try again.'
          return of(null)
        })
      )
  }
}
