import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { PersonaEligibilitySidePanelComponent } from '@se-po/se-my-sportsengine-ui-persona-eligibility-side-panel'
import { HouseholdProfilesService, MembershipListItem, MembershipListItemsService } from '@se-po/shared-data-access-services'
import { Subscription } from 'rxjs'
import { SeFeMenuComponent, SeFeMenuOptions } from 'se-fe-menu'
import { SeFeTableComponent, SeFeTableDataSource, SeFeTableSortDirective } from 'se-fe-table'
import { UserProfile } from 'se-resource-types/dist/lib/CentralService/Households'
import { Persona } from 'se-resource-types/dist/lib/CentralService/Profiles'
import { PersonaEligibility } from 'se-resource-types/dist/lib/EligibilitySearchService'

@Component({
  selector: 'se-po-membership-table',
  templateUrl: './membership-table.component.html',
  styleUrls: ['./membership-table.component.scss'],
})
export class MembershipTableComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild('personaEligibilitySidePanel') public personaEligibilitySidePanel: PersonaEligibilitySidePanelComponent
  @ViewChild(SeFeTableComponent) public table: SeFeTableComponent<ElementRef>
  @ViewChild(SeFeTableSortDirective) public sorter: SeFeTableSortDirective
  @ViewChildren(SeFeMenuComponent) public menus: QueryList<SeFeMenuComponent>

  public chipPersonaOpts = {}
  public dataSource: SeFeTableDataSource
  public displayedColumns = [
    'membership',
    'status',
    'organization',
    'validityDates',
    'person'
  ]
  public form: FormGroup
  public listItems: MembershipListItem[]
  public personFilterTitle = 'Person'
  public statusFilterTitle = 'Status'
  public profiles: UserProfile[]
  public personOptions = {}
  public personMenuOptions: SeFeMenuOptions = {
    name: 'personMenu',
    maxHeight: 'auto'
  }
  public ready = false
  public refreshEmptyStateTitle: string
  public statusCheckOptions = [
    {
      label: 'Canceled',
      value: 'canceled'
    },
    {
      label: 'Eligible',
      value: 'eligible'
    },
    {
      label: 'Expired',
      value: 'expired'
    },
    {
      label: 'Ineligible',
      value: 'ineligible'
    }
  ]
  public statusMenuOptions: SeFeMenuOptions = {
    name: 'statusMenu',
    maxHeight: 'auto'
  }
  public displayPersonaFilterCount = 0
  public displayStatusFilterCount = 0
  public showChipAvatar = false
  public sidePanelProfile: UserProfile
  public sidePanelEligibilityRuleSetId: string
  public sidePanelOrganizationId: number
  public usavPersonaEligibilities: PersonaEligibility[] = []

  private sorterSubscription: Subscription

  constructor(
    private membershipListItemsService: MembershipListItemsService,
    private householdProfilesService: HouseholdProfilesService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  private get selectedPersonFilters(): any[] {
    if (this.form.get('personFilters').value) {
      return Object.values(this.form.get('personFilters').value).filter(value => value)
    }
    return []
  }

  private get selectedStatusFilters(): any[] {
    if (this.form.get('statusFilters').value !== null) {
      return this.form.get('statusFilters').value
    }
    return []
  }

  public ngOnInit(): void {
    this.form = new FormGroup({
      statusFilters: new FormControl(),
      personFilters: new FormGroup({})
    })
  }

  public async ngAfterViewInit(): Promise<void> {
    this.profiles = await this.householdProfilesService.findMyProfiles('manager').toPromise()
    this.listItems = await this.membershipListItemsService.getListItems(this.profiles.map((profile) => profile.persona.id))
    this.listItems = this.transformData()
    this.usavPersonaEligibilities = this.listItems
      .map((listItem) => listItem.personaEligibility)
      .filter((pe) =>
        pe.organization_ids.includes(22518) ||
        pe.eligibility_credentials.find((ec: any) => ec.linked_eligibility_rule_set?.boss_organization?.id === 22518)
      )
    this.dataSource = new SeFeTableDataSource(this.listItems, this.sorter)
    this.table.dataSource = this.dataSource
    this.sorterSubscription = this.sorter.seFeSortChange.subscribe((event) => this.onSort(event))
    this.profiles.map((profile) => {
      this.personOptions[profile.persona.id] = {
        checkboxOpts: {
          label: profile.persona.full_name,
          value: profile.persona.id
        },
        image: this.setImageUrl(profile.persona)
      }
      const parentControl = this.form.get('personFilters') as FormGroup
      const control = new FormControl(false)
      parentControl.addControl(profile.persona.id.toString(), control)
    })
    this.checkQueryParams()
    this.ready = true
  }

  public ngOnDestroy(): void {
    this.sorterSubscription.unsubscribe()
  }

  public async applyFilters(): Promise<void> {
    this.setTitles()
    this.dataSource.data = this.listItems
    await this.applyPersonFilters()
    await this.applyStatusFilters()
    this.menus.forEach((menu) => menu.visible && menu.closeMenu())
  }

  public clearAllFilters(): void {
    this.form.reset()
    this.applyFilters()
  }

  public clearFilter(formControlName?: string): void {
    this.form.get(formControlName).reset()
    this.applyFilters()
  }

  public async openPersonaEligibilitySidePanel(listItem: MembershipListItem): Promise<void> {
    if (this.isSidePanelTrigger(listItem)) {
      this.personaEligibilitySidePanel.close()
    } else {
      this.sidePanelProfile = this.profiles.find((profile) => profile.persona.id === listItem.personaEligibility.persona_id)
      this.sidePanelEligibilityRuleSetId = listItem.personaEligibility.eligibility_rule_set_id
      this.sidePanelOrganizationId = listItem.personaEligibility.eligibility_rule_set_organization_id
      setTimeout(() => this.personaEligibilitySidePanel.open())
      await this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { eligibilityRuleSetId: this.sidePanelEligibilityRuleSetId },
        queryParamsHandling: 'merge'
      })
    }
  }

  public async onPersonaEligibilitySidePanelClose(): Promise<void> {
    this.sidePanelProfile = null
    this.sidePanelEligibilityRuleSetId = null
    this.sidePanelOrganizationId = null
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { eligibilityRuleSetId: null, background_screen: null, custom_credential_configuration: null },
      queryParamsHandling: 'merge'
    })
  }

  public isSidePanelTrigger(listItem: MembershipListItem): boolean {
    const { eligibility_rule_set_id, persona_id } = listItem.personaEligibility
    return this.sidePanelProfile?.persona?.id === persona_id && this.sidePanelEligibilityRuleSetId === eligibility_rule_set_id
  }

  public setChipAvatar(): void {
    if (this.selectedPersonFilters.length === 1) {
      const profile = this.profiles.find((p) => this.form.get('personFilters').value[p.persona.id.toString()])
      this.chipPersonaOpts = {
        identity: profile?.persona?.full_name,
        imgURL: this.setImageUrl(profile?.persona)
      }
      this.showChipAvatar = true
    } else {
      this.showChipAvatar = false
    }
  }

  private checkQueryParams(): void {
    const params = window.location.href.split('?')
    const urlParams = new URLSearchParams(params[1])
    const eligibilityRuleSetId = urlParams.get('eligibilityRuleSetId')
    const personFilters = urlParams.get('personFilters')
    const statusFilters = urlParams.get('statusFilters')
    if (eligibilityRuleSetId && personFilters) {
      const personFilterList = personFilters.split(',').map(p => Number(p))
      const listItemToOpen = this.listItems.find((listItem) =>
        personFilterList.includes(listItem.personaEligibility.persona_id) &&
        listItem.personaEligibility.eligibility_rule_set_id === eligibilityRuleSetId
      )
      this.openPersonaEligibilitySidePanel(listItemToOpen)
    }
    if (personFilters) {
      personFilters.split(',').forEach((id) => this.form.get('personFilters').get(id).setValue(true))
    }
    if (statusFilters) {
      this.form.get('statusFilters').setValue(statusFilters.split(','))
    }
    this.applyFilters()
  }

  private setTitles(): void {
    const firstName = this.getPersonFilterFirstName()
    const capitalizedStatus = this.selectedStatusFilters.length ? this.capitalizeFirstLetter(this.selectedStatusFilters[0]) : ''
    this.personFilterTitle = firstName || 'Person'
    this.displayPersonaFilterCount = this.selectedPersonFilters?.length || 0
    this.displayStatusFilterCount = this.selectedStatusFilters?.length
    this.statusFilterTitle = (this.selectedStatusFilters?.length === 1 ? capitalizedStatus : 'Status')
    this.refreshEmptyStateTitle = firstName ? `No results found for ${firstName}` : 'No results found'
  }

  private capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  private getPersonFilterFirstName(): string {
    if (this.selectedPersonFilters.length === 1) {
      return this.profiles.find((profile) => this.form.get('personFilters').value[`${profile.persona.id}`])?.persona.first_name
    }
    return null
  }

  private async applyPersonFilters(): Promise<void> {
    this.setChipAvatar()
    if (this.atLeastOnePersonaSelected()) {
      this.dataSource.data = this.dataSource.data.filter(item => {
        const pId = item.personaEligibility.persona_id.toString()
        return this.form.get('personFilters').value[pId] === true
      })
    }
    const personFilters = Object.entries(this.form.value.personFilters)
      .filter(([key, value]) => value).map(([key]) => key).join() || null
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { personFilters },
      queryParamsHandling: 'merge'
    })
  }

  private atLeastOnePersonaSelected(): boolean {
    return Object.values(this.form.get('personFilters').value).some(value => value === true)
  }

  private async applyStatusFilters(): Promise<void> {
    if (this.form.value.statusFilters?.length) {
      const filters = [...this.form.value.statusFilters]
      if (filters.includes('eligible')) {
        filters.push('upcoming')
      }
      if (filters.includes('ineligible')) {
        filters.push('incomplete', 'requested')
      }
      this.dataSource.data = this.dataSource.data.filter((listItem) =>
        filters.includes(listItem.eligibilityStatus)
      )
    }
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { statusFilters: this.form.value.statusFilters?.join() },
      queryParamsHandling: 'merge'
    })
  }

  private transformData(): any {
    return this.listItems.map((listItem: any) => ({
      org: listItem.personaEligibility.eligibility_rule_set.boss_organization,
      profileImage: this.profileImage(listItem.personaEligibility),
      ...listItem
    }))
  }

  private profileImage(personaEligibility: PersonaEligibility): string {
    if (personaEligibility.profile_image) {
      const cropIcon = personaEligibility.profile_image.find((image: any) => image.image_type === 'crop_icon')
      if (cropIcon && cropIcon.url) {
        return cropIcon.url.replace(/_https/, '')
      }
    }
    return ''
  }

  private setImageUrl(persona: Persona): string {
    const profileImages = persona?.profile_images
    const imageUrl = (profileImages || []).find(i => i.image_type === 'crop')?.url
    return imageUrl
  }

  private onSort(data: any): void {
    if (!data.direction) {
      this.dataSource.data = this.membershipListItemsService.sortEligibilityStatus(this.dataSource.data as any)
    } else if (data.column === 'membership') {
      this.sort(['title'], data)
    } else if (data.column === 'status') {
      this.sort(['chip', 'title'], data)
    } else if (data.column === 'organization') {
      this.sort(['org', 'name'], data)
    } else if (data.column === 'validityDates') {
      this.sort(['triggerCredential', 'effective_at'], data)
    } else if (data.column === 'person') {
      this.sort(['personaEligibility', 'name'], data)
    }
  }

  private sort(fields: string[], data: any): void {
    const isAsc = data.direction === 'asc'
    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      const first = fields.reduce((acc, cur) => acc[cur], a)
      const second = fields.reduce((acc, cur) => acc[cur], b)
      if (first > second) return isAsc ? 1 : -1
      if (first < second) return isAsc ? -1 : 1
      return 0
    })
  }

}
