import { Component, OnInit } from '@angular/core'

import { AnalyticsService } from '@se-po/shared-data-access-services'
import { TeamService } from '../../services/team.service'

@Component({
  selector: 'app-dashboard-teams',
  templateUrl: './dashboard-teams.component.html',
  styleUrls: ['./dashboard-teams.component.scss']
})
export class DashboardTeamsComponent implements OnInit {
  public loaded = false
  public teams: any[] = []
  public descriptions: any = { }
  public teamAttrs: any = { }

  constructor(
    private analyticsService: AnalyticsService,
    private teamService: TeamService
  ) { }

  public ngOnInit(): void {
    this.loadTeams()
  }

  public fireAnalytics(): void {
    this.analyticsService.seEvent('MyTeams.SeeAll.Click', 8)
  }

  private loadTeams(): void {
    this.teamService.findMine({ per_page: 5 }).subscribe(r => {
      r.forEach(t => this.teamAttrs[t.id] = { href: t.url })
      this.teams = r
      this.loaded = true
    })
  }
}
