import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SeFeButtonModule } from 'se-fe-button'
import { SeFeEmptyStateModule } from 'se-fe-empty-state'
import { SeFeHeaderModule } from 'se-fe-header'
import { SeFeIconModule, seFeIconSpotWhistle } from 'se-fe-icon'
import { SeFeListModule } from 'se-fe-list'
import { SeFeSpinnerModule } from 'se-fe-spinner'
import { TeamListComponent } from './team-list/team-list.component'

@NgModule({
  imports: [
    CommonModule,
    SeFeButtonModule,
    SeFeEmptyStateModule,
    SeFeHeaderModule,
    SeFeIconModule.include([
      seFeIconSpotWhistle
    ]),
    SeFeListModule,
    SeFeSpinnerModule
  ],
  declarations: [
    TeamListComponent
  ],
  exports: [
    TeamListComponent
  ]
})
export class SharedFeatureTeamListModule {}
