<div class="pl-text--right">
  <span
      [textContent]="'PAGINATION.range_shown' | translate: {
        start: (start | number),
        end: (end | number)
      }"
      class="se-link"
      [seFeMenuTrigger]="menuOptions.name">
  </span>
  <span [textContent]="'PAGINATION.range_label' | translate: {
    total: (total | number),
    label: pluralLabel
  }"></span>
  <se-fe-menu
    [seFeDataOptions]="menuOptions"
  >
  </se-fe-menu>
  <span>
    <se-fe-icon-button [seFeDataIsDisabled]="!!metadata?.first_page" (click)="back()">
      <se-fe-icon
        seFeDataName="chevron_left"
        seFeDataSize="700"
        seFeDataAriaLabel="'PAGINATION.previous' | translate"
        seFeDataMargin="false">
      </se-fe-icon>
    </se-fe-icon-button>
    <se-fe-icon-button [seFeDataIsDisabled]="!!metadata?.last_page" (click)="forward()">
      <se-fe-icon
        seFeDataName="chevron_right"
        seFeDataSize="700"
        seFeDataAriaLabel="'PAGINATION.next' | translate"
        seFeDataMargin="false">
      </se-fe-icon>
    </se-fe-icon-button>
  </span>
</div>


